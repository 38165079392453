/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Swal from "sweetalert2";
import NotesContext from "AppContext";
import AppointmentModal from "layouts/dashboards/appointments/components/AppointmentModal";
import { Box, CircularProgress, Grid, Icon, Stack, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Echo from "laravel-echo";
import AuthButton from "components/Buttons/AuthButton";
import { pusherOptions } from "common/helpers";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PaperPlane from "../../../assets/images/authentication_v2/PaperPlane.svg";
import avatarChat from "../../../assets/images/authentication_v2/avatarChat.svg";
import "./Chat.css";

const theme = createTheme({
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: "#FF7701",
        },
      },
    },
  },
});
function Note({
  customerUser,
  setCustomerUser,
  appointment,
  refreshList,
  setRefreshList,
  onlineUsers,
}) {
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const history = useHistory();
  const [uploadImages, setUploadImages] = useState([]);
  const [selectedApointmentId, setSelectedApointmentId] = useState(null);
  const useStyles = makeStyles(() => ({
    boxMessages: {},
    stackMessages: {
      width: "100%",
      minWidth: "230px",
      padding: "10px 7px 10px 15px",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "row",
      wordBreak: "break-word",
      overflowWrap: "normal",
      "@media (max-width: 425px)": {
        minWidth: "100px",
      },
    },
    messages: {
      border: "none",
      fontSize: "12px",
      fontWeight: "400",
      fontFamily: "Poppins",
      color: "#4A4A4A",
    },
    body: {
      width: "100%",
      height: "90%",
      marginTop: "5%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      overflowY: "scroll",
      padding: "0 4%",
      paddingBottom: "3%",
      "& .css-h4p8d0": {
        marginTop: "7%",
        marginBottom: "3%",
      },
    },
    input: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      backgroundColor: "white",
      border: "none",
    },
    sendButton: {
      background: "transparent",
      border: "0",
      cursor: "pointer",
      marginLeft: "5px",
    },
  }));
  const classes = useStyles();

  const [sendLoading, setSendLoading] = useState(false);
  const { totalNewNotes, setTotalNewNotes } = useContext(NotesContext);
  const [openAppointmentModal, setOpenAppointmentModal] = useState(false);
  // const handleOpenAppointmentModal = (pAppointmentId) => {
  //   setSelectedApointmentId(pAppointmentId);
  //   setOpenAppointmentModal(true);
  // };
  const handleCloseAppointmentModal = () => {
    setSelectedApointmentId(null);
    setOpenAppointmentModal(false);
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const fetchNotes = () => {
    axios
      .get(
        `/api/customers/${customerUser.customer.id}/campaign-leads/${appointment.campaign_lead.id}/notes/mark-notes-as-read`
      )
      .then((res) => {
        setTotalNewNotes(res.data && res.data.message && res.data.message);
        // setRefreshList(!refreshList);
      })
      .catch(() => {});

    axios
      .get(
        `/api/customers/${customerUser.customer.id}/campaign-leads/${appointment.campaign_lead.id}/notes/order-by/asc`
      )
      .then((res) => {
        const notesRes = res.data.data.map((r) => ({
          image: avatarChat,
          id: r.id,
          name: r.author,
          authorId: r.author_id,
          message: r.message,
          createdAt: r.created_at,
          canBeDeleted: r.can_be_deleted,
          attachments: r.attachments,
        }));
        setNotes(notesRes);
      })
      .catch(() => {
        setNotes([]);
      });
  };

  const eraseNote = (id) => {
    axios
      .delete(
        `/api/customers/${customerUser.customer.id}/campaign-leads/${appointment.campaign_lead.id}/notes/${id}/delete`
      )
      .then(() => {
        fetchNotes();
      })
      .catch(() => {
        setNotes([]);
      });
  };

  useEffect(() => {
    const echo = new Echo(pusherOptions);

    echo.private(`chat.customer.${customerUser.customer.id}`).listen(".new-note", (e) => {
      if (
        e.customer_id === customerUser.customer.id &&
        e.note.author_id !== JSON.parse(atob(localStorage.getItem("auth_user"))).id &&
        e.note.campaign_lead_id === appointment.campaign_lead.id
      ) {
        const r = e.note;
        setNotes((current) => [
          ...current,
          {
            image: avatarChat,
            id: r.id,
            name: "Agent",
            authorId: r.author_id,
            message: r.message,
            createdAt: r.created_at,
            canBeDeleted: false,
            attachments: r.attachments,
          },
        ]);
      }
    });
    return () => {
      echo.leave(`chat.customer.${customerUser.customer.id}`);
    };
  }, []);
  const inputRef = useRef(null);

  useEffect(() => {
    fetchNotes();
    return () => {};
  }, [appointment, customerUser, totalNewNotes, openAppointmentModal]);

  useEffect(() => {
    scrollToBottom();
    return () => {};
  }, [notes]);

  const onSubmitNote = () => {
    setSendLoading(true);
    const payload = new FormData();
    payload.append("note", note);

    uploadImages.forEach((image) => {
      payload.append("images[]", image);
    });
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaign-leads/${appointment.campaign_lead.id}/create-note`,
        payload
      )
      .then(({ data }) => {
        setSendLoading(false);
        const r = data.data;
        fetchNotes();
        setNote("");
        setUploadImages([]);
        if (inputRef.current) {
          inputRef.current.value = "";
        }
      })
      .catch(() => {
        setSendLoading(false);
        fetchNotes();
        setNotes([]);
        setUploadImages([]);
        setNote("");

        if (inputRef.current) {
          inputRef.current.value = "";
        }
      });
  };

  const handleRefreshList = () => {
    fetchNotes();
  };

  const handleEventClick = (pAppointment) => {
    if (pAppointment.review_status.includes("to_do")) {
      Swal.fire({
        title: "Appointment Confirmation",
        text: "Do you want to confirm the appointment?",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, confirm it!",
      }).then((result) => {
        if (result.isConfirmed) {
          const context = {
            is_confirmation_form: "yes",
            review_status: "accepted",
          };
          axios
            .post(
              `/api/customers/${customerUser.customer.id}/campaigns/${pAppointment.campaign_id}/appointments/${pAppointment.id}/update-review-status`,
              context
            )
            .then(() => {
              fetchNotes();
              history.push({
                pathname: "/enrichment",
                state: {
                  appointmentIdParam: pAppointment.id,
                  customerUser: { customerUser },
                  campaignId: appointment.campaign_id,
                  appointmentId: selectedApointmentId,
                },
              });
            })
            .catch((err) => {
              Swal.fire("Error!", err.message, "error");
            });
        }
      });
    } else if (pAppointment.class_name.includes("error")) {
      Swal.fire("Warning!", "You are not allowed to access the overview page", "warning");
    } else {
      history.push({
        pathname: "/enrichment",
        state: {
          appointmentIdParam: pAppointment.id,
          customerUser: { customerUser },
          campaignId: appointment.campaign_id,
          appointmentId: selectedApointmentId,
        },
      });
    }
  };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    setUploadImages((prevImages) => [...prevImages, ...files]);
  };

  const handleInputChange = (event) => {
    setNote(event.target.value);
  };

  return (
    <Grid container>
      {openAppointmentModal && selectedApointmentId ? (
        <AppointmentModal
          customerUser={customerUser}
          campaignId={appointment.campaign_id}
          appointmentId={selectedApointmentId}
          open={openAppointmentModal}
          handleClose={handleCloseAppointmentModal}
          refreshList={handleRefreshList}
          redirectionEnabled={false}
        />
      ) : (
        ""
      )}
      <Stack sx={{ width: "100%" }}>
        <Stack sx={{ width: "100%", height: "70vh" }} direction="column">
          <Stack
            sx={{ width: "100%" }}
            direction={{ sm: "column", md: "row" }}
            alignItems="center"
            han
            justifyContent="space-between"
          >
            <Typography fontFamily="Poppins" fontSize="18px" fontWeight="700" color="#4A4A4A">
              {appointment.lead_identificator.replace("Firmenname: ", "")}
            </Typography>
            <Stack>
              {appointment && !appointment.class_name.includes("info") && (
                <AuthButton
                  backgroundColor="#FF4D00D9"
                  onClick={() => handleEventClick(appointment)}
                >
                  Appointment
                </AuthButton>
              )}
            </Stack>
          </Stack>
          <Box className={classes.body}>
            {!appointment.review_status.includes("to_do") ? (
              notes &&
              notes.length > 0 &&
              notes.map(({ image, id, name, message, canBeDeleted, authorId, attachments }) => (
                <Box
                  key={id}
                  display="flex"
                  alignItems={name === "me" ? "flex-end" : "flex-start"}
                  flexDirection="column"
                  mt={2}
                >
                  <Box
                    className={classes.boxMessages}
                    sx={
                      name === "me"
                        ? {
                            display: "flex",
                            flexDirection: "row-reverse",
                            gap: "15px",
                          }
                        : {
                            display: "flex",
                            flexDirection: "row",
                            gap: "15px",
                          }
                    }
                  >
                    <div style={{ position: "relative" }}>
                      {name !== "me" && (
                        <div
                          className={
                            onlineUsers.includes(authorId)
                              ? "online-indicator"
                              : "offline-indicator"
                          }
                        >
                          <span className="blink" />
                        </div>
                      )}
                      <img src={image} alt={name} />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      {message !== null && (
                        <Stack
                          sx={{
                            backgroundColor: name === "me" ? "#FF4D00D9" : "#F7F7F7",
                            marginBottom: "10px", // Space between message and images
                          }}
                          className={classes.stackMessages}
                        >
                          <Typography
                            sx={{
                              color: name === "me" ? "white !important" : "#4A4A4A !important",
                            }}
                            className={classes.messages}
                          >
                            {ReactHtmlParser(message)}
                          </Typography>
                        </Stack>
                      )}
                      {attachments.length > 0 && (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          {attachments.map((img) => (
                            <a
                              key={img.id}
                              href={`${process.env.REACT_APP_API_URL}${img.url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ margin: "5px 0" }}
                            >
                              <img
                                src={`${process.env.REACT_APP_API_URL}${img.url}`}
                                alt={img.filename}
                                style={{
                                  width: "200px",
                                  height: "150px",
                                }}
                              />
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                    <Stack flexDirection="row" alignItems="center">
                      {canBeDeleted ? (
                        <Typography onClick={() => eraseNote(id)} sx={{ cursor: "pointer" }}>
                          <Icon sx={{ color: "#5F5F5FCC", fontSize: "15px" }}>delete</Icon>
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Stack>
                  </Box>
                </Box>
              ))
            ) : (
              <Grid container>
                <Grid item xs={12} textAlign="center">
                  <AuthButton onClick={() => handleEventClick(appointment)}>
                    Approve&nbsp;<Icon sx={{ fontWeight: "bold" }}>check</Icon>
                  </AuthButton>
                </Grid>
              </Grid>
            )}
            <div ref={messagesEndRef} />
          </Box>
        </Stack>
        <Grid container spacing={1} mt={2}>
          {uploadImages.map((image, index) => (
            <Grid item key={index}>
              <img
                src={URL.createObjectURL(image)}
                alt={`preview-${index}`}
                style={{ maxWidth: "50px", maxHeight: "100px", margin: "5px" }}
              />
            </Grid>
          ))}
        </Grid>
        {appointment &&
        !appointment.review_status.includes("to_do") &&
        !appointment.class_name.includes("error") ? (
          <Stack direction="row" alignItems="center" mb={{ xs: 10, lg: 5, sm: 10 }}>
            <form className={classes.input}>
              <input
                style={{
                  border: "0",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "5px",
                  paddingTop: "2%",
                  paddingBottom: "2%",
                  paddingLeft: "2%",
                  width: "100%",
                  outline: "none",
                }}
                ref={inputRef}
                type="text"
                placeholder="Write here..."
                value={note}
                onChange={handleInputChange}
                maxLength={123}
              />
              <Grid item sx={{ textAlign: "right", marginLeft: "5px" }}>
                <input
                  type="file"
                  id="image-upload"
                  style={{ display: "none" }}
                  multiple
                  onChange={handleImageUpload}
                />
                <label
                  htmlFor="image-upload"
                  style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                >
                  {/* {upload} */}
                  <CloudUploadIcon style={{ color: "#FF4D00D9" }} size="100%" />
                </label>
              </Grid>
              {appointment &&
                !appointment.review_status.includes("to_do") &&
                !appointment.class_name.includes("error") &&
                (sendLoading ? (
                  <ThemeProvider theme={theme}>
                    <Stack width="4%" ml={1}>
                      <CircularProgress size="100%" />
                    </Stack>
                  </ThemeProvider>
                ) : (
                  <button className={classes.sendButton} type="submit" onClick={onSubmitNote}>
                    <img src={PaperPlane} alt="Send" />
                  </button>
                ))}
            </form>
          </Stack>
        ) : (
          ""
        )}
      </Stack>
    </Grid>
  );
}

Note.propTypes = {
  customerUser: PropTypes.objectOf(PropTypes.object).isRequired,
  setCustomerUser: PropTypes.func.isRequired,
  appointment: PropTypes.objectOf(PropTypes.object).isRequired,
  refreshList: PropTypes.bool.isRequired,
  setRefreshList: PropTypes.func.isRequired,
  onlineUsers: PropTypes.array.isRequired,
};

export default Note;
