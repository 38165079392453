import React from "react";
import PropTypes from "prop-types";

function DateTimeFormatterV2({ dateTime }) {
  const ensureDate = (date) => (date instanceof Date ? date : new Date(date));
  function formatDateToEuropeanWithTime(date) {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const datetime = ensureDate(date).toLocaleDateString("ge-GE", options);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${datetime}, ${hours}:${minutes}`;
  }

  const formattedDateTime = formatDateToEuropeanWithTime(new Date(dateTime));

  return <span>{formattedDateTime}</span>;
}

DateTimeFormatterV2.propTypes = {
  dateTime: PropTypes.instanceOf(Date).isRequired,
};

export default DateTimeFormatterV2;
