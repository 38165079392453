import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

function TagHistoryRel({ othersData }) {
  const useStyles = makeStyles(() => ({
    TableContainerWebsite: {
      borderRadius: "0px 0px 3.5px 3.5px",
      marginBottom: "5%",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      // padding: "0 2%",
      maxWidth: "90%",
      maxHeight: "400px",
      overflowY: "auto",
      overflowX: "hidden",
    },
    tableCell: {
      borderBottom: "0",
      margin: "0",
    },
    table: {
      fontSize: "14px",
      fontWeight: "400",
      // borderTop: "1px solid #b8bdbd",
    },
    tableRow: { borderBottom: "1px solid #50555C" },
    box: {
      color: "black",
      marginBottom: "4px",
      marginTop: "4px",
      padding: "2px 0 2px 6px",
      borderRadius: "3px",
      backgroundColor: "#FF8800 !important",
    },
    typeValue: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    noInfo: { padding: "15px" },
    whiteSpace: { whiteSpace: "nowrap" },
  }));
  const classes = useStyles();
  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "#FF7701",
          },
        },
      },
    },
  });
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#FF8800",
      color: "#fff",
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #FF8800",
      borderRadius: "5px",
    },
  }));

  function calculateDays(firstTimestamp, lastTimestamp) {
    const diffInMilliseconds = lastTimestamp - firstTimestamp;
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const diffInDays = diffInMilliseconds / millisecondsPerDay;
    return Math.round(diffInDays);
  }

  let maxDays = -1;

  Object.values(othersData).map((value) => {
    let itemMaxDays = -1;
    Object.values(value).map((item) => {
      const days = calculateDays(item.first_detected, item.last_detected);
      if (days > itemMaxDays) {
        itemMaxDays = days;
      }
      return itemMaxDays;
    });
    if (itemMaxDays > maxDays) {
      maxDays = itemMaxDays;
    }
    return maxDays;
  });

  function calculateWidth(firstDetected, lastDetected) {
    const days = calculateDays(firstDetected, lastDetected);
    const width = (days * 100) / maxDays;

    return width;
  }

  function calculateDuration(firstDate, lastDate) {
    const startDate = new Date(firstDate);
    const endDate = new Date(lastDate);

    const diffInMilliseconds = endDate.getTime() - startDate.getTime();
    const totalSeconds = Math.floor(diffInMilliseconds / 1000);
    const years = Math.floor(totalSeconds / (365 * 24 * 60 * 60));
    const months = Math.floor((totalSeconds % (365 * 24 * 60 * 60)) / (30 * 24 * 60 * 60));
    const days = Math.floor((totalSeconds % (30 * 24 * 60 * 60)) / (24 * 60 * 60));

    return `${years} years, ${months} months, ${days} days`;
  }

  return (
    <ThemeProvider theme={theme}>
      <Typography fontSize="14px" fontWeight="500" mb={2}>
        TAG HISTORY & RELATIONSHIP
      </Typography>
      <TableContainer className={classes.TableContainerWebsite} component={Paper}>
        <Table>
          {othersData && Object.keys(othersData).length > 0 && (
            <TableBody>
              {Object.entries(othersData).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell className={classes.table} width="10%" component="th" scope="row">
                    {key}
                  </TableCell>
                  <TableCell className={classes.whiteSpace}>
                    {Object.values(value).map((label) => (
                      <HtmlTooltip
                        title={
                          <Box>
                            <Box color="inherit">
                              {label.type}-{label.value}
                            </Box>
                            <Box>
                              {key} :{" "}
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "short",
                              }).format(label.first_detected)}{" "}
                              -{" "}
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "short",
                              }).format(label.last_detected)}
                            </Box>
                            <Box>
                              Duration :
                              {calculateDuration(label.first_detected, label.last_detected)}
                            </Box>
                          </Box>
                        }
                      >
                        <Box
                          sx={{
                            width: `${calculateWidth(label.first_detected, label.last_detected)}%`,
                          }}
                          className={classes.box}
                        >
                          <Typography>
                            {label.type}-{label.value}
                          </Typography>
                        </Box>
                      </HtmlTooltip>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {othersData && Object.keys(othersData).length === 0 && (
          <Typography className={classes.noInfo}>No information yet!</Typography>
        )}
      </TableContainer>
    </ThemeProvider>
  );
}

TagHistoryRel.propTypes = {
  othersData: PropTypes.array.isRequired,
};

export default TagHistoryRel;
