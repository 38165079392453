import PropTypes from "prop-types";

function ProfileIcon({ color }) {
  return (
    <svg width="34" height="34" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.4844 12.2129C19.4844 13.1987 19.1921 14.1624 18.6444 14.9821C18.0967 15.8017 17.3182 16.4406 16.4074 16.8179C15.4967 17.1951 14.4945 17.2938 13.5276 17.1015C12.5607 16.9092 11.6726 16.4345 10.9755 15.7374C10.2784 15.0403 9.80373 14.1522 9.6114 13.1853C9.41908 12.2184 9.51779 11.2162 9.89504 10.3055C10.2723 9.39468 10.9112 8.61623 11.7308 8.06854C12.5505 7.52084 13.5142 7.22852 14.5 7.22852C15.8215 7.23002 17.0884 7.75563 18.0228 8.69006C18.9573 9.62449 19.4829 10.8914 19.4844 12.2129ZM26.2813 13.1191C26.2813 15.4493 25.5903 17.727 24.2958 19.6645C23.0012 21.6019 21.1612 23.1119 19.0085 24.0036C16.8558 24.8953 14.4869 25.1286 12.2016 24.674C9.91626 24.2194 7.81704 23.0974 6.1694 21.4497C4.52177 19.8021 3.39971 17.7029 2.94513 15.4176C2.49055 13.1322 2.72385 10.7634 3.61555 8.61065C4.50724 6.45791 6.01728 4.61793 7.95469 3.32339C9.89211 2.02885 12.1699 1.33789 14.5 1.33789C17.6236 1.34119 20.6183 2.58348 22.827 4.79218C25.0357 7.00088 26.278 9.99557 26.2813 13.1191ZM24.4688 13.1191C24.4673 11.7774 24.1953 10.4497 23.6689 9.21542C23.1426 7.98119 22.3727 6.86578 21.4054 5.9359C20.4381 5.00602 19.2932 4.28077 18.0392 3.80353C16.7851 3.32628 15.4477 3.10684 14.1069 3.15832C8.77137 3.36449 4.51653 7.80852 4.53126 13.1475C4.53637 15.5779 5.43277 17.9221 7.05063 19.7359C7.7095 18.7803 8.54644 17.9606 9.51563 17.3219C9.59826 17.2673 9.6965 17.2413 9.79531 17.2478C9.89412 17.2544 9.98807 17.2931 10.0628 17.3581C11.2943 18.4234 12.8683 19.0096 14.4966 19.0096C16.125 19.0096 17.6989 18.4234 18.9304 17.3581C19.0051 17.2931 19.0991 17.2544 19.1979 17.2478C19.2967 17.2413 19.395 17.2673 19.4776 17.3219C20.448 17.9603 21.2861 18.7799 21.946 19.7359C23.5718 17.9155 24.47 15.5599 24.4688 13.1191Z"
        fill={color}
        fillOpacity="0.8"
      />
    </svg>
  );
}

ProfileIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ProfileIcon;
