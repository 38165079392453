import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Stack, Typography } from "@mui/material";
import axios from "axios";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TagHistoryTable from "./TagHistoryTable";
import WebsiteTable from "./WebsiteTable";
// import NotFound from "./NotFound";
import TagHistoryRel from "./TagHistoryRel";
import IpAddressHistoryRelationshpis from "./IpAddressHistoryRelationshpis";

function Relationship({ customerUser, appointmentId }) {
  const [otherData, setOtherData] = useState([]);
  const [tagHistoryData, setTagHistoryData] = useState([]);
  const [loadingRel, setLoadingRel] = useState(false);
  const [originalDomain, setOriginalDomain] = useState("");
  const [ipAddressHistory, setIpAddressHistory] = useState([]);

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "#FF7701",
          },
        },
      },
    },
  });

  useEffect(() => {
    if (appointmentId !== undefined && appointmentId) {
      setLoadingRel(true);
      axios
        .get(
          `/api/customers/${customerUser.customerUser.customer.id}/appointments/${appointmentId}/builtwith/relationship`
        )
        .then(async (response) => {
          if (response) {
            setOtherData(response.data.others);
            setTagHistoryData(response.data.tagHistory);
            setOriginalDomain(response.data.domain);
            setIpAddressHistory(response.data.ip);
          }
          setLoadingRel(false);
        })
        .catch((error) => {
          setLoadingRel(false);
          console.error(error);
        });
    }
  }, [appointmentId]);

  return loadingRel ? (
    <ThemeProvider theme={theme}>
      <Stack mt="15%" flexDirection="column" alignItems="center">
        <CircularProgress size="5%" />
      </Stack>
    </ThemeProvider>
  ) : (
    <>
      <Container fixed style={{ marginTop: "30px" }}>
        {otherData || tagHistoryData ? (
          <>
            {originalDomain ? (
              <Typography fontSize="25px" mb={4}>
                {originalDomain}
              </Typography>
            ) : (
              ""
            )}
            <Grid container>
              <Grid item mb={2} sm={12} md={6}>
                <TagHistoryTable tagHistoryData={tagHistoryData} />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <WebsiteTable websiteData={otherData} />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TagHistoryRel othersData={otherData} />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <IpAddressHistoryRelationshpis
                  originalDomain={originalDomain}
                  ipAddressHistory={ipAddressHistory}
                />
              </Grid>
            </Grid>
          </>
        ) : null}
      </Container>
    </>
  );
}

Relationship.propTypes = {
  customerUser: PropTypes.any.isRequired,
  appointmentId: PropTypes.number.isRequired,
};

export default Relationship;
