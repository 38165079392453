import { Button, CircularProgress, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function AuthButton({ children, fontSize, onClick, backgroundColor, loading, disabled }) {
  return (
    <Button
      sx={{
        width: "100%",
        backgroundColor: { backgroundColor },
        borderRadius: "5px",
        "&:hover": {
          backgroundColor: { backgroundColor },
        },
      }}
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    >
      {loading ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        <Typography color="#fff" fontSize={fontSize} fontWeight="600" fontFamily="Poppins">
          {children}
        </Typography>
      )}
    </Button>
  );
}

AuthButton.propTypes = {
  children: PropTypes.string.isRequired,
  fontSize: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default AuthButton;
