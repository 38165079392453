/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useRef, useEffect } from "react";
// import Divider from "@mui/material/Divider";
import axios from "axios";
import PropTypes from "prop-types";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box, CircularProgress, Grid, Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import ReactQuill from "react-quill";
import PaperPlane from "../../../../../assets/images/authentication_v2/PaperPlane.svg";

const theme = createTheme({
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: "#FF7701",
        },
      },
    },
  },
});

function Editor({ noteId, message, appointment, customerUser, forceUpdateComponent }) {
  const [note, setNote] = useState("");
  const [sendLoading, setSendLoading] = useState(false);
  const inputRef = useRef(null);

  const onSubmitNote = (id = null) => {
    setSendLoading(true);
    let payload = {
      comment: note,
    };
    if (id) {
      payload = {
        id,
        ...payload,
      };
    }
    axios
      .post(
        `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${appointment.campaign_lead.campaign_id}/oic/${appointment.id}/create-comment`,
        payload
      )
      .then(() => {
        setSendLoading(false);
        setNote("");

        if (inputRef.current) {
          inputRef.current.value = "";
        }

        forceUpdateComponent(noteId);
      })
      .catch(() => {
        setSendLoading(false);
        if (inputRef.current) {
          inputRef.current.value = "";
        }
      });
  };

  const isEnabled = () => {
    if (note) return false;
    return true;
  };

  const useStyles = makeStyles(() => ({
    footer: {
      paddingTop: "1%",
      display: "flex",
      justifyContent: "flex-end",
    },
    input: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      backgroundColor: "white",
      paddingTop: "15px",
      // border: "1px solid #D1D5DB",

      // borderRadius: "35px",
      marginRight: "3%",
      marginLeft: "3%",
    },
    placeholder: {
      border: "none",
      width: "80%",
      padding: "1% 0 1% 0",
      background: "transparent",
      "&:focus": {
        border: "0",
        outline: "none",
      },
    },
    sendButton: {
      background: "transparent",
      border: "0",
      cursor: isEnabled() ? "default" : "pointer",
      marginLeft: "5px",
    },
    header: {
      padding: "1%",
      fontSize: "15px",
      fontWeight: "bold",
      color: "#000000",
      backgroundColor: "#F0F0F0",
      textAlign: "center",
    },
    cardContent: { margin: "0", padding: "0" },
    card: {
      borderRadius: "0px 0px 3.5px 3.5px",
      marginBottom: "5%",
      paddingBottom: "2%",
      // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    },
    body: {
      padding: "5%",
      maxWidth: "100%",
      maxHeight: "300px",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column-reverse",
    },
    stackMessages: {
      width: "100%",
      minWidth: "230px",
      padding: "10px 7px 10px 15px",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "row",
      wordBreak: "break-word",
      overflowWrap: "normal",
    },
    messages: {
      border: "none",
      fontSize: "12px",
      fontWeight: "400",
      fontFamily: "Poppins",
      color: "#4A4A4A",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (noteId) {
      setNote(message);
    }
  }, []);

  return (
    <Box className={classes.footer}>
      <form className={classes.input}>
        <Grid container flexDirection="column">
          <Grid item>
            <ReactQuill
              style={{ width: "100%" }} // Adjust minHeight as needed
              theme="snow"
              // ref={inputRef}
              value={note}
              onChange={setNote}
            />
          </Grid>
          <Grid item sx={{ textAlign: "right" }}>
            {sendLoading ? (
              <ThemeProvider theme={theme}>
                <Stack width="3%" ml="auto" mt={1}>
                  <CircularProgress size="100%" />
                </Stack>
              </ThemeProvider>
            ) : (
              <button
                className={classes.sendButton}
                disabled={isEnabled()}
                type="submit"
                onClick={() => onSubmitNote(noteId)}
              >
                <img src={PaperPlane} alt="Send" />
              </button>
            )}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default Editor;

Editor.defaultProps = {
  noteId: null,
  message: null,
};

Editor.propTypes = {
  noteId: PropTypes.number,
  message: PropTypes.string,
  appointment: PropTypes.objectOf(PropTypes.object).isRequired,
  customerUser: PropTypes.objectOf(PropTypes.object).isRequired,
  forceUpdateComponent: PropTypes.func.isRequired,
};
