import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Checkbox, Grid, Stack, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import SuiTypography from "components/SuiTypography";
import AuthButton from "components/Buttons/AuthButton";
import LetterLogo from "assets/images/iconComponents/LetterLogo";
import authBG from "../../../assets/images/authentication_v2/authBG.svg";
import teleroidsArm from "../../../assets/images/authentication_v2/teleroidsArm.png";

function SignUp() {
  const useStyles = makeStyles(() => ({
    container: {
      backgroundImage: `url(${authBG})`,
      backgroundAttachment: "fixed",
      height: "100vh",
      width: "100%",
    },
    gridItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "2%",
    },
    boxCon: {
      backgroundColor: "white !important",
      width: "100%",
    },
    defaultTypo: { fontSize: "13px", fontFamily: "Poppins", fontWeight: "400" },
    footer: { fontWeight: "400", fontSize: "12px", fontFamily: "Poppins", padding: "3%" },
    forgot: { fontSize: "20px", fontFamily: "Poppins", fontWeight: "600", color: "#000000" },
    box: {
      backgroundColor: "#FF4D002B",
      width: "100%",
      padding: "7px 60px",
      textAlign: "center",
      fontSize: "12px",
      fontFamily: "Poppins",
      fontWeight: "600",
      color: "#4A4A4A",
      borderRadius: "5px",
    },
    agent: {
      fontFamily: "Poppins",
      fontSize: "26px",
      fontWeight: "600",
      borderBottom: "1px solid #00000026",
      paddingBottom: "3px",
      color: "#FF4D00D9",
      cursor: "pointer",
    },
    inputs: { width: "100%" },
    resetTypo: {
      color: "#00000096",
      fontSize: "12px",
      fontFamily: "Poppins",
      textTransform: "none",
      fontWeight: "400",
    },
    forgetTypo: { color: "#8D8D8D", fontSize: "12px", fontFamily: "Poppins", fontWeight: "600" },
  }));

  const classes = useStyles();
  const theme = createTheme({});

  const history = useHistory();
  const [code, setCode] = useState("");
  const [registrationLoading, setRegistrationLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setCode(params.get("code"));
    return () => {};
  }, []);

  const [agreement, setAgreement] = useState(true);

  const handleSetAgremment = () => setAgreement(!agreement);

  const [signUp, setSignUp] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    linkedin: "",
    xing: "",
    error_list: [],
  });
  const [linkedinUrlError, setLinkedinUrlError] = useState("");
  const [xingUrlError, setXingUrlError] = useState("");

  const validateLinkedinUrl = (inputUrl) => {
    const urlPattern = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[A-Za-z0-9_-]+\/?$/;
    return urlPattern.test(inputUrl);
  };

  const validateXingUrl = (inputUrl) => {
    const urlPattern = /^(https?:\/\/)?(www\.)?xing\.com\/profile\/[A-Za-z0-9_-]+\/?$/;
    return urlPattern.test(inputUrl);
  };

  const handleSignUpInput = (e) => {
    if (e.target.name === "linkedin") {
      if (!validateLinkedinUrl(e.target.value) && e.target.value !== "") {
        setLinkedinUrlError("Please set a valid LinkedIn url");
      } else {
        setLinkedinUrlError("");
        e.persist();
        delete signUp.error_list[e.target.name];
        setSignUp({ ...signUp, [e.target.name]: e.target.value });
      }
    } else if (e.target.name === "xing") {
      if (!validateXingUrl(e.target.value) && e.target.value !== "") {
        setXingUrlError("Please set a valid Xing url");
      } else {
        setXingUrlError("");
        e.persist();
        delete signUp.error_list[e.target.name];
        setSignUp({ ...signUp, [e.target.name]: e.target.value });
      }
    } else {
      e.persist();
      delete signUp.error_list[e.target.name];
      setSignUp({ ...signUp, [e.target.name]: e.target.value });
    }
  };

  const submitSignUp = (e) => {
    e.preventDefault();
    setRegistrationLoading(true);
    axios.get("/sanctum/csrf-cookie").then(() => {
      const data = {
        code,
        name: signUp.name,
        email: signUp.email,
        linkedin: signUp.linkedin,
        xing: signUp.xing,
        password: signUp.password,
        password_confirmation: signUp.password_confirmation,
      };
      axios
        .post("/api/register", data)
        .then((res) => {
          localStorage.removeItem("auth_token");
          localStorage.setItem("auth_user", btoa(JSON.stringify(res.data.user)));
          setRegistrationLoading(false);
          history.push("/authentication/verification/cover");
        })
        .catch((error) => {
          setSignUp({ ...signUp, error_list: [] });
          const errorData = error.response === undefined ? null : error.response.data;

          if (errorData && errorData.errors !== undefined) {
            setSignUp({ ...signUp, error_list: errorData.errors });
          }

          if (errorData && errorData.message !== undefined) {
            Swal.fire({
              title: "Error!",
              text: errorData.message,
              icon: "error",
            });
          }
          setRegistrationLoading(false);
          // return Promise.reject(error);
        });
    });
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid container className={classes.container}>
        <Grid item md={3.5} sm={2} xs={0.5} />
        <Grid item md={5} sm={8} xs={11} className={classes.gridItem}>
          <Stack direction="column" alignItems="center">
            <LetterLogo />
            <Typography className={classes.defaultTypo} mt={1} mb={5}>
              Hello There! Enter your email and password to Register
            </Typography>
          </Stack>
          <Grid item md={12} sm={12} xs={12} className={classes.boxCon}>
            <Grid item mt={-6} md={12} sm={12} xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  mt={3}
                  md={3}
                  sm={5}
                  xs={5}
                  sx={{
                    marginLeft: { xs: "28%", sm: "30%", md: "0", lg: "0" },
                  }}
                >
                  <img width="90%" src={teleroidsArm} alt="Logo" />
                </Grid>
                <Grid
                  item
                  md={6.5}
                  sm={12}
                  xs={12}
                  //   sx={{
                  //     marginLeft: { xs: "10%", sm: "20%", md: "0", lg: "0" },
                  //   }}
                >
                  <Typography textAlign="justify" className={classes.box}>
                    Please use the same email as the one that came in invitation!
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={3.2} xs={1.1} sm={2.1} />
              <Grid
                item
                md={6}
                sm={8}
                xs={10}
                sx={{
                  marginBottom: { xs: "0", sm: "4%", md: "0", lg: "0" },
                }}
              >
                <Stack mb={4}>
                  <Typography className={classes.defaultTypo}>Name</Typography>
                  <TextField
                    className={classes.inputs}
                    name="name"
                    type="name"
                    variant="standard"
                    onChange={handleSignUpInput}
                  />
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    color="error"
                  >
                    {signUp.error_list.name}
                  </SuiTypography>
                </Stack>
                <Stack mb={4}>
                  <Typography className={classes.defaultTypo}>Email</Typography>
                  <TextField
                    className={classes.inputs}
                    name="email"
                    type="email"
                    variant="standard"
                    onChange={handleSignUpInput}
                  />
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    color="error"
                  >
                    {signUp.error_list.email}
                  </SuiTypography>
                </Stack>
                <Stack mb={4}>
                  <Typography className={classes.defaultTypo}>LinkedIn</Typography>
                  <TextField
                    className={classes.inputs}
                    name="linkedin"
                    type="text"
                    variant="standard"
                    onChange={handleSignUpInput}
                  />
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    color="error"
                  >
                    {linkedinUrlError}
                    {signUp.error_list.linkedin}
                  </SuiTypography>
                </Stack>
                <Stack mb={4}>
                  <Typography className={classes.defaultTypo}>Xing</Typography>
                  <TextField
                    className={classes.inputs}
                    name="xing"
                    type="text"
                    variant="standard"
                    onChange={handleSignUpInput}
                  />
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    color="error"
                  >
                    {xingUrlError}
                    {signUp.error_list.xing}
                  </SuiTypography>
                </Stack>
                <Stack mb={4}>
                  <Typography className={classes.defaultTypo}>Password</Typography>
                  <TextField
                    className={classes.inputs}
                    name="password"
                    type="password"
                    variant="standard"
                    onChange={handleSignUpInput}
                  />
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    color="error"
                  >
                    {signUp.error_list.password}
                  </SuiTypography>
                </Stack>
                <Typography className={classes.defaultTypo}>Confirm Password</Typography>
                <TextField
                  sx={{ width: "100%" }}
                  name="password_confirmation"
                  type="password"
                  variant="standard"
                  onChange={handleSignUpInput}
                />
                <SuiTypography component="label" variant="caption" fontWeight="bold" color="error">
                  {signUp.error_list.password_confirmation}
                </SuiTypography>
                <Grid container alignItems="center">
                  <Grid item xs={3} md={1} sm={3.5} />
                  <Grid
                    container
                    mt={2}
                    mb={2}
                    xs={12}
                    sm={12}
                    md={12}
                    direction="row"
                    alignItems="center"
                  >
                    <Checkbox
                      checked={agreement}
                      onChange={handleSetAgremment}
                      sx={{ padding: "0", marginRight: 1 }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography
                      sx={{ cursor: "pointer" }}
                      variant="button"
                      onClick={handleSetAgremment}
                      className={classes.resetTypo}
                    >
                      I agree with the
                      <span style={{ color: "#5DB1FE", fontWeight: "500" }}> Terms</span> and
                      <span style={{ color: "#5DB1FE", fontWeight: "500" }}> Conditions</span>.
                    </Typography>
                    <Stack width="100%" mt={3}>
                      <ThemeProvider theme={theme}>
                        <AuthButton
                          loading={registrationLoading}
                          backgroundColor="#FF4D00D9"
                          fontSize="15px"
                          onClick={submitSignUp}
                          disabled={!agreement || xingUrlError || linkedinUrlError}
                        >
                          SIGN UP
                        </AuthButton>
                      </ThemeProvider>
                    </Stack>
                  </Grid>
                  <Grid item xs={2} md={3} sm={2} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Typography ml={4} className={classes.footer}>
            Copyright © 2023 CASA AKTIVA GmbH
          </Typography>
        </Grid>
        <Grid item md={3.5} sm={2} xs={0.5} />
      </Grid>
    </ThemeProvider>
  );
}

export default SignUp;
