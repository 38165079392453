import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Grid, Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import LetterLogo from "assets/images/iconComponents/LetterLogo";
import authBG from "../../../assets/images/authentication_v2/authBG.svg";
import teleroidsArm from "../../../assets/images/authentication_v2/teleroidsArm.png";
import ClientLogin from "./ClientLogin";
import AgentLogin from "./AgentLogin";

function CustomTabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
  };
}

function Authentication() {
  const [value, setValue] = React.useState(0);
  const [style, setStyle] = useState({
    border: "0 !important",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStyle({
      border: "0 !important",
    });
  };
  const useStyles = makeStyles(() => ({
    container: {
      backgroundImage: `url(${authBG})`,
      width: "100%",
      position: "fixed",
      right: 0,
    },
    gridItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "2%",
    },
    boxCon: {
      backgroundColor: "white !important",
      padding: "0 3%",
      width: "100%",
    },
    tabs: { width: "100%" },
    defaultTypo: { fontSize: "13px", fontFamily: "Poppins" },
    footer: { fontWeight: "400", fontSize: "12px", fontFamily: "Poppins", padding: "3%" },
  }));

  const classes = useStyles();
  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            display: "none",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDEDED",
            color: "#5F5F5F",
            fontFamily: "Poppins",
            padding: "0 7% 0 7%",
            borderRadius: "6px",
            textTransform: "none",
            marginRight: "-2%",
            fontSize: "13px",
            fontWeight: "600",
            "&.Mui-selected": {
              backgroundColor: "#FF4D00D9",
              color: "#fff",
              fontFamily: "Poppins",
              borderRadius: "6px",
              padding: "1% 7% 1% 7%",
              marginRight: "-2%",
              zIndex: "1",
              fontSize: "13px",
              fontWeight: "600",
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid container className={classes.container}>
        <Grid item md={3.5} sm={2} xs={0.5} />
        <Grid item md={5} sm={8} xs={11} className={classes.gridItem}>
          <Stack direction="column" alignItems="center">
            <LetterLogo />
            <Typography className={classes.defaultTypo} mt={3} mb={5}>
              Hello There! Enter your email and password to Sign In
            </Typography>
          </Stack>
          <Grid item md={12} sm={12} xs={12} className={classes.boxCon}>
            <Grid item md={12} sm={12} xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  md={3}
                  sm={3}
                  xs={5}
                  sx={{
                    marginLeft: { xs: "25%", sm: "0", md: "0", lg: "0" },
                  }}
                >
                  <img width="100%" src={teleroidsArm} alt="Logo" />
                </Grid>
                <Grid
                  item
                  md={9}
                  sm={9}
                  xs={12}
                  sx={{
                    marginLeft: { xs: "4%", sm: "0", md: "0", lg: "0" },
                  }}
                >
                  <Tabs className={classes.tabs} value={value} onChange={handleChange}>
                    <Tab
                      style={value === 0 ? style : {}}
                      label="Client Sign-In"
                      {...a11yProps(0)}
                    />
                    <Tab style={value === 1 ? style : {}} label="Agent Sign-In" {...a11yProps(1)} />
                  </Tabs>
                </Grid>
              </Grid>
              <CustomTabPanel value={value} index={0}>
                <ClientLogin />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <AgentLogin />
              </CustomTabPanel>
            </Grid>
          </Grid>
          <Typography className={classes.footer}>Copyright © 2023 CASA AKTIVA GmbH</Typography>
        </Grid>
        <Grid item md={3.5} sm={2} xs={0.5} />
      </Grid>
    </ThemeProvider>
  );
}

export default Authentication;
