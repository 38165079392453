import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, styled } from "@mui/material";
// import About from "../components/About";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CompanyIndex from "components/EnrichmentOic/components/CompanyIndex";
// import FieldInfo from "../components/FieldInfo";
import Appointment from "../components/Appointment/Appointment";
import arrowLeft from "../../../assets/images/enrichment/arrowLeft.svg";
import NotFound from "../components/NotFound";
// import TechTable from "../components/TechTable";

const StyledTabs = styled(Tabs)({
  borderBottom: "2px solid #FF7701",
});

const StyledTab = styled(Tab)({
  color: "#5C5C5C",
  fontWeight: "600",
  textTransform: "none",
  "&.Mui-selected": {
    color: "#FF7701",
  },
});

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "10px",
          backgroundColor: "rgba(237, 108, 2, 1)",
          "&:hover": {
            backgroundColor: "rgb(255 138 43)",
          },
        },
      },
    },
  },
});

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

TabPanel.defaultProps = {
  children: null,
};

const useStyles = makeStyles({
  header: {
    "@media (min-width: 450px) and (max-width:900px)": {
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
  },
  tab: {
    "@media (min-width: 320px) and (max-width:370px)": {
      marginLeft: "60px",
    },
    "@media (min-width: 375px) and (max-width:425px)": {
      marginLeft: "60px",
    },
  },
  button: {
    display: "none",
    "@media (max-width: 768px)": {
      display: "inline-block",
    },
  },
});

function Slider({
  companyInfo,
  aboutInfo,
  employeeInfo,
  setEmployeeInfo,
  customerUser,
  campaignId,
  appointmentId,
  loadingCompany,
}) {
  const history = useHistory();
  const [value, setValue] = useState(0);
  const companyIndexRef = useRef(null);

  useEffect(() => {
    localStorage.setItem("selectedTab", value);
  }, [value]);

  useEffect(() => {
    const storedValue = localStorage.getItem("selectedTab");
    setValue(storedValue ? parseInt(storedValue, 10) : 0);
  }, []);

  const handleBackButton = () => {
    history.goBack();
  };

  const handleEmployeeButtonClick = () => {
    if (companyIndexRef.current) {
      companyIndexRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const showEmployeeButton = () => value === 1;

  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", height: "100%", padding: "0" }}>
      <Grid
        container
        className={classes.header}
        alignItems="center"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Grid item xs={1} sm={1} md={2} lg={4}>
          <Button onClick={handleBackButton} sx={{ color: "#000000", textTransform: "none" }}>
            <img src={arrowLeft} alt="Arrow Left" />
            Back
          </Button>
        </Grid>
        <Grid item xs={5} sm={8} md={6} lg={4} className={classes.tab}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
          >
            <StyledTab label="Lead info" />
            <StyledTab label="Company Info" />
            {/* <StyledTab label="AI" /> */}
          </StyledTabs>
        </Grid>
        {showEmployeeButton() && (
          <Grid ml={2} item xs={1} sm={1} md={2} lg={4}>
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                onClick={handleEmployeeButtonClick}
                className={classes.button}
              >
                Employee
              </Button>
            </ThemeProvider>
          </Grid>
        )}
        <Grid item sm={1} md={2} lg={4} />
      </Grid>
      <TabPanel value={value} index={0}>
        <Appointment
          customerUser={customerUser}
          campaignId={campaignId}
          appointmentId={appointmentId}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {companyInfo.length > 0 || aboutInfo.description ? (
          <CompanyIndex
            customerUser={customerUser}
            ref={companyIndexRef}
            loadingCompany={loadingCompany}
            companyInfo={companyInfo}
            employeeInfo={employeeInfo}
            setEmployeeInfo={setEmployeeInfo}
            aboutInfo={aboutInfo}
            appointmentId={appointmentId}
          />
        ) : (
          <NotFound />
        )}
      </TabPanel>
    </Box>
  );
}

Slider.propTypes = {
  companyInfo: PropTypes.array.isRequired,
  aboutInfo: PropTypes.string.isRequired,
  employeeInfo: PropTypes.array.isRequired,
  setEmployeeInfo: PropTypes.any.isRequired,
  customerUser: PropTypes.object.isRequired,
  campaignId: PropTypes.object.isRequired,
  appointmentId: PropTypes.number.isRequired,
  loadingCompany: PropTypes.bool.isRequired,
};

export default Slider;
