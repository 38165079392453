import React, { useContext, useState } from "react";
import NotesContext from "AppContext";
import { Box, CardActions, CardMedia, Grid, Stack, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { createTheme } from "@mui/material/styles";
import MuiLink from "@mui/material/Link";
import profileImg from "../../../assets/images/enrichment/profileImg.svg";
import LinkedIn from "./iconComponents/LinkedIn";
import EmployeeTable from "./Table";
import EducationTable from "./EducationTable";
import Instagram from "./iconComponents/Instagram";
import Facebook from "./iconComponents/Facebook";
import Close from "../../../assets/images/enrichment/close.svg";

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

TabPanel.defaultProps = {
  children: null,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  modal: {
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    top: "0",
    bottom: "0",
    right: "0",
    left: "0",
    zIndex: "1000",
    overflowY: "auto",
  },
  skillWrapper: {
    columns: 2,
    columnGap: "1em",
  },
  containerBox: {
    position: "relative",
    top: "13%",
    left: "2%",
    backgroundColor: "white",
    border: "1px solid #FF7701",
    marginBottom: "8%",
    padding: "0%",
  },
  cardContent: {
    padding: "0",
    marginBottom: "10px",
    width: "85%",
    height: "35%",
    overflow: "hidden",
  },
  employeeName: { marginBottom: "5px" },
  employeeTitle: { fontSize: "16px", fontWeight: "400" },
  muiLink: { margin: "0", padding: "0" },
  close: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-end",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "10%",
    backgroundColor: "white",
  },
  keyName: {
    fontSize: "15px",
    fontWeight: "400",
  },
  valueName: {
    fontSize: "18px",
    fontWeight: "400",
    width: "90%",
    wordBreak: "break-word",
  },
  valueName1: {
    fontSize: "18px",
    fontWeight: "400",
    width: "100%",
  },
  profileImg: { width: 100, height: 100, margin: "0", marginTop: "6%" },
  valueNameLink: {
    textDecoration: "underline",
    fontSize: "18px",
    fontWeight: "400",
    cursor: "pointer",
  },
  link: { color: "black !important" },
  box: { "& .css-19kzrtu": { padding: "5% 0" } },
}));

function EmployeeModal({ close, selectedemployees }) {
  const [value, setValue] = useState(0);
  const [style, setStyle] = useState({
    borderBottom: "1px solid orange",
    borderRadius: "0",
    color: "#2C2C2E",
    fontWeight: "400",
  });

  const { companyPhone } = useContext(NotesContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStyle({
      borderBottom: "1px solid orange",
      borderRadius: "0",
      transition: "border-color 0.3s ease-in-out",
      color: "#2C2C2E",
      fontWeight: "400",
    });
  };

  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {},
        },
      },
    },
  });

  const classes = useStyles();

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <Grid container className={classes.modal}>
      <Grid item md={11.5} className={classes.containerBox}>
        <Typography onClick={() => close(false)} className={classes.close} color="#FF7701">
          <img width={45} src={Close} alt="Close" />
        </Typography>
        <Stack className={classes.box}>
          <Stack
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            paddingLeft="7%"
          >
            <Stack flexDirection="row" gap={1}>
              <CardMedia
                className={classes.profileImg}
                image={selectedemployees.profile_pic ? selectedemployees.profile_pic : profileImg}
              />
              <Stack mt={3}>
                <Typography className={classes.employeeName} variant="h5">
                  {selectedemployees.name}
                </Typography>
                <Typography className={classes.employeeTitle} color="#ED944D">
                  {selectedemployees.current_title}
                </Typography>
                <CardActions className={classes.cardAction}>
                  <Stack direction="row" spacing={2}>
                    {selectedemployees.links ? (
                      <>
                        {Object.entries(selectedemployees.links).map(([key, label]) => {
                          if (key === "instagram") {
                            return (
                              <MuiLink
                                className={classes.muiLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={label}
                                key={key}
                              >
                                <Instagram color="#BDBDBD" />
                              </MuiLink>
                            );
                          }
                          if (key === "facebook") {
                            return (
                              <MuiLink
                                className={classes.muiLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={label}
                                key={key}
                              >
                                <Facebook color="#BDBDBD" />
                              </MuiLink>
                            );
                          }
                          if (key === "linkedin") {
                            return (
                              <MuiLink
                                className={classes.muiLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={label}
                                key={key}
                              >
                                <LinkedIn color="#BDBDBD" />
                              </MuiLink>
                            );
                          }
                          return "";
                        })}
                      </>
                    ) : (
                      ""
                    )}
                  </Stack>
                </CardActions>
              </Stack>
            </Stack>
            <Grid item mt={3} md={8}>
              <Tabs
                classes={{ flexContainer: classes.flexContainer }}
                TabIndicatorProps={{ style: { display: "none" } }}
                value={value}
                style={{
                  padding: 0,
                  marginBottom: "1%",
                }}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  theme={theme}
                  label="Employee information"
                  {...a11yProps(0)}
                  style={value === 0 ? style : {}}
                />
                <Tab
                  theme={theme}
                  label="Job History"
                  {...a11yProps(1)}
                  style={value === 1 ? style : {}}
                />
                <Tab
                  theme={theme}
                  label="Education"
                  {...a11yProps(2)}
                  style={value === 2 ? style : {}}
                />
              </Tabs>
            </Grid>
          </Stack>

          <TabPanel padding={0} value={value} index={0}>
            <Stack width="100%" flexDirection="row" alignItems="flex-start" paddingLeft="7%">
              <Grid container mt={1} md={8} spacing={1}>
                <Grid item md={4} mb={8}>
                  <Typography className={classes.keyName} color="#626262">
                    Location
                  </Typography>
                  {selectedemployees.city ? (
                    <Typography className={classes.valueName} color="#000000">
                      {selectedemployees.city}
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Grid>
                <Grid item md={4} mb={8}>
                  <Typography className={classes.keyName} color="#626262">
                    Employeer Website
                  </Typography>
                  {selectedemployees.current_employer_website ? (
                    <Typography className={classes.valueName} color="#000000">
                      {selectedemployees.current_employer_website}
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Grid>
                <Grid item md={4} mb={8} flexDirection="column">
                  <Typography className={classes.keyName} color="#626262">
                    Url&apos;s
                  </Typography>
                  {selectedemployees.links
                    ? Object.entries(selectedemployees.links).map(([key, label]) =>
                        key && label ? (
                          <Typography className={classes.valueNameLink}>
                            <a
                              className={classes.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={label}
                            >
                              {capitalizeFirstLetter(key)}
                            </a>
                          </Typography>
                        ) : (
                          ""
                        )
                      )
                    : "-"}
                </Grid>
                <Grid item md={4} mb={8}>
                  <Typography className={classes.keyName} color="#626262">
                    Birth Year
                  </Typography>
                  {selectedemployees.birth_year ? (
                    <Typography className={classes.valueName} color="#000000">
                      {selectedemployees.birth_year}
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Grid>
                <Grid item md={4} mb={8}>
                  <Typography className={classes.keyName} color="#626262">
                    Personal Phone Number
                  </Typography>
                  {selectedemployees.phones && selectedemployees.phones.length > 0
                    ? selectedemployees.phones.map((item) => (
                        <Typography className={classes.valueName} color="#000000">
                          {item.number}
                        </Typography>
                      ))
                    : "-"}
                </Grid>
                <Grid item md={4} mb={8}>
                  <Typography className={classes.keyName} color="#626262">
                    Company Phone Number
                  </Typography>
                  {companyPhone ? (
                    <Typography className={classes.valueName} color="#000000">
                      {companyPhone}
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Grid>
                <Grid item md={4} mb={8}>
                  <Typography className={classes.keyName} color="#626262">
                    Employee Location
                  </Typography>
                  {selectedemployees.location ? (
                    <Typography className={classes.valueName} color="#000000">
                      {selectedemployees.location}
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Grid>
                <Grid item md={4} mb={8}>
                  <Typography className={classes.keyName} color="#626262">
                    Work Email
                  </Typography>
                  {selectedemployees.current_work_email ? (
                    <Typography className={classes.valueName} color="#000000">
                      {selectedemployees.current_work_email}
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Grid>
                <Grid item md={4} mb={8}>
                  <Typography className={classes.keyName} color="#626262">
                    Recommended Personal Email
                  </Typography>
                  {selectedemployees.recommended_personal_email ? (
                    <Typography className={classes.valueName} color="#000000">
                      {selectedemployees.recommended_personal_email}
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Grid>
                <Grid item md={4} mb={8}>
                  <Typography className={classes.keyName} color="#626262">
                    Current Employer
                  </Typography>
                  {selectedemployees.current_employer ? (
                    <Typography className={classes.valueName} color="#000000">
                      {selectedemployees.current_employer}
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Grid>
              </Grid>
              <Grid item mt={1} mr={13} md={4}>
                {selectedemployees.skills ? (
                  <>
                    <Grid item md={12} mb={12}>
                      <Typography
                        className={classes.keyName}
                        style={{ textAlign: "center" }}
                        color="#626262"
                      >
                        Skills
                      </Typography>
                      {selectedemployees.skills.length > 0 ? (
                        <div className={classes.skillWrapper}>
                          {selectedemployees.skills.map((skill) => (
                            <Typography className={classes.valueName1} color="#000000">
                              {skill}
                            </Typography>
                          ))}
                        </div>
                      ) : (
                        "-"
                      )}
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </Stack>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EmployeeTable selectedemployees={selectedemployees} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <EducationTable selectedemployees={selectedemployees} />
          </TabPanel>
        </Stack>
      </Grid>
    </Grid>
  );
}

EmployeeModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedemployees: PropTypes.array.isRequired,
};

export default EmployeeModal;
