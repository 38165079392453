import React from "react";
import PropTypes from "prop-types";

function DateFormatter({ dateTime }) {
  // Helper to ensure dateTime is a Date instance
  const ensureDate = (date) => (date instanceof Date ? date : new Date(date));

  function formatDate(date) {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return ensureDate(date).toLocaleDateString("ge-GE", options);
  }

  const formattedDateTime = formatDate(dateTime);

  return <span>{formattedDateTime}</span>;
}

DateFormatter.propTypes = {
  dateTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
};

export default DateFormatter;
