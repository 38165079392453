/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo, createContext, useContext } from "react";

// react-router components
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";
import Swal from "sweetalert2";
// eslint-disable-next-line no-unused-vars
import Pusher from "pusher-js";
import Echo from "laravel-echo";

// Soft UI Dashboard PRO React components
// import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
// import Configurator from "examples/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
import publicRoutes from "public.routes";
import privateCustomerAdminRoutes from "private.customer.admin.routes";
import privateCustomerUserRoutes from "private.customer.user.routes";
import privateCustomerBusinessPartnerRoutes from "private.customer.business_partner.routes";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav } from "context";

// Images
import brand from "assets/images/logo-teleroids.jpg";

import axios from "axios";
import { Stack } from "@mui/material";
import UserRoles from "common/constants/user-roles";
import NotesContext from "AppContext";
import { pusherOptions } from "common/helpers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppointmentChat from "./examples/AppointmentChat";

export const UserContext = createContext();
export const useUser = () => useContext(UserContext);
export const CustomerUserContext = createContext();
export const useCustomerUser = () => useContext(CustomerUserContext);

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/`;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post.Accept = "application/json";
axios.defaults.withCredentials = true;

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("auth_token");
  const newConfig = config;
  newConfig.headers.common.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const history = useHistory();
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState({});
  const [customerUser, setCustomerUser] = useState({});
  const [notes, setNotes] = useState({});
  const [appointmentData, setAppointmentData] = useState({});
  const [totalNewNotes, setTotalNewNotes] = useState(-1);
  const location = useLocation();

  const addPusherLister = () => {
    const echo = new Echo(pusherOptions);
    echo.join("online");

    echo.private("user.notification").listen(".new-user-notification", (e) => {
      if (
        e.user_id === JSON.parse(atob(localStorage.getItem("auth_user"))).id &&
        e.type === "Note"
      ) {
        setAppointmentData(e);
        setTotalNewNotes((prev) => prev + 1);
      }
    });
  };

  const switchCustomer = async () => {
    const selectedCustomerId = localStorage.getItem("selectedCustomerId");
    if (selectedCustomerId) {
      const customerUserRes = await axios.get(`/api/customers/${selectedCustomerId}/user`);
      const customerUserVal = customerUserRes.data.data;
      setCustomerUser(customerUserVal);
      setTotalNewNotes(customerUserVal.customer.num_of_unread_notes);
      addPusherLister();
    } else {
      const customersData = await axios.get(`/api/customers`);
      const objectCustomerData = Object.entries(customersData.data);
      if (objectCustomerData.length === 1) {
        const customerUserRes = await axios.get(`/api/customers/${objectCustomerData[0][0]}/user`);
        const customerUserVal = customerUserRes.data.data;
        setCustomerUser(customerUserVal);
        setTotalNewNotes(customerUserVal.customer.num_of_unread_notes);
        addPusherLister();
      } else {
        await Swal.fire({
          title: "Select a Customer",
          input: "select",
          inputOptions: customersData.data,
          inputPlaceholder: "required",
          showCancelButton: false,
          allowOutsideClick: false,
          inputValidator(value) {
            return new Promise((resolve) => {
              if (value !== "") {
                resolve();
              } else {
                resolve("You need to select a customer");
              }
            });
          },
        })
          .then(async (result) => {
            if (result.isConfirmed) {
              const customerUserRes = await axios.get(`/api/customers/${result.value}/user`);
              const customerUserVal = customerUserRes.data.data;
              setCustomerUser(customerUserVal);
              setTotalNewNotes(customerUserVal.customer.num_of_unread_notes);
              localStorage.setItem("selectedCustomerId", result.value);

              addPusherLister();

              // Swal.fire({
              //   icon: "success",
              //   html: `You selected customer ${customerUserVal.customer.name}`,
              // });
            }
          })
          .catch(() => {
            window.relocation = "/";
          });
      }
    }
  };

  useEffect(() => {
    axios
      .post(`/api/checking-authenticated`)
      .then(async (res) => {
        if (res.status === 200) {
          await switchCustomer();
          setAuthenticated(true);
          setUser(JSON.parse(atob(localStorage.getItem("auth_user"))));
        }
        setLoading(false);
      })
      .catch(() => {
        setUser(null);
        setLoading(false);
      });
    return () => {
      setAuthenticated(false);
    };
  }, []);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} component={route.component} key={route.key} />;
      }

      return null;
    });

  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      if ((err || []).length !== undefined) {
        if (err.response.status === 401) {
          Swal.fire({
            title: "Unauthorized!",
            text: err.response.data.message,
            icon: "warning",
          });
          history.push("/");
        } else if (err.response.status === 403) {
          Swal.fire({
            title: "Forbidden!",
            text: err.response.data.message,
            icon: "warning",
          });
          history.push("/authentication/error/500");
        } else if (err.response.status === 404) {
          Swal.fire({
            title: "404 Error!",
            text: err.response.data.message,
            icon: "warning",
          });
          history.push("/authentication/error/404");
        }
      }
      return Promise.reject(err);
    }
  );

  if (loading) {
    return (
      <Stack
        alignItems="center"
        className="loader"
        display="flex"
        justifyContent="center"
        height="100vh"
        width="100%"
        bgcolor="secondary"
      >
        Welcome to Teleroids
      </Stack>
    );
  }

  let routes = publicRoutes;
  if (authenticated) {
    switch (customerUser.customer_role) {
      case UserRoles.CUSTOMER_ADMIN:
        routes = privateCustomerAdminRoutes;
        break;
      case UserRoles.CUSTOMER_USER:
        routes = privateCustomerUserRoutes;
        break;
      case UserRoles.CUSTOMER_BUSINESS_PARTNER:
        routes = privateCustomerBusinessPartnerRoutes;
        break;
      default:
        routes = [];
    }
    if (!customerUser.allow_google_drive) {
      routes = routes.filter((item) => item.key !== "file");
    }
  }

  const hideSidenavRoutes = [
    "/enrichment",
    "/employee-profile",
    "/oic-employee-profile",
    "/enrichment-oic",
    "/authentication/sign-in/cover",
    "/authentication/verification/cover",
    "/authentication/reset-password/cover",
    "/authentication/sign-up/cover",
  ]; // add the routes where you want to hide the sidenav

  const shouldHideSidenav = hideSidenavRoutes.includes(location.pathname);

  return direction === "rtl" ? (
    <UserContext.Provider value={user}>
      <CustomerUserContext.Provider value={[customerUser, setCustomerUser]}>
        <NotesContext.Provider value={{ notes, setNotes, totalNewNotes, setTotalNewNotes }}>
          <CacheProvider value={rtlCache}>
            <ToastContainer rtl={1} />

            <ThemeProvider theme={themeRTL}>
              <CssBaseline />
              {authenticated && layout === "dashboard" && !shouldHideSidenav && (
                <>
                  <Sidenav
                    color={sidenavColor}
                    brand={brand}
                    brandName={customerUser && customerUser.customer && customerUser.customer.name}
                    customerUser={customerUser}
                    totalNewNotes={totalNewNotes}
                    routes={routes}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                  />

                  {/* <Configurator />
                {configsButton} */}
                </>
              )}
              {/* {layout === "vr" && <Configurator />} */}
              {layout === "vr"}
              <Switch>
                {getRoutes(routes)}
                <Redirect from="*" to="/" />
              </Switch>
              {authenticated && appointmentData && <AppointmentChat noteData={appointmentData} />}
            </ThemeProvider>
          </CacheProvider>
        </NotesContext.Provider>{" "}
      </CustomerUserContext.Provider>
    </UserContext.Provider>
  ) : (
    <UserContext.Provider value={user}>
      <CustomerUserContext.Provider value={[customerUser, setCustomerUser]}>
        <NotesContext.Provider value={{ notes, setNotes, totalNewNotes, setTotalNewNotes }}>
          <ThemeProvider theme={theme}>
            <ToastContainer />
            <CssBaseline />
            {authenticated && layout === "dashboard" && !shouldHideSidenav && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand={brand}
                  brandName={customerUser && customerUser.customer && customerUser.customer.name}
                  customerUser={customerUser}
                  totalNewNotes={totalNewNotes}
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                {/* {renderInfoSB} */}
                {/* <Configurator />
              {configsButton} */}
              </>
            )}
            {/* {layout === "vr" && <Configurator />}  */}
            {layout === "vr"}
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/" />
            </Switch>
            {authenticated && appointmentData && <AppointmentChat noteData={appointmentData} />}
          </ThemeProvider>
        </NotesContext.Provider>
      </CustomerUserContext.Provider>
    </UserContext.Provider>
  );
}
