import { useContext, useEffect, useState } from "react";
import { Drawer, Grid, IconButton, CssBaseline } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import axios from "axios";
import Echo from "laravel-echo";
import { pusherOptions } from "common/helpers";
// eslint-disable-next-line import/no-cycle
import { CustomerUserContext } from "App";
import { useSoftUIController } from "context";
import chat from "assets/images/enrichment/chat.svg";
import NewMessage from "../../layouts/pages/chat_v2/NewMessage";
import OpenSidebar from "../../layouts/pages/chat_v2/OpenSidebar";

function AppointmentChat({ noteData }) {
  const [controller] = useSoftUIController();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const useStyles = makeStyles(() => ({
    titles: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      color: "#4A4A4A",
      margin: 0,
    },
    value: {
      fontFamily: "Poppins",
      fontSize: "12px",
      fontWeight: "400",
      color: "#4A4A4A",
      marginLeft: "10px",
    },
    value2: { fontFamily: "Poppins", fontSize: "12px", fontWeight: "400", color: "#5F5F5F" },
    skillWrapper: {
      margin: "5px",
      display: "flex",
    },
    container: {
      position: "fixed",
      transition: "bottom 0.2s ease-in-out",
      bottom: sidebarOpen
        ? `${document.querySelector(".MuiDrawer-paperAnchorBottom").clientHeight}px`
        : "0",
      right: 10,
      backgroundColor: "#ff6826",
      border: "1px solid #F7F7F7",
      borderRadius: "20px 20px 20px 20px",
      width: "45px",
      height: "45px",
      marginBottom: "5px",
    },
    image: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    sidebar: {
      display: "flex",
      justifyContent: "end",
    },
    drawerPaper: {
      height: "80%",
      width: "20%",
      left: "auto",
      right: "10px",
      border: "1px solid #dbdbdb",
      margin: 0,
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      padding: "10px",
      borderRadius: "20px 0 0 0",
      "@media (max-width: 1024px)": {
        width: "70%",
      },
      "@media (max-width: 991px)": {
        width: "70%",
      },
    },
    messageDrawerPaper: {
      height: "50%",
      width: "20%",
      left: "auto",
      border: "1px solid #dbdbdb",
      margin: 0,
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "20px 20px 0 0",
      "@media (max-width: 1024px)": {
        width: "70%",
        height: "70%",
      },
      "@media (max-width: 991px)": {
        width: "70%",
      },
    },
  }));

  const classes = useStyles();
  const [customerUser, setCustomerUser] = useContext(CustomerUserContext);
  const [refreshList, setRefreshList] = useState(false);
  const [notes, setNotes] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [chatFilter, setChatFilter] = useState(0);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const openChat = (pAppointment, res = null) => {
    if (res) {
      res.pivot_unread_notes = 0;
    }
    if (pAppointment) {
      setAppointments((prevAppointments) => {
        if (!prevAppointments.some((app) => app.id === pAppointment.id)) {
          let newAppIds;
          if (prevAppointments.length >= 3) {
            newAppIds = [...prevAppointments.slice(1), pAppointment];
          } else {
            newAppIds = [...prevAppointments, pAppointment];
          }
          setSidebarOpen(true);
          return newAppIds;
        }
        return prevAppointments;
      });
    }
  };

  const closeChat = (id) => {
    const updatedAppointments = appointments.filter((appointment) => appointment.id !== id);
    setAppointments(updatedAppointments);
  };

  const feedNotes = () => {
    if (customerUser && customerUser.customer) {
      axios
        .get(`/api/customers/${customerUser.customer.id}/campaign-lead/notes`, {
          params: {
            filter: chatFilter,
          },
        })
        .then((res) => {
          if (res) {
            setNotes(res.data);

            // let total = 0;
            // for (let i = 0; i < res.data.length; i += 1) {
            //   total += res.data[i].pivot_unread_notes;
            // }
            // setTotalNewNotes(total);
          }
        })
        .catch(() => {
          setNotes([]);
          // setTotalNewNotes(0);
        });
    }
  };

  useEffect(() => {
    if (chatFilter !== null) {
      feedNotes();
    }
  }, [chatFilter]);

  useEffect(() => {
    const echo = new Echo(pusherOptions);

    echo.private("user.notification").listen(".new-user-notification", () => {
      feedNotes();
    });

    return () => {
      echo.leave("user.notification");
    };
  }, [notes]);

  useEffect(() => {
    feedNotes();
    const echo = new Echo(pusherOptions);
    echo
      .join("online")
      .here((users) => {
        const usersIds = [];
        users.forEach(({ id }) => {
          usersIds.push(id);
        });
        setOnlineUsers(usersIds);
      })
      .joining((user) => {
        setOnlineUsers((oldArray) => [...oldArray, user.id]);
      })
      .leaving((user) => {
        const usersIds = onlineUsers.filter((id) => id !== user.id);
        setOnlineUsers(usersIds);
      });
    return () => {};
  }, [null, refreshList]);

  useEffect(() => {
    const appointmentId = noteData?.note?.appointment_id;
    if (appointmentId !== undefined && appointmentId && notes && notes.length > 0) {
      const dataV = notes.filter((r) => `${r.appointment.id}` === `${appointmentId}`)[0];
      if (dataV && dataV.appointment) {
        openChat(dataV.appointment);
      }
    }

    return () => {};
  }, [noteData]);

  return (
    <>
      <Grid
        item
        container
        sx={{ backgroundColor: "#FBFAFB", overflow: "hidden" }}
        ml={controller.miniSidenav ? "100px" : "300px"}
        md={controller.miniSidenav ? 10.8 : 9}
      >
        <div className={classes.container}>
          <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={toggleSidebar}>
            <div className={classes.image}>
              <img src={chat} alt={chat} />
            </div>
          </IconButton>
        </div>
      </Grid>
      <div className={classes.sidebar}>
        <CssBaseline />
        <Drawer
          variant="persistent"
          anchor="bottom"
          open={sidebarOpen}
          style={{ width: "100%", maxWidth: "400px" }}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <OpenSidebar notes={notes} openChat={openChat} setChatFilter={setChatFilter} />
        </Drawer>
      </div>
      <div>
        {appointments.map((appointment, index) => (
          <Drawer
            key={appointment.id}
            variant="persistent"
            anchor="bottom"
            open={1}
            classes={{
              paper: classes.messageDrawerPaper,
            }}
            sx={{
              "& .MuiPaper-root": {
                "@media (max-width: 1024px)": {
                  right: 0,
                },
                right: `calc(${20 * index + 20}% + ${10 * index + 20}px)`,
              },
            }}
          >
            {appointment && (
              <NewMessage
                closeChat={closeChat}
                appointment={appointment}
                customerUser={customerUser}
                onlineUsers={onlineUsers}
                setCustomerUser={setCustomerUser}
                refreshList={refreshList}
                setRefreshList={setRefreshList}
              />
            )}
          </Drawer>
        ))}
      </div>
    </>
  );
}
AppointmentChat.defaultProps = {
  noteData: {},
};

// Typechecking props for
AppointmentChat.propTypes = {
  noteData: PropTypes.objectOf(PropTypes.any),
};
export default AppointmentChat;
