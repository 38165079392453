import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Grid, Stack, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import AuthButton from "components/Buttons/AuthButton";
import LetterLogo from "assets/images/iconComponents/LetterLogo";
import authBG from "../../../assets/images/authentication_v2/authBG.svg";
import teleroidsArm from "../../../assets/images/authentication_v2/teleroidsArm.png";

function Confirmation() {
  const useStyles = makeStyles(() => ({
    container: {
      backgroundImage: `url(${authBG})`,
      backgroundAttachment: "fixed",
      height: "100vh",
      width: "100%",
    },
    gridItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "5%",
    },
    boxCon: {
      backgroundColor: "white !important",
      width: "100%",
    },
    defaultTypo: { fontSize: "13px", fontFamily: "Poppins", fontWeight: "400" },
    footer: { fontWeight: "400", fontSize: "12px", fontFamily: "Poppins", padding: "3%" },
    forgot: { fontSize: "20px", fontFamily: "Poppins", fontWeight: "600", color: "#000000" },
    box: {
      backgroundColor: "#FF94550F",
      width: "100%",
      padding: "5% ",
      textAlign: "center",
      fontSize: "12px",
      fontFamily: "Poppins",
      fontWeight: "400",
    },
    agent: {
      fontFamily: "Poppins",
      fontSize: "26px",
      fontWeight: "600",
      borderBottom: "1px solid #00000026",
      paddingBottom: "3px",
      color: "#FF4D00D9",
      cursor: "pointer",
    },
    inputs: { width: "100%", marginBottom: "12%" },
    resetTypo: {
      color: "#00000096",
      fontSize: "12px",
      fontFamily: "Poppins",
      textTransform: "none",
      fontWeight: "600",
    },
    forgetTypo: { color: "#8D8D8D", fontSize: "12px", fontFamily: "Poppins", fontWeight: "600" },
  }));

  const classes = useStyles();
  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            display: "none",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDEDED",
            color: "#5F5F5F",
            fontFamily: "Poppins",
            padding: "0 7% 0 7%",
            borderRadius: "6px",
            textTransform: "none",
            marginRight: "-2%",
            "&.Mui-selected": {
              backgroundColor: "#FF4D00D9",
              color: "#fff",
              fontFamily: "Poppins",
              borderRadius: "6px",
              padding: "1% 7% 1% 7%",
              marginRight: "-2%",
              zIndex: "1",
            },
          },
        },
      },
    },
  });

  const history = useHistory();

  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

  const [resetPassword, setResetPassword] = useState({
    email: "",
    error_list: [],
  });

  const handleResetPasswordInput = (e) => {
    e.persist();
    delete resetPassword.error_list[e.target.name];
    setResetPassword({ ...resetPassword, [e.target.name]: e.target.value });
  };

  const submitResetPassword = (e) => {
    e.preventDefault();
    setResetPasswordLoading(true);
    axios.get("/sanctum/csrf-cookie").then(() => {
      const data = {
        email: resetPassword.email,
      };
      axios
        .post("/api/forgot-password", data)
        .then((res) => {
          Swal.fire({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setResetPasswordLoading(false);
          history.push("/authentication/sign-in/cover");
        })
        .catch((error) => {
          setResetPassword({ ...resetPassword, error_list: [] });
          const errorData = error.response === undefined ? null : error.response.data;

          if (errorData && errorData.errors !== undefined) {
            setResetPassword({ ...resetPassword, error_list: errorData.errors });
          }

          if (errorData && errorData.message !== undefined) {
            Swal.fire({
              title: "Error!",
              text: errorData.message,
              icon: "error",
            });
          }
          setResetPasswordLoading(false);
          // return Promise.reject(error);
        });
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container className={classes.container}>
        <Grid item md={3.5} sm={2} xs={0.5} />
        <Grid item md={5} sm={8} xs={11} className={classes.gridItem}>
          <Stack mb={10} direction="column" alignItems="center">
            <LetterLogo />
          </Stack>
          <Grid item md={12} sm={12} xs={12} className={classes.boxCon}>
            <Grid item md={12} sm={12} xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  md={3}
                  sm={5}
                  xs={5}
                  sx={{
                    marginLeft: { xs: "28%", sm: "30%", md: "0", lg: "0" },
                  }}
                >
                  <img width="100%" src={teleroidsArm} alt="Logo" />
                </Grid>
                <Grid
                  item
                  mb={0}
                  mt={3}
                  md={9}
                  sm={12}
                  xs={12}
                  sx={{
                    marginLeft: { xs: "10%", sm: "20%", md: "0", lg: "0" },
                  }}
                >
                  <Typography ml={8} className={classes.forgot}>
                    Reset Password
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={2.7} sm={2} xs={1} />
              <Grid item md={7} sm={8} xs={10}>
                <Typography className={classes.box}>
                  Please enter your email address. You will recieve a link to create a new password
                  via email.
                </Typography>
              </Grid>
              <Grid item md={2} />
              <Grid item md={3.2} xs={1.1} sm={2.1} />
              <Grid
                item
                mt={3.5}
                md={6}
                sm={8}
                xs={10}
                sx={{
                  marginBottom: { xs: "0", sm: "4%", md: "0", lg: "0" },
                }}
              >
                <Typography className={classes.defaultTypo}>Email</Typography>
                <TextField
                  className={classes.inputs}
                  name="email"
                  type="email"
                  variant="standard"
                  onChange={handleResetPasswordInput}
                />
                <ThemeProvider theme={theme}>
                  <AuthButton
                    loading={resetPasswordLoading}
                    backgroundColor="#FF4D00D9"
                    fontSize="15px"
                    onClick={submitResetPassword}
                  >
                    RESET
                  </AuthButton>
                </ThemeProvider>
                <Grid container mt={1.4} alignItems="center">
                  <Grid item xs={3} md={3} sm={3.5} />
                  <Grid mt={0.7} item xs={3} sm={2.6} md={3}>
                    <Typography className={classes.forgetTypo}>Go back to</Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3}>
                    <Typography
                      component={Link}
                      to="/"
                      variant="button"
                      className={classes.resetTypo}
                    >
                      Login Page
                    </Typography>
                  </Grid>
                  <Grid item xs={2} md={2} sm={2} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Typography ml={4} className={classes.footer}>
            Copyright © 2023 CASA AKTIVA GmbH
          </Typography>
        </Grid>
        <Grid item md={3.5} sm={2} xs={0.5} />
      </Grid>
    </ThemeProvider>
  );
}

export default Confirmation;
