import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function OicCampaigns({ filteredData, handleCampaignClick, handleAllCampaignsClick, isSelected }) {
  return (
    <Grid container mt={1.5} mb={0.5}>
      <Stack
        sx={{
          padding: "10px",
          backgroundColor: isSelected === "#E7E7E7" ? "#FF4D00D9" : "#FFFFFF",
          borderRadius: "5px",
          marginRight: "1%",
          marginBottom: "1%",
          cursor: "pointer",
          color: isSelected === "#E7E7E7" ? "#fff" : "000000",
        }}
        direction="row"
        alignItems="center"
        onClick={(e) => handleAllCampaignsClick(e)}
      >
        <Typography sx={{ fontSize: "12px", fontWeight: "400", fontFamily: "Poppins" }}>
          All Appointments
        </Typography>
      </Stack>
      {filteredData
        ? filteredData.map((campaignItem) => (
            <Stack
              sx={{
                padding: "10px",
                backgroundColor: isSelected === campaignItem.id ? "#FF4D00D9" : "#FFFFFF",
                borderRadius: "5px",
                marginRight: "1%",
                marginBottom: "1%",
                cursor: "pointer",
                color: isSelected === campaignItem.id ? "#fff" : "#000000",
              }}
              direction="row"
              alignItems="center"
              onClick={(e) => handleCampaignClick(e, campaignItem)}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: "400", fontFamily: "Poppins" }}>
                {campaignItem.name}
              </Typography>
            </Stack>
          ))
        : null}
    </Grid>
  );
}

OicCampaigns.propTypes = {
  filteredData: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleCampaignClick: PropTypes.func.isRequired,
  handleAllCampaignsClick: PropTypes.func.isRequired,
  isSelected: PropTypes.string.isRequired,
};

export default OicCampaigns;
