import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TagHistoryDefault from "components/Enrichment/components/iconComponents/TagHistoryDefault";
import { Typography } from "@mui/material";

function TagHistoryTable({ tagHistoryData }) {
  const useStyles = makeStyles(() => ({
    TableContainerTagHistory: {
      borderRadius: "0px 0px 3.5px 3.5px",
      marginBottom: "5%",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      // padding: "0 2%",
      maxWidth: "90%",
      maxHeight: "400px",
      overflowY: "auto",
    },
    tableCell: {
      borderBottom: "0",
      margin: "0",
    },
    table: {
      fontSize: "14px",
      fontWeight: "400",
      borderTop: "1px solid #b8bdbd",
    },
    tableRow: { borderBottom: "1px solid #50555C" },
    noInfo: { padding: "15px" },
  }));
  const classes = useStyles();
  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "#FF7701",
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Typography fontSize="14px" fontWeight="500" mb={2}>
        TAG HISTORY
      </Typography>
      <TableContainer className={classes.TableContainerTagHistory} component={Paper}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>Type</TableCell>
              <TableCell className={classes.tableCell}>ID</TableCell>
              <TableCell className={classes.tableCell}>First Detected</TableCell>
              <TableCell className={classes.tableCell}>Last Detected</TableCell>
            </TableRow>
          </TableHead>
          {tagHistoryData && Object.keys(tagHistoryData).length > 0 && (
            <TableBody>
              {Object.values(tagHistoryData).map((value) =>
                Object.values(value).map((item) => (
                  <TableRow key={item.id}>
                    <TableCell className={classes.table} width="10%" component="th" scope="row">
                      <TagHistoryDefault />
                    </TableCell>
                    <TableCell className={classes.table} width="40%" component="th" scope="row">
                      {item.type}-{item.value}
                    </TableCell>
                    <TableCell className={classes.table} width="25%" component="th" scope="row">
                      {item.first_detected
                        ? new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "short",
                          }).format(item.first_detected)
                        : null}
                    </TableCell>
                    <TableCell className={classes.table} width="25%" component="th" scope="row">
                      {item.last_detected
                        ? new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "short",
                          }).format(item.last_detected)
                        : null}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
        {tagHistoryData && Object.keys(tagHistoryData).length === 0 && (
          <Typography className={classes.noInfo}>No information yet!</Typography>
        )}
      </TableContainer>
    </ThemeProvider>
  );
}

TagHistoryTable.propTypes = {
  tagHistoryData: PropTypes.any.isRequired,
};

export default TagHistoryTable;
