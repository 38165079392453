import { makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import { Box, Card, CardContent, Grid } from "@mui/material";
import PropTypes from "prop-types";
import ListItemText from "@mui/material/ListItemText";

function LeadInfo({ appointment }) {
  const useStyles = makeStyles(() => ({
    header: {
      padding: "1%",
      fontSize: "15px",
      fontWeight: "bold",
      color: "#000000",
      backgroundColor: "#F0F0F0",
      textAlign: "center",
    },
    cardContent: { margin: "0", padding: "0" },
    card: {
      borderRadius: "0px 0px 3.5px 3.5px",
      marginBottom: "5%",
      // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    },
    typographyLabelStyle: {
      fontSize: "14px",
      color: "#626262",
      marginBottom: "7px",
    },
    typographyBodyStyle: {
      fontWeight: "400",
      fontSize: "14px",
      color: "black",
    },
    boxStyle: {
      paddingTop: "10px",
      paddingLeft: "20px",
      paddingBottom: "20px",
    },
    gridStyle: {
      padding: "5px 30px 30px 30px",
    },
    listItemTextStyle: {
      margin: "10px",
    },
    mainGridStyle: {
      marginTop: "0px",
    },
  }));
  const classes = useStyles();
  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.header} variant="h6">
            Lead Information
          </Typography>
          <Box className={classes.body}>
            <Grid container className={classes.mainGridStyle}>
              {appointment.lead_raw_fields
                ? appointment.lead_raw_fields.map((res) =>
                    res.data !== undefined && res.data && res.data.length > 0 ? (
                      <>
                        <Box className={classes.boxStyle}>
                          <Typography className={classes.typographyLabelStyle} component="div">
                            {res.field_group_name}
                          </Typography>
                        </Box>
                        <Grid container className={classes.gridStyle} spacing={3}>
                          {res.data.map((r) => (
                            <Grid item sx={12} sm={4} md={4}>
                              <ListItemText
                                primary={
                                  <Typography
                                    className={classes.typographyLabelStyle}
                                    component="div"
                                  >
                                    {r.name}
                                  </Typography>
                                }
                                secondary={
                                  <Typography
                                    className={classes.typographyBodyStyle}
                                    component="div"
                                  >
                                    {r.value}
                                  </Typography>
                                }
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    ) : (
                      ""
                    )
                  )
                : ""}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
LeadInfo.propTypes = {
  appointment: PropTypes.object.isRequired,
};
export default LeadInfo;
