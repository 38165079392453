/* eslint-disable react/style-prop-object */
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { CustomerUserContext } from "App";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import { Grid, Stack, CircularProgress } from "@mui/material";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import {
  FullFileBrowser,
  setChonkyDefaults,
  ChonkyActions,
  FileToolbar,
} from "../../../node_modules/chonky/dist";
import { ChonkyIconFA } from "../../../node_modules/chonky-icon-fontawesome/dist";

import styles from "./index.module.css";
// import CreateFolder from "features/file-manager/components/create-folder";

setChonkyDefaults({
  iconComponent: ChonkyIconFA,
  // disableSelection: false,
  // disableDragAndDropProvider: false,
});

function GoogleFileManager() {
  const [customerUser] = useContext(CustomerUserContext);
  const [currentPath, setCurrentPath] = useState("0");
  const [folderChain, setFolderChain] = useState([{ id: "xcv", name: "", isDir: true }]);
  const [files, setFiles] = useState([]);
  const fileUploadRef = useRef();
  const [loading, setLoading] = useState(false);

  const fetchContent = useCallback((folderPath) => {
    setLoading(true);
    axios
      .get(`/api/customers/${customerUser.customer.id}/google-drive/list-files/${folderPath}`)
      .then((res) => {
        // Swal.fire("Success!", r.message, "success");
        setCurrentPath(res.data.currentPath);
        setFolderChain(
          res.data.folders.map((item) => ({
            id: item.id,
            name: item.name,
            // thumbnailUrl: "https://chonky.io/chonky-sphere-v2.png",
            modDate: new Date(item.timestamp * 1000),
            isDir: item.type === "dir",
          }))
        );
        setFiles(
          res.data.content.map((item) => ({
            id: item.id,
            name: item.name,
            parentDirId: item.parentDirId,
            // thumbnailUrl: "https://chonky.io/chonky-sphere-v2.png",
            isDir: item.type === "dir",
            size: item.size,
            modDate: new Date(item.timestamp * 1000),
            ext: item.extension,
          }))
        );
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleFileUpload = async (event) => {
    // eslint-disable-next-line no-shadow
    const { files } = event.target;

    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      console.log(`File ${i + 1}: ${file.name}`);
      const formData = new FormData();
      formData.append("file", file);
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      axios
        .post(
          `/api/customers/${customerUser.customer.id}/google-drive/upload-file/${currentPath}`,
          formData,
          { headers }
        )
        .then((response) => {
          console.log("File uploaded successfully:", response.data);
        })
        .catch((err) => {
          Swal.fire("Error! Permission denied.", err.message, "error");
        })
        .finally(() => {
          fetchContent(currentPath);
        });
    }

    // Process the uploaded files (e.g., send them to the server)
    // files.foreach((file) => {
    //   const formData = new FormData();
    //   formData.append("files[]", file);
    //   axios
    //     .post(
    //       `/api/customers/${customerUser.customer.id}/google-drive/upload-file/${currentPath}`,
    //       formData
    //     )
    //     .then((response) => {
    //       console.log("File uploaded successfully:", response.data);
    //     })
    //     .catch((err) => {
    //       Swal.fire("Error!", err.message, "error");
    //     })
    //     .finally(() => {});
    // });
  };

  const previewFile = async (fileId) => {
    await axios
      .get(`/api/customers/${customerUser.customer.id}/google-drive/preview-file/${fileId}`, {
        responseType: "blob",
      })
      .then((res) => {
        window.open(URL.createObjectURL(res.data), "_blank");
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      })
      .finally(() => {});
  };

  const downloadFile = async (fileId, filename) => {
    axios
      .get(`/api/customers/${customerUser.customer.id}/google-drive/download-file/${fileId}`, {
        responseType: "blob",
      })
      .then((res) => {
        saveAs(res.data, filename);
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      })
      .finally(() => {});
  };

  const downloadFolder = async (folderId, folderName) => {
    axios
      .get(`/api/customers/${customerUser.customer.id}/google-drive/download-folder/${folderId}`, {
        responseType: "blob",
      })
      .then((res) => {
        saveAs(res.data, folderName);
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      })
      .finally(() => {});
  };

  const deleteFileOrFolder = async (fileOrFolderId) => {
    axios
      .delete(
        `/api/customers/${customerUser.customer.id}/google-drive/delete-file-or-folder/${currentPath}/file-or-folder/${fileOrFolderId}`
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        Swal.fire("Error! Permission denied.", err.message, "error");
      })
      .finally(() => {
        fetchContent(currentPath);
      });
  };

  const handleFolderChainChange = (newChain) => {
    // Handle folder chain changes here
    console.log(newChain);
  };

  const handleAction = async (data) => {
    if (data.id === ChonkyActions.OpenFiles.id) {
      // Open the files
      if (data.payload.files.length === 1 && data.payload.files[0].isDir) {
        const currFolder = data.payload.files[0].id;
        setCurrentPath(currFolder);
        fetchContent(currFolder);
      } else {
        // setLoading(true);
        const file = data.payload.files[0];
        await previewFile(file.id);
        // setLoading(false);
      }
    } else if (data.id === ChonkyActions.UploadFiles.id) {
      // Delete the files
      fileUploadRef.current.click();
    } else if (data.id === ChonkyActions.DownloadFiles.id) {
      // Delete the files
      data.state.selectedFilesForAction.map((item) =>
        !item.isDir ? downloadFile(item.id, item.name) : downloadFolder(item.id, `${item.name}.zip`)
      );
    } else if (data.id === ChonkyActions.DeleteFiles.id) {
      // Delete the files
      data.state.selectedFilesForAction.map((item) => deleteFileOrFolder(item.id));
    }
  };

  useEffect(() => {
    fetchContent(0);
    return () => {};
  }, []);

  const loader2 = (
    <Stack
      alignItems="center"
      className="loader"
      display="flex"
      justifyContent="center"
      height="100vh"
      width="100%"
    >
      <CircularProgress /> Loading..
    </Stack>
  );

  const useStyles = makeStyles(() => ({
    titles: { fontFamily: "Poppins", fontSize: "14px", fontWeight: "500", color: "#4A4A4A" },
    value: { fontFamily: "Poppins", fontSize: "12px", fontWeight: "400", color: "#4A4A4A" },
    value2: { fontFamily: "Poppins", fontSize: "12px", fontWeight: "400", color: "#5F5F5F" },
    skillWrapper: {
      margin: "5px",
      display: "flex",
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {loading ? (
        loader2
      ) : (
        <Grid container spacing={2}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            // sx={{ border: "1px solid" }}
            justifyContent="center"
            align="center"
          >
            <div className={styles.customFullFileBrowser}>
              <div className={styles.hiddenComponent}>
                <input type="file" ref={fileUploadRef} multiple onChange={handleFileUpload} />
              </div>
              {/* <CreateFolder currentPath={currentPath} /> */}
              <FullFileBrowser
                files={files}
                folderChain={folderChain}
                onFolderChainChange={handleFolderChainChange}
                onFileAction={handleAction}
                toolbar={FileToolbar}
                defaultFileViewActionId={ChonkyActions.EnableListView.id}
                fileActions={[
                  ChonkyActions.UploadFiles,
                  ChonkyActions.DownloadFiles,
                  ChonkyActions.DeleteFiles,
                ]}
              />
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default GoogleFileManager;
