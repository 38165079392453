import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  Grid,
  Box,
  Button,
  Menu,
  IconButton,
} from "@mui/material";
import { CustomerUserContext } from "App";
import Swal from "sweetalert2";
import LogoutIcon from "layouts/crm_dashboard/icons/LogoutIcon";
import ProfileIcon from "layouts/crm_dashboard/icons/ProfileIcon";
import ChatIcon from "layouts/crm_dashboard/icons/ChatIcon";
import exit from "../../../assets/images/authentication_v2/exit.svg";
import exclamation from "../../../assets/images/authentication_v2/exclamation.svg";
import SwitchUserIcon from "../SwitchUserIcon";

function Navbar({ componentName }) {
  const history = useHistory();
  const [customerUser, setCustomerUser] = useContext(CustomerUserContext);
  const [open, setOpen] = useState(false);
  const [customersData, setCustomersData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [okButtonPressed, setOkButtonPressed] = useState(false);

  const fetchCustomers = async () => {
    const response = await axios.get(`/api/customers`);
    setCustomersData(response.data);
  };

  const handleOpen = () => {
    fetchCustomers();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOkButtonPressed(false);
  };

  const handleCustomerChange = (event) => {
    setSelectedCustomer(event.target.value);
    setOkButtonPressed(false);
  };

  const handleSwitchCustomer = async () => {
    if (selectedCustomer) {
      localStorage.setItem("selectedCustomerId", selectedCustomer);

      const customerUserRes = await axios.get(`/api/customers/${selectedCustomer}/user`);
      const customerUserVal = customerUserRes.data.data;

      if (customerUser !== customerUserVal) {
        setCustomerUser(customerUserVal);
      }

      setOpen(false);
      Swal.fire({
        icon: "success",
        html: `You selected customer ${customerUserVal.customer.name}`,
      });
    } else {
      setOkButtonPressed(true);
    }
  };

  const useStyles = makeStyles(() => ({
    tabs: { width: "100%" },
    typography: {
      fontSize: "13px",
      fontWeight: "600",
      fontFamily: "Poppins",
      color: "white",
      backgroundColor: "#ff6826",
      maxHeight: "32px",
      padding: "2px 26px 2px 26px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "6px",
      cursor: "pointer",
    },
    dialogPaper: { borderRadius: "5px" },
  }));

  const classes = useStyles();
  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            display: "none",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDEDED",
            color: "#5F5F5F",
            fontFamily: "Poppins",
            padding: "8px 16px 8px 18px",
            borderRadius: "6px",
            textTransform: "none",
            marginRight: "-2%",
            fontSize: "13px",
            fontWeight: "600",
            minHeight: "5px",
            "&.Mui-selected": {
              backgroundColor: "#FF4D00D9",
              color: "#fff",
              fontFamily: "Poppins",
              borderRadius: "6px",
              padding: "8px 16px 8px 18px",
              marginRight: "-2%",
              zIndex: "1",
              fontSize: "13px",
              fontWeight: "600",
            },
          },
        },
      },
    },
  });

  const handleLogout = () => {
    axios
      .post(`/api/logout`)
      .then((res) => {
        if (res.status === 202) {
          localStorage.removeItem("auth_token");
          localStorage.removeItem("auth_user");
          localStorage.removeItem("selectedCustomerId");
          localStorage.removeItem("companyId");
          localStorage.removeItem("employeeDetail");
          localStorage.removeItem("customerUser");
          window.location = "/";
        }
      })
      .catch((errorData) => {
        Swal.fire({
          title: "Error!",
          text: errorData.message,
          icon: "error",
        }).then(() => {
          window.location = "/";
        });
      });
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const openOption = Boolean(anchorEl);

  const options = [
    {
      key: 0,
      icon: <ProfileIcon fontSize="small" color="#5F5F5F" />,
      text: "Account Profile",
    },
    {
      key: 1,
      icon: <SwitchUserIcon fontSize="small" color="#5F5F5F" />,
      text: "Switch Customer",
    },
    {
      key: 2,
      icon: (
        <span style={{ marginLeft: "5px", marginTop: "5px" }}>
          <LogoutIcon fontSize="small" color="#5F5F5F" />
        </span>
      ),
      text: "Log out",
    },
  ];

  const handleOptionClose = (key) => {
    if (key === 0) {
      history.push("/pages/account/profile");
    } else if (key === 1) {
      handleOpen();
    } else if (key === 2) {
      handleLogout();
    }

    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onChatClick = () => {
    history.push("/pages/chat");
  };
  return (
    <AppBar position="static">
      <Toolbar disableGutters>
        <Grid container style={{ alignItems: "center" }}>
          <Grid item md={6} sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography sx={{ fontSize: "1.3rem", fontWeight: "bold", fontFamily: "Poppins" }}>
              {componentName}
            </Typography>
          </Grid>
          <Grid item md={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Stack spacing={2} direction="row" alignItems="center">
              <IconButton onClick={onChatClick}>
                <ChatIcon cursor="pointer" color="#5F5F5F" />
              </IconButton>
              <ThemeProvider theme={theme}>
                <Typography className={classes.typography} onClick={handleClick}>
                  {customerUser.customer.name} <ArrowDropDownIcon style={{ color: "white" }} />
                </Typography>
                {anchorEl !== null && (
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={openOption}
                    onClose={() => handleOptionClose()}
                    PaperProps={{
                      style: {
                        minWidth: "12%",
                        left: "auto",
                        paddingTop: "4px",
                        paddingBottom: "2px",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <ListItem
                        sx={{ paddingTop: "0px", paddingBottom: "0px" }}
                        key={option.key}
                        onClick={() => handleOptionClose(option.key)}
                      >
                        <ListItemButton
                          sx={{
                            "& .MuiListItemIcon-root": { minWidth: "1px", marginRight: "8px" },
                          }}
                        >
                          <ListItemIcon>{option.icon}</ListItemIcon>
                          <ListItemText
                            sx={{
                              "& .MuiTypography-root": {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          >
                            {option.text}
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </Menu>
                )}
              </ThemeProvider>
              <Dialog classes={{ paper: classes.dialogPaper }} open={open}>
                <Box sx={{ width: "500px", padding: "25px" }}>
                  <Stack
                    sx={{
                      borderBottom: "1px solid #FF4D00D9",
                      paddingBottom: "5px",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      fontSize="20px"
                      fontWeight="400"
                      fontFamily="Poppins"
                      color="#4A4A4A"
                    >
                      Switch Customer
                    </Typography>
                    <Box sx={{ cursor: "pointer" }} onClick={handleClose}>
                      <img src={exit} alt="Exit" />
                    </Box>
                  </Stack>
                  <DialogContent>
                    <FormControl fullWidth>
                      <Typography
                        fontSize="12px"
                        fontWeight="400"
                        fontFamily="Poppins"
                        color="#4A4A4A"
                        mt={2}
                        mb={2}
                      >
                        Switching Customer is a process to select another customer.
                      </Typography>
                      <select
                        style={{
                          padding: "10px",
                          border: "1px solid #FF4D00D9",
                          borderRadius: "5px",
                          paddingRight: "10px",
                        }}
                        value={selectedCustomer}
                        onChange={handleCustomerChange}
                      >
                        <option
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            fontFamily: "Poppins",
                            color: "#4A4A4A",
                          }}
                          value=""
                        >
                          Select Customer
                        </option>
                        {Object.entries(customersData).map(([key, value]) => (
                          <>
                            <option
                              style={{
                                fontSize: "12px",
                                fontWeight: "400",
                                fontFamily: "Poppins",
                                color: "#4A4A4A",
                              }}
                              key={key}
                              value={key}
                            >
                              {value}
                            </option>
                          </>
                        ))}
                      </select>
                      {okButtonPressed && (
                        <Stack
                          mt={2}
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ backgroundColor: "#E7E7E754", padding: "8px" }}
                        >
                          <img src={exclamation} alt="exclamation" />
                          <Typography
                            fontSize="14px"
                            fontWeight="400"
                            fontFamily="Poppins"
                            color="#4A4A4A"
                            ml={1}
                          >
                            You need to select a customer.
                          </Typography>
                        </Stack>
                      )}
                    </FormControl>
                  </DialogContent>
                  <DialogActions sx={{ padding: "0" }}>
                    <Button
                      sx={{
                        width: "30%",
                        backgroundColor: "#FF4D00D9",
                        borderRadius: "5px",
                        padding: "0 !important",
                        marginRight: "15px",
                        "&:hover": {
                          backgroundColor: "#FF4D00D9",
                        },
                        "&:focus:not(:hover)": {
                          backgroundColor: "#FF4D00D9",
                          boxShadow: "none",
                        },
                      }}
                      variant="contained"
                      onClick={handleSwitchCustomer}
                    >
                      <Typography color="#FFFFFF" fontFamily="Poppins" fontSize="12px">
                        OK
                      </Typography>
                    </Button>
                  </DialogActions>
                </Box>
              </Dialog>
            </Stack>
          </Grid>
          <hr
            style={{
              width: "100%",
              margin: "20px auto",
              marginBottom: "1px",
              marginTop: "1px",
              border: "0.5px solid #5F5F5F33",
            }}
          />
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

Navbar.propTypes = {
  componentName: PropTypes.string.isRequired,
};

export default Navbar;
