/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import axios from "axios";

import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import SuiTypography from "components/SuiTypography";
// import SuiBadge from "components/SuiBadge";

function RapidApi({ appointment, customerUser }) {
  const [rapidApi, setRapidApi] = useState(null);

  const fetchRapidApis = () => {
    axios
      .get(
        `/api/customers/${customerUser.customer.id}/campaign-leads/${appointment.campaign_lead.id}/company-info`
      )
      .then((res) => {
        setRapidApi(res.data);
      })
      .catch(() => {
        setRapidApi(null);
      });
  };

  useEffect(() => {
    fetchRapidApis();
    return () => {
      setRapidApi(null);
    };
  }, []);

  return (
    <SuiBox p={0}>
      {rapidApi && (
        <Grid container spacing={3} alignItems="top">
          <Grid item xs={4}>
            <Grid container spacing={3} alignItems="top">
              {rapidApi.name && (
                <Grid item xs={12}>
                  <Card>
                    <SuiBox p={3}>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        Name
                      </SuiTypography>
                      <br />
                      <SuiTypography variant="bold" color="text" fontWeight="medium">
                        {rapidApi.name}
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </Grid>
              )}
              {rapidApi.year_founded && (
                <Grid item xs={12}>
                  <Card>
                    <SuiBox p={3}>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        Year Founded
                      </SuiTypography>
                      <br />
                      <SuiTypography variant="bold" color="text" fontWeight="medium">
                        {rapidApi.year_founded}
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </Grid>
              )}
              {rapidApi.updated_at && (
                <Grid item xs={12}>
                  <Card>
                    <SuiBox p={3}>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        Updated At
                      </SuiTypography>
                      <br />
                      <SuiTypography variant="bold" color="text" fontWeight="medium">
                        {rapidApi.updated_at}
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </Grid>
              )}
              {rapidApi.phone_number && (
                <Grid item xs={12}>
                  <Card>
                    <SuiBox p={3}>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        Phhone Number
                      </SuiTypography>
                      <br />
                      <SuiTypography variant="bold" color="text" fontWeight="medium">
                        {rapidApi.phone_number}
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </Grid>
              )}
              {rapidApi.name && (
                <Grid item xs={12}>
                  <Card>
                    <SuiBox p={3}>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        Address
                      </SuiTypography>
                      <br />
                      {rapidApi.address ?? (
                        <SuiTypography variant="bold" color="text" fontWeight="medium">
                          {rapidApi.address}
                        </SuiTypography>
                      )}
                      <br />
                      {rapidApi.address_country ?? (
                        <SuiTypography variant="bold" color="text" fontWeight="medium">
                          {rapidApi.address_country}
                        </SuiTypography>
                      )}
                      <br />
                      {rapidApi.address_country_code ?? (
                        <SuiTypography variant="bold" color="text" fontWeight="medium">
                          {rapidApi.address_country_code}
                        </SuiTypography>
                      )}
                      <br />
                      {rapidApi.address_name ?? (
                        <SuiTypography variant="bold" color="text" fontWeight="medium">
                          {rapidApi.address_name}
                        </SuiTypography>
                      )}
                      <br />
                      {rapidApi.address_postal_code ?? (
                        <SuiTypography variant="bold" color="text" fontWeight="medium">
                          {rapidApi.address_postal_code}
                        </SuiTypography>
                      )}
                    </SuiBox>
                  </Card>
                </Grid>
              )}

              {rapidApi.alternate_name && (
                <Grid item xs={12}>
                  <Card>
                    <SuiBox p={3}>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        Alternate Name
                      </SuiTypography>
                      <br />
                      <SuiTypography variant="bold" color="text" fontWeight="medium">
                        {rapidApi.alternate_name}
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </Grid>
              )}
              {rapidApi.business_type && (
                <Grid item xs={12}>
                  <Card>
                    <SuiBox p={3}>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        Business Type
                      </SuiTypography>
                      <br />
                      <SuiTypography variant="bold" color="text" fontWeight="medium">
                        {rapidApi.business_type}
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </Grid>
              )}
              {rapidApi.business_type && (
                <Grid item xs={12}>
                  <Card>
                    <SuiBox p={3}>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        Address
                      </SuiTypography>
                      {rapidApi.address && (
                        <>
                          <br />
                          <SuiTypography variant="bold" color="text" fontWeight="medium">
                            {rapidApi.address}
                          </SuiTypography>
                        </>
                      )}
                      {rapidApi.address_country && (
                        <>
                          <br />
                          <SuiTypography variant="bold" color="text" fontWeight="medium">
                            {rapidApi.address_country}
                          </SuiTypography>
                        </>
                      )}
                      {rapidApi.address_country_code && (
                        <>
                          <br />
                          <SuiTypography variant="bold" color="text" fontWeight="medium">
                            {rapidApi.address_country_code}
                          </SuiTypography>
                        </>
                      )}
                      {rapidApi.address_name && (
                        <>
                          <br />
                          <SuiTypography variant="bold" color="text" fontWeight="medium">
                            {rapidApi.address_name}
                          </SuiTypography>
                        </>
                      )}
                      {rapidApi.address_postal_code && (
                        <>
                          <br />
                          <SuiTypography variant="bold" color="text" fontWeight="medium">
                            {rapidApi.address_postal_code}
                          </SuiTypography>
                        </>
                      )}
                    </SuiBox>
                  </Card>
                </Grid>
              )}
              {rapidApi.domain && (
                <Grid item xs={12}>
                  <Card>
                    <SuiBox p={3}>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        Domain
                      </SuiTypography>
                      <br />
                      <SuiTypography variant="bold" color="text" fontWeight="medium">
                        {rapidApi.domain} <br />
                        {rapidApi.domain_name && rapidApi.domain_name} <br />
                        {rapidApi.domain_tld && rapidApi.domain_tld}
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </Grid>
              )}

              {rapidApi.industries && (
                <Grid item xs={12}>
                  <Card>
                    <SuiBox p={3}>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        Industries
                      </SuiTypography>
                      {rapidApi.industries.map((r) => (
                        <SuiTypography variant="bold" color="text" fontWeight="medium">
                          <br />
                          {r}
                        </SuiTypography>
                      ))}
                    </SuiBox>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={3} alignItems="center">
              {rapidApi.monthly_visitors && (
                <Grid item xs={12}>
                  <Card>
                    <SuiBox p={3}>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        Monthly Visitors
                      </SuiTypography>
                      <br />
                      <SuiTypography variant="bold" color="text" fontWeight="medium">
                        {rapidApi.monthly_visitors}
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </Grid>
              )}
              {rapidApi.revenue && (
                <Grid item xs={12}>
                  <Card>
                    <SuiBox p={3}>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        Revenue
                      </SuiTypography>
                      <br />
                      <SuiTypography variant="bold" color="text" fontWeight="medium">
                        {rapidApi.revenue}
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </Grid>
              )}
              {rapidApi.size && (
                <Grid item xs={12}>
                  <Card>
                    <SuiBox p={3}>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        Size
                      </SuiTypography>
                      <br />
                      <SuiTypography variant="bold" color="text" fontWeight="medium">
                        {rapidApi.size}
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </Grid>
              )}
              {rapidApi.social_networks && (
                <Grid item xs={12}>
                  <Card>
                    <SuiBox p={3}>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        Social Networks
                      </SuiTypography>
                      {Object.keys(rapidApi.social_networks).map((r) => (
                        <SuiTypography variant="bold" color="text" fontWeight="medium">
                          <br />
                          {rapidApi.social_networks[r]}
                        </SuiTypography>
                      ))}
                    </SuiBox>
                  </Card>
                </Grid>
              )}
              {rapidApi.technologies && (
                <Grid item xs={12}>
                  <Card>
                    <SuiBox p={3}>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        Technologies
                      </SuiTypography>
                      {rapidApi.technologies.map((r) => (
                        <SuiTypography variant="bold" color="text" fontWeight="medium">
                          <br />
                          {r}
                        </SuiTypography>
                      ))}
                    </SuiBox>
                  </Card>
                </Grid>
              )}

              {rapidApi.technology_categories && (
                <Grid item xs={12}>
                  <Card>
                    <SuiBox p={3}>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        Technology Categories
                      </SuiTypography>
                      {rapidApi.technology_categories.map((r) => (
                        <SuiTypography variant="bold" color="text" fontWeight="medium">
                          <br />
                          {r}
                        </SuiTypography>
                      ))}
                    </SuiBox>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={3} direction="row" justify="flex-end" alignItems="top">
              {rapidApi.description && (
                <Grid item xs={12}>
                  <Card>
                    <SuiBox p={3}>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        Description
                      </SuiTypography>
                      <br />
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        {rapidApi.description}
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </SuiBox>
  );
}

export default RapidApi;

// RapidApi.TrackReviewChanges = {
//   appointment: null,
// };

RapidApi.propTypes = {
  appointment: PropTypes.objectOf(PropTypes.object).isRequired,
  customerUser: PropTypes.objectOf(PropTypes.object).isRequired,
};
