import PropTypes from "prop-types";

function HomeIcon({ color }) {
  return (
    <svg width="34" height="34" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6721 3.12968C15.3446 2.85208 14.9293 2.69971 14.5 2.69971C14.0707 2.69971 13.6554
 2.85208 13.3279 3.12968L4.26542 10.8183C4.06474 10.9884 3.90351 11.2002 3.79292 11.4389C3.68233 
 11.6776 3.62503 11.9376 3.625 12.2006V23.5626C3.625 24.0433 3.81596 24.5043 4.15587 24.8442C4.49578 
 25.1841 4.9568 25.3751 5.4375 25.3751H11.1771C11.4174 25.3751 11.6479 25.2796 11.8179 25.1097C11.9879 
 24.9397 12.0833 24.7092 12.0833 24.4688V16.9168H16.9167V24.4688C16.9167 24.9691 17.3227 25.3751 17.8229 
 25.3751H23.5625C24.0432 25.3751 24.5042 25.1841 24.8441 24.8442C25.184 24.5043 25.375 24.0433 25.375
  23.5626V12.2006C25.375 11.9376 25.3177 11.6776 25.2071 11.4389C25.0965 11.2002 24.9353 10.9884 24.7346
   10.8183L15.6721 3.12968Z"
        fill={color}
        fillOpacity="0.85"
      />
    </svg>
  );
}

HomeIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default HomeIcon;
