/* eslint-disable jsx-a11y/anchor-is-valid */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props

// @mui material components
import Link from "@mui/material/Link";
// import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React base styles
import axios from "axios";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import AppointmentChat from "examples/AppointmentChat";
import { useSoftUIController } from "context";
import { makeStyles } from "@material-ui/core/styles";
import FooterModal from "./FooterModal";

function Footer() {
  const [controller] = useSoftUIController();

  const { miniSidenav } = controller;
  const useStyles = makeStyles(() => ({
    footerContainer: {
      width: "100%",
      backgroundColor: "#f8f9fa !important",
      position: "fixed",
      bottom: 0,
    },
    footer: {
      width: miniSidenav ? `calc(100% - 160px)` : `calc(100% - 340px)`,
      "@media (max-width: 1024px)": {
        marginLeft: miniSidenav ? "0px" : "180px",
        display: "block",
      },
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
      paddingTop: "20px",
      paddingBottom: "20px",
      paddingRight: "10px",
      paddingLeft: "10px",
      color: "#4A4A4A",
    },
  }));
  const classes = useStyles();

  const { href, name } = { href: "https://www.casa-aktiva.com/", name: "Casa Aktiva" };
  const [footerItems, setFooterItems] = useState(null);
  const [footerItem, setFooterItem] = useState(null);
  const [openAppointmentModal, setOpenAppointmentModal] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/footer-pages`)
      .then((res) => {
        if (res) {
          setFooterItems(res.data);
        }
      })
      .catch(() => {});
    return () => {
      setFooterItems(null);
    };
  }, []);

  const handleLinkClick = (id) => {
    setFooterItem(footerItems.filter((res) => res.id === id)[0]);
    setOpenAppointmentModal(true);
  };

  const handleCloseAppointmentModal = () => {
    setFooterItem(null);
    setOpenAppointmentModal(false);
  };

  return (
    <div className={classes.footerContainer}>
      {openAppointmentModal && footerItem ? (
        <FooterModal
          open={openAppointmentModal}
          handleClose={handleCloseAppointmentModal}
          footerItem={footerItem}
        />
      ) : (
        ""
      )}
      <div className={classes.footer}>
        <SuiBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          fontSize="12px"
          fontWeight="400"
          px={1.5}
          fontFamily="Poppins"
        >
          Copyright &copy; {new Date().getFullYear()}
          <Link href={href} target="_blank" display="flex" alignItems="center">
            <Typography
              color="#4A4A4A"
              fontSize="12px"
              fontWeight="400"
              textTransform="uppercase"
              fontFamily="Poppins"
            >
              &nbsp;{name}&nbsp;
            </Typography>
            <Typography color="#4A4A4A" fontSize="12px" fontWeight="400" fontFamily="Poppins">
              GmbH
            </Typography>
          </Link>
        </SuiBox>
        <SuiBox
          component="ul"
          sx={({ breakpoints }) => ({
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            listStyle: "none",
            mt: 1,
            mb: 0,
            p: 0,

            [breakpoints.up("lg")]: {
              mt: 0,
            },
          })}
        >
          {footerItems &&
            footerItems.map((res) => (
              <SuiBox key={res.id} component="li" px={2} lineHeight={1} sx={{ cursor: "pointer" }}>
                <Link target="_blank">
                  <Typography
                    color="#4A4A4A"
                    fontSize="12px"
                    fontWeight="400"
                    fontFamily="Poppins"
                    onClick={() => handleLinkClick(res.id)}
                  >
                    {res.label}
                  </Typography>
                </Link>
              </SuiBox>
            ))}
          <AppointmentChat />
        </SuiBox>
      </div>
    </div>
  );
}

export default Footer;
