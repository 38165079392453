import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import MuiLink from "@mui/material/Link";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import LinkedIn from "./iconComponents/LinkedIn";
import Facebook from "./iconComponents/Facebook";
import Twitter from "./iconComponents/Twitter";
import Crunchbase from "./iconComponents/Crunchbase";
import Instagram from "./iconComponents/Instagram";

const useStyles = makeStyles(() => ({
  styleStack: {
    width: "100%",
  },
  styleTypographyTitle: {
    fontSize: "15px",
    fontWeight: "400",
    fontStyle: "normal",
    marginRight: "170px",
    color: "#FF7701",
    lineHeight: "35px",
    textTransform: "uppercase",
    margin: "0",
  },
  icon: {
    transition: "transform 0.1s ease",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  styleTypographyNormal: {
    // width: "75%",
    textAlign: "left",
    fontSize: "15px",
    fontWeight: "400",
    fontStyle: "normal",
    color: "#000000",
    lineHeight: "15px",
    // marginRight: "170px",
    // padding: "2% 5% 4% 5%",
    // marginBottom: "70px",
  },
  styleTypographyLink: {
    // width: "75%",
    textAlign: "left",
    fontSize: "15px",
    fontWeight: "400",
    fontStyle: "normal",
    color: "#FF7701",
    lineHeight: "15px",
    // marginRight: "170px",
    // padding: "2% 5% 4% 5%",
    // marginBottom: "70px",
  },
  styleTypographyNormal1: {
    // width: "75%",
    textAlign: "center",
    fontSize: "15px",
    fontWeight: "400",
    fontStyle: "normal",
    color: "#000000",
    lineHeight: "17px",
    // marginRight: "170px",
    // padding: "2% 5% 4% 5%",
    // marginBottom: "70px",
  },
  styleTypographyNormalBold1: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    fontStyle: "normal",
    color: "#000000",
    lineHeight: "15px",
  },
  styleTypographydesc: {
    // width: "75%",
    textAlign: "left",
    fontSize: "15px",
    fontWeight: "400",
    fontStyle: "normal",
    color: "#000000",
    lineHeight: "20px",
    marginBottom: "70px",
  },
  styleTypography2: {
    width: "100%",
    padding: "0px 5px 15px",
    borderBottom: "1px solid #ccc",
    marginBottom: "8px",
  },
}));

function Header({ companyInfo, loadingCompany }) {
  // const formatString = (str) => {
  //   const words = str.split("_");
  //   const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  //   const formattedString = capitalizedWords.join(" ");

  //   return formattedString;
  // };

  const classes = useStyles();

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "#FF7701",
          },
        },
      },
    },
  });

  return loadingCompany ? (
    <ThemeProvider theme={theme}>
      <Stack mt="4%" flexDirection="column" alignItems="center">
        <CircularProgress size="5%" />
      </Stack>
    </ThemeProvider>
  ) : (
    <>
      <Stack
        className={classes.styleTypography2}
        alignItems="left"
        mb={2}
        sx={{
          display: { xs: "none", sm: "block" }, // Hide on 'xs' (extra small) screens and show on 'sm' (small) screens and larger
        }}
      >
        {companyInfo && (
          <Typography
            style={{ display: "flex", gap: "20px", alignItems: "center" }}
            component="div"
          >
            <Typography>
              <img
                src="https://seeklogo.com/images/B/business-management-logo-03334B0904-seeklogo.com.png"
                alt="Google Logo"
                style={{ borderRadius: "50%", width: "100px", border: "1px solid gray" }}
              />
            </Typography>
            <Typography component="div">
              <Typography className={classes.styleTypographyTitle}>{companyInfo.name}</Typography>
              {companyInfo.links && (
                <Grid item xs={12} sm={6} mt={1}>
                  {companyInfo.links && (
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{ gap: "10px" }}
                    >
                      {Object.entries(companyInfo.links).map(([key, value]) => {
                        if (
                          value &&
                          ["linkedin", "facebook", "twitter", "crunchbase", "instagram"].includes(
                            key
                          )
                        ) {
                          const IconComponent = {
                            linkedin: LinkedIn,
                            facebook: Facebook,
                            twitter: Twitter,
                            crunchbase: Crunchbase,
                            instagram: Instagram,
                          }[key];

                          return (
                            <Box key={key} className={classes.icon}>
                              <MuiLink href={value} target="_blank" rel="noopener noreferrer">
                                <IconComponent color="#f88d46" />
                              </MuiLink>
                            </Box>
                          );
                        }
                        return null;
                      })}
                    </Box>
                  )}
                </Grid>
              )}
            </Typography>
          </Typography>
        )}
      </Stack>
    </>
  );
}

Header.propTypes = {
  companyInfo: PropTypes.array.isRequired,
  loadingCompany: PropTypes.bool.isRequired,
};

export default Header;
