import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Grid, styled } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import arrowLeft from "../../../assets/images/enrichment/arrowLeft.svg";
import Appointment from "../components/Appointment/Appointment";
import NotFound from "../components/NotFound";
import CompanyIndex from "../components/CompanyIndex";
import AiP from "../components/AiP";
import AiPa from "../components/AiPa";

const StyledTabs = styled(Tabs)({
  borderBottom: "2px solid #FF7701",
});

const useStyles = makeStyles(() => ({
  header: {
    "@media (min-width: 450px) and (max-width:900px)": {
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
  },
  tab: {
    "@media (min-width: 320px) and (max-width:370px)": {
      marginLeft: "60px",
    },
    "@media (min-width: 375px) and (max-width:425px)": {
      marginLeft: "60px",
    },
  },
  button: {
    display: "none",
    "@media (max-width: 768px)": {
      display: "inline-block",
    },
  },
}));

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "10px",
          backgroundColor: "rgba(237, 108, 2, 1)",
          "&:hover": {
            backgroundColor: "rgb(255 138 43)",
          },
        },
      },
    },
  },
});
const StyledTab = styled(Tab)({
  color: "#5C5C5C",
  fontWeight: "600",
  textTransform: "none",
  "&.Mui-selected": {
    color: "#FF7701",
  },
});

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

TabPanel.defaultProps = {
  children: null,
};

function Slider({
  companyInfo,
  aboutInfo,
  employeeInfo,
  setEmployeeInfo,
  customerUser,
  campaignId,
  appointmentId,
  loadingCompany,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [leadIdentificator, setLeadIdentificator] = useState("");
  const companyIndexRef = useRef(null);
  const aiActivated = customerUser?.customerUser?.customer?.ai_activated;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBackButton = () => {
    history.goBack();
  };

  const handleEmployeeButtonClick = () => {
    if (companyIndexRef.current) {
      companyIndexRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const showEmployeeButton = () => value === 1;

  return (
    <>
      <Box sx={{ width: "100%", height: "100%", padding: "0" }}>
        <Grid
          container
          className={classes.header}
          alignItems="center"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Grid item xs={1} sm={1} md={2} lg={4}>
            <Button onClick={handleBackButton} sx={{ color: "#000000", textTransform: "none" }}>
              <img src={arrowLeft} alt="Arrow Left" />
              Back
            </Button>
          </Grid>
          <Grid item xs={5} sm={8} md={6} lg={4} className={classes.tab}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              centered
            >
              <StyledTab label="Lead info" />
              <StyledTab label="Company Info" />
              {aiActivated && <StyledTab label="Ai Researcher" />}
              {aiActivated && <StyledTab label="Ai Assistant" />}
            </StyledTabs>
          </Grid>
          {showEmployeeButton() && (
            <Grid ml={2} item xs={1} sm={1} md={2} lg={4}>
              <ThemeProvider theme={theme}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleEmployeeButtonClick}
                    className={classes.button}
                  >
                    Employee
                  </Button>
                </div>
              </ThemeProvider>
            </Grid>
          )}
        </Grid>

        <TabPanel value={value} index={0}>
          <Appointment
            setLeadIdentificator={setLeadIdentificator}
            customerUser={customerUser}
            campaignId={campaignId}
            appointmentId={appointmentId}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          {companyInfo.length > 0 || aboutInfo.description ? (
            <CompanyIndex
              customerUser={customerUser}
              ref={companyIndexRef}
              loadingCompany={loadingCompany}
              companyInfo={companyInfo}
              employeeInfo={employeeInfo}
              setEmployeeInfo={setEmployeeInfo}
              aboutInfo={aboutInfo}
              appointmentId={appointmentId}
            />
          ) : (
            <NotFound />
          )}
        </TabPanel>
        {aiActivated && (
          <TabPanel value={value} index={2}>
            <AiP
              customerUser={customerUser}
              leadIdentificator={leadIdentificator}
              loadingCompany={loadingCompany}
            />
          </TabPanel>
        )}
        {aiActivated && (
          <TabPanel value={value} index={3}>
            <AiPa
              customerUser={customerUser}
              leadIdentificator={leadIdentificator}
              loadingCompany={loadingCompany}
            />
          </TabPanel>
        )}
      </Box>
    </>
  );
}

Slider.propTypes = {
  companyInfo: PropTypes.any.isRequired,
  aboutInfo: PropTypes.any.isRequired,
  employeeInfo: PropTypes.any.isRequired,
  setEmployeeInfo: PropTypes.any.isRequired,
  customerUser: PropTypes.object.isRequired,
  campaignId: PropTypes.number.isRequired,
  appointmentId: PropTypes.number.isRequired,
  loadingCompany: PropTypes.bool.isRequired,
};

export default Slider;
