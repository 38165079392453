function ChevronDown() {
  return (
    <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4062 0.249318L7.81328 6.59648L1.21014 0.249318C1.01205 0.0589038 0.660155 0.0589038 0.462367 0.249318C0.264579 0.439733 0.264851 0.778249 0.462945 0.968663L7.44024 7.65434C7.55029 7.76012 7.68229 7.80244 7.81425 7.80244C7.94621 7.80244 8.07814 7.76012 8.18802 7.65434L15.1546 0.947506C15.3523 0.757091 15.3521 0.418576 15.154 0.228162C14.9559 0.0377469 14.604 0.0377466 14.4062 0.249318Z"
        fill="#1C2434"
      />
    </svg>
  );
}

export default ChevronDown;
