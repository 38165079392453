import { Grid, Icon, Menu, Stack, ThemeProvider, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useState } from "react";
import ReorderIcon from "@mui/icons-material/Reorder";
import MenuItem from "@mui/material/MenuItem";
import avatarChat from "../../../assets/images/authentication_v2/avatarChat.svg";

function OpenSidebar({ notes, openChat, setChatFilter }) {
  const useStyles = makeStyles(() => ({
    value: {
      fontFamily: "Poppins",
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "400",
      color: "#4A4A4A",
      marginTop: "10px",
      marginBottom: "10px",
    },
    description: {
      fontFamily: "Poppins",
      textAlign: "center",
      fontSize: "14px",
      fontWeight: "300",
      color: "#4A4A4A",
      marginTop: "10px",
      width: "100%",
      marginBottom: "10px",
    },
    button: {
      border: "1px solid #5c5c5c",
      borderRadius: "20px",
      backgroundColor: "white",
      color: "#5c5c5c",
      padding: "8px 15px",
      fontSize: "14px",
      cursor: "pointer",
      width: "200px",
      marginLeft: "80px",
    },
    search: {
      display: "flex",
      flexWrap: "wrap",
      width: "85%",
      alignItems: "center",
    },
    menuIcon: {
      flex: "1",
      cursor: "pointer",
    },
    tab: {
      width: "50%",
    },
    customTablist: {
      "& .Mui-selected": {
        color: "#ff4d00 !important",
        borderColor: "#ff4d00 !important",
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "#ff4d00 !important",
      },
    },
  }));
  const classes = useStyles();
  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "lightgrey",
          },
        },
      },
    },
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const options = ["All", "Unread", "Read"];

  const handleClose = (key) => {
    setAnchorEl(null);
    if (key !== undefined) {
      setChatFilter(key);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNoteClick = (appointment, res) => {
    openChat(appointment, res);
  };

  function handleSearch(res) {
    const nameMatches =
      res.appointment &&
      res.appointment.campaign_lead &&
      res.appointment.lead_identificator.toLowerCase().includes(searchQuery.toLowerCase());

    return nameMatches;
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={classes.main}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              backgroundColor: "#F7F7F7",
              padding: "10px",
            }}
          >
            <span className={classes.search}>
              <Icon sx={{ color: "#FF4D00D9" }}>search</Icon>
              <input
                style={{
                  border: "0",
                  backgroundColor: "#F7F7F7",
                  outline: "none",
                }}
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search messages"
              />
            </span>
            <ReorderIcon className={classes.menuIcon} onClick={handleClick} />
          </div>
          {anchorEl !== null && (
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={() => handleClose()}
              PaperProps={{
                style: {
                  width: "15ch",
                },
              }}
            >
              {options.map((option, key) => (
                <MenuItem
                  onClick={() => {
                    handleClose(key);
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          )}
        </div>
        <div style={{ overflow: "auto", marginTop: "10px" }}>
          {notes && notes.length > 0 ? (
            notes
              .filter((res) => handleSearch(res))
              .map((res, index) => (
                <Grid
                  item
                  sx={{
                    backgroundColor:
                      res.appointment.class_name === "error" ? "#FBFAFB" : "#FFFFFFF",
                    marginBottom: "0",
                    cursor: "pointer",
                    borderBottom: "1px solid #FF4D00D9",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    padding: "5px",
                  }}
                  mb={2}
                  lg={12}
                  onClick={() => handleNoteClick(res.appointment, res)}
                  key={res.appointment.id || index}
                >
                  <img src={avatarChat} alt={avatarChat} />
                  <Stack style={{ marginLeft: "10px", width: "200px", flex: 1 }}>
                    <Typography
                      fontFamily="Poppins"
                      fontSize="15px"
                      fontWeight={res.pivot_unread_notes !== 0 ? "700" : "500"}
                      color={res.appointment.class_name === "error" ? "#C3BDBD" : "#FF4D00D9"}
                      style={{ textWrap: "wrap" }}
                    >
                      {res.appointment &&
                        res.appointment.campaign_lead &&
                        res.appointment.lead_identificator.replace("Firmenname: ", "")}
                    </Typography>
                  </Stack>
                  <div style={{ marginTop: "4px", marginRight: "2px" }}>
                    <Typography
                      fontFamily="Poppins"
                      fontSize="12px"
                      fontWeight="500"
                      color={res.appointment.class_name === "error" ? "#C3BDBD" : "#4A4A4A"}
                    >
                      {res.pivot_updated_at}
                    </Typography>
                    {res.pivot_unread_notes !== 0 && (
                      <Typography
                        sx={{
                          backgroundColor: "#FF4D00D9",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        ml={4}
                        mt={1}
                        fontSize="12px"
                        fontFamily="Poppins"
                        fontWeight="500"
                        color="#FFFFFF"
                      >
                        {res.pivot_unread_notes}
                      </Typography>
                    )}
                  </div>
                </Grid>
              ))
          ) : (
            <>
              <span>
                <img
                  src="https://media.istockphoto.com/id/1450180713/vector/job-interview-concept.jpg?s=612x612&w=0&k=20&c=UedCwHHJiMLvum9EZoH5DFMphFFwnMyyXfHMkxhrmSI="
                  alt="A beautiful landscape"
                  style={{
                    width: "100%",
                    alignItems: "center",
                  }}
                />
              </span>
              <Stack style={{ textWrap: "wrap" }} sx={{ flexWrap: "wrap" }}>
                <Typography className={classes.value}>No Message yet</Typography>
              </Stack>
            </>
          )}
        </div>
      </ThemeProvider>
    </>
  );
}

OpenSidebar.propTypes = {
  openChat: PropTypes.func.isRequired,
  notes: PropTypes.any.isRequired,
  setChatFilter: PropTypes.any.isRequired,
};

export default OpenSidebar;
