import PropTypes from "prop-types";

function ChatIcon({ color }) {
  return (
    <svg width="34" height="34" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.9375 4.875H4.06253C3.63155 4.875 3.21823 5.0462 2.91348 5.35095C2.60873 5.6557 2.43753 6.06902 2.43753 6.5V22.75C2.43566 23.0599 2.52332 23.3637 2.68997 23.6249C2.85663 23.8862 3.09519 24.0937 3.37698 24.2227C3.59172 24.3226 3.82566 24.3746 4.06253 24.375C4.444 24.3741 4.81282 24.2381 5.10354 23.9911C5.10839 23.9879 5.11283 23.9842 5.11675 23.9799L8.37894 21.125H21.9375C22.3685 21.125 22.7818 20.9538 23.0866 20.649C23.3913 20.3443 23.5625 19.931 23.5625 19.5V6.5C23.5625 6.06902 23.3913 5.6557 23.0866 5.35095C22.7818 5.0462 22.3685 4.875 21.9375 4.875ZM16.25 15.4375H9.75003C9.53454 15.4375 9.32788 15.3519 9.1755 15.1995C9.02313 15.0472 8.93753 14.8405 8.93753 14.625C8.93753 14.4095 9.02313 14.2028 9.1755 14.0505C9.32788 13.8981 9.53454 13.8125 9.75003 13.8125H16.25C16.4655 13.8125 16.6722 13.8981 16.8246 14.0505C16.9769 14.2028 17.0625 14.4095 17.0625 14.625C17.0625 14.8405 16.9769 15.0472 16.8246 15.1995C16.6722 15.3519 16.4655 15.4375 16.25 15.4375ZM16.25 12.1875H9.75003C9.53454 12.1875 9.32788 12.1019 9.1755 11.9495C9.02313 11.7972 8.93753 11.5905 8.93753 11.375C8.93753 11.1595 9.02313 10.9528 9.1755 10.8005C9.32788 10.6481 9.53454 10.5625 9.75003 10.5625H16.25C16.4655 10.5625 16.6722 10.6481 16.8246 10.8005C16.9769 10.9528 17.0625 11.1595 17.0625 11.375C17.0625 11.5905 16.9769 11.7972 16.8246 11.9495C16.6722 12.1019 16.4655 12.1875 16.25 12.1875Z"
        fill={color}
        fillOpacity="0.8"
      />
    </svg>
  );
}

ChatIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ChatIcon;
