import { makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import ListItemText from "@mui/material/ListItemText";

function LeadInfo({ appointment }) {
  const useStyles = makeStyles(() => ({
    typographyLabelStyle: {
      fontSize: "14px",
      color: "#626262",
      marginBottom: "7px",
    },
    typographyBodyStyle: {
      fontWeight: "400",
      fontSize: "14px",
      color: "black",
    },
    boxStyle: {
      // paddingLeft: "20px",
      paddingBottom: "20px",
    },
    gridStyle: {
      padding: "5px 30px 30px 30px",
    },
    listItemTextStyle: {
      margin: "10px",
    },
    mainGridStyle: {
      marginTop: "0px",
    },
  }));
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.mainGridStyle}>
        {appointment.lead_raw_fields
          ? appointment.lead_raw_fields.map((res) =>
              res.data !== undefined && res.data && res.data.length > 0 ? (
                <>
                  <Box paddingLeft={{ sm: "0", xs: "0", lg: "20px" }} className={classes.boxStyle}>
                    <Typography className={classes.typographyLabelStyle} component="div">
                      {res.field_group_name}
                    </Typography>
                  </Box>
                  <Grid container padding={{ sm: "10" }} spacing={3}>
                    {res.data.map((r) => (
                      <Grid item sx={12} sm={4} md={4}>
                        <ListItemText
                          primary={
                            <Typography className={classes.typographyLabelStyle} component="div">
                              {r.name}
                            </Typography>
                          }
                          secondary={
                            <Typography className={classes.typographyBodyStyle} component="div">
                              {r.value}
                            </Typography>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                ""
              )
            )
          : ""}
      </Grid>
    </>
  );
}
LeadInfo.propTypes = {
  appointment: PropTypes.array.isRequired,
};
export default LeadInfo;
