import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

function WebsiteTable({ websiteData }) {
  const useStyles = makeStyles(() => ({
    TableContainerWebsite: {
      borderRadius: "0px 0px 3.5px 3.5px",
      marginBottom: "5%",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      // padding: "0 2%",
      maxWidth: "90%",
      maxHeight: "400px",
      overflowY: "auto",
    },
    tableCell: {
      borderBottom: "0",
      margin: "0",
    },
    table: {
      fontSize: "14px",
      fontWeight: "400",
      borderTop: "1px solid #b8bdbd",
    },
    tableRow: { borderBottom: "1px solid #50555C" },
    noInfo: { padding: "15px", fontSize: "14px" },
  }));
  const classes = useStyles();
  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "#FF7701",
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Typography fontSize="14px" color="#767676" fontWeight="500" mb={2}>
        CONNECTED WEBSITE
      </Typography>
      <TableContainer className={classes.TableContainerWebsite} component={Paper}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>Website</TableCell>
            </TableRow>
          </TableHead>
          {websiteData && Object.keys(websiteData).length > 0 && (
            <TableBody>
              {Object.keys(websiteData).map((key) => (
                <TableRow>
                  <TableCell className={classes.table} width="10%" component="th" scope="row">
                    {key}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {websiteData && Object.keys(websiteData).length === 0 && (
          <Typography className={classes.noInfo}>No information yet!</Typography>
        )}
      </TableContainer>
    </ThemeProvider>
  );
}

WebsiteTable.propTypes = {
  websiteData: PropTypes.array.isRequired,
};

export default WebsiteTable;
