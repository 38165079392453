import PropTypes from "prop-types";

// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
// import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";

// Custom styles for Calendar
import CalendarRoot from "examples/Calendar/CalendarRoot";
// import SuiButton from "components/SuiButton";
// import { Grid } from "@mui/material";

function Calendar({ header, showAppointmentCalendar, ...rest }) {
  const validClassNames = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ];

  const events = rest.events
    ? rest.events.map((el) => ({
        ...el,
        className: validClassNames.find((item) => item === el.className)
          ? `event-${el.className}`
          : "event-info",
      }))
    : [];

  const uniqueDates = new Map();

  // Iterate through the records and filter by date
  const filteredEvents = events.filter((event) => {
    const startDate = event.start.split(" ")[0]; // Extract date part
    if (!uniqueDates.has(startDate)) {
      uniqueDates.set(startDate, true);
      return true;
    }
    return false;
  });

  const renderEventContent = (eventInfo) => (
    <div>{eventInfo.event.start.getHours() === 0 && <div />}</div>
  );

  const today = new Date().toISOString().split("T")[0]; // Get today's date in ISO format

  const eventRender = (info) => {
    if (info.dateStr === today) {
      info.el.classList.add("rounded-today"); // Add the custom class to today's date cell
    }
  };

  return (
    <Card
      sx={{
        height: "100%",
        borderRadius: "0px !important",
        backgroundColor: "#E7E7E733 !important",
        boxShadow: "none !important",
        margin: "10px 0px 5px 10px",
      }}
    >
      {/* <SuiBox pb={1} px={2} lineHeight={1}>
        <Grid container justifyContent="center" pt={2}>
          <Grid item xs={12} md={6}>
            {header.title ? (
              <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {header.title}
              </SuiTypography>
            ) : null}
          </Grid>
          {header.date ? (
            <SuiTypography component="p" variant="button" color="text" fontWeight="medium">
              {header.date}
            </SuiTypography>
          ) : null}
          {showAppointmentCalendar && (
            <Grid item xs={12} md={6}>
              <SuiTypography component="h6" variant="button" color="text" textAlign="right">
                <SuiButton size="small" color="warning" onClick={showAppointmentCalendar}>
                  Subscribe
                </SuiButton>
              </SuiTypography>
            </Grid>
          )}
        </Grid>
      </SuiBox> */}
      <CalendarRoot p={2}>
        <FullCalendar
          {...rest}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={filteredEvents}
          initialView="dayGridMonth"
          height="100%"
          eventContent={renderEventContent}
          slotLabelFormat={{
            weekday: "short",
            month: "numeric",
            day: "numeric",
          }}
          dayHeaderContent={(arg) =>
            arg.date.toLocaleDateString("en-US", { weekday: "short" }).charAt(0)
          }
          headerToolbar={{
            left: "prev title next",
            center: "",
            right: "customButton",
          }}
          customButtons={{
            customButton: {
              text: "Subscribe",
              click: showAppointmentCalendar,
              buttonClassNames: "custom-button-class",
            },
          }}
          eventRender={eventRender}
        />
      </CalendarRoot>
    </Card>
  );
}

Calendar.defaultProps = {
  header: {
    title: "",
    date: "",
  },
};

Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
  showAppointmentCalendar: PropTypes.func.isRequired,
};

export default Calendar;
