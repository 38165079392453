import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import { CircularProgress, Box } from "@mui/material";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { dateToYMD } from "common/helpers";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import { makeStyles } from "@material-ui/core/styles";
// import Slider from "@mui/material/Slider";
// import Typography from "@mui/material/Typography";
// import FollowUp from "../../../../layouts/pages/dashboard/FollowUp";
import AppointmentInfo from "./AppointmentInfo";
import LeadInfo from "./LeadInfo";
import NoteAppointment from "../NoteAppointment";
// import ChatSVG from "../iconComponents/ChatSVG";
// import Chat from "../Chat";
// import InternalNote from "./InternalNote";
import InternalNote from "./InternalNote";
import NextAppointment from "./NextAppointment";
import Comment from "./InternalNote/InternalNote";

function Appointment({ customerUser, campaignId, appointmentId, setLeadIdentificator }) {
  const [appointment, setAppointment] = useState(null);
  const [downloadCounter, setDownloadCounter] = useState(0);
  const [reasonVal, setReasonVal] = useState("");
  const [appointmentReviewStatus, setAppointmentReviewStatus] = useState(null);
  const [appointmentReviewStatuses, setAppointmentReviewStatuses] = useState([]);
  const [reason, setReason] = useState(null);
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [today, setToday] = useState(dateToYMD(new Date()));
  const [loading, setLoading] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingCsv, setLoadingCsv] = useState(false);
  const [loadingXls, setLoadingXls] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  // const [isHovering, setIsHovering] = useState(false);
  // const [rate, setRate] = useState(null);
  // const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    setLoading(true);

    axios
      .get(
        `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${campaignId.campaignId}/appointments/appointment-review-statuses`
      )
      .then((response) => {
        if (response) {
          setAppointmentReviewStatuses(response.data);
          if (response.data[0].value) {
            setAppointmentReviewStatus(response.data[0].value);
          }
        }
      })
      .catch(() => {});
    axios
      .get(
        `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${campaignId.campaignId}/appointments/${appointmentId}`
      )
      .then(async (res) => {
        const appointmentRes = res.data.data;
        // const dealMade = appointmentRes?.deal_made_at === null;
        // setIsShow(dealMade);
        if (appointmentRes.campaign_lead.lead_identificator) {
          setLeadIdentificator(appointmentRes.campaign_lead.lead_identificator);
        }
        setAppointment(appointmentRes);
        // setRate(appointmentRes.client_rating);
        setDownloadCounter(appointmentRes.download_counter.toString());
        if (appointmentRes.review_status) {
          setAppointmentReviewStatus(appointmentRes.review_status);
        }
        setToday(dateToYMD(new Date(appointmentRes.appointment_at)));
        if (appointmentRes.review_status) {
          setReasonVal(
            appointmentRes.review_status !== "accepted" ? appointmentRes.review_status : ""
          );
        }

        if (!appointmentRes.is_prospect_contacted) {
          Swal.fire({
            title: "Did you contact the prospect?",
            text: "You won't be able to revert this!",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, prospect was contacted!",
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire("Congrats!", "Your got in touch with the prospect!", "success");
              axios
                .get(
                  `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${campaignId.campaignId}/appointments/${appointmentId}/prospect-contacted`
                )
                .then(() => {
                  axios
                    .get(
                      `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${campaignId.campaignId}/appointments/${appointmentId}`
                    )
                    .then((resA) => {
                      const appointmentResVal = resA.data.data;
                      setAppointment(appointmentResVal);
                    })
                    .catch(() => {});
                })
                .catch(() => {});
            }
          });
        }

        setLoading(false);
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
        setLoading(false);
      });
    return () => {
      setAppointment(null);
    };
  }, [appointmentId]);

  const changeReviewStatus = (pReviewStatus) => {
    const payload = {
      review_status: pReviewStatus,
      reason,
    };
    axios
      .post(
        `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${campaignId.campaignId}/appointments/${appointmentId}/update-review-status`,
        payload
      )
      .then((res) => {
        const appointmentRes = res.data;
        setAppointmentReviewStatus(appointmentRes.value);
        if (appointmentReviewStatus) {
          setReasonVal(appointmentReviewStatus);
        }
        Swal.fire("Success!", appointmentRes.message, "success");
        history.push("/dashboards/appointments");
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
  };

  const handleAppointmentReviewStatusChange = (e) => {
    setAppointmentReviewStatus(e.value);
    if (appointmentReviewStatuses.length > 0 && e.value !== "cancellation") {
      changeReviewStatus(e.value);
    }
  };

  const handleAppointmentReviewStatus = (e) => {
    e.persist();
    changeReviewStatus(appointmentReviewStatus);
  };

  const handleDownloadPdf = () => {
    setLoadingPdf(true);

    const payload = {
      is_appointment_approved_pdf: "YES",
    };

    if (appointment.customer_write_permission) {
      axios
        .post(
          `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${campaignId.campaignId}/appointments/${appointmentId}/download-appointment-pdf`,
          payload,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const filename = `export_${
            appointment.campaign_lead.lead_identificator
          }_appointment_${new Date().toJSON().slice(0, 16).replace(":", "_")}.pdf`;
          saveAs(res.data, filename);
          Swal.fire("Success!", res.data.message, "success");
          setDownloadCounter(parseInt(downloadCounter, 10) + 1);
          setLoadingPdf(false);
        })
        .catch((err) => {
          Swal.fire("Error!", err.message, "error");
          setLoadingPdf(false);
        });
    }
  };

  const handleDownloadCsv = () => {
    setLoadingCsv(true);
    const payload = {
      is_appointment_approved_csv: "YES",
    };

    if (appointment.customer_write_permission) {
      axios
        .post(
          `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${campaignId.campaignId}/appointments/${appointmentId}/download-appointment-csv`,
          payload,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const filename = `export_${
            appointment.campaign_lead.lead_identificator
          }_appointment_${new Date().toJSON().slice(0, 16).replace(":", "_")}.csv`;
          saveAs(res.data, filename);
          Swal.fire("Success!", res.data.message, "success");
          setDownloadCounter(parseInt(downloadCounter, 10) + 1);
          setLoadingCsv(false);
        })
        .catch((err) => {
          Swal.fire("Error!", err.message, "error");
          setLoadingCsv(false);
        });
    }
  };

  const handleDownloadXls = () => {
    setLoadingXls(true);
    const payload = {
      is_appointment_approved_csv: "YES",
    };

    if (appointment.customer_write_permission) {
      axios
        .post(
          `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${campaignId.campaignId}/appointments/${appointmentId}/download-appointment-xls`,
          payload,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const filename = `export_${
            appointment.campaign_lead.lead_identificator
          }_appointment_${new Date().toJSON().slice(0, 16).replace(":", "_")}.xls`;
          saveAs(res.data, filename);
          Swal.fire("Success!", res.data.message, "success");
          setDownloadCounter(parseInt(downloadCounter, 10) + 1);
          setLoadingXls(false);
        })
        .catch((err) => {
          Swal.fire("Error!", err.message, "error");
          setLoadingXls(false);
        });
    }
  };

  // const changeRate = (pRate) => {
  //   axios
  //     .post(
  //       `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${campaignId.campaignId}/appointments/${appointmentId}/rate`,
  //       {
  //         rate: pRate,
  //       }
  //     )
  //     .then(() => {
  //       // Swal.fire("Success!", res.data.message, "success");
  //     })
  //     .catch((err) => {
  //       Swal.fire("Error!", err.message, "error");
  //     });
  // };

  // const handleRate = (e, value) => {
  //   if (value === rate) return;
  //   setRate(value);
  //   changeRate(value);
  // };

  // const handleChatOpen = () => {
  //   setIsOpen(true);
  // };
  // const useStyles = makeStyles(() => ({
  //   slider: { width: "60%", padding: "3% 0" },
  // }));
  // const classes = useStyles();
  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "#FF7701",
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          track: {
            backgroundColor: "#FF7701",
          },
          root: {
            color: "orange",
          },
          thumb: {
            color: "white",
          },
          valueLabel: {
            backgroundColor: "#FF7701",
          },
          markLabel: {
            marginTop: "5%",
          },
          mark: {
            height: "20%",
            color: "black",
            marginTop: "4%",
          },
        },
      },
    },
  });

  return (
    <>
      {loading ? (
        <Box mt="35vh" display="flex" alignItems="center" justifyContent="center">
          <ThemeProvider theme={theme}>
            <CircularProgress size="5%" />
          </ThemeProvider>
        </Box>
      ) : (
        <>
          {appointment ? (
            <>
              <Grid container flexDirection="row">
                <Grid item sm={12} lg={7}>
                  <Grid container>
                    <Box>
                      <Grid item lg={9}>
                        <AppointmentInfo
                          handleDownloadPdf={handleDownloadPdf}
                          handleDownloadCsv={handleDownloadCsv}
                          handleDownloadXls={handleDownloadXls}
                          appointment={appointment}
                          loadingPdf={loadingPdf}
                          loadingCsv={loadingCsv}
                          loadingXls={loadingXls}
                        />
                      </Grid>
                    </Box>
                    <Grid item sm={12} lg={10} ml={{ lg: 2, xs: 0 }}>
                      {/* <Box>
                        <Typography
                          sx={{
                            fontSize: "12px",
                          }}
                          pl={3}
                          pt={2}
                        >
                          Rate Appointment
                        </Typography>
                        <Typography component="div" pl={3}>
                          {rate ? (
                            <ThemeProvider theme={theme}>
                              <Slider
                                className={classes.slider}
                                aria-label="Rating"
                                value={rate}
                                defaultValue={rate}
                                step={25}
                                min={25}
                                max={100}
                                color="secondary"
                                onChangeCommitted={handleRate}
                              />
                            </ThemeProvider>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </Box> */}
                      <LeadInfo appointment={appointment} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} lg={5}>
                  {/* <Grid item sm={12} md={12}>
                    {isShow && (
                      <ThemeProvider theme={theme}>
                        <FollowUp
                          setIsShow={setIsShow}
                          appointment={appointment}
                          customer={customerUser?.customerUser?.customer}
                        />
                      </ThemeProvider>
                    )}
                  </Grid> */}
                  <Box>
                    <Grid item sm={12} md={12}>
                      <NextAppointment
                        customerUser={customerUser}
                        appointment={appointment}
                        setReason={setReason}
                        reasonVal={reasonVal}
                        handleAppointmentReviewStatusChange={handleAppointmentReviewStatusChange}
                        handleAppointmentReviewStatus={handleAppointmentReviewStatus}
                        appointmentReviewStatuses={appointmentReviewStatuses}
                        appointmentReviewStatus={appointmentReviewStatus}
                      />
                    </Grid>
                    <Grid item sm={12} md={12}>
                      <NoteAppointment appointment={appointment} />
                    </Grid>
                    {appointment.customer_write_permission && (
                      <>
                        <Grid item sm={12} md={12}>
                          <InternalNote appointment={appointment} customerUser={customerUser} />
                        </Grid>
                        <Grid item sm={12} md={12}>
                          <Comment appointment={appointment} customerUser={customerUser} />
                        </Grid>
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : null}
        </>
      )}
    </>
  );
}

Appointment.propTypes = {
  customerUser: PropTypes.object.isRequired,
  campaignId: PropTypes.number.isRequired,
  appointmentId: PropTypes.number.isRequired,
  setLeadIdentificator: PropTypes.func.isRequired,
};

export default Appointment;
