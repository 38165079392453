/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

import ReactHtmlParser from "react-html-parser";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiAvatar from "components/SuiAvatar";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import avatar01 from "assets/images/avatar-01.jpg";
import axios from "axios";

import PropTypes from "prop-types";
import SuiEditor from "components/SuiEditor";
import { LoadingButton } from "@mui/lab";

function Comment({ appointment, customerUser, campaignId }) {
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [sendLoading, setSendLoading] = useState(false);

  const fetchComments = () => {
    axios
      .get(
        `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${appointment.id}/comments`
      )
      .then((res) => {
        const commentsRes = res.data.data.map((r) => ({
          image: avatar01,
          id: r.id,
          name: r.author,
          message: r.note,
          createdAt: r.created_at,
        }));
        setComments(commentsRes);
      })
      .catch(() => {
        setComments([]);
      });
  };

  useEffect(() => {
    fetchComments();
    return () => {};
  }, [appointment]);

  const onSubmitComment = () => {
    setSendLoading(true);
    const payload = {
      comment,
    };
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${appointment.id}/create-comment`,
        payload
      )
      .then(() => {
        setSendLoading(false);
        fetchComments();
        setComment("");
      })
      .catch(() => {
        setSendLoading(false);
        setComments([]);
      });
  };

  return (
    <Card>
      <SuiBox p={3}>
        <SuiBox mt={3} mb={1} px={1}>
          {comments.map(({ image, id, name, message, createdAt }, key) => (
            <SuiBox key={id} display="flex" mt={key === 0 ? 0 : 3}>
              <SuiBox flexShrink={0}>
                <SuiAvatar src={image} alt={name} />
              </SuiBox>
              <SuiBox flexGrow={1} ml={2}>
                <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  {name}
                </SuiTypography>
                <SuiBox mt={1} mb={2} lineHeight={0.75}>
                  <SuiTypography variant="button" fontWeight="regular" color="dark">
                    {ReactHtmlParser(message)}
                  </SuiTypography>
                  <SuiBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 2, sm: 0 }}>
                    <SuiTypography variant="button" fontWeight="regular" color="text">
                      {createdAt}
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
              <br />
            </SuiBox>
          ))}
        </SuiBox>
        <Divider />
        <SuiEditor
          value={comment}
          onChange={(value) => {
            setComment(value);
          }}
        />
        <SuiBox display="flex" alignItems="center" mt={3}>
          <SuiBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 2, sm: 0 }}>
            <SuiBox ml={2}>
              {sendLoading ? (
                <LoadingButton loading variant="outlined" color="primary" size="small" />
              ) : (
                <SuiButton variant="gradient" color="info" size="small" onClick={onSubmitComment}>
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; Send
                </SuiButton>
              )}
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default Comment;

// Comment.TrackReviewChanges = {
//   appointment: null,
// };

Comment.propTypes = {
  appointment: PropTypes.objectOf(PropTypes.object).isRequired,
  customerUser: PropTypes.objectOf(PropTypes.object).isRequired,
  campaignId: PropTypes.number.isRequired,
};
