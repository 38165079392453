import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { Badge, Button, Grid, Stack } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CloseIcon from "layouts/crm_dashboard/icons/CloseIcon";

function CalendarModal({ openModal, closeModal, events, handleEventClick, dateClicked }) {
  const isTimeBetween = (startTime, endTime) => {
    const currentTime = new Date();
    const formattedStartTime = new Date(currentTime.toDateString(), " ", startTime);
    const formattedEndTime = new Date(currentTime.toDateString(), " ", endTime);

    return currentTime >= formattedStartTime && currentTime <= formattedEndTime;
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);

    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
    return formattedDate;
  }

  const themeButton = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: "white",
            fontSize: "12px",
            fontFamily: "Poppins",
            backgroundColor: "#FF4D00D9",
            width: "40%",
            fontWeight: "700",
            boxShadow: "none",
            padding: "10px 10px",
          },
        },
      },
      MuiBadge: {
        styleOverrides: {
          badge: {
            backgroundColor: "#FF4D00D9 !important",
          },
        },
      },
    },
  });

  const themeActiveButton = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: "black",
            fontSize: "12px",
            fontFamily: "Poppins",
            backgroundColor: "#F7F3F1",
            width: "40%",
            fontWeight: "700",
            boxShadow: "none",
            padding: "10px 10px",
            "&:hover": {
              backgroundColor: "#FF4D00D9",
              color: "white",
            },
          },
        },
      },
    },
  });

  const themeCancelButton = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: "#FF4D00D9",
            fontSize: "12px",
            fontFamily: "Poppins",
            backgroundColor: "white",
            width: "40%",
            fontWeight: "700",
            boxShadow: "none",
            padding: "10px 10px",
            border: "1px solid #FF4D00D9",
            "&:hover": {
              backgroundColor: "#FF4D00D9",
              color: "white",
            },
          },
        },
      },
    },
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    padding: "30px 10px",
    borderRadius: "8px",
  };

  return (
    <div>
      <Modal
        open={openModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container sx={{ overflow: "auto" }}>
            <Grid item xl={12} lg={10} md={12} xs={25}>
              <Stack
                sx={{ padding: "0 40px" }}
                width="100%"
                direction="row"
                display="flex"
                justifyContent="space-between"
              >
                <Typography sx={{ fontSize: "20px", fontFamily: "Poppins" }}>
                  Calendar Appointments
                </Typography>
                <Button
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                  type="button"
                  onClick={closeModal}
                >
                  <CloseIcon />
                </Button>
              </Stack>

              <hr
                style={{
                  width: "88%",
                  margin: "20px auto",
                  marginBottom: "20px",
                  marginTop: "1px",
                  border: "0.2px solid #FF4D00D9",
                }}
              />
              <Typography
                sx={{
                  padding: "0px 40px",
                  paddingBottom: "14px",
                  fontSize: "18px",
                  fontFamily: "Poppins",
                }}
              >
                {formatDate(dateClicked)}
              </Typography>
            </Grid>
            <Grid item xl={12} lg={12} md={12} xs={12}>
              {events.map((event) => (
                <Stack
                  sx={{ padding: "10px 5px" }}
                  width="100%"
                  direction="row"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap="12%"
                >
                  <Typography sx={{ fontSize: "14px", fontFamily: "Poppins" }}>
                    {isTimeBetween(event.start.split(" ")[1], event.end.split(" ")[1]) ? (
                      <ThemeProvider theme={themeButton}>
                        <Badge sx={{ marginRight: "20px" }} variant="dot" />
                      </ThemeProvider>
                    ) : (
                      ""
                    )}
                    {event.start.split(" ")[1]} - {event.end.split(" ")[1]}
                  </Typography>
                  <ThemeProvider
                    theme={
                      isTimeBetween(event.start.split(" ")[1], event.end.split(" ")[1])
                        ? themeButton
                        : themeActiveButton
                    }
                  >
                    <Button
                      type="button"
                      onClick={() => handleEventClick(event)}
                      variant="contained"
                    >
                      {event.title.replace("Firmenname: ", "")}
                    </Button>
                  </ThemeProvider>
                </Stack>
              ))}
              <ThemeProvider theme={themeCancelButton}>
                <Stack
                  sx={{ padding: "20px 5px" }}
                  width="100%"
                  direction="row"
                  display="flex"
                  justifyContent="center"
                >
                  <Box width="170px" />
                  <Button onClick={closeModal} type="button" variant="contained">
                    Cancel
                  </Button>
                </Stack>
              </ThemeProvider>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

CalendarModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  handleEventClick: PropTypes.func.isRequired,
  dateClicked: PropTypes.string.isRequired,
};

export default CalendarModal;
