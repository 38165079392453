/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useEffect, Suspense } from "react";
import axios from "axios";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { CircularProgress, Pagination, Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Card from "@mui/material/Card";
import profileImg from "../../../assets/images/enrichment/profileImg.svg";
import EmployeeModal from "./EmployeeModal";

const useStyles = makeStyles(() => ({
  typography: {
    color: "#2C2C2E",
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    marginTop: "1%",
  },

  inputFields: {
    borderRadius: "3px !important",
    height: "30px !important",
    width: "25% !important",
  },
  styleKeys: {
    fontSize: "14px",
    color: "#767676",
    marginBottom: "0px",
    minWidth: "150px",
    fontWeight: "bold",
  },
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  profileImg: { minWidth: 80, width: 80, height: 80, margin: "0", "border-radius": "50%" },

  row: {
    display: "flex",
    alignItems: "center",
    "@media (min-width: 768px)": {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },
    "@media (max-width :1672px)": {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },
    "@media (max-width: 439px)": {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },
  },
  button: {
    marginLeft: "5px",
    "@media (max-width: 1206px)": {
      marginLeft: "auto",
    },
  },
  employeeDetail: {
    marginLeft: "10px",
    flex: 1,
  },
  profileImgSearch: { width: 40, height: 40 },
  styleValues: {
    fontWeight: "400",
    fontSize: "18px",
    color: "#000000",
  },
  employeesList: {
    overflowY: "auto",
    maxHeight: "calc(100vh - 170px)",
    width: "100%",
    backgroundColor: "none",
    marginTop: "10px",
  },
  cardContent: {
    padding: "0",
    width: "85%",
    minHeight: "29%",
    overflow: "hidden",
  },
  employeeName: {
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    flexWrap: "wrap",
    WebkitLineClamp: 1,
    maxWidth: "100%",
    textOverflow: "ellipsis",
    marginBottom: "1px",
  },
  truncatedTitle: {
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    flexWrap: "wrap",
    WebkitLineClamp: 2,
    maxWidth: "100%",
    textOverflow: "ellipsis",
  },
  location: {
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    flexWrap: "wrap",
    WebkitLineClamp: 1,
    maxWidth: "100%",
    textOverflow: "ellipsis",
  },
  employeeNameSearch: { fontSize: "16px" },
  employeeTitle: {
    fontSize: "14px",
    lineHeight: "20px",
    margin: "0",
    padding: "0",
  },
  employeeLocationSearch: { fontSize: "12px", fontWeight: "200" },
  muiLink: { marginX: "0" },
  cardAction: { padding: "0" },
  pagination: { margin: "auto" },
  centerButton: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  enriched: {
    position: "fixed",
    top: "380px",
    right: "-30px",
    color: "#FF7701",
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "Poppins",
    cursor: "pointer",
    flexDirection: "column",
    alignItems: "center",
    gap: "1%",
    transform: "rotate(90deg)",
  },
  leftArrow: { transform: "rotate(-90deg)" },
}));

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          maxWidth: "50%",
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: "#FF7701",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "20px !important",
          backgroundColor: "white",
        },
        input: {
          padding: "6px 10px !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "rgba(237, 148, 77, 1) !important",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          alignSelf: "auto",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "10px",
          backgroundColor: "rgba(237, 108, 2, 1)",
          "&:hover": {
            backgroundColor: "rgba(237, 108, 2, 0.8)",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
          boxShadow: "none",
          border: "1px solid rgba(237, 148, 77, 1) !important",
          borderTop: "none",
          paddingTop: "15px",
        },
      },
    },
  },
});

const handleError = (error) => {
  const errorData = error.response?.data;
  if (errorData && errorData.message) {
    Swal.fire({ title: "Error!", text: errorData.message, icon: "error" });
  }
  console.error(error);
};

function EmployeeSidebar({ customerUser, domain, companyId, appointmentId }) {
  const LIMIT = 5;
  const classes = useStyles();
  // const [employeeInfo, setEmployeeInfo] = useState(null);
  const [loading, setLoading] = useState({ main: false, db: false, rr: false });
  const [currentPage, setCurrentPage] = useState(1);
  const [enrichEmployees, setEnrichEmployees] = useState(null);
  const [totalEmployees, setTotalEmployees] = useState(1);
  const [startIndex, setStartIndex] = useState(1);
  const [searchEmployeeFlag, setSearchEmployeeFlag] = useState(false);
  // const [isModal, setIsModal] = useState(false);
  // const [selectedEmployees, setSelectedEmployees] = useState(null);
  const [employeesInfoSearch, setEmployeesInfoSearch] = useState("");
  const [employeesData, setEmployeesData] = useState([]);
  const [employeeInfo, setEmployeeInfo] = useState({ employees: [] });
  const [loadingEmployee, setLoadingEmployee] = useState(false);
  const [loadingEmployee1, setLoadingEmployee1] = useState(false);

  const handleAllEmployeesClick = () => {
    setEnrichEmployees(0);
  };

  const handleEnrichEmployeesClick = () => {
    setEnrichEmployees(1);
  };

  const handleChange = (e) => {
    setEmployeesInfoSearch(e.target.value);
  };

  const replaceItems = (employeesRR, employeesDB) =>
    employeesRR.map((itemRR) => employeesDB.find((itemDB) => itemDB.rr_id === itemRR.id) || itemRR);

  const getDBEmployeesList = async () => {
    try {
      setSearchEmployeeFlag(false);
      setLoading((prev) => ({ ...prev, db: true }));
      if (appointmentId) {
        const response = await axios.get(
          `/api/customers/${customerUser.customerUser.customer.id}/appointments/${appointmentId}/employees/enrichment`
        );
        setEmployeeInfo({ employees: response.data });
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading((prev) => ({ ...prev, db: false }));
    }
  };

  const getRREmployeesList = async (newEmployees = null) => {
    try {
      setSearchEmployeeFlag(false);
      setLoading((prev) => ({ ...prev, rr: true }));
      const employees = newEmployees || employeeInfo.employees;
      if (!employees) return;

      const response = await axios.get(
        `/api/customers/${customerUser.customerUser.customer.id}/appointments/${appointmentId}/search/employee/withoutName/${domain}/${startIndex}/${LIMIT}`,
        { params: { compId: companyId, enrich: enrichEmployees || 0 } }
      );

      const employeesItems = replaceItems(response.data.profiles || [], employees || []);

      setEmployeesData(employeesItems);
      setTotalEmployees(response.data.pagination.total);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading((prev) => ({ ...prev, rr: false }));
    }
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      setLoading(true);
      if (employeesInfoSearch) {
        setLoading((prev) => ({ ...prev, main: true }));
        try {
          setSearchEmployeeFlag(true);
          const response = await axios.get(
            `/api/customers/${customerUser.customerUser.customer.id}/appointments/${appointmentId}/search/employee/${employeesInfoSearch}/${domain}/${startIndex}/${LIMIT}`
          );

          const filteredEmployeesDB = employeeInfo.employees.filter((item) =>
            item.name.toLowerCase().includes(employeesInfoSearch.toLowerCase())
          );

          const filteredEmployeesRR = response.data.profiles.filter(
            (itemRR) => !filteredEmployeesDB.some((itemDB) => itemDB.rr_id === itemRR.id)
          );

          const filteredEmployees = filteredEmployeesDB.concat(filteredEmployeesRR);
          setEmployeesData(filteredEmployees);
          setTotalEmployees(response.data.pagination.total);
        } catch (error) {
          handleError(error);
        } finally {
          setLoading((prev) => ({ ...prev, main: false }));
        }
      } else {
        setLoading((prev) => ({ ...prev, main: true }));
        await getDBEmployeesList();
        await getRREmployeesList();
        setLoading((prev) => ({ ...prev, main: false }));
      }
      setLoading(false);
    };

    fetchEmployees();
  }, [startIndex, enrichEmployees, employeesInfoSearch]);

  const handleStartIndex = (currentPageNumber) => {
    setStartIndex(currentPageNumber === 1 ? 1 : (currentPageNumber - 1) * LIMIT + 1);
    setCurrentPage(currentPageNumber);
  };

  const onPageChanged = useCallback(
    (event, page) => {
      event.preventDefault();
      setCurrentPage(page);
      if (!searchEmployeeFlag) {
        handleStartIndex(page);
      }
    },
    [searchEmployeeFlag]
  );

  // const handleModalOpen = (employee) => {
  //   setIsModal(true);
  //   setSelectedEmployees(employee);
  // };

  const handleSeeMoreClick = (employee) => {
    if (employee) {
      localStorage.setItem("employeeDetail", JSON.stringify(employee));
      localStorage.setItem("customerUser", JSON.stringify(customerUser));
      localStorage.setItem("companyId", companyId);
      localStorage.setItem("appointmentId", appointmentId);
      window.open("/employee-profile");
    }
  };

  let employeesDataPagination = [];
  let PAGINATION_NUMBER = 0;

  if (searchEmployeeFlag && employeesData) {
    employeesDataPagination = employeesData.slice(
      (currentPage - 1) * LIMIT,
      (currentPage - 1) * LIMIT + LIMIT
    );
    PAGINATION_NUMBER = Math.ceil(employeesData.length / LIMIT);
  } else {
    PAGINATION_NUMBER = Math.round(totalEmployees / LIMIT);
    employeesDataPagination = employeesData;
  }

  return (
    <>
      {employeeInfo ? (
        <Stack width="100%" mb="3%" alignItems="center" justifyContent="center">
          {/* { isModal && (
            <EmployeeModal
              customerUser={customerUser}
              close={setIsModal}
              selectedEmployees={selectedEmployees}
              setSelectedEmployees={setSelectedEmployees}
              companyId={companyId}
              appointmentId={appointmentId}
              getRREmployeesList={getRREmployeesList}
              setEmployeeInfo={setEmployeeInfo}
            />
          )} */}
          <ThemeProvider theme={theme}>
            <TextField
              onChange={handleChange}
              id="outlined-basic"
              placeholder="Search employee.."
              style={{
                width: "100%",
                marginBottom: "10px",
              }}
              InputProps={{
                startAdornment: loading ? (
                  <ThemeProvider theme={theme}>
                    <Stack>
                      <CircularProgress size={15} />
                    </Stack>
                  </ThemeProvider>
                ) : (
                  <InputAdornment position="start" style={{ marginLeft: "0" }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div style={{ display: "flex" }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: enrichEmployees ? "rgb(235 235 235)" : "rgba(237, 108, 2, 1)",
                  color: enrichEmployees ? "rgba(237, 108, 2, 1)" : "rgb(235 235 235)",
                  marginLeft: "10px",
                }}
                onClick={handleAllEmployeesClick}
              >
                All Employees
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: enrichEmployees ? "rgba(237, 108, 2, 1)" : "rgb(235 235 235)",
                  color: enrichEmployees ? "rgb(235 235 235)" : "rgba(237, 108, 2, 1)",
                  marginLeft: "10px",
                }}
                onClick={handleEnrichEmployeesClick}
              >
                Enriched Employees
              </Button>
            </div>
          </ThemeProvider>
          {loadingEmployee ? (
            <ThemeProvider theme={theme}>
              <Stack my="10%" flexDirection="column" alignItems="center">
                <CircularProgress size="30px" />
              </Stack>
            </ThemeProvider>
          ) : (
            <Suspense
              fallback={
                <ThemeProvider theme={theme}>
                  <Stack my="10%" flexDirection="column" alignItems="center">
                    <CircularProgress size="30px" />
                  </Stack>
                </ThemeProvider>
              }
            >
              <div className={classes.employeesList}>
                {employeesDataPagination.map((employee) => (
                  <Card
                    key={employee.id}
                    style={{
                      width: "98%",
                      marginBottom: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    <CardContent style={{ padding: "16px" }}>
                      <div className={classes.row}>
                        <CardMedia
                          className={classes.profileImg}
                          image={employee.profile_pic ? employee.profile_pic : profileImg}
                        />
                        <div className={classes.employeeDetail}>
                          <Typography className={classes.employeeName} variant="h6" component="div">
                            {employee.name}
                          </Typography>
                          <Typography className={`${classes.styleKeys} ${classes.truncatedTitle}`}>
                            {employee.current_title}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                            }}
                            className={classes.styleKeys}
                          >
                            <LocationOnIcon style={{ marginTop: "2px" }} />
                            <Typography
                              className={`${classes.styleKeys} ${classes.location}`}
                              component="div"
                            >
                              {employee.location ? employee.location.slice(0, 50) : "N/A"}
                            </Typography>
                          </div>
                        </div>
                        <div className={classes.button}>
                          <ThemeProvider theme={theme}>
                            <Button
                              onClick={() => handleSeeMoreClick(employee)}
                              variant="contained"
                              style={{
                                backgroundColor:
                                  employee.rr_id != null
                                    ? "rgb(235, 235, 235)"
                                    : "rgba(237, 108, 2, 1)",
                                color:
                                  employee.rr_id != null
                                    ? "rgba(237, 108, 2, 1)"
                                    : "rgb(235, 235, 235)",
                              }}
                            >
                              {loadingEmployee1 ? (
                                <ThemeProvider theme={theme}>
                                  <Stack mt="15%" flexDirection="column" alignItems="center">
                                    <CircularProgress size="20px" />
                                  </Stack>
                                </ThemeProvider>
                              ) : (
                                "See More"
                              )}
                            </Button>
                          </ThemeProvider>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </Suspense>
          )}
          <Pagination
            className={classes.pagination}
            onChange={onPageChanged}
            page={currentPage}
            count={PAGINATION_NUMBER}
            shape="rounded"
          />
        </Stack>
      ) : null}
    </>
  );
}

EmployeeSidebar.propTypes = {
  customerUser: PropTypes.any.isRequired,
  domain: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired,
  appointmentId: PropTypes.number.isRequired,
};
export default EmployeeSidebar;
