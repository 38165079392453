import React from "react";
import QuestionMark from "./iconComponents/QuestionMark";

function NotFound() {
  return (
    <div>
      <QuestionMark />
    </div>
  );
}

export default NotFound;
