import React from "react";
import PropTypes from "prop-types";

function Star({ fill }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2584 16.8419L12 16.686L11.7416 16.8419L6.57422 19.9608L7.93708 14.0829L8.00507 13.7897L7.77773 13.5924L3.21987 9.63666L9.23296 9.11815L9.5327 9.0923L9.65025 8.81536L12 3.27966L14.3497 8.81536L14.4673 9.0923L14.767 9.11815L20.7786 9.63654L16.2126 13.5921L15.9845 13.7897L16.0531 14.0836L17.4239 19.9596L12.2584 16.8419Z"
        stroke="#EBCB24"
      />
    </svg>
  );
}
Star.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default Star;
