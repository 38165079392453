/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import MuiLink from "@mui/material/Link";
import { makeStyles } from "@material-ui/core/styles";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import LanguageIcon from "@mui/icons-material/Language";
import AppsIcon from "@mui/icons-material/Apps";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FaxIcon from "@mui/icons-material/Fax";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import QrCodeTwoToneIcon from "@mui/icons-material/QrCodeTwoTone";
import GroupsIcon from "@mui/icons-material/Groups";
import BusinessIcon from "@mui/icons-material/Business";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import CompareIcon from "@mui/icons-material/Compare";
import LinkedIn from "./iconComponents/LinkedIn";
import Facebook from "./iconComponents/Facebook";
import Twitter from "./iconComponents/Twitter";
import Crunchbase from "./iconComponents/Crunchbase";
import Instagram from "./iconComponents/Instagram";

const companyInfoIcons = {
  domain: LanguageIcon,
  phone: LocalPhoneIcon,
  industry: WarehouseIcon,
  fax: FaxIcon,
  num_employees: PeopleAltIcon,
  year_founded: CalendarMonthIcon,
};

const useStyles = makeStyles(() => ({
  skillWrapper: {
    margin: "5px",
    display: "flex",
  },
  styleValuesLinks: {
    textDecoration: "underline",
  },
  styleListItemText: {
    marginLeft: "10px",
    marginTop: "0px",
    marginBottom: "0px",
    display: "flex",
  },
  styleKeys: {
    fontSize: "15px",
    color: "#767676",
    marginBottom: "0px",
    minWidth: "150px",
    fontWeight: "bold",
  },
  styleValues: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#000000",
  },
  scrollContainer: {
    maxHeight: "200px", // Adjust the height as needed
    overflowY: "auto",
  },
}));

function FieldInfo2({ companyInfo, description, loadingCompany }) {
  const formatString = (str) => {
    const words = str.split("_");
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    const formattedString = capitalizedWords.join(" ");

    return formattedString;
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const classes = useStyles();

  function concatAddress(street, city, region, countryCode, postalCode) {
    const addressParts = [street, city, region, countryCode, postalCode].filter((part) => part);
    return addressParts.join(", ");
  }

  const address = concatAddress(
    companyInfo.street,
    companyInfo.city,
    companyInfo.region,
    companyInfo.country_code,
    companyInfo.postal_code
  );

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "#FF7701",
          },
        },
      },
    },
  });

  const renderIcon = (key) => {
    const IconComponent = companyInfoIcons[key];
    return <IconComponent />;
  };

  return loadingCompany ? (
    <ThemeProvider theme={theme}>
      <Stack mt="16%" flexDirection="column" alignItems="center">
        <CircularProgress size="5%" />
      </Stack>
    </ThemeProvider>
  ) : (
    <Grid container spacing={1} flexDirection="row">
      <Grid>
        <Grid container spacing={1}>
          {description ? (
            <Grid item xs={12} style={{ marginBottom: "20px" }}>
              <ListItemText className={classes.styleListItemText}>
                <p style={{ textAlign: "start", fontSize: "14px" }}>{description.description}</p>
              </ListItemText>
            </Grid>
          ) : null}
          {companyInfo ? (
            <>
              {Object.entries(companyInfo).map(([key, value]) =>
                key &&
                value &&
                ![
                  "techstack",
                  "competitors",
                  "departments",
                  "industries",
                  "company_growth",
                  "name",
                  "revenue",
                  "street",
                  "postal_code",
                  "country_code",
                  "region",
                  "city",
                  "sic_codes",
                  "links",
                  "funding_investors",
                  "id",
                ].includes(key) ? (
                  <Grid item xs={12} key={key}>
                    <ListItem>
                      <ListItemText
                        className={classes.styleListItemText}
                        primary={
                          <Typography
                            className={classes.styleKeys}
                            style={{ display: "flex", alignItems: "center" }}
                            component="div"
                          >
                            <div style={{ marginRight: "5px", marginTop: "2px" }}>
                              {Object.prototype.hasOwnProperty.call(companyInfoIcons, key) &&
                                renderIcon(key)}
                            </div>
                            <div>{formatString(key)}</div>
                          </Typography>
                        }
                        secondary={
                          <Typography className={classes.styleValues} component="div">
                            {value}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Grid>
                ) : null
              )}
              {companyInfo.revenue ? (
                <Grid item xs={12}>
                  <ListItem>
                    <ListItemText
                      className={classes.styleListItemText}
                      primary={
                        <Typography className={classes.styleKeys} component="div">
                          <CurrencyExchangeIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                          Revenue
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.styleValues} component="div">
                          {parseFloat(companyInfo.revenue).toLocaleString("en-US", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              ) : null}
              {address ? (
                <Grid item xs={12}>
                  <ListItem>
                    <ListItemText
                      className={classes.styleListItemText}
                      primary={
                        <Typography className={classes.styleKeys} component="div">
                          <LocationOnIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                          Address
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.styleValues}> {address}</Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              ) : null}
              {companyInfo.sic_codes && companyInfo.sic_codes.length > 0 ? (
                <Grid item xs={12}>
                  <ListItem>
                    <ListItemText
                      className={classes.styleListItemText}
                      primary={
                        <Typography className={classes.styleKeys} component="div">
                          <QrCodeTwoToneIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                          Sic Codes
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.styleValues}>
                          {companyInfo.sic_codes.join(" , ")}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              ) : null}
              {companyInfo.links && companyInfo.links.length > 0 ? (
                <Grid item xs={12}>
                  <ListItem>
                    <ListItemText
                      className={classes.styleListItemText}
                      primary={
                        <Typography className={classes.styleKeys} component="div">
                          <AppsIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                          Social Media
                        </Typography>
                      }
                      secondary={
                        <div className={classes.skillWrapper}>
                          {Object.entries(companyInfo.links)
                            .filter(
                              ([key, value]) =>
                                key &&
                                value &&
                                value !== null &&
                                (key === "linkedin" ||
                                  key === "facebook" ||
                                  key === "twitter" ||
                                  key === "crunchbase" ||
                                  key === "instagram")
                            )
                            .map(([key, value], index, array) => (
                              <React.Fragment key={key}>
                                <MuiLink
                                  className={classes.styleValuesLinks}
                                  style={{ marginRight: "2px", marginLeft: "2px" }}
                                  rowSpacing={2}
                                >
                                  <a href={value} target="_blank" rel="noopener noreferrer">
                                    {key === "linkedin" && <LinkedIn color="#BDBDBD" />}
                                    {key === "facebook" && <Facebook color="#BDBDBD" />}
                                    {key === "twitter" && <Twitter color="#BDBDBD" />}
                                    {key === "crunchbase" && <Crunchbase color="#BDBDBD" />}
                                    {key === "instagram" && <Instagram color="#BDBDBD" />}
                                  </a>
                                </MuiLink>
                                {index !== array.length - 1 && " , "}
                              </React.Fragment>
                            ))}
                        </div>
                      }
                    />
                  </ListItem>
                </Grid>
              ) : null}
            </>
          ) : null}
          {companyInfo.competitors && companyInfo.competitors.length > 0 ? (
            <Grid item xs={12}>
              <ListItem>
                <ListItemText
                  className={classes.styleListItemText}
                  primary={
                    <Typography className={classes.styleKeys} component="div">
                      <CompareIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                      Competitors
                    </Typography>
                  }
                  secondary={
                    <Typography className={classes.styleValues}>
                      {companyInfo.competitors.join(" , ")}
                    </Typography>
                  }
                />
              </ListItem>
            </Grid>
          ) : null}
          {companyInfo.funding_investors && companyInfo.funding_investors.length > 0 ? (
            <Grid item xs={12}>
              <ListItem>
                <ListItemText
                  className={classes.styleListItemText}
                  primary={
                    <Typography className={classes.styleKeys} component="div">
                      <GroupsIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                      Funding Investors
                    </Typography>
                  }
                  secondary={
                    <Typography className={classes.styleValues}>
                      {companyInfo.funding_investors.join(" , ")}
                    </Typography>
                  }
                />
              </ListItem>
            </Grid>
          ) : null}
          {companyInfo.naics_codes && companyInfo.naics_codes.length > 0 ? (
            <Grid item xs={12}>
              <ListItem>
                <ListItemText
                  className={classes.styleListItemText}
                  primary={
                    <Typography className={classes.styleKeys} component="div">
                      <QrCodeTwoToneIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                      Naics Codes
                    </Typography>
                  }
                  secondary={
                    <Typography className={classes.styleValues}>
                      {companyInfo.naics_codes.join(" , ")}
                    </Typography>
                  }
                />
              </ListItem>
            </Grid>
          ) : null}
          {companyInfo.industries && companyInfo.industries.length > 0 ? (
            <Grid item xs={12}>
              <ListItem>
                <ListItemText
                  className={classes.styleListItemText}
                  primary={
                    <Typography className={classes.styleKeys} component="div">
                      <DomainAddIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                      Industries
                    </Typography>
                  }
                  secondary={
                    <Typography className={classes.styleValues}>
                      {companyInfo.industries.join(" , ")}
                    </Typography>
                  }
                />
              </ListItem>
            </Grid>
          ) : null}
          {companyInfo.techstack && companyInfo.techstack.length > 0 ? (
            <Grid item xs={12}>
              <ListItem>
                <ListItemText
                  className={classes.styleListItemText}
                  primary={
                    <Typography className={classes.styleKeys} component="div">
                      <CollectionsBookmarkIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                      Techstack
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography className={classes.styleValues}>
                        {isExpanded
                          ? companyInfo.techstack.join(", ")
                          : companyInfo.techstack.slice(0, 25).join(", ")}

                        {/* {companyInfo.techstack.length > 25 && ( */}
                        <Button onClick={toggleExpand} style={{ padding: "0", marginLeft: "5px" }}>
                          {isExpanded ? "Read Less" : "...Read More"}
                        </Button>
                        {/* )} */}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            </Grid>
          ) : null}
          {companyInfo.departments && Object.keys(companyInfo.departments).length > 0 ? (
            <Grid item xs={12}>
              <ListItem>
                <ListItemText
                  className={classes.styleListItemText}
                  primary={
                    <Typography className={classes.styleKeys} component="div">
                      <BusinessIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                      Departments
                    </Typography>
                  }
                  secondary={Object.entries(companyInfo.departments).map(([key, value]) => (
                    <Typography className={classes.styleValues}>
                      {key}: {value}
                    </Typography>
                  ))}
                />
              </ListItem>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}

FieldInfo2.propTypes = {
  description: PropTypes.object.isRequired,
  companyInfo: PropTypes.object.isRequired,
  loadingCompany: PropTypes.bool.isRequired,
};

export default FieldInfo2;
