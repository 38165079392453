import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  Icon,
  MenuItem,
  Pagination,
  Select,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSoftUIController } from "context";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { createTheme } from "@mui/material/styles";
import AuthButton from "components/Buttons/AuthButton";
import TableSort from "components/authentication_v2/TableSort";
import axios from "axios";
import Swal from "sweetalert2";
import PermissionSwitch from "./PermissionSwitch_v2";

function UserModal({ close, userId, customerId }) {
  const [controller] = useSoftUIController();

  const useStyles = makeStyles(() => ({
    modal: {
      position: "fixed",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      top: "0",
      bottom: "0",
      right: "0",
      zIndex: "1000",
    },
    containerBox: {
      position: "relative",
      top: "13%",
      left: controller.miniSidenav ? "3%" : "3%",
      backgroundColor: "white",
      border: "1px solid #FF7701",
      padding: "10px",
      height: "85%",
      overflow: "auto",
    },
    defaultTypo: { fontSize: "14px", fontFamily: "Poppins", fontWeight: "400", color: "#5F5F5F" },
    inputs: { width: 400 },
    TableContainer: {
      padding: "0 2%",
    },
    tableHead: {
      borderBottom: "0",
      margin: "0",
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "Poppins",
      color: "#010101",
    },
    table: {
      fontSize: "11px",
      fontFamily: "Poppins",
      fontWeight: "400",
      borderTop: "1px solid #5F5F5F26",
      color: "#4A4A4A",
    },

    tableRow: { borderBottom: "1px solid #50555C" },
    pagination: { float: "right", padding: "2% 0" },
    itemStatus: {
      borderRadius: "5px",
      padding: "4% 0",
      fontSize: "12px",
      cursor: "pointer",
      textAlign: "center",
    },
    dialogPaper: { boxShadow: "none" },
  }));

  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {},
        },
      },
    },
  });

  const classes = useStyles();

  const [userAllCampaignsDataTable, setAllCampaignsDataTable] = useState(null);
  const [userCampaignsDataTable, setUserCampaignsDataTable] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [campaignId, setCampaignId] = useState(null);

  const refreshUserCampaignsTable = () => {
    axios
      .get(`/api/customers/${customerId}/profile/${userId}/campaigns`, {
        params: {
          search: searchQuery,
        },
      })
      .then((res) => {
        setAllCampaignsDataTable(res.data.all_campaigns);
        setUserCampaignsDataTable(res.data.customer_campaigns);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDetachCampaign = (pCampaignId) => {
    axios
      .post(`/api/customers/${customerId}/profile/${userId}/detach-campaigns`, {
        campaign_id: pCampaignId,
      })
      .then(() => {
        setRefresh(!refresh);
      })
      .catch((error) => {
        setCampaignId(null);
        const errorData = error.response === undefined ? null : error.response.data;

        if (errorData && errorData.message !== undefined) {
          Swal.fire({
            title: "Error!",
            text: errorData.message,
            icon: "error",
          });
        }
      });
  };

  const handleCampaignAttachment = () => {
    axios
      .post(`/api/customers/${customerId}/profile/${userId}/attach-campaigns`, {
        campaign_id: campaignId,
      })
      .then(() => {
        setRefresh(!refresh);
      })
      .catch((error) => {
        setRefresh(!refresh);
        const errorData = error.response === undefined ? null : error.response.data;

        if (errorData && errorData.message !== undefined) {
          Swal.fire({
            title: "Error!",
            text: errorData.message,
            icon: "error",
          });
        }
      });
  };

  useEffect(() => {
    setAllCampaignsDataTable(null);
    setTimeout(() => {
      refreshUserCampaignsTable();
    }, 100);
    return () => {};
  }, [refresh]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [sortingOrderBy, setSortingOrderBy] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const handleTypeSort = () => {
    if (sortingOrderBy === "type") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("type");
      setSortingOrder("asc");
    }
  };

  const handleNameSort = () => {
    if (sortingOrderBy === "name") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("name");
      setSortingOrder("asc");
    }
  };
  const handlePermissionSort = () => {
    if (sortingOrderBy === "permission") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("permission");
      setSortingOrder("asc");
    }
  };
  const handleNotifySort = () => {
    if (sortingOrderBy === "notify") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("notify");
      setSortingOrder("asc");
    }
  };

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      userCampaignsDataTable ? userCampaignsDataTable.length : null - page * rowsPerPage
    );

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleSearch(item) {
    const nameMatches = item.name.toLowerCase().includes(searchQuery.toLowerCase());
    const typeMatches = item.type.toLowerCase().includes(searchQuery.toLowerCase());

    return nameMatches || typeMatches;
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        className={classes.modal}
        sx={{
          left: {
            xs: controller.miniSidenav ? "15%" : "19%",
            sm: controller.miniSidenav ? "10%" : "19%",
            md: controller.miniSidenav ? "5%" : "19%",
          },
        }}
      >
        <Grid
          item
          md={controller.miniSidenav ? 10.7 : 9}
          sm={10}
          xs={7}
          className={classes.containerBox}
        >
          <Stack
            overflow="auto"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ borderBottom: "1px solid #FF4D00D9", margin: "15px 20px", paddingBottom: "10px" }}
          >
            <Typography fontSize="16px" fontWeight="400" fontFamily="Poppins" color="#4A4A4A">
              User Campaigns
            </Typography>
            <Icon onClick={() => close(false)} sx={{ cursor: "pointer" }}>
              close
            </Icon>
          </Stack>
          <Grid
            item
            md={12}
            mt={3}
            padding={3}
            mb={6}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack direction="row" alignItems="center" gap="3%">
              <Stack>
                <FormControl sx={{ width: 400 }}>
                  <Select
                    sx={{
                      border: "1px solid #FF4D00D9",
                      minHeight: "0",
                      height: "35px",
                    }}
                    onChange={(e) => setCampaignId(e.target.value)}
                    // variant="standard"
                  >
                    {userAllCampaignsDataTable && userAllCampaignsDataTable.length > 0
                      ? userAllCampaignsDataTable.map((item) => (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Stack>
              <Stack width="100%">
                <AuthButton
                  backgroundColor="#FF4D00D9"
                  fontSize="13px"
                  onClick={handleCampaignAttachment}
                >
                  ATTACH
                </AuthButton>
              </Stack>
            </Stack>
          </Grid>
          <Stack
            sx={{
              backgroundColor: "#F7F7F7",
              borderRadius: "5px",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingLeft: "10px",
              width: "17%",
              float: "right",
            }}
            mb={3}
            mr={3}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Icon sx={{ color: "#FF4D00D9" }}>search</Icon>
            <input
              style={{
                border: "0",
                background: "transparent",
                outline: "none",
              }}
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Stack>

          <TableContainer className={classes.TableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableSort
                    align="center"
                    sorted={sortingOrderBy === "type" ? sortingOrder : "none"}
                    onClick={handleTypeSort}
                  >
                    Type
                  </TableSort>
                  <TableSort
                    align="left"
                    sorted={sortingOrderBy === "name" ? sortingOrder : "none"}
                    onClick={handleNameSort}
                  >
                    Name
                  </TableSort>
                  <TableSort
                    align="left"
                    sorted={sortingOrderBy === "permission" ? sortingOrder : "none"}
                    onClick={handlePermissionSort}
                  >
                    Write
                  </TableSort>
                  <TableSort
                    align="left"
                    sorted={sortingOrderBy === "notify" ? sortingOrder : "none"}
                    onClick={handleNotifySort}
                  >
                    Notify
                  </TableSort>
                  <TableSort align="left">Action</TableSort>
                </TableRow>
              </TableHead>
              <TableBody>
                {userCampaignsDataTable
                  ? (rowsPerPage > 0
                      ? stableSort(
                          userCampaignsDataTable.filter(handleSearch),
                          getComparator(sortingOrder, sortingOrderBy)
                        ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : stableSort(
                          userCampaignsDataTable,
                          getComparator(sortingOrder, sortingOrderBy)
                        )
                    ).map((item) => (
                      <TableRow>
                        <TableCell className={classes.table} scope="row">
                          {item.type ? item.type : null}
                        </TableCell>
                        <TableCell className={classes.table} scope="row">
                          {item.name ? item.name : null}
                        </TableCell>
                        <TableCell className={classes.table} scope="row">
                          <PermissionSwitch
                            action="set_permission"
                            campaignId={item.id ? item.id : null}
                            value={item.permission ? item.permission : null}
                            refreshUserInfo={() => setRefresh(!refresh)}
                            userId={userId}
                            customerId={customerId}
                          />
                        </TableCell>
                        <TableCell className={classes.table} scope="row">
                          <PermissionSwitch
                            action="send_notifications"
                            campaignId={item.id ? item.id : null}
                            value={item.notify ? item.notify : null}
                            refreshUserInfo={() => setRefresh(!refresh)}
                            userId={userId}
                            customerId={customerId}
                          />
                        </TableCell>
                        <TableCell
                          onClick={() => handleDetachCampaign(item.id)}
                          className={classes.table}
                          scope="row"
                        >
                          <Typography
                            sx={{
                              backgroundColor: "#FF4D00D9",
                              borderRadius: "5px",
                              textAlign: "center",
                              padding: "5px",
                              cursor: "pointer",
                            }}
                            fontSize="11px"
                            fontWeight="500"
                            fontFamily="Poppins"
                            color="#FFFFFF"
                          >
                            DETACH
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 20 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Pagination
              className={classes.pagination}
              count={Math.ceil(
                userCampaignsDataTable ? userCampaignsDataTable.length / rowsPerPage : null
              )}
              page={page + 1}
              onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

UserModal.propTypes = {
  close: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  customerId: PropTypes.number.isRequired,
};

export default UserModal;
