import PropTypes from "prop-types";

function FollowUpIcon({ color }) {
  return (
    <svg fill={color} width="36" height="36" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.6083273,19.5107621 C19.8516248,19.2441317 20,18.8893933 20,18.5 L20,5.5 C20,4.67157288 19.3284271,4 18.5,4 L5.5,4 C4.67157288,4 4,4.67157288 4,5.5 L4,18.5 C4,19.3284271 4.67157288,20 5.5,20 C6.32842712,20 7,19.3284271 7,18.5 L7,16.5 C7,16.1666667 7.32,15.9266667 7.64,16.02 L19.6083273,19.5107621 L19.6083273,19.5107621 Z M17.7142857,20 L8,17.1666667 L8,18.5 C8,19.062803 7.81402759,19.5821697 7.50018309,20 L17.7142857,20 L17.7142857,20 Z M18.5,3 C19.8807119,3 21,4.11928813 21,5.5 L21,18.5 C21,19.8807119 19.8807119,21 18.5,21 L5.5,21 C4.11928813,21 3,19.8807119 3,18.5 L3,5.5 C3,4.11928813 4.11928813,3 5.5,3 L18.5,3 Z" />
    </svg>
  );
}

FollowUpIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default FollowUpIcon;
