/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, Grid, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import axios from "axios";
import Swal from "sweetalert2";
import ROICardChart from "examples/Charts/AnayliticsChart/ROiCardChart";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "10px",
          backgroundColor: "rgba(237, 108, 2, 1)",
          transition: "transform 0.1s ease",
          width: "100px",
          "&:hover": {
            backgroundColor: "rgb(255 138 43)",
            transform: "scale(1.1)",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          textAlign: "center",
        },
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  input: {
    maxWidth: "100px",
  },
  sendButton: {
    border: "0",
    cursor: "pointer",
  },
  labelAndInput: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 320px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  btn: {
    display: "flex",
    justifyContent: "end",
    paddingTop: "7px",
    paddingRight: "50px",
    "@media (max-width: 900px)": {
      paddingRight: "10px",
    },
    "@media (max-width: 320px)": {
      paddingRight: "50px",
    },
  },
}));

function SimpleTabInfo({ customerUser }) {
  const [roiData, setRoiData] = useState(null);
  const [totalDeals, setTotalDeals] = useState(0);
  const [offers, setOffers] = useState(0);
  const [appointment, setAppointment] = useState(0);
  const [followUps, setFollowUps] = useState(0);
  const [price, setPrice] = useState(0);
  const [averageDeals, setAverageDeals] = useState(0);
  const [cardInfo, setCardInfo] = useState([]);
  const isSubmitDisabled =
    !price || !appointment || !averageDeals || (!followUps && !offers && !totalDeals);

  const classes = useStyles();
  const cardData = (data) => {
    setCardInfo([
      {
        label: "Campaign Lead to Deal",
        desc: "The percentage of leads generated from a campaign that convert into deals. This metric helps evaluate the effectiveness of a campaign in driving sales conversions.",
        progress: {
          content: `${data?.statistic?.["cr_campaign_lead_to_deal_%"]}%`,
        },
      },
      {
        label: "Campaign Lead to Follow Up",
        desc: "The percentage of leads from a campaign that move to the follow-up stage. It measures the initial engagement effectiveness and how well leads are being nurtured.",
        progress: {
          content: `${data?.statistic?.["cr_campaign_lead_to_follow_up_%"]}%`,
        },
      },
      {
        label: "Campaign Lead to Offer",
        desc: "The percentage of campaign leads that progress to receiving an offer. This metric assesses the quality of leads and their movement through the sales funnel.",
        progress: {
          content: `${data?.statistic?.["cr_campaign_lead_to_offer_%"]}%`,
        },
      },
      {
        label: "Follow Up to Deal",
        desc: "The percentage of follow-ups that result in a deal. This metric indicates the effectiveness of follow-up strategies in closing sales.",
        progress: {
          content: `${data?.statistic?.["cr_follow_up_to_deal_%"]}%`,
        },
      },
      {
        label: "Follow Up to Offer",
        desc: "The percentage of follow-ups that progress to an offer being made. It shows how effective the follow-up process is in advancing leads further down the sales funnel.",
        progress: {
          content: `${data?.statistic?.["cr_follow_up_to_offer_%"]}%`,
        },
      },
      {
        label: "Offer To Deal",
        desc: "The percentage of offers that convert into deals. This metric measures the success rate of converting proposals into actual sales.",
        progress: {
          content: `${data?.statistic?.["cr_offer_to_deal_%"]}%`,
        },
      },
      {
        label: "Profitability Rating",
        desc: "A rating that evaluates the profitability of the marketing campaigns based on revenue generated versus cost incurred. It provides an overall assessment of campaign efficiency.",
        progress: {
          content: `${data?.statistic?.profitability_rating} (${data?.statistic?.profitability_good_or_bad})`,
        },
      },
      {
        label: "Predictive Deal",
        desc: "An estimation of potential deals that are likely to close based on current pipeline data and predictive analytics. This helps in forecasting sales outcomes.",
        progress: {
          content: data?.statistic?.predictive_deal,
        },
      },
      {
        label: "Net Profit",
        desc: "The percentage of total revenue that remains as profit after all expenses, including marketing, sales, and operational costs, are subtracted. This metric provides insight into the overall profitability and efficiency of the marketing efforts relative to the revenue generated.",
        progress: {
          currency: `€`,
          content: `${data?.statistic?.net_profit_10_p}`,
        },
      },
      {
        label: "Customer Acquisition Cost",
        desc: "The total cost of acquiring a new customer, including marketing and sales expenses. Lower costs indicate more efficient marketing efforts.",
        progress: {
          content: `€${data?.statistic?.customer_ac_cost}`,
        },
      },
      {
        label: "Total Revenue",
        desc: "The total revenue generated from all marketing campaigns. It reflects the overall financial impact of marketing efforts.",
        progress: {
          content: `€${data?.statistic?.total_revenue}`,
        },
      },
      {
        label: "ROI",
        desc: "The Return on Investment (ROI) from marketing activities. This metric shows the profitability of marketing campaigns relative to their costs.",
        progress: {
          content: data?.statistic?.roi,
        },
      },
    ]);
  };

  const handleStaticROIInfo = () => {
    const payload = {
      price,
      total_appointments: appointment,
      total_follow_ups: followUps,
      total_offers: offers,
      total_deals: totalDeals,
      average_deal_size_in_eur: averageDeals,
      real_values: false,
      campaign_type: "appointments",
    };
    axios
      .post(`/api/customers/${customerUser?.customer.id}/kpi/roi/state`, payload)
      .then((res) => {
        setRoiData(res?.data);
        cardData(res?.data);
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
  };

  return (
    <>
      <div>
        <Grid container spacing={1} p={2} columns={{ xs: 4, sm: 4, md: 12 }}>
          <ThemeProvider theme={theme}>
            <Grid item xs={12} sm={6} md={4}>
              <Box mr={{ sm: 0, xs: 0, md: 4 }}>
                <div className={classes.labelAndInput}>
                  <Typography>Price</Typography>
                  <TextField
                    className={classes.input}
                    type="number"
                    variant="standard"
                    // onChange={(e) => setPrice(Number(e.target.value))}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (value >= 0) {
                        setPrice(value);
                      } else {
                        e.target.value = 0;
                      }
                    }}
                  />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box mr={{ sm: 0, xs: 0, md: 4 }}>
                <div className={classes.labelAndInput}>
                  <Typography>Average Deal Size Eur</Typography>
                  <TextField
                    className={classes.input}
                    type="number"
                    variant="standard"
                    // onChange={(e) => setAverageDeals(Number(e.target.value))}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (value >= 0) {
                        setAverageDeals(value);
                      } else {
                        e.target.value = 0;
                      }
                    }}
                  />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box mr={{ sm: 0, xs: 0, md: 4 }}>
                <div className={classes.labelAndInput}>
                  <Typography>Total Appointments</Typography>
                  <TextField
                    className={classes.input}
                    type="number"
                    variant="standard"
                    // onChange={(e) => setAppointment(Number(e.target.value))}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (value >= 0) {
                        setAppointment(value);
                      } else {
                        e.target.value = 0;
                      }
                    }}
                  />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box mr={{ sm: 0, xs: 0, md: 4 }}>
                <div className={classes.labelAndInput}>
                  <Typography>Total Follow Ups</Typography>
                  <TextField
                    className={classes.input}
                    type="number"
                    variant="standard"
                    // onChange={(e) => setFollowUps(Number(e.target.value))}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (value >= 0) {
                        setFollowUps(value);
                      } else {
                        e.target.value = 0;
                      }
                    }}
                  />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box mr={{ sm: 0, xs: 0, md: 4 }}>
                <div className={classes.labelAndInput}>
                  <Typography>Total Offers</Typography>
                  <TextField
                    className={classes.input}
                    type="number"
                    variant="standard"
                    // onChange={(e) => setOffers(Number(e.target.value))}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (value >= 0) {
                        setOffers(value);
                      } else {
                        e.target.value = 0;
                      }
                    }}
                  />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box mr={{ sm: 0, xs: 0, md: 4 }}>
                <div className={classes.labelAndInput}>
                  <Typography>Total Deals</Typography>
                  <TextField
                    className={classes.input}
                    type="number"
                    variant="standard"
                    // onChange={(e) => setTotalDeals(Number(e.target.value))}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (value >= 0) {
                        setTotalDeals(value);
                      } else {
                        e.target.value = 0;
                      }
                    }}
                  />
                </div>
              </Box>
            </Grid>
          </ThemeProvider>
        </Grid>
        <Grid className={classes.btn}>
          <ThemeProvider theme={theme}>
            <Button
              variant="contained"
              disabled={isSubmitDisabled}
              onClick={handleStaticROIInfo}
              style={{
                backgroundColor: "rgb(235 235 235)",
                color: "rgba(237, 108, 2, 1)",
              }}
            >
              Submit
            </Button>
          </ThemeProvider>
        </Grid>
        {cardInfo.length !== 0 && (
          <ThemeProvider theme={theme}>
            <Grid item xs={12} md={12} mt={3} paddingBottom={{ sm: 4, xs: 12 }}>
              <ROICardChart
                color="#444"
                title=""
                description={cardInfo.length !== 0 ? roiData?.statistic?.advice : ""}
                chart={{}}
                items={cardInfo}
              />
            </Grid>
          </ThemeProvider>
        )}
      </div>
    </>
  );
}

export default SimpleTabInfo;
SimpleTabInfo.propTypes = {
  customerUser: PropTypes.any.isRequired,
};
