import { useLayoutEffect } from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import ReactHtmlParser from "react-html-parser";

import { Card, CardContent, Grid } from "@mui/material";

// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import SuiTypography from "components/SuiTypography";
import CloseIcon from "../../assets/images/enrichment/close.svg";

// react-html-parser components

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "1rem",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

// const color = "secondary";

const FooterModal = ({ footerItem, open, handleClose }) => {
  useLayoutEffect(() => {
    console.log("Asd");
  }, [footerItem]);

  return footerItem ? (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <SuiBox sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <Grid container spacing={3} direction="row">
            <Grid item sm={6}>
              <SuiBox display="flex" justifyContent="flex-start">
                <span pl={3}>{footerItem.label}</span>
              </SuiBox>
            </Grid>
            <Grid item sm={6}>
              <SuiBox display="flex" justifyContent="flex-end">
                <Typography onClick={handleClose}>
                  <img width={25} src={CloseIcon} alt="Close" style={{ cursor: "pointer" }} />
                </Typography>
                {/* <Button
                  sx={{
                    backgroundColor: "#344767",
                    padding: "8px",
                    color: "#fff",
                    ":hover": { backgroundColor: "#344767" },
                  }}
                  onClick={handleClose}
                >
                  <Icon fontSize="small">close</Icon>
                </Button> */}
              </SuiBox>
            </Grid>
          </Grid>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, height: "78vh", overflow: "auto" }}>
          <SuiBox pb={3}>
            <Grid container spacing={3} mb={2}>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <Card>
                  <CardContent>
                    <SuiTypography variant="caption">
                      {ReactHtmlParser(footerItem.body)}
                    </SuiTypography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </SuiBox>
        </Typography>
      </SuiBox>
    </Modal>
  ) : (
    ""
  );
};

FooterModal.propTypes = {
  footerItem: PropTypes.objectOf(PropTypes.any).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default FooterModal;
