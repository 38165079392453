import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";

const iconStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginLeft: "4px",
};

const iconStyles2 = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginLeft: "4px",
  marginTop: "-15px",
};

function TableSort({ children, sorted, align, onClick, width, justifyContent }) {
  const useStyles = makeStyles(() => ({
    Box: {
      display: "flex",
      alignItems: "center",
      justifyContent,
      cursor: "pointer",
      fontSize: "15px",
    },
  }));
  const classes = useStyles();

  return (
    <TableCell width={width} align={align} onClick={onClick}>
      <Box className={classes.Box}>
        {children}
        <Stack direction="column" alignItems="center" justifyContent={justifyContent}>
          <span style={iconStyles}>
            {sorted === "asc"}
            <Icon sx={{ color: sorted === "asc" ? "#5F5F5F" : "#5F5F5F73" }}>arrow_drop_up</Icon>
          </span>
          <span style={iconStyles2}>
            {sorted === "desc"}
            <Icon sx={{ color: sorted === "desc" ? "#5F5F5F" : "#5F5F5F73" }}>arrow_drop_down</Icon>
          </span>
        </Stack>
      </Box>
    </TableCell>
  );
}

TableSort.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  sorted: PropTypes.oneOf(["none", "asc", "desc"]).isRequired,
  align: PropTypes.oneOf(["left", "right", "center"]),
  onClick: PropTypes.func.isRequired,
  justifyContent: PropTypes.oneOf([
    "flex-start",
    "flex-end",
    "center",
    "space-between",
    "space-around",
    "space-evenly",
  ]),
};

TableSort.defaultProps = {
  width: "auto",
  align: "left",
  justifyContent: "flex-start",
};

export default TableSort;
