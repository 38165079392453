import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  box: {
    backgroundColor: "#FF94550F",
    width: "100%",
    padding: "5% ",
    textAlign: "center",
    marginTop: "3%",
    fontSize: "10px",
    fontWeight: "400",
    fontFamily: "Poppins",
    color: "#5F5F5F",
  },
  agent: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "600",
    borderBottom: "1px solid #00000026",
    paddingBottom: "3px",
    color: "#FF4D00D9",
    cursor: "pointer",
  },
}));

function AgentLogin() {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item md={2.3} />
      <Grid item md={8} sm={12} xs={12}>
        <Typography className={classes.box}>
          When you click our Agent Sign-in, the link will move you to our another platform to have
          access.
        </Typography>
      </Grid>
      <Grid item md={1.7} />
      <Grid item md={4.2} xs={2} sm={4} />
      <Grid item mb={20} mt={4} md={6} sm={8} xs={10}>
        <Typography component="a" href={process.env.REACT_APP_API_URL} className={classes.agent}>
          Agent Sign-in
        </Typography>
      </Grid>
    </Grid>
  );
}

export default AgentLogin;
