import axios from "axios";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import { Grid, CircularProgress, Box, styled } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect, useMemo, useState } from "react";
import DateTimeFormatterV2 from "components/authentication_v2/DateTimeFormatterV2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import TrackReviewChanges from "layouts/dashboards/appointments/components/TrackReviewChanges";
import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";
import ReviewStatus from "./ReviewStatus";

const CustomTypography = styled(Typography)({
  fontSize: "14px",
  color: "#626262",
  marginBottom: "7px",
  "&.body": {
    fontWeight: "400",
    fontSize: "18px",
    color: "black",
  },
});

function NextAppointment({
  customerUser,
  appointment,
  reasonVal,
  setReason,
  handleAppointmentReviewStatusChange,
  appointmentReviewStatuses,
  handleAppointmentReviewStatus,
  appointmentReviewStatus,
}) {
  const history = useHistory();
  const [loading2, setLoading2] = useState(false);
  const [selectValue, setSelectValue] = useState("favorite");
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    if (appointment?.campaign_lead?.customer_review_status) {
      setSelectValue(
        appointment.campaign_lead.customer_review_status === null
          ? "favorite"
          : appointment.campaign_lead.customer_review_status
      );
    }
  }, [appointment]);

  const handleSubmit = (value) => {
    const payload = {
      review_status: value,
    };
    axios
      .post(
        `/api/customers/${customerUser?.customerUser?.customer?.id}/campaign-leads/${appointment?.campaign_lead?.id}/review-status`,
        payload
      )
      .then(() => {
        Swal.fire("Success!", "Review Status Changed Successfully", "success");
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          Swal.fire("Access Denied", "You can not access the page.", "error");
        } else {
          Swal.fire("Error!", err.message, "error");
        }
      });
  };
  const options = [
    { value: "favorite", label: "Favorite" },
    { value: "follow_up", label: "Follow Up" },
    { value: "offer", label: "Offer" },
    { value: "deal", label: "Deal" },
  ];

  // Find the label that corresponds to the selectValue
  const selectedOption = options.find((option) => option.value === selectValue);

  const handleValueChange = (event) => {
    setSelectValue(event.value);
    handleSubmit(event.value);
  };
  const handleOpenAppointment = (appointmentId, campaignId) => {
    history.push({
      pathname: "/enrichment",
      state: {
        appointmentIdParam: appointmentId,
        customerUser,
        campaignId: { campaignId },
        appointmentId: { appointmentId },
      },
    });
  };
  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "#FF7701",
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          track: {
            backgroundColor: "#FF7701",
          },
          root: {
            color: "orange",
          },
          thumb: {
            color: "white",
          },
          valueLabel: {
            backgroundColor: "#FF7701",
          },
          markLabel: {
            marginTop: "5%",
          },
          mark: {
            height: "20%",
            color: "black",
            marginTop: "4%",
          },
        },
      },
    },
  });

  useMemo(() => {
    setLoading2(true);

    axios
      .get(
        `api/customers/${customerUser.customerUser.customer.id}/campaigns/appointments/next-appointments`
      )
      .then((response) => {
        if (response) {
          setAppointments(response.data);
          setLoading2(false);
        }
      })
      .catch(() => {
        setLoading2(false);
      });
    return () => {
      setAppointments(null);
    };
  }, [customerUser]);

  const useStyles = makeStyles(() => ({
    suiSelectStyle: {
      maxWidth: "80%",
      "& .css-l4kyc0-Control": {
        backgroundColor: "rgba(255, 119, 1, 1)",
        fontSize: "13px",
        height: "1.5rem",
        border: "none",
        borderRadis: "1px",
      },
      "& .css-12vptoo-Control": {
        backgroundColor: "rgba(255, 119, 1, 1)",
        fontSize: "14px",
        height: "1.7rem",
      },
      "& .css-mxm12o-SingleValue": {
        color: "black",
      },
      "& .css-1rkaz1j-DropdownIndicator svg": {
        fill: "white",
        stroke: "white",
      },
    },
    gridOneStyle: {
      alignItems: "center",
    },
    typographyLabelStyle: {
      fontSize: "14px",
      color: "#626262",
      marginBottom: "7px",
    },
    typographyBodyStyle: {
      fontWeight: "400",
      fontSize: "18px",
      color: "black",
    },
    boxStyle: {
      paddingLeft: "10px",
      paddingBottom: "15px",
    },
    gridStyle: {
      padding: "5px 30px 30px 30px",
    },
    listItemTextStyle: {
      margin: "10px",
    },
    mainGridStyle: {
      marginTop: "25px",
    },
  }));
  const classes = useStyles();
  return loading2 ? (
    <Box my="5%" display="flex" alignItems="center" justifyContent="center">
      <ThemeProvider theme={theme}>
        <CircularProgress size="7%" />
      </ThemeProvider>
    </Box>
  ) : (
    <Box mt={0}>
      <Grid className={classes.mainGridStyle} display={{ md: "flex", sm: "block" }} gap={{ md: 5 }}>
        {appointment?.is_favorite ? (
          <Grid item xs={8} lg={6} sx={{ zIndex: 1000 }} ml={{ xs: 10, sm: 0, md: 0 }}>
            <Grid className={classes.gridOneStyle} item xs={12} sm={12}>
              <SuiBox p={1}>
                <SuiSelect
                  className={classes.suiSelectStyle}
                  style={{ zIndex: 1000 }}
                  placeholder="Type here..."
                  defaultValue={selectedOption}
                  onChange={handleValueChange}
                  options={options}
                />
              </SuiBox>
            </Grid>
          </Grid>
        ) : (
          <Grid item sm={6} md={6} ml={{ xs: 10, sm: 0, md: 0 }}>
            <ReviewStatus
              setReason={setReason}
              reasonVal={reasonVal}
              handleAppointmentReviewStatusChange={handleAppointmentReviewStatusChange}
              handleAppointmentReviewStatus={handleAppointmentReviewStatus}
              appointmentReviewStatuses={appointmentReviewStatuses}
              appointmentReviewStatus={appointmentReviewStatus}
              appointment={appointment}
            />
          </Grid>
        )}

        <Grid textAlign="center" mt={2}>
          <TrackReviewChanges timeline={appointment.timeline} />
        </Grid>
      </Grid>

      <Grid container className={classes.mainGridStyle} flexDirection="column">
        {appointments && appointments.data && appointments.data.length > 0 ? (
          <Grid item className={classes.boxStyle}>
            <CustomTypography sx={{ fontSize: "0.875rem", fontWeight: "bold" }}>
              Next Appointments
            </CustomTypography>
          </Grid>
        ) : (
          ""
        )}
        {appointments.data
          ? appointments.data.map((data) =>
              data ? (
                <Grid
                  item
                  key={data.id}
                  onClick={() => handleOpenAppointment(data.id, data.campaign_id)}
                >
                  <Grid container>
                    <Grid className={classes.boxStyle}>
                      <CustomTypography sx={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                        <DateTimeFormatterV2 dateTime={new Date(data.appointment_at)} />
                      </CustomTypography>
                    </Grid>
                    <Grid
                      ml={3}
                      item
                      sx={{
                        cursor: "pointer",
                        color: "#FF7701",
                        fontSize: "0.875rem",
                        fontWeight: "bold",
                      }}
                    >
                      {data.lead_identificator}
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )
            )
          : ""}
      </Grid>
    </Box>
  );
}

export default NextAppointment;

NextAppointment.propTypes = {
  customerUser: PropTypes.object.isRequired,
  appointment: PropTypes.any.isRequired,
  setReason: PropTypes.any.isRequired,
  reasonVal: PropTypes.any.isRequired,
  handleAppointmentReviewStatusChange: PropTypes.any.isRequired,
  handleAppointmentReviewStatus: PropTypes.any.isRequired,
  appointmentReviewStatuses: PropTypes.any.isRequired,
  appointmentReviewStatus: PropTypes.any.isRequired,
};
