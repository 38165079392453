/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

import ReactHtmlParser from "react-html-parser";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiAvatar from "components/SuiAvatar";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import avatar01 from "assets/images/avatar-01.jpg";
import axios from "axios";

import PropTypes from "prop-types";
import SuiEditor from "components/SuiEditor";
import SuiBadge from "components/SuiBadge";
import { LoadingButton } from "@mui/lab";
// import SuiBadge from "components/SuiBadge";

function Note({ appointment, customerUser }) {
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [sendLoading, setSendLoading] = useState(false);

  const fetchNotes = () => {
    axios
      .get(
        `/api/customers/${customerUser.customer.id}/campaign-leads/${appointment.campaign_lead.id}/notes/mark-notes-as-read`
      )
      .then(() => {})
      .catch(() => {});

    axios
      .get(
        `/api/customers/${customerUser.customer.id}/campaign-leads/${appointment.campaign_lead.id}/notes/order-by/desc`
      )
      .then((res) => {
        const notesRes = res.data.data.map((r) => ({
          image: avatar01,
          id: r.id,
          name: r.author,
          message: r.message,
          createdAt: r.created_at,
          canBeDeleted: r.can_be_deleted,
        }));
        setNotes(notesRes);
      })
      .catch(() => {
        setNotes([]);
      });
  };

  const eraseNote = (id) => {
    axios
      .delete(
        `/api/customers/${customerUser.customer.id}/campaign-leads/${appointment.campaign_lead.id}/notes/${id}/delete`
      )
      .then(() => {
        fetchNotes();
      })
      .catch(() => {
        setNotes([]);
      });
  };

  // useEffect(() => {
  //   const refreshIntervalId = setInterval(() => {
  //     fetchNotes();
  //   }, 1000 * 30);
  //   return () => {
  //     clearInterval(refreshIntervalId);
  //   };
  // }, []);

  useEffect(() => {
    fetchNotes();
    return () => {};
  }, [appointment]);

  const onSubmitNote = () => {
    setSendLoading(true);
    const payload = {
      note,
    };
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaign-leads/${appointment.campaign_lead.id}/create-note`,
        payload
      )
      .then(() => {
        setSendLoading(false);
        fetchNotes();
        setNote("");
      })
      .catch(() => {
        setSendLoading(false);
        setNotes([]);
      });
  };

  return (
    <Card>
      <SuiBox p={3}>
        <SuiEditor
          value={note}
          onChange={(value) => {
            setNote(value);
          }}
        />
        <SuiBox display="flex" alignItems="center" mt={3}>
          <SuiBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 2, sm: 0 }}>
            <SuiBox ml={2}>
              {sendLoading ? (
                <LoadingButton loading variant="outlined" color="primary" size="small" />
              ) : (
                <SuiButton variant="gradient" color="info" size="small" onClick={onSubmitNote}>
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; Send
                </SuiButton>
              )}
            </SuiBox>
          </SuiBox>
        </SuiBox>
        <Divider />
        <SuiBox mt={3} mb={1} px={1}>
          {notes.map(({ image, id, name, message, createdAt, canBeDeleted }, key) => (
            <SuiBox key={id} display="flex" mt={key === 0 ? 0 : 3}>
              <SuiBox flexShrink={0}>
                <SuiAvatar src={image} alt={name} />
              </SuiBox>
              <SuiBox flexGrow={1} ml={2}>
                <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  {name}
                  {canBeDeleted ? (
                    <SuiBadge
                      color="error"
                      onClick={() => eraseNote(id)}
                      badgeContent="del"
                      sx={{ cursor: "pointer" }}
                    />
                  ) : (
                    ""
                  )}
                </SuiTypography>
                <SuiBox mt={1} mb={2} lineHeight={0.75}>
                  <SuiTypography variant="button" fontWeight="regular" color="dark">
                    {ReactHtmlParser(message)}
                  </SuiTypography>
                  <SuiBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 2, sm: 0 }}>
                    <SuiTypography variant="button" fontWeight="regular" color="text">
                      {createdAt}
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
              <br />
            </SuiBox>
          ))}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default Note;

// Note.TrackReviewChanges = {
//   appointment: null,
// };

Note.propTypes = {
  appointment: PropTypes.objectOf(PropTypes.object).isRequired,
  customerUser: PropTypes.objectOf(PropTypes.object).isRequired,
};
