/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";

// prop-types is a library for typechecking of props.
// import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";
// import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
// import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";

// DefaultNavbar dropdown menus
// import AuthenticationMenu from "examples/Navbars/DefaultNavbar/Menus/AuthenticationMenu";

function DefaultNavbarMobile() {
  // const { width } = open && open.getBoundingClientRect();
  // const [openCollapse, setOpenCollapse] = useState(false);

  // const handleSepOpenCollapse = (name) =>
  //   openCollapse === name ? setOpenCollapse(false) : setOpenCollapse(name);

  return (
    <Menu
    // anchorOrigin={{
    //   vertical: "bottom",
    //   horizontal: "center",
    // }}
    // transformOrigin={{
    //   vertical: "top",
    //   horizontal: "center",
    // }}
    // anchorEl={false}
    // // open={Boolean(open)}
    // onClose={false}
    // MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      <SuiBox px={0.5}>
        {/* <DefaultNavbarLink
          name="authentication"
          collapseStatus={openCollapse === "authentication"}
          onClick={() => handleSepOpenCollapse("authentication")}
        >
          <SuiBox maxHeight="16rem" overflow="auto">
            <AuthenticationMenu routes={routes} mobileMenu />
          </SuiBox>
        </DefaultNavbarLink> */}
      </SuiBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  // routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  // open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  // close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
