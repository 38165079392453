import { useState, useLayoutEffect } from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import { useHistory } from "react-router-dom";
import axios from "axios";
import SuiTypography from "components/SuiTypography";
import { Card, CardContent, Grid, Icon, Slider, CardHeader } from "@mui/material";
import FormField from "layouts/applications/wizard/components/FormField";
import Swal from "sweetalert2";
import Avatar from "@mui/material/Avatar";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ProfileImage from "assets/images/enrichment/profile-default.png";
import ButtonImage1 from "assets/images/enrichment/button1.svg";
// import ButtonImage2 from "assets/images/enrichment/button2.svg";
import CampaignImage from "assets/images/enrichment/campaign.svg";
import CreatedImage from "assets/images/enrichment/subscriptions.svg";
import StartDateImage from "assets/images/enrichment/start-date.svg";
import StatusImage from "assets/images/enrichment/status-img.svg";
import BookedImage from "assets/images/enrichment/booked-img.svg";
import AppointmentImage from "assets/images/enrichment/appointment-img.svg";
import InfoImage from "assets/images/enrichment/info-img.svg";
import Button from "@mui/material/Button";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import SuiSelect from "components/SuiSelect";
import SuiButton from "components/SuiButton";
import SuiVectorMapRoot from "components/SuiVectorMap/SuiVectorMapRoot";
import EventCalendar from "examples/Calendar";
import { dateToYMD } from "common/helpers";
import { saveAs } from "file-saver";
import ReactHtmlParser from "react-html-parser";
// import TeleroidsInfoCard from "./Cards/TeleroidsInfoCard";
import TrackReviewChanges from "./TrackReviewChanges";
import Note from "./Note";
import RapidApi from "./RapidApi";
import Comment from "./Comment";
// import MiniStatisticsCard from "./Cards/StatisticsCards/MiniStatisticsCard";

// react-html-parser components

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "1rem",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

// const color = "secondary";

const AppointmentModal = ({
  campaignId,
  appointmentId,
  open,
  handleClose,
  refreshList,
  customerUser,
  redirectionEnabled,
}) => {
  const history = useHistory();
  const [appointment, setAppointment] = useState(null);
  const [rate, setRate] = useState(null);
  const [isFavorite, setIsFavorite] = useState(null);
  const [isLeadActive, setIsLeadActive] = useState(true);
  const [today, setToday] = useState(dateToYMD(new Date()));
  const [dataEnrichmentInfo, setDataEnrichmentInfo] = useState(false);

  const [appointmentReviewStatuses, setAppointmentReviewStatuses] = useState([]);
  const [appointmentReviewStatus, setAppointmentReviewStatus] = useState(null);
  const [reason] = useState(null);
  const [reasonVal, setReasonVal] = useState(null);
  const [downloadCounter, setDownloadCounter] = useState(0);
  const [headerInfo, setHeaderInfo] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [fundingInvestorsData, setFundingInvestorsData] = useState([]);
  const [sicCodeData, setSicCodeData] = useState([]);

  useLayoutEffect(() => {
    if (appointmentId !== undefined && appointmentId) {
      axios
        .get(
          `/api/customers/${customerUser.customer.id}/appointments/${appointmentId}/company/enrichment`
        )
        .then(async (response) => {
          setHeaderInfo({
            name: response.data[0].name,
            domain: response.data[0].domain,
            links: response.data[0].links,
            industry: response.data[0].industry,
            phone: response.data[0].phone,
            num_employees: response.data[0].num_employees,
            revenue: response.data[0].revenue,
            street: response.data[0].company_enriched_address.street,
            city: response.data[0].company_enriched_address.city,
            region: response.data[0].company_enriched_address.region,
            country_code: response.data[0].company_enriched_address.country_code,
            postal_code: response.data[0].company_enriched_address.postal_code,
            description: response.data[0].description,

            year_founded: response.data[0].year_founded,
            fax: response.data[0].fax,
            funding_investors: response.data[0].funding_investors,
            sic_codes: response.data[0].sic_codes,
          });
          setEmployeeData(response.data[0].company_employee_data);
          setFundingInvestorsData(response.data[0].funding_investors);
          setSicCodeData(response.data[0].sic_codes);
        })
        .catch((error) => {
          const errorData = error.response === undefined ? null : error.response.data;

          if (errorData && errorData.message !== undefined) {
            Swal.fire({
              title: "Error!",
              text: errorData.message,
              icon: "error",
            });
          }
        });
    }
    axios
      .get(
        `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/appointment-review-statuses`
      )
      .then((response) => {
        if (response) {
          setAppointmentReviewStatuses(response.data);
          setAppointmentReviewStatus(response.data[0].value);
        }
      })
      .catch(() => {});

    axios
      .get(
        `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${appointmentId}`
      )
      .then(async (res) => {
        const appointmentRes = res.data.data;

        setAppointment(appointmentRes);
        setRate(appointmentRes.client_rating);
        setDownloadCounter(appointmentRes.download_counter.toString());
        setAppointmentReviewStatus(appointmentRes.review_status);
        setToday(dateToYMD(new Date(appointmentRes.appointment_at)));
        setReasonVal(
          appointmentRes.review_status !== "accepted" ? appointmentRes.review_status : null
        );
        setIsFavorite(appointmentRes.is_favorite);
        if (redirectionEnabled) {
          history.push("/dashboards/appointments");
        }

        if (!appointmentRes.is_prospect_contacted) {
          Swal.fire({
            title: "Did you contact the prospect?",
            text: "You won't be able to revert this!",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, prospect was contacted!",
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire("Congrats!", "Your got in touch with the prospect!", "success");
              axios
                .get(
                  `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${appointmentId}/prospect-contacted`
                )
                .then(() => {
                  axios
                    .get(
                      `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${appointmentId}`
                    )
                    .then((resA) => {
                      const appointmentResVal = resA.data.data;
                      setAppointment(appointmentResVal);
                    })
                    .catch(() => {});
                })
                .catch(() => {});
            }
          });
        }
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
    return () => {
      setAppointment(null);
      refreshList();
    };
  }, [appointmentId]);

  const changeRate = (pRate) => {
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${appointmentId}/rate`,
        {
          rate: pRate,
        }
      )
      .then(() => {
        // Swal.fire("Success!", res.data.message, "success");
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
  };

  const handleRate = (e, value) => {
    if (value === rate) return;
    setRate(value);
    changeRate(value);
  };

  // useEffect(() => {

  //   return () => {};
  // }, [appointment]);

  const handleDownloadPdf = () => {
    const payload = {
      is_appointment_approved_pdf: "YES",
    };

    if (appointment.customer_write_permission) {
      axios
        .post(
          `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${appointmentId}/download-appointment-pdf`,
          payload,
          {
            responseType: "blob",
          }
        )
        // .then((res) => res.blob())
        .then((res) => {
          const filename = `export_${
            appointment.campaign_lead.lead_identificator
          }_appointment_${new Date().toJSON().slice(0, 16).replace(":", "_")}.pdf`;
          saveAs(res.data, filename);
          Swal.fire("Success!", res.data.message, "success");
          setDownloadCounter(parseInt(downloadCounter, 10) + 1);
        })
        .catch((err) => {
          Swal.fire("Error!", err.message, "error");
        });
    }
  };

  const handleDownloadCsv = () => {
    const payload = {
      is_appointment_approved_csv: "YES",
    };

    if (appointment.customer_write_permission) {
      axios
        .post(
          `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${appointmentId}/download-appointment-csv`,
          payload,
          {
            responseType: "blob",
          }
        )
        // .then((res) => res.blob())
        .then((res) => {
          const filename = `export_${
            appointment.campaign_lead.lead_identificator
          }_appointment_${new Date().toJSON().slice(0, 16).replace(":", "_")}.csv`;
          saveAs(res.data, filename);
          Swal.fire("Success!", res.data.message, "success");
          setDownloadCounter(parseInt(downloadCounter, 10) + 1);
        })
        .catch((err) => {
          Swal.fire("Error!", err.message, "error");
        });
    }
  };

  const handleDownloadXls = () => {
    const payload = {
      is_appointment_approved_csv: "YES",
    };

    if (appointment.customer_write_permission) {
      axios
        .post(
          `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${appointmentId}/download-appointment-xls`,
          payload,
          {
            responseType: "blob",
          }
        )
        // .then((res) => res.blob())
        .then((res) => {
          const filename = `export_${
            appointment.campaign_lead.lead_identificator
          }_appointment_${new Date().toJSON().slice(0, 16).replace(":", "_")}.xls`;
          saveAs(res.data, filename);
          Swal.fire("Success!", res.data.message, "success");
          setDownloadCounter(parseInt(downloadCounter, 10) + 1);
        })
        .catch((err) => {
          Swal.fire("Error!", err.message, "error");
        });
    }
  };

  const changeReviewStatus = (pReviewStatus) => {
    const payload = {
      review_status: pReviewStatus,
      reason,
    };
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${appointmentId}/update-review-status`,
        payload
      )
      .then((res) => {
        const appointmentRes = res.data;
        setAppointmentReviewStatus(appointmentRes.value);
        setReasonVal(appointmentReviewStatus);
        refreshList();
        Swal.fire("Success!", appointmentRes.message, "success");
        handleClose();
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function concatAddress(street, city, region, countryCode, postalCode) {
    const addressParts = [street, city, region, countryCode, postalCode].filter((part) => part);
    return addressParts.join(", ");
  }

  const address = concatAddress(
    headerInfo.street,
    headerInfo.city,
    headerInfo.region,
    headerInfo.country_code,
    headerInfo.postal_code
  );

  const handleAppointmentReviewStatusChange = (e) => {
    setAppointmentReviewStatus(e.value);
    if (appointmentReviewStatuses.length > 0 && e.value !== "cancellation") {
      changeReviewStatus(e.value);
    }
  };

  const handleUpdateFavorite = (e) => {
    e.persist();
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${appointmentId}/update-favorite-status`
      )
      .then((res) => {
        const appointmentRes = res.data;
        setIsFavorite(appointmentRes.value);
        // Swal.fire("Like!", appointmentRes.message, "success");
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
  };

  return appointment ? (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <SuiBox sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <Grid container spacing={3} direction="row">
            <Grid item sm={6}>
              <SuiBox display="flex" justifyContent="flex-start">
                <span pl={3}>Appointment</span>
              </SuiBox>
            </Grid>
            <Grid item sm={6}>
              <SuiBox display="flex" justifyContent="flex-end">
                <SuiButton
                  // size="small"
                  aria-label="close"
                  // color="inherit"
                  color="dark"
                  onClick={handleClose}
                >
                  <Icon fontSize="small">close</Icon>
                </SuiButton>
              </SuiBox>
            </Grid>
          </Grid>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, height: "78vh", overflow: "auto" }}>
          <SuiBox pb={3}>
            <Grid container spacing={3} mb={2}>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <SuiBox p={1}>
                  <CardHeader
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    avatar={<Avatar aria-label="recipe" src={CampaignImage} />}
                    title={
                      <Typography sx={{ fontSize: "14px" }} component="div" fontWeight="semibold">
                        Campaign Name
                      </Typography>
                    }
                    subheader={
                      <Typography variant="h6" component="div" fontWeight="semibold">
                        {appointment.campaign_name.toString()}
                      </Typography>
                    }
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <SuiBox p={1}>
                  <CardHeader
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        src={CreatedImage}
                        sx={{
                          borderRadius: "0",
                        }}
                      />
                    }
                    title={
                      <Typography sx={{ fontSize: "14px" }} component="div" fontWeight="semibold">
                        Created
                      </Typography>
                    }
                    subheader={
                      <Typography variant="h6" component="div" fontWeight="semibold">
                        {appointment.campaign_created.toString()}
                      </Typography>
                    }
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <SuiBox p={1}>
                  {/* <MiniStatisticsCard
                    title={{ text: "Start Date", fontWeight: "bold" }}
                    count={appointment.campaign_start_date.toString()}
                    icon={{ color: "warning", component: "paid" }}
                  /> */}
                  <CardHeader
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        src={StartDateImage}
                        sx={{
                          borderRadius: "0",
                        }}
                      />
                    }
                    title={
                      <Typography sx={{ fontSize: "14px" }} component="div" fontWeight="semibold">
                        Start Date
                      </Typography>
                    }
                    subheader={
                      <Typography variant="h6" component="div" fontWeight="semibold">
                        {appointment.campaign_start_date.toString()}
                      </Typography>
                    }
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <SuiBox p={1}>
                  {/* <MiniStatisticsCard
                    title={{ text: "Status", fontWeight: "bold" }}
                    count={appointment.campaign_status.toString().toUpperCase().replace("_", " ")}
                    icon={
                      appointment.campaign_status.toString() === "in_progress"
                        ? { color: "info", component: "paid" }
                        : appointment.campaign_status.toString() === "completed"
                        ? { color: "success", component: "paid" }
                        : { color: "secondary", component: "paid" }
                    }
                  /> */}
                  <CardHeader
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        src={StatusImage}
                        sx={{
                          borderRadius: "0",
                        }}
                      />
                    }
                    title={
                      <Typography sx={{ fontSize: "14px" }} component="div" fontWeight="semibold">
                        Status
                      </Typography>
                    }
                    subheader={
                      <Typography variant="h6" component="div" fontWeight="semibold">
                        {appointment.campaign_status.toString().toUpperCase().replace("_", " ")}
                      </Typography>
                    }
                  />
                </SuiBox>
              </Grid>
            </Grid>

            <Grid container spacing={3} mb={2}>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <SuiBox p={1}>
                  {/* <MiniStatisticsCard
                    title={{ text: "Booked on", fontWeight: "bold" }}
                    count={appointment.created_at.toString()}
                    icon={{ color: "dark", component: "account_circle" }}
                  /> */}
                  <CardHeader
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        src={BookedImage}
                        sx={{
                          borderRadius: "0",
                        }}
                      />
                    }
                    title={
                      <Typography sx={{ fontSize: "14px" }} component="div" fontWeight="semibold">
                        Booked on
                      </Typography>
                    }
                    subheader={
                      <Typography variant="h6" component="div" fontWeight="semibold">
                        {appointment.created_at.toString()}
                      </Typography>
                    }
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <SuiBox p={1}>
                  <CardHeader
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        src={AppointmentImage}
                        sx={{
                          borderRadius: "0",
                        }}
                      />
                    }
                    title={
                      <Typography sx={{ fontSize: "14px" }} component="div" fontWeight="semibold">
                        Appointment at
                      </Typography>
                    }
                    subheader={
                      <Typography variant="h6" component="div" fontWeight="semibold">
                        {appointment.appointment_at.toString()}
                      </Typography>
                    }
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <SuiBox p={1}>
                  {/* <MiniStatisticsCard
                    title={{ text: "Info", fontWeight: "bold" }}
                    percentage={{
                      color: "button",
                      text: appointment.prospect_contacted_txt.toString(),
                    }}
                    icon={{ color: "warning", component: "alarm" }}
                  /> */}
                  <CardHeader
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        src={InfoImage}
                        sx={{
                          borderRadius: "0",
                        }}
                      />
                    }
                    title={
                      <Typography sx={{ fontSize: "14px" }} component="div" fontWeight="semibold">
                        Info
                      </Typography>
                    }
                    subheader={
                      <Typography
                        sx={{ fontSize: "14px", fontWeight: "500" }}
                        component="div"
                        fontWeight="semibold"
                      >
                        {appointment.prospect_contacted_txt.toString()}
                      </Typography>
                    }
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={3}>
                <SuiBox p={1}>
                  {/* <MiniStatisticsCard
                    handleDownloadPdf={handleDownloadPdf}
                    handleDownloadCsv={handleDownloadCsv}
                    handleDownloadXls={handleDownloadXls}
                    title={{ text: "Downloads", fontWeight: "bold" }}
                    count={downloadCounter}
                    icon={{ color: "warning", component: "download" }}
                  /> */}
                  <CardHeader
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    title={
                      <Typography sx={{ fontSize: "14px" }} component="div" fontWeight="semibold">
                        Info
                      </Typography>
                    }
                    subheader={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{ fontSize: "18px", fontWeight: "500", marginRight: "20px" }}
                          component="div"
                          fontWeight="semibold"
                        >
                          {downloadCounter}
                        </Typography>

                        <Button
                          sx={{
                            width: "15px",
                            marginRight: "8px",
                            backgroundColor: "#FF7701",
                          }}
                          variant="contained"
                          endIcon={<ArrowDownwardIcon />}
                          onClick={() => handleDownloadPdf()}
                        >
                          PDF
                        </Button>
                        <Button
                          sx={{ width: "15px", marginRight: "8px", backgroundColor: "#FF7701" }}
                          variant="contained"
                          endIcon={<ArrowDownwardIcon />}
                          onClick={() => handleDownloadCsv()}
                        >
                          CSV
                        </Button>
                        <Button
                          sx={{ width: "15px", marginRight: "8px", backgroundColor: "#FF7701" }}
                          variant="contained"
                          endIcon={<ArrowDownwardIcon />}
                          onClick={() => handleDownloadXls()}
                        >
                          XLS
                        </Button>
                      </div>
                    }
                  />
                </SuiBox>
              </Grid>
            </Grid>

            <Grid container spacing={3} mb={2} justifyContent="end" alignItems="center">
              <Grid item xs={12} md={4} lg={3} xl={3}>
                <Button
                  onClick={() => {
                    setIsLeadActive(true);
                    setDataEnrichmentInfo(false);
                  }}
                  sx={{
                    width: "100%",
                    paddingY: "6%",
                    backgroundColor: "#2659B9",
                    "&:hover": {
                      backgroundColor: "#2659B9",
                    },
                    "&:focus:not(:hover)": {
                      backgroundColor: "#2659B9",
                      boxShadow: "0rem 0rem 0rem 0.1rem rgb(53 53 53 / 50%)",
                    },
                    "& .MuiButton-endIcon": {
                      display: "none",
                    },
                  }}
                  variant="contained"
                >
                  <span style={{ fontSize: "1.4rem", fontWeight: 400, color: "white" }}>Lead</span>
                  <img
                    src={ButtonImage1}
                    alt="Lead Button"
                    style={{ marginLeft: "8px", width: "20px", height: "20px" }}
                  />
                </Button>
              </Grid>
              {/* <Grid item xs={12} md={4} lg={3} xl={3}>
                <Button
                  onClick={() => {
                    setIsLeadActive(false);
                    setDataEnrichmentInfo(false);
                  }}
                  sx={{
                    width: "100%",
                    paddingY: "6%",
                    backgroundColor: "#73C15B",
                    "&:hover": {
                      backgroundColor: "#73C15B",
                    },
                    "&:focus:not(:hover)": {
                      backgroundColor: "#73C15B",
                      boxShadow: "0rem 0rem 0rem 0.1rem rgb(53 53 53 / 50%)",
                    },
                    "& .MuiButton-endIcon": {
                      display: "none",
                    },
                  }}
                  variant="contained"
                >
                  <span style={{ fontSize: "1.4rem", fontWeight: 400, color: "white" }}>
                    Company
                  </span>
                  <img
                    src={ButtonImage1}
                    alt="Lead Button"
                    style={{ marginLeft: "8px", width: "20px", height: "20px" }}
                  />
                </Button>
              </Grid> */}
              <Grid item xs={12} md={4} lg={3} xl={3}>
                <Button
                  onClick={() => {
                    setIsLeadActive(true);
                    setDataEnrichmentInfo(true);
                  }}
                  sx={{
                    width: "100%",
                    paddingY: "6%",
                    backgroundColor: "#FF7701",
                    "&:hover": {
                      backgroundColor: "#FF7701",
                    },
                    "&:focus:not(:hover)": {
                      backgroundColor: "#FF7701",
                      boxShadow: "0rem 0rem 0rem 0.1rem rgb(53 53 53 / 50%)",
                    },
                    "& .MuiButton-endIcon": {
                      display: "none",
                    },
                  }}
                  variant="contained"
                >
                  <span style={{ fontSize: "1.4rem", fontWeight: 400, color: "white" }}>
                    Company
                  </span>
                  <img
                    src={ButtonImage1}
                    alt="Lead Button"
                    style={{ marginLeft: "8px", width: "20px", height: "20px" }}
                  />
                </Button>
              </Grid>
            </Grid>
            {/* <Grid container spacing={3} mb={2}>
              <Grid item xs={12} md={12}>
                Created at {appointment.created_at}
                <br />
                Updated at {appointment.updated_at}
                <br />
                Confirmed at {appointment.confirmed_at}
                <br />
                Approved at {appointment.approved_at}
                <br />
                Appointment at {appointment.appointment_at}
              </Grid>
            </Grid> */}
            <Grid container spacing={3}>
              {!isLeadActive ? (
                <Grid item xs={12}>
                  <RapidApi appointment={appointment} customerUser={customerUser} />
                </Grid>
              ) : (
                <>
                  {dataEnrichmentInfo ? (
                    <>
                      <Grid item xs={12} lg={8} md={8} mb={3}>
                        <Grid item xs={12} sm={12}>
                          <SuiBox mb={3}>
                            <Card>
                              <CardContent>
                                <Typography variant="body1" color="text.secondary">
                                  <Typography
                                    sx={{ marginTop: "10px" }}
                                    variant="h5"
                                    component="div"
                                  >
                                    Company Info
                                  </Typography>
                                  <Grid sx={{ marginTop: "1px" }} container spacing={4}>
                                    {headerInfo.description ? (
                                      <Grid item xs={12}>
                                        <div>
                                          <h5 style={{ textAlign: "center" }}>Description</h5>
                                          <p style={{ textAlign: "start", fontSize: "16px" }}>
                                            {headerInfo.description}
                                          </p>
                                        </div>
                                      </Grid>
                                    ) : (
                                      ""
                                    )}
                                    {headerInfo.name ? (
                                      <Grid item xs={6}>
                                        <div>
                                          <h5 style={{ textAlign: "start" }}>Company Name</h5>
                                          <p style={{ textAlign: "start", fontSize: "16px" }}>
                                            {headerInfo.name}
                                          </p>
                                        </div>
                                      </Grid>
                                    ) : null}
                                    {headerInfo.domain ? (
                                      <Grid item xs={6}>
                                        <div>
                                          <h5 style={{ textAlign: "start" }}>Domain</h5>
                                          <p style={{ textAlign: "start", fontSize: "16px" }}>
                                            <a
                                              href={`http://${headerInfo.domain}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {headerInfo.domain}
                                            </a>
                                          </p>
                                        </div>
                                      </Grid>
                                    ) : null}

                                    {headerInfo.revenue ? (
                                      <Grid item xs={6}>
                                        <div>
                                          <h5 style={{ textAlign: "start" }}>Revenue</h5>
                                          <p style={{ textAlign: "start", fontSize: "16px" }}>
                                            {parseFloat(headerInfo.revenue).toLocaleString(
                                              "en-US",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                          </p>
                                        </div>
                                      </Grid>
                                    ) : null}
                                    {headerInfo.num_employees ? (
                                      <Grid item xs={6}>
                                        <div>
                                          <h5 style={{ textAlign: "start" }}>Employees</h5>
                                          <p style={{ textAlign: "start", fontSize: "16px" }}>
                                            {parseFloat(headerInfo.num_employees).toLocaleString()}
                                          </p>
                                        </div>
                                      </Grid>
                                    ) : null}
                                    {headerInfo.industry ? (
                                      <Grid item xs={6}>
                                        <div>
                                          <h5 style={{ textAlign: "start" }}>Industry</h5>
                                          <p style={{ textAlign: "start", fontSize: "16px" }}>
                                            {headerInfo.industry}
                                          </p>
                                        </div>
                                      </Grid>
                                    ) : null}
                                    {address && (
                                      <Grid item xs={6}>
                                        <div>
                                          <h5 style={{ textAlign: "start" }}>Address</h5>
                                          <p style={{ textAlign: "start", fontSize: "16px" }}>
                                            {address}
                                          </p>
                                        </div>
                                      </Grid>
                                    )}

                                    {headerInfo.phone ? (
                                      <Grid item xs={6}>
                                        <div>
                                          <h5 style={{ textAlign: "start" }}>Phone Number</h5>
                                          <p style={{ textAlign: "start", fontSize: "16px" }}>
                                            {headerInfo.phone}
                                          </p>
                                        </div>
                                      </Grid>
                                    ) : null}
                                    {headerInfo.year_founded ? (
                                      <Grid item xs={6}>
                                        <div>
                                          <h5 style={{ textAlign: "start" }}>Year Founded</h5>
                                          <p style={{ textAlign: "start", fontSize: "16px" }}>
                                            {headerInfo.year_founded}
                                          </p>
                                        </div>
                                      </Grid>
                                    ) : null}
                                    {headerInfo.fax ? (
                                      <Grid item xs={6}>
                                        <div>
                                          <h5 style={{ textAlign: "start" }}>Fax</h5>
                                          <p style={{ textAlign: "start", fontSize: "16px" }}>
                                            {headerInfo.fax}
                                          </p>
                                        </div>
                                      </Grid>
                                    ) : null}
                                    {sicCodeData.length > 0 ? (
                                      <Grid item xs={6}>
                                        <div>
                                          <h5 style={{ textAlign: "start" }}>Sic Codes</h5>
                                          {sicCodeData.map((data) => (
                                            <p style={{ textAlign: "start", fontSize: "16px" }}>
                                              · {data}
                                            </p>
                                          ))}
                                        </div>
                                      </Grid>
                                    ) : null}
                                    {headerInfo.links ? (
                                      <Grid item xs={6}>
                                        <div>
                                          <h5 style={{ textAlign: "start" }}>Social Media links</h5>
                                          {Object.entries(headerInfo.links).map(([key, value]) =>
                                            key && value && value !== null ? (
                                              <p
                                                style={{
                                                  textAlign: "start",
                                                  fontSize: "16px",
                                                  textDecoration: "underline",
                                                }}
                                                key={key}
                                              >
                                                <a
                                                  href={value}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  {" "}
                                                  {capitalizeFirstLetter(key)}
                                                </a>{" "}
                                              </p>
                                            ) : null
                                          )}
                                        </div>
                                      </Grid>
                                    ) : null}

                                    {fundingInvestorsData.length > 0 ? (
                                      <Grid item xs={6}>
                                        <div>
                                          <h5 style={{ textAlign: "start" }}>Funding Investors</h5>
                                          {fundingInvestorsData.map((data) => (
                                            <p style={{ textAlign: "start", fontSize: "16px" }}>
                                              · {data}
                                            </p>
                                          ))}
                                        </div>
                                      </Grid>
                                    ) : null}
                                  </Grid>
                                </Typography>
                              </CardContent>
                            </Card>
                          </SuiBox>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={4} mb={3} md={4}>
                        <Grid item xs={12} sm={12}>
                          <SuiBox mb={3}>
                            <Card>
                              <CardContent>
                                <Typography variant="body1" color="text.secondary">
                                  <Grid sx={{ marginTop: "10px" }} container>
                                    <Typography
                                      sx={{ marginBottom: "30px" }}
                                      variant="h5"
                                      component="div"
                                    >
                                      Employees
                                    </Typography>
                                    {employeeData.map((employee) => (
                                      <CardHeader
                                        sx={{
                                          border: "1px solid #E0E0E0",
                                          backgroundColor: "#FAFAFA",
                                          width: "100%",
                                          borderRadius: "3px",
                                          marginTop: "8px",
                                        }}
                                        avatar={<Avatar aria-label="recipe" src={ProfileImage} />}
                                        title={
                                          <Typography
                                            variant="h6"
                                            component="div"
                                            fontWeight="semibold"
                                          >
                                            {employee.name}
                                          </Typography>
                                        }
                                        subheader={employee.current_title}
                                      />
                                    ))}
                                  </Grid>
                                </Typography>
                              </CardContent>
                            </Card>
                          </SuiBox>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} lg={8} mb={3}>
                      <Grid item xs={12} sm={12}>
                        <SuiBox mb={3}>
                          <Card
                          // sx={{ overflow: "visible" }}
                          // sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
                          //   background: gradients[color]
                          //     ? `${linearGradient(
                          //         rgba(gradients[color].main, 0.9),
                          //         rgba(gradients[color].state, 0.9)
                          //       )}`
                          //     : `${linearGradient(
                          //         rgba(gradients.dark.main, 0.9),
                          //         rgba(gradients.dark.state, 0.9)
                          //       )}`,
                          // })}
                          >
                            <CardContent>
                              {appointment.customer_write_permission && (
                                <>
                                  <Typography gutterBottom variant="h5" component="div" my={2}>
                                    <Grid container spacing={3} direction="row">
                                      <Grid item sm={6}>
                                        <SuiBox display="flex" justifyContent="flex-start">
                                          <span pl={3}> Lead Info</span>
                                        </SuiBox>
                                      </Grid>
                                      <Grid item sm={6}>
                                        <SuiBox display="flex" justifyContent="flex-end">
                                          <Icon
                                            onClick={handleUpdateFavorite}
                                            fontSize="medium"
                                            sx={{ cursor: "pointer" }}
                                            color={isFavorite ? "warning" : "light"}
                                          >
                                            thumb_up
                                          </Icon>
                                        </SuiBox>
                                      </Grid>
                                    </Grid>
                                  </Typography>
                                  <Typography variant="body2" color="text.secondary" py={2}>
                                    {rate ? (
                                      <Slider
                                        aria-label="Rating"
                                        defaultValue={rate}
                                        // getAriaValueText={handleRate}
                                        valueLabelDisplay="auto"
                                        // valueLabelDisplay="on"
                                        step={25}
                                        marks
                                        min={25}
                                        max={100}
                                        color="secondary"
                                        onChangeCommitted={handleRate}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Typography>
                                </>
                              )}
                              <Typography variant="body2" color="text.secondary">
                                {appointment.lead_raw_fields
                                  ? appointment.lead_raw_fields.map((res) =>
                                      res.data !== undefined && res.data && res.data.length > 0 ? (
                                        <SuiBox key={res.field_group_name} mt={3}>
                                          <SuiTypography variant="h5">
                                            {res.field_group_name}
                                          </SuiTypography>
                                          <SuiBox mt={1}>
                                            <Grid container spacing={3}>
                                              {res.data.map((r) => (
                                                <Grid item xs={12} sm={6} key={r.value}>
                                                  <FormField
                                                    type="text"
                                                    label={r.name}
                                                    defaultValue={`${r.value}`}
                                                    disabled
                                                  />
                                                </Grid>
                                              ))}
                                            </Grid>
                                          </SuiBox>
                                        </SuiBox>
                                      ) : (
                                        ""
                                      )
                                    )
                                  : ""}
                              </Typography>
                            </CardContent>
                          </Card>
                        </SuiBox>
                      </Grid>
                      {appointment.customer_write_permission && (
                        <Grid item xs={12}>
                          <Comment
                            appointment={appointment}
                            customerUser={customerUser}
                            campaignId={campaignId}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {appointment && !dataEnrichmentInfo ? (
                    <Grid item xs={12} lg={4} mb={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Card>
                            <CardContent>
                              <SuiTypography variant="h6">
                                Note - Appointment
                                <br />
                                <br />
                                {appointment.campaign_lead.note_for_customer &&
                                  (typeof appointment.campaign_lead.note_for_customer === "string"
                                    ? ReactHtmlParser(appointment.campaign_lead.note_for_customer)
                                    : appointment.campaign_lead.note_for_customer)}
                              </SuiTypography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            <Grid
                              item
                              xs={12}
                              lg={8}
                              ml={1}
                              sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                            >
                              {reasonVal}
                            </Grid>
                            {appointmentReviewStatuses.length > 0 &&
                            appointmentReviewStatus in
                              [
                                appointmentReviewStatuses[0].value,
                                appointmentReviewStatuses[1].value,
                              ] ? (
                              <Grid item xs={12} lg={8} mb={3}>
                                {appointmentReviewStatus}
                              </Grid>
                            ) : (
                              appointment.customer_write_permission && (
                                <Grid item xs={12} lg={8} mb={3} sx={{ zIndex: 1000 }}>
                                  <Grid item xs={12} sm={12}>
                                    <SuiBox p={1}>
                                      <SuiTypography
                                        sx={{ fontSize: "0.75rem", fontWeight: "bold" }}
                                        mb={1}
                                      >
                                        Review Status
                                      </SuiTypography>
                                      <SuiSelect
                                        style={{ zIndex: 1000 }}
                                        placeholder="Type here..."
                                        defaultValue={{ value: "0", label: "Select an option" }}
                                        onChange={handleAppointmentReviewStatusChange}
                                        options={[
                                          { value: "0", label: "Select an option" },
                                          ...appointmentReviewStatuses.map((i) => ({
                                            label:
                                              i.label.replace("_", " ").charAt(0).toUpperCase() +
                                              i.label.replace("_", " ").slice(1),
                                            value: i.value,
                                          })),
                                        ]}
                                      />
                                    </SuiBox>
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <TrackReviewChanges timeline={appointment.timeline} />
                          </Grid>
                        </Grid>
                        {appointment.customer_write_permission && (
                          <Grid item xs={12}>
                            <Note appointment={appointment} customerUser={customerUser} />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <SuiBox mb={3}>
                            <SuiVectorMapRoot
                              appointmentsOrOICLeads={[appointment]}
                              fetchCampaignAppointmentsOrOics={null}
                              openAppointmetModal={null}
                              campaignType={appointment.campaign_type}
                              campaignId={appointment.campaign_id}
                            />
                          </SuiBox>
                        </Grid>
                        <Grid item xs={12}>
                          <SuiBox mb={3}>
                            <EventCalendar
                              initialView="dayGridMonth"
                              timeZone="UTC"
                              initialDate={
                                appointment.appointment_at ? appointment.appointment_at : today
                              }
                              events={[
                                {
                                  id: `${appointment.id}`,
                                  title: `${appointment.lead_identificator}`,
                                  start: appointment.appointment_at,
                                  end: appointment.appointment_at,
                                  class_name: `${appointment.class_name}`,
                                  className: `${appointment.class_name}`,
                                  key: appointment.id,
                                },
                              ]}
                              selectable
                              editable
                            />
                          </SuiBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </>
              )}
            </Grid>
          </SuiBox>
        </Typography>
      </SuiBox>
    </Modal>
  ) : (
    ""
  );
};

AppointmentModal.defaultProps = {
  redirectionEnabled: true,
};

AppointmentModal.propTypes = {
  campaignId: PropTypes.number.isRequired,
  appointmentId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired,
  customerUser: PropTypes.objectOf(PropTypes.object).isRequired,
  redirectionEnabled: PropTypes.bool,
};

export default AppointmentModal;
