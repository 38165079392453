import React from "react";
import PropTypes from "prop-types";

function TimeFormatter({ dateTime }) {
  function formatDateToEuropeanWithTime(date) {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  const formattedDateTime = formatDateToEuropeanWithTime(new Date(dateTime));

  return <span>{formattedDateTime}</span>;
}

TimeFormatter.propTypes = {
  dateTime: PropTypes.instanceOf(Date).isRequired,
};

export default TimeFormatter;
