import React from "react";
import PropTypes from "prop-types";

function DateTimeFormatter({ dateTime }) {
  function formatDateToEuropeanWithTime(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year}, ${hours}:${minutes}`;
  }

  const formattedDateTime = formatDateToEuropeanWithTime(new Date(dateTime));

  return <span>{formattedDateTime}</span>;
}

DateTimeFormatter.propTypes = {
  dateTime: PropTypes.instanceOf(Date).isRequired,
};

export default DateTimeFormatter;
