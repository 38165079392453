import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { Button, Grid, Stack } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CloseIcon from "layouts/crm_dashboard/icons/CloseIcon";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "38%",
  bgcolor: "background.paper",
  padding: "30px 10px",
  borderRadius: "8px",
};

function SubscriptionLink({ openModal, closeModal, calendarLink }) {
  const Generate = () => {
    closeModal(false);

    setTimeout(() => {
      closeModal(true);
    }, 1000);
  };

  const themeCancelButton = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: "#FF4D00D9",
            fontSize: "12px",
            fontFamily: "Poppins",
            backgroundColor: "white",
            width: "40%",
            fontWeight: "700",
            boxShadow: "none",
            padding: "10px 10px",
            border: "1px solid #FF4D00D9",
            "&:hover": {
              backgroundColor: "#FF4D00D9",
              color: "white",
            },
          },
        },
      },
    },
  });

  return (
    <div>
      <Modal
        open={openModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} xs={12}>
              <Stack
                sx={{ padding: "0 40px" }}
                width="100%"
                direction="row"
                display="flex"
                justifyContent="space-between"
              >
                <Typography sx={{ fontSize: "20px", fontFamily: "Poppins" }}>
                  The calendar subscription link
                </Typography>
                <Button
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                  type="button"
                  onClick={() => closeModal(false)}
                >
                  <CloseIcon />
                </Button>
              </Stack>

              <hr
                style={{
                  width: "88%",
                  margin: "20px auto",
                  marginBottom: "20px",
                  marginTop: "1px",
                  border: "0.2px solid #FF4D00D9",
                }}
              />
              <Stack direction="column" alignItems="center">
                <Typography
                  sx={{
                    padding: "0px 40px",
                    paddingBottom: "14px",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "Poppins",
                    color: "#4A4A4A",
                  }}
                >
                  The calendar subscription link is a process you can subscribe to your events.
                </Typography>
                <input
                  style={{
                    width: "85%",
                    border: "1px solid #FF4D00D9",
                    borderRadius: "5px",
                    padding: "10px 5px",
                  }}
                  value={calendarLink}
                />
              </Stack>
            </Grid>
            <Grid sx={{ marginLeft: "8%", marginRight: "8%" }} item xl={12} lg={12} md={12} xs={12}>
              <ThemeProvider theme={themeCancelButton}>
                <Stack
                  sx={{ padding: "20px 5px" }}
                  width="100%"
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  gap={3}
                >
                  <Button
                    sx={{
                      backgroundColor: "#FF4D00D9",
                      border: "0",
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "700",
                      color: "white",
                    }}
                    onClick={Generate}
                    type="button"
                    variant="contained"
                  >
                    Generate
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "#E7E7E7",
                      border: "0",
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "700",
                      "&:hover": {
                        backgroundColor: "#E7E7E7",
                        border: "0",
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        fontWeight: "700",
                        color: "#FF4D00D9 !important",
                      },
                    }}
                    onClick={() => closeModal(false)}
                    type="button"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </Stack>
              </ThemeProvider>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

SubscriptionLink.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  calendarLink: PropTypes.string.isRequired,
};

export default SubscriptionLink;
