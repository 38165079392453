import { CircularProgress, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TechTable2 from "./TechTable2";

const useStyles = makeStyles(() => ({
  styleKeys: {
    fontSize: "15px",
    fontWeight: "400",
    color: "#5c5c5c",
    marginBottom: "7px",
  },
  skillWrapper: {
    margin: "5px",
    display: "flex",
  },
  styleValues: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#000000",
  },
  styleValuesLinks: {
    textDecoration: "underline",
  },
  styleListItemText: {
    margin: "5px",
  },
  scrollContainer: {
    maxHeight: "200px", // Adjust the height as needed
    overflowY: "auto",
  },
}));

function TechStack({ customerUser, appointmentId, loadingCompany }) {
  const classes = useStyles();

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "#FF7701",
          },
        },
      },
    },
  });
  return loadingCompany ? (
    <ThemeProvider theme={theme}>
      <Stack mt="16%" flexDirection="column" alignItems="center">
        <CircularProgress size="5%" />
      </Stack>
    </ThemeProvider>
  ) : (
    <Grid container spacing={1} flexDirection="row" mt={2}>
      <Grid item xs={12} md={12}>
        {appointmentId && (
          <Grid item xs={12} md={12} mt={1} ml={2}>
            <Typography className={classes.styleKeys} ml={1} component="div">
              Tech Stack per domain(s)
            </Typography>
            <TechTable2 customerUser={customerUser} appointmentId={appointmentId} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

TechStack.propTypes = {
  customerUser: PropTypes.any.isRequired,
  appointmentId: PropTypes.number.isRequired,
  loadingCompany: PropTypes.bool.isRequired,
};

export default TechStack;
