import { useContext, useEffect, useState } from "react";
import { createTheme } from "@mui/material/styles";
import { CircularProgress, Grid, Stack, ThemeProvider, Typography } from "@mui/material";
import MuiLink from "@mui/material/Link";
import { CustomerUserContext } from "App";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Swal from "sweetalert2";
import CompanyProfileIcon from "assets/images/iconComponents/CompanyProfileIcon";
import LinkedIn from "components/Enrichment/components/iconComponents/LinkedIn";
import Facebook from "components/Enrichment/components/iconComponents/Facebook";
import Twitter from "components/Enrichment/components/iconComponents/Twitter";
import Crunchbase from "components/Enrichment/components/iconComponents/Crunchbase";
import Instagram from "components/Enrichment/components/iconComponents/Instagram";

function CompanyProfile() {
  const [customerUser] = useContext(CustomerUserContext);
  const [companyProfileData, setCompanyProfileData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCompanyInfo = () => {
    if (customerUser && customerUser.customer) {
      setLoading(true);
      axios
        .get(
          `/api/customers/${customerUser.customer.id}/customer/enriched-data/${customerUser.customer.domain}`
        )
        .then((res) => {
          // Swal.fire("Success!", r.message, "success");
          setCompanyProfileData(res.data[0]);
        })
        .catch((err) => {
          Swal.fire("Error!", err.message, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    fetchCompanyInfo();
    return () => {};
  }, []);

  const useStyles = makeStyles(() => ({
    titles: { fontFamily: "Poppins", fontSize: "14px", fontWeight: "500", color: "#4A4A4A" },
    value: { fontFamily: "Poppins", fontSize: "12px", fontWeight: "400", color: "#4A4A4A" },
    value2: { fontFamily: "Poppins", fontSize: "12px", fontWeight: "400", color: "#5F5F5F" },
    skillWrapper: {
      margin: "5px",
      display: "flex",
    },
  }));

  const classes = useStyles();

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "lightgrey",
          },
        },
      },
    },
  });

  function SocialMedia(key) {
    switch (key) {
      case "linkedin":
        return <LinkedIn color="#0A66C2" />;
      case "facebook":
        return <Facebook color="#3D5A98" />;
      case "twitter":
        return <Twitter color="#1D9BF0" />;
      case "crunchbase":
        return <Crunchbase color="#1D9BF0" />;
      case "instagram":
        return <Instagram />;
      default:
        return key;
    }
  }

  const loader2 = (
    <Stack
      alignItems="center"
      className="loader"
      display="flex"
      justifyContent="center"
      height="100vh"
      width="100%"
    >
      <CircularProgress /> Loading..
    </Stack>
  );

  function concatAddress(street, city, region, countryCode, postalCode) {
    const addressParts = [street, city, region, countryCode, postalCode].filter((part) => part);
    return addressParts.join(", ");
  }

  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        loader2
      ) : (
        <Grid container>
          <Grid
            item
            mt={2}
            mb={2}
            lg={12}
            md={12}
            sm={12}
            xs={11}
            sx={{
              backgroundColor: "#FFFFFF",
              padding: "20px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" },
                alignItems: "flex-start",
                gap: "5%",
                paddingBottom: "2%",
              }}
            >
              <CompanyProfileIcon />

              <Stack direction="column">
                <Typography
                  mb={2}
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontWeight="700"
                  color="#4A4A4A"
                  textTransform="capitalize"
                >
                  {companyProfileData && companyProfileData.name ? companyProfileData.name : null}
                </Typography>
                <Stack
                  direction="column"
                  mb={2}
                  mr={7}
                  sx={{ marginTop: companyProfileData && companyProfileData.name ? "0" : "11%" }}
                >
                  <Typography className={classes.titles}>Headquarters</Typography>
                  <Typography className={classes.value}>
                    {companyProfileData && companyProfileData.company_enriched_address
                      ? concatAddress(
                          companyProfileData.company_enriched_address.city,
                          companyProfileData.company_enriched_address.country
                        )
                      : "-"}
                  </Typography>
                </Stack>
                <Stack direction="column">
                  <Typography className={classes.titles}>Company Size</Typography>
                  <Typography className={classes.value}>
                    {companyProfileData && companyProfileData.num_employees
                      ? `${companyProfileData.num_employees} employees`
                      : "-"}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="column" mt={4.5}>
                <Stack direction="column" mb={2} mr={8}>
                  <Typography className={classes.titles}>Industry</Typography>
                  <Typography className={classes.value}>
                    {companyProfileData && companyProfileData.industry
                      ? companyProfileData.industry
                      : "-"}
                  </Typography>
                </Stack>
                <Stack direction="column">
                  <Typography className={classes.titles}> Founded</Typography>
                  <Typography className={classes.value}>
                    {companyProfileData && companyProfileData.year_founded
                      ? companyProfileData.year_founded
                      : "-"}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="column" mt={4.5}>
                <Stack direction="column">
                  <Stack direction="column" mb={2} mr={7}>
                    <Typography className={classes.titles}>Address</Typography>
                    <Typography className={classes.value}>
                      {companyProfileData && companyProfileData.company_enriched_address
                        ? concatAddress(
                            companyProfileData.company_enriched_address.street,
                            companyProfileData.company_enriched_address.city,
                            companyProfileData.company_enriched_address.region,
                            companyProfileData.company_enriched_address.country_code,
                            companyProfileData.company_enriched_address.postal_code
                          )
                        : "-"}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="column">
                  <Typography className={classes.titles}>Website</Typography>
                  <Typography className={classes.value}>
                    {companyProfileData && companyProfileData.domain
                      ? companyProfileData.domain
                      : "-"}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              mb={2}
              mr={2}
              lg={5.9}
              md={5.8}
              sm={5.8}
              xs={5.3}
              sx={{
                backgroundColor: "#FFFFFF",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "30px",
              }}
            >
              <Typography fontFamily="Poppins" fontSize="16px" fontWeight="700" color="#4A4A4A">
                About Us
              </Typography>
              <Typography
                mt={4}
                fontFamily="Poppins"
                fontSize="12px"
                fontWeight="400"
                color="#5F5F5F"
              >
                {companyProfileData && companyProfileData.description
                  ? companyProfileData.description
                  : "-"}
              </Typography>
            </Grid>
            <Grid
              item
              mb={2}
              lg={5.9}
              md={5.9}
              sm={5.9}
              xs={5.2}
              sx={{
                backgroundColor: "#FFFFFF",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "30px",
              }}
            >
              <Grid container>
                <Typography fontFamily="Poppins" fontSize="16px" fontWeight="700" color="#4A4A4A">
                  Detail Informations
                </Typography>
                <Grid container spacing={2} mt={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12} mb={3}>
                    <Typography mb={2} className={classes.titles}>
                      Fax
                    </Typography>

                    <Typography className={classes.value2}>
                      {companyProfileData && companyProfileData.fax ? companyProfileData.fax : "-"}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} mb={3}>
                    <Typography mb={2} className={classes.titles}>
                      Domain
                    </Typography>
                    <Typography className={classes.value2}>
                      {companyProfileData && companyProfileData.domain
                        ? companyProfileData.domain
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} mb={3}>
                    <Typography mb={2} className={classes.titles}>
                      Phone
                    </Typography>
                    <Typography className={classes.value2}>
                      {companyProfileData && companyProfileData.phone
                        ? companyProfileData.phone
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} mb={3}>
                    <Typography mb={2} className={classes.titles}>
                      Revenue
                    </Typography>
                    <Typography className={classes.value2}>
                      {companyProfileData && companyProfileData.revenue
                        ? companyProfileData.revenue
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} mb={3}>
                    <Typography mb={2} className={classes.titles}>
                      Sic Codes
                    </Typography>
                    {companyProfileData &&
                    companyProfileData.sic_codes &&
                    companyProfileData.sic_codes.length > 0
                      ? companyProfileData.sic_codes.map((data) => (
                          <Typography className={classes.value2}>· {data}</Typography>
                        ))
                      : "-"}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} mb={3}>
                    <Typography mb={2} className={classes.titles}>
                      Social Media
                    </Typography>

                    {companyProfileData && companyProfileData.links
                      ? Object.entries(companyProfileData.links).map(([key, value]) => {
                          if (key && value && value !== null) {
                            if (
                              key === "linkedin" ||
                              key === "facebook" ||
                              key === "twitter" ||
                              key === "crunchbase"
                            ) {
                              return (
                                <Grid item key={key}>
                                  <MuiLink
                                    sx={{
                                      textDecoration: "none",
                                      textTransform: "capitalize",
                                      fontFamily: "Poppins",
                                      fontSize: "12px",
                                      fontWeight: "400",
                                    }}
                                    component="a"
                                    href={value}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {SocialMedia(key)}
                                  </MuiLink>
                                </Grid>
                              );
                            }
                          }
                          return null;
                        })
                      : "-"}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </ThemeProvider>
  );
}

export default CompanyProfile;
