/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// import SuiProgress from "components/SuiProgress";
import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import newAppts from "../../assets/images/authentication_v2/newAppts.svg";
import allAppts from "../../assets/images/authentication_v2/allAppts.svg";
import successsfulAppts from "../../assets/images/authentication_v2/successsfulAppts.svg";
import allLeads from "../../assets/images/authentication_v2/allLeads.svg";
import importedLeads from "../../assets/images/authentication_v2/importedLeads.svg";
import newMessages from "../../assets/images/authentication_v2/newMessages.svg";
import activeCampaigns from "../../assets/images/authentication_v2/activeCampaigns.svg";

function ReportChartItem({ label, progress }) {
  const labelToIcon = {
    "All Leads": allLeads,
    "Imported Leads": importedLeads,
    "new Messages": newMessages,
    "Active Campaigns": activeCampaigns,
    "New Appointments": newAppts,
    "Booked Appointments": allAppts,
    Processed: successsfulAppts,
    "Successful Appointments": successsfulAppts,
    Target: successsfulAppts,
    "Potential Appointments": successsfulAppts,
  };

  const iconSrc = labelToIcon[label] || null;

  return (
    <Box width="100%" padding={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          sx={{
            fontFamily: "Poppins",
            color: "#010101",
            fontSize: "14px",
            fontWeight: "400",
            textTransform: "capitalize",
          }}
        >
          {label}
        </Typography>
        <img src={iconSrc} alt="Icon" />
      </Stack>
      <SuiBox mt={1}>
        <SuiTypography fontFamily="Poppins" fontSize="20px" fontWeight="bold" color="#010101">
          {progress.content}
        </SuiTypography>
        <SuiBox width="100%" mt={0.5} mb={2}>
          <LinearProgress
            variant="determinate"
            sx={{
              width: `${progress.percentage}%`,
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#FF4D00D9",
              },
            }}
          />
        </SuiBox>
      </SuiBox>
    </Box>
  );
}

// Typechecking props for the ReportsBarChartItem
ReportChartItem.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"])
      .isRequired,
    component: PropTypes.node.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  progress: PropTypes.shape({
    content: PropTypes.any.isRequired,
    percentage: PropTypes.number.isRequired,
  }).isRequired,
};

export default ReportChartItem;
