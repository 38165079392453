/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";
import typography from "assets/theme/base/typography";
// import colors from "assets/theme/base/colors";
import boxShadows from "assets/theme/base/boxShadows";

// // Soft UI Dashboard PRO React helper functions
// import linearGradient from "assets/theme/functions/linearGradient";
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;
const { size } = typography;
// const { gradients } = colors;
const { buttonBoxShadow } = boxShadows;

export default {
  ".swal2-container": {
    zIndex: `${9999} !important`,
  },

  ".swal2-popup": {
    borderRadius: `5px !important`,

    "& .button, & .swal2-confirm": {
      fontSize: `14px !important`,
      fontWeight: "700",
      borderRadius: `5px !important`,
      padding: "0 60px",
      margin: pxToRem(3.75),
      textAlign: "center",
      textTransform: "uppercase",
      userSelect: "none",
      backgroundSize: "150% !important",
      backgroundPositionX: "25% !important",
      transition: `all 150ms ease-in`,
      backgroundColor: "#FF4D00D9 !important",
      color: "#FFFFFF",
      height: pxToRem(40),
      boxShadow: `${buttonBoxShadow.main} !important`,
      border: "none",
      cursor: "pointer",
      fontFamily: "Poppins",

      "&:hover, &:focus, &:active": {
        backgroundColor: "#FF4D00D9 !important",
        boxShadow: `${buttonBoxShadow.stateOf} !important`,
      },

      "& .material-icons-round": {
        fontSize: size.sm,
        marginRight: pxToRem(4),
        verticalAlign: "middle",
      },
    },

    "& .button, & .swal2-cancel": {
      fontSize: `14px !important`,
      fontWeight: "700",
      borderRadius: `5px !important`,
      padding: "0 60px",
      margin: pxToRem(3.75),
      textAlign: "center",
      textTransform: "uppercase",
      userSelect: "none",
      backgroundSize: "150% !important",
      backgroundPositionX: "25% !important",
      transition: `all 150ms ease-in`,
      backgroundColor: "#E7E7E7 !important",
      color: "#FF4D00D9",
      height: pxToRem(40),
      boxShadow: `${buttonBoxShadow.main} !important`,
      border: "none",
      cursor: "pointer",
      fontFamily: "Poppins",

      "&:hover, &:focus, &:active": {
        backgroundColor: "#E7E7E7 !important",
        color: "#FF4D00D9",
        boxShadow: `${buttonBoxShadow.stateOf} !important`,
      },

      "& .material-icons-round": {
        fontSize: size.sm,
        marginRight: pxToRem(4),
        verticalAlign: "middle",
      },
    },

    "& .button.button-success": {
      backgroundColor: "#FF4D00D9 !important",
      fontSize: `16px !important`,
      fontWeight: "400",
      borderRadius: `5px !important`,
      padding: "0 60px",
      margin: pxToRem(3.75),
      textAlign: "center",
      textTransform: "uppercase",

      "&:hover, &:focus, &:active": {
        backgroundColor: "#FF4D00D9 !important",
        fontSize: `16px !important`,
        fontWeight: "400",
        borderRadius: `5px !important`,
        padding: "0 60px",
        margin: pxToRem(3.75),
        textAlign: "center",
        textTransform: "uppercase",
      },
    },

    "& .button.button-error": {
      backgroundColor: "#E7E7E7 !important",
      fontSize: `16px !important`,
      fontWeight: "400",
      borderRadius: `5px !important`,
      padding: "0 60px",
      margin: pxToRem(3.75),
      textAlign: "center",
      textTransform: "uppercase",

      "&:hover, &:focus, &:active": {
        backgroundColor: "#E7E7E7 !important",
        fontSize: `16px !important`,
        fontWeight: "400",
        borderRadius: `5px !important`,
        padding: "0 60px",
        margin: pxToRem(3.75),
        textAlign: "center",
        textTransform: "uppercase",
      },
    },

    "& .button-flex": {
      display: "inline-flex !important",
      alignItems: "center",
    },

    "& a, a:visited": {
      color: "#545454",
      textDecoration: "none",
    },

    "& .swal2-image": {
      borderRadius: borderRadius.xl,
      marginBottom: 0,
    },

    "& .swal2-title": {
      fontWeight: "400",
      fontSize: "20px",
      fontFamily: "Poppins",
      color: "#010101",
    },

    "& .swal2-icon.swal2-success .swal2-success-ring": {
      border: "4px solid #a5dc86",
    },
  },
};
