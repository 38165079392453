/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  header: {
    padding: "1%",
    fontSize: "15px",
    fontWeight: "bold",
    color: "#000000",
    backgroundColor: "#F0F0F0",
    textAlign: "center",
  },
  cardContent: { margin: "0", padding: "0" },
  card: {
    borderRadius: "0px 0px 3.5px 3.5px",
    marginBottom: "5%",
    // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  body: { margin: "5%", fontSize: "14px" },
}));

function NoteAppointment({ appointment }) {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.header} variant="h6">
          Agent Note
        </Typography>
        <Box className={classes.body}>
          {appointment.campaign_lead.note_for_customer ? (
            typeof appointment.campaign_lead.note_for_customer === "string" ? (
              ReactHtmlParser(appointment.campaign_lead.note_for_customer)
            ) : (
              appointment.campaign_lead.note_for_customer
            )
          ) : (
            <Typography
              sx={{ textAlign: "center", mt: 2, fontSize: "13px" }}
              variant="body2"
              color="textSecondary"
            >
              No agent notes available
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

NoteAppointment.propTypes = {
  appointment: PropTypes.object.isRequired,
};

export default NoteAppointment;
