function ChevronUp() {
  return (
    <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.858288 8.46925L7.12798 2.19165L13.4074 8.46925C13.5957 8.65758 13.9304 8.65758 14.1185 8.46925C14.3066 8.28092 14.3063 7.94611 14.1179 7.75779L7.48273 1.14538C7.37807 1.04076 7.25255 0.998909 7.12706 0.998909C7.00157 0.998909 6.87611 1.04076 6.77161 1.14538L0.146642 7.77871C-0.0414488 7.96704 -0.0411906 8.30185 0.147191 8.49017C0.335571 8.6785 0.670213 8.6785 0.858288 8.46925Z"
        fill="#1C2434"
      />
    </svg>
  );
}

export default ChevronUp;
