import PropTypes from "prop-types";

function AppointmentAnalyticsIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="25"
      height="25"
      viewBox="0 0 256 256"
    >
      <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
        <path
          fill={color}
          d="M 49.501 20 H 7.378 c -0.552 0 -1 -0.448 -1 -1 s 0.448 -1 1 -1 h 42.123 c 0.553 0 1 0.448 1 1 S 50.054 20 49.501 20 z"
          transform="matrix(1 0 0 1 0 0)"
        />
        <path
          fill={color}
          d="M 82.622 20 h -3.814 c -0.553 0 -1 -0.448 -1 -1 s 0.447 -1 1 -1 h 3.814 c 0.553 0 1 0.448 1 1 S 83.175 20 82.622 20 z"
          transform="matrix(1 0 0 1 0 0)"
        />
        <path
          fill={color}
          d="M 73.622 20 h -3.814 c -0.553 0 -1 -0.448 -1 -1 s 0.447 -1 1 -1 h 3.814 c 0.553 0 1 0.448 1 1 S 74.175 20 73.622 20 z"
          transform="matrix(1 0 0 1 0 0)"
        />
        <path
          fill={color}
          d="M 46.907 79.28 h -3.814 c -0.552 0 -1 -0.447 -1 -1 s 0.448 -1 1 -1 h 3.814 c 0.553 0 1 0.447 1 1 S 47.46 79.28 46.907 79.28 z"
          transform="matrix(1 0 0 1 0 0)"
        />
        <path
          fill={color}
          d="M 85.427 10.719 H 4.573 C 2.052 10.719 0 12.771 0 15.292 v 59.416 c 0 2.521 2.052 4.572 4.573 4.572 h 31.5 c 0.552 0 1 -0.447 1 -1 s -0.448 -1 -1 -1 h -31.5 C 3.154 77.28 2 76.126 2 74.708 V 26.016 h 86 v 48.692 c 0 1.418 -1.154 2.572 -2.573 2.572 h -31.5 c -0.553 0 -1 0.447 -1 1 s 0.447 1 1 1 h 31.5 c 2.521 0 4.573 -2.051 4.573 -4.572 V 15.292 C 90 12.771 87.948 10.719 85.427 10.719 z M 2 24.016 v -8.723 c 0 -1.419 1.154 -2.573 2.573 -2.573 h 80.854 c 1.419 0 2.573 1.154 2.573 2.573 v 8.723 H 2 z"
          transform="matrix(1 0 0 1 0 0)"
        />
        <path
          fill={color}
          d="M 22.741 71.875 h -6.482 c -1.462 0 -2.651 -1.189 -2.651 -2.651 v -8.482 c 0 -1.462 1.189 -2.651 2.651 -2.651 h 6.482 c 1.462 0 2.651 1.189 2.651 2.651 v 8.482 C 25.393 70.686 24.203 71.875 22.741 71.875 z M 16.259 60.09 c -0.359 0 -0.651 0.292 -0.651 0.651 v 8.482 c 0 0.359 0.292 0.651 0.651 0.651 h 6.482 c 0.359 0 0.651 -0.292 0.651 -0.651 v -8.482 c 0 -0.359 -0.292 -0.651 -0.651 -0.651 H 16.259 z"
          transform="matrix(1 0 0 1 0 0)"
        />
        <path
          fill={color}
          d="M 39.741 71.875 h -6.482 c -1.462 0 -2.651 -1.189 -2.651 -2.651 V 56.741 c 0 -1.462 1.189 -2.651 2.651 -2.651 h 6.482 c 1.462 0 2.651 1.189 2.651 2.651 v 12.482 C 42.393 70.686 41.203 71.875 39.741 71.875 z M 33.259 56.09 c -0.359 0 -0.651 0.292 -0.651 0.651 v 12.482 c 0 0.359 0.292 0.651 0.651 0.651 h 6.482 c 0.359 0 0.651 -0.292 0.651 -0.651 V 56.741 c 0 -0.359 -0.292 -0.651 -0.651 -0.651 H 33.259 z"
          transform="matrix(1 0 0 1 0 0)"
        />
        <path
          fill={color}
          d="M 56.741 71.875 h -6.482 c -1.462 0 -2.651 -1.189 -2.651 -2.651 V 46.741 c 0 -1.462 1.189 -2.651 2.651 -2.651 h 6.482 c 1.462 0 2.651 1.189 2.651 2.651 v 22.482 C 59.393 70.686 58.203 71.875 56.741 71.875 z M 50.259 46.09 c -0.359 0 -0.651 0.292 -0.651 0.651 v 22.482 c 0 0.359 0.292 0.651 0.651 0.651 h 6.482 c 0.359 0 0.651 -0.292 0.651 -0.651 V 46.741 c 0 -0.359 -0.292 -0.651 -0.651 -0.651 H 50.259 z"
          transform="matrix(1 0 0 1 0 0)"
        />
        <path
          fill={color}
          d="M 73.741 71.875 h -6.482 c -1.462 0 -2.651 -1.189 -2.651 -2.651 V 34.741 c 0 -1.462 1.189 -2.651 2.651 -2.651 h 6.482 c 1.462 0 2.651 1.189 2.651 2.651 v 34.482 C 76.393 70.686 75.203 71.875 73.741 71.875 z M 67.259 34.09 c -0.359 0 -0.651 0.292 -0.651 0.651 v 34.482 c 0 0.359 0.292 0.651 0.651 0.651 h 6.482 c 0.359 0 0.651 -0.292 0.651 -0.651 V 34.741 c 0 -0.359 -0.292 -0.651 -0.651 -0.651 H 67.259 z"
          transform="matrix(1 0 0 1 0 0)"
        />
      </g>
    </svg>
  );
}

AppointmentAnalyticsIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default AppointmentAnalyticsIcon;
