import PropTypes from "prop-types";

function LogoutIcon({ color }) {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5156 14.5004C9.5156 14.2601 9.61108 14.0296 9.78104 13.8596C9.95099 13.6897 10.1815 13.5942 10.4219 13.5942H23.6809L21.3114 11.5642C21.2209 11.4868 21.1466 11.3923 21.0927 11.2861C21.0387 11.18 21.0062 11.0642 20.997 10.9455C20.9878 10.8268 21.0021 10.7074 21.039 10.5942C21.076 10.481 21.1348 10.3762 21.2123 10.2858C21.2897 10.1953 21.3842 10.121 21.4904 10.0671C21.5965 10.0132 21.7122 9.98066 21.831 9.97146C21.9497 9.96226 22.069 9.97653 22.1822 10.0135C22.2954 10.0504 22.4002 10.1093 22.4907 10.1867L26.7199 13.8117C26.8193 13.8968 26.8992 14.0024 26.954 14.1213C27.0088 14.2402 27.0371 14.3695 27.0371 14.5004C27.0371 14.6313 27.0088 14.7607 26.954 14.8796C26.8992 14.9985 26.8193 15.1041 26.7199 15.1892L22.4907 18.8142C22.308 18.9706 22.0707 19.048 21.831 19.0294C21.5912 19.0108 21.3687 18.8978 21.2123 18.7151C21.0559 18.5324 20.9785 18.2951 20.997 18.0554C21.0156 17.8156 21.1287 17.5931 21.3114 17.4367L23.6797 15.4067H10.4219C10.1815 15.4067 9.95099 15.3112 9.78104 15.1413C9.61108 14.9713 9.5156 14.7408 9.5156 14.5004Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M17.6719 9.66699C17.6719 10.5152 17.6719 10.9394 17.4677 11.2451C17.3797 11.3765 17.2668 11.4894 17.1354 11.5774C16.8297 11.7816 16.4055 11.7816 15.5573 11.7816H10.4219C9.70082 11.7816 9.00929 12.068 8.49943 12.5779C7.98956 13.0877 7.70313 13.7793 7.70313 14.5003C7.70313 15.2214 7.98956 15.9129 8.49943 16.4228C9.00929 16.9326 9.70082 17.2191 10.4219 17.2191H15.5573C16.4055 17.2191 16.8297 17.2191 17.1354 17.4221C17.2669 17.5104 17.3798 17.6237 17.4677 17.7556C17.6719 18.0613 17.6719 18.4854 17.6719 19.3337C17.6719 22.7508 17.6719 24.4606 16.6098 25.5215C15.5488 26.5837 13.8403 26.5837 10.4231 26.5837H9.21475C5.79517 26.5837 4.08779 26.5837 3.02567 25.5215C1.96354 24.4606 1.96354 22.7508 1.96354 19.3337V9.66699C1.96354 6.24983 1.96354 4.54003 3.02567 3.47912C4.08779 2.41699 5.79638 2.41699 9.21354 2.41699H10.4219C13.8403 2.41699 15.5488 2.41699 16.6098 3.47912C17.6719 4.54003 17.6719 6.24983 17.6719 9.66699Z"
        fill="#5F5F5F"
        fillOpacity="0.8"
      />
    </svg>
  );
}

LogoutIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default LogoutIcon;
