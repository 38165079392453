function ArrowOpenIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9569 16.4207L14.6146 18.0784L19.59 13.1041L14.6157 8.12988L12.958 9.78875L15.0999 11.9318H6.26397V14.2765H15.0999L12.9569 16.4207Z"
        fill="#5F5F5F"
        fillOpacity="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.000183105 21.3106C0.000183105 22.5543 0.49424 23.7471 1.37367 24.6265C2.25309 25.5059 3.44585 26 4.68955 26H21.1023C22.346 26 23.5388 25.5059 24.4182 24.6265C25.2976 23.7471 25.7917 22.5543 25.7917 21.3106V4.89786C25.7917 3.65416 25.2976 2.46141 24.4182 1.58198C23.5388 0.702553 22.346 0.208496 21.1023 0.208496H4.68955C3.44585 0.208496 2.25309 0.702553 1.37367 1.58198C0.49424 2.46141 0.000183105 3.65416 0.000183105 4.89786V21.3106ZM4.68955 23.6553H21.1023C21.7242 23.6553 22.3206 23.4083 22.7603 22.9686C23.2 22.5289 23.447 21.9325 23.447 21.3106V4.89786C23.447 4.27601 23.2 3.67963 22.7603 3.23992C22.3206 2.80021 21.7242 2.55318 21.1023 2.55318H4.68955C4.0677 2.55318 3.47132 2.80021 3.03161 3.23992C2.59189 3.67963 2.34486 4.27601 2.34486 4.89786V21.3106C2.34486 21.9325 2.59189 22.5289 3.03161 22.9686C3.47132 23.4083 4.0677 23.6553 4.68955 23.6553Z"
        fill="#5F5F5F"
        fillOpacity="0.8"
      />
    </svg>
  );
}

export default ArrowOpenIcon;
