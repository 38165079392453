import React from "react";
import PropTypes from "prop-types";

function Facebook(props) {
  const { color } = props;
  return (
    <svg width="24" height="24" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_55_170)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.48633 0H13.5137C14.4375 0.00255561 15.3228 0.370685 15.9761 1.02394C16.6293 1.67721 16.9974 2.56248 17 3.48633V13.5137C16.9974 14.4375 16.6293 15.3228 15.9761 15.9761C15.3228 16.6293 14.4375 16.9974 13.5137 17H3.48633C2.56248 16.9974 1.67721 16.6293 1.02394 15.9761C0.370685 15.3228 0.00255561 14.4375 0 13.5137L0 3.48633C0.00255561 2.56248 0.370685 1.67721 1.02394 1.02394C1.67721 0.370685 2.56248 0.00255561 3.48633 0Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.58333 4.88509H11.3915V2.71582H9.58333C8.91306 2.71765 8.2708 2.98491 7.7971 3.45913C7.3234 3.93335 7.05684 4.5759 7.05575 5.24618V6.33081H5.60864V8.50008H7.05575V14.2843H9.22363V8.50008H11.0304L11.3915 6.33081H9.22363V5.24618C9.2247 5.15098 9.2629 5.05997 9.33009 4.99252C9.39728 4.92507 9.48814 4.88653 9.58333 4.88509Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_55_170">
          <rect width="17" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

Facebook.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Facebook;
