import PropTypes from "prop-types";

function ActivityLogIcon({ color, width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <path
        fill={color}
        d="M22,24H2V2.5C2,1.122,3.122,0,4.5,0h15c1.378,0,2.5,1.122,2.5,2.5V24Zm-19-1H21V2.5c0-.827-.673-1.5-1.5-1.5H4.5c-.827,0-1.5,.673-1.5,1.5V23ZM18,5h-7v1h7v-1Zm0,6h-7v1h7v-1Zm0,6h-7v1h7v-1ZM9,7h-3v-3h3v3Zm-2-1h1v-1h-1v1Zm2,7h-3v-3h3v3Zm-2-1h1v-1h-1v1Zm2,7h-3v-3h3v3Zm-2-1h1v-1h-1v1Z"
      />
    </svg>
  );
}

ActivityLogIcon.propTypes = {
  color: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

ActivityLogIcon.defaultProps = {
  width: 34,
  height: 34,
};

export default ActivityLogIcon;
