import React, { useState, useContext } from "react";
import { Grid, Stack, ThemeProvider, createTheme, Typography } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar/Navbar";
import Footer from "examples/Footer";
import EmbeddedAppointments from "layouts/dashboards/appointments_v2/EmbeddedAppointments";
import EmbeddedOicLeads from "layouts/dashboards/oic_v2/EmbeddedOicLeads";
import { useSoftUIController } from "context";
import { CustomerUserContext } from "App";
import ActivityLogs from "./ActivityLogs";

const Dashboard = () => {
  const [controller] = useSoftUIController();
  const [customerUser] = useContext(CustomerUserContext);
  const [tabId, setTabId] = useState(0);

  const theme = createTheme({
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDEDED",
            color: "#5F5F5F",
            fontFamily: "Poppins",
            padding: "8px 16px 8px 18px",
            borderRadius: "6px",
            textTransform: "none",
            marginRight: "-2%",
            fontSize: "13px",
            fontWeight: "600",
            minHeight: "5px",
            "&.Mui-selected": {
              backgroundColor: "#FF4D00D9",
              color: "#fff",
              fontFamily: "Poppins",
              borderRadius: "6px",
              padding: "8px 16px 8px 18px",
              marginRight: "-2%",
              zIndex: "1",
              fontSize: "13px",
              fontWeight: "600",
            },
          },
        },
      },
      // MuiTab: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: "#EDEDED",
      //       color: "#5F5F5F",
      //       "&.Mui-selected": {
      //         backgroundColor: "#FF4D00D9",
      //         color: "#fff",
      //       },
      //     },
      //   },
      // },
    },
  });

  const handleClick = (id) => () => {
    setTabId(id);
  };

  const layoutMarginLeft = controller.miniSidenav ? "0px" : "0px";

  return (
    <DashboardLayout>
      <DashboardNavbar componentName="Dashboard" />
      <Grid container sx={{ backgroundColor: "#FBFAFB", overflow: "hidden", ml: layoutMarginLeft }}>
        <ThemeProvider theme={theme}>
          <Grid container item spacing={3} sx={{ p: 3, backgroundColor: "#FFFFFF" }}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <ActivityLogs customerId={customerUser.customer.id} />
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={9}>
              <Grid display={{ xs: "block", sm: "flex", md: "flex", lg: "flex" }}>
                <Stack
                  sx={{
                    padding: "10px",
                    backgroundColor: tabId === 0 ? "#FF4D00D9" : "#FFFFFF",
                    borderRadius: "5px",
                    marginRight: "1%",
                    marginBottom: "1%",
                    cursor: "pointer",
                    color: tabId === 0 ? "#fff" : "#000000",
                    textAlign: "center",
                  }}
                  xs={{ flexGrow: 1 }}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  onClick={handleClick(0)}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "500", fontFamily: "Poppins" }}>
                    Appointments
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    padding: "10px",
                    backgroundColor: tabId === 1 ? "#FF4D00D9" : "#FFFFFF",
                    borderRadius: "5px",
                    marginRight: "1%",
                    marginBottom: "1%",
                    cursor: "pointer",
                    color: tabId === 1 ? "#fff" : "#000000",
                    textAlign: "center",
                  }}
                  xs={{ flexGrow: 1 }}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  onClick={handleClick(1)}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "500", fontFamily: "Poppins" }}>
                    OIC
                  </Typography>
                </Stack>
              </Grid>
              {tabId === 0 ? (
                <EmbeddedAppointments customerUser={customerUser} />
              ) : (
                <EmbeddedOicLeads customerUser={customerUser} />
              )}
            </Grid>
          </Grid>
        </ThemeProvider>
        <Stack sx={{ width: "100%", flexShrink: "0" }}>
          <Footer />
        </Stack>
      </Grid>
    </DashboardLayout>
  );
};

export default Dashboard;
