import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AppointmentChat from "examples/AppointmentChat";
import Swal from "sweetalert2";
import BackgroundSpiral from "components/Enrichment/components/iconComponents/BackgroundSpiral";
import { Box } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "../../components/Enrichment/Slider/index";

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "white",
    height: "100vh",
  },
  slider: {
    position: "relative",
  },
}));

function Enrichment() {
  const classes = useStyles();

  const location = useLocation();

  const [companyInfo, setCompanyInfo] = useState([]);
  const [aboutInfo, setAboutInfo] = useState([]);
  const [employeeInfo, setEmployeeInfo] = useState([]);
  const { appointmentIdParam, customerUser, campaignId } = location.state;
  const [loadingCompany, setLoadingCompany] = useState(false);

  useEffect(() => {
    if (appointmentIdParam !== undefined && appointmentIdParam) {
      setLoadingCompany(true);
      axios
        .get(
          `/api/customers/${customerUser.customerUser.customer.id}/appointments/${appointmentIdParam}/company/enrichment`
        )
        .then((response) => {
          setCompanyInfo({
            id: response.data[0].id,
            name: response.data[0].name,
            domain: response.data[0].domain,
            links: response.data[0].links,
            industry: response.data[0].industry,
            num_employees: response.data[0].num_employees,
            revenue: response.data[0].revenue,
            fax: response.data[0].fax,
            funding_investors: response.data[0].funding_investors,
            phone: response.data[0].phone,
            sic_codes: response.data[0].sic_codes,
            year_founded: response.data[0].year_founded,
            street: response.data[0].company_enriched_address.street,
            city: response.data[0].company_enriched_address.city,
            region: response.data[0].company_enriched_address.region,
            country_code: response.data[0].company_enriched_address.country_code,
            postal_code: response.data[0].company_enriched_address.postal_code,
            techstack: response.data[0].techstack,
            competitors: response.data[0].competitors,
            departments: response.data[0].departments,
            industries: response.data[0].industries,
            company_growth: response.data[0].company_growth,
          });
          setAboutInfo({ description: response.data[0].description });
          setEmployeeInfo({
            employees: response.data[0].company_employee_data,
          });
          setLoadingCompany(false);
        })

        .catch((error) => {
          const errorData = error.response === undefined ? null : error.response.data;

          if (errorData && errorData.message !== undefined) {
            Swal.fire({
              title: "Error!",
              text: errorData.message,
              icon: "error",
            });
          }
        });
      setLoadingCompany(false);
    }
  }, [appointmentIdParam]);

  return (
    <Box className={classes.container}>
      <BackgroundSpiral />
      <Box className={classes.slider}>
        <Slider
          companyInfo={companyInfo}
          aboutInfo={aboutInfo}
          employeeInfo={employeeInfo}
          setEmployeeInfo={setEmployeeInfo}
          customerUser={customerUser}
          campaignId={campaignId}
          appointmentId={appointmentIdParam}
          loadingCompany={loadingCompany}
        />
      </Box>
      <AppointmentChat />
    </Box>
  );
}

export default Enrichment;
