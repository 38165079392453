import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export default styled(Box)(() => {
  const palette = {
    white: "#FFFFFF", // Note: 'white' isn't a standard MUI palette color, this is an example
    dark: {
      main: "#212121", // MUI doesn't provide a 'dark' color, this is typically defined by you
    },
    light: {
      main: "#f5f5f5", // Similar to MUI's grey[100] or grey[50], assuming as 'light'
    },
    grey: {
      700: "#616161", // Example of a grey shade from MUI's grey palette
    },
    gradients: {
      primary: {
        main: "linear-gradient(to right, #ff6f00, #ff8f00)", // Custom defined
        state: "linear-gradient(to right, #ff8f00, #ff6f00)", // Custom defined
      },
      secondary: {
        main: "linear-gradient(to right, #2196f3, #21cbf3)", // Custom defined
        state: "linear-gradient(to right, #21cbf3, #2196f3)", // Custom defined
      },
    },
  };

  const typography = {
    size: {
      sm: "0.875rem", // Example, not a default MUI typography property
    },
    fontWeightMedium: 500, // Default MUI fontWeightMedium
    fontWeightBold: 700, // Default MUI fontWeightBold
  };

  const functions = {
    linearGradient: (color1, color2) => `linear-gradient(to right, ${color1}, ${color2})`, // Custom function
    pxToRem: (size) => `${size / 16}rem`, // MUI's default pxToRem function (assuming the browser default font-size is 16px)
  };

  const boxShadows = {
    buttonBoxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)", // Example box shadow
  };

  const { white, dark, light, grey, gradients } = palette;
  const { size, fontWeightMedium, fontWeightBold } = typography;
  const { linearGradient, pxToRem } = functions;
  const { buttonBoxShadow } = boxShadows;

  return {
    "& .fc-rounded-today": {
      backgroundColor: "black",
      borderRadius: "50%",
      zIndex: "99999",
    },

    "& .fc-media-screen": {
      height: "100%",
      color: dark.main,
    },

    "& .fc-theme-standard .fc-scrollgrid": {
      border: "none",
    },

    "& .fc-theme-standard thead tr th": {
      borderLeft: "none",
      borderRight: "none",
    },

    "& .fc-theme-standard td, .fc-theme-standard th": {
      borderColor: light.main,
      border: "none",
    },

    "& .fc th": {
      textAlign: "center",
    },

    "& .fc .fc-col-header-cell-cushion": {
      fontSize: "16px",
      fontWeight: fontWeightBold,
      color: "black",
      fontFamily: "Poppins",
    },

    "& .fc .fc-daygrid-day-number": {
      color: grey[700],
      fontSize: "12px",
      fontWeight: 400,
      fontFamily: "Poppins",
      width: "100%",
      height: "10%",
      textAlign: "center",
      padding: "0",
      paddingTop: "8px",
    },

    "& .fc-scrollgrid-section.fc-scrollgrid-section-header > td": {
      border: "none",
    },

    "& .fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid > td": {
      border: "none",
    },

    "& .fc-scrollgrid-sync-table": {
      height: "auto !important",
    },

    "& .fc-day-today": {
      backgroundColor: "#FF4D00D9 !important",
      borderRadius: "5%",
    },

    "& .fc-day-today .fc-daygrid-day-number": {
      color: "white",
    },

    "& .fc .fc-view-harness-active > .fc-view": {
      position: "static",
      height: "100%",
    },

    "& .fc .fc-scroller-liquid-absolute": {
      position: "static",
    },

    "& .fc-daygrid-event": {
      margin: `${pxToRem(0.5)} ${pxToRem(2)}`,
      border: "none",
      borderRadius: pxToRem(5.6),
      fontSize: size.sm,
      fontWeight: fontWeightMedium,
      pointerEvents: "none",
      "&:hover, &:focus, &:active": {
        background: "none",
      },
    },

    "& .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events": {
      position: "relative",
      minHeight: "12px",
      paddingBottom: "12px",
    },

    "& .fc-event-title": {
      padding: `${pxToRem(3)} ${pxToRem(4.8)} ${pxToRem(2.5)} !important`,
      display: "none",
    },

    "& .fc-today-button": {
      display: "none",
    },

    "& .fc-toolbar .fc-left": {
      display: "flex",
      alignItems: "center",
    },

    "& .fc-button, .fc-today-button, .fc-button:disabled": {
      background: `#FF4D00D9`,
      border: `none`,
      fontSize: `${size.sm} !important`,
      boxShadow: `none`,
      opacity: "1 !important",
      transition: `all 150ms ease-in`,

      "&:hover, &:focus, &:active": {
        transform: "scale(1.02)",
        boxShadow: `${buttonBoxShadow.stateOf} !important`,
        backgroundColor: "#FF4D00D9 !important",
        border: "none !important",
      },
    },

    "& .fc-prev-button ": {
      background: "none",
    },
    "& .fc-next-button": {
      background: "none",
      margin: 0,
      padding: 0,
    },

    "& .fc-direction-ltr .fc-toolbar > * > :not(:first-child)": {
      marginLeft: "2px",
      display: "flex",
      alignItems: "center",
    },

    "& .fc-customButton-button": {
      padding: "6px 25px",
      fontSize: "12px",
      fontFamily: "Poppins",
      fontWeight: `${fontWeightBold}`,
    },

    "& .fc .fc-button .fc-icon": {
      fontSize: "20px",
      color: "black",
      "&:hover, &:focus, &:active": {
        background: "none !important",
        border: "none !important",
      },
    },

    "& .fc-toolbar-title": {
      fontSize: `17px !important`,
      fontWeight: `${fontWeightBold} !important`,
      fontFamily: "Poppins",
    },

    "& .event-primary": {
      backgroundImage: linearGradient(gradients.primary.main, gradients.primary.state),
      "& *": { color: white.main },
    },

    "& .event-secondary": {
      backgroundImage: linearGradient(gradients.secondary.main, gradients.secondary.state),
      "& *": { color: white.main },
    },

    "& .event-info": {
      backgroundImage: "none",
      "& *": {
        width: "6px",
        height: "6px",
        backgroundColor: "#FF4D00D9",
        borderRadius: "50%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    },

    "& .fc-day-today .event-info": {
      backgroundImage: "none",
      "& *": {
        backgroundColor: "white",
      },
    },

    "& .event-success": {
      backgroundImage: "none",
      "& *": {
        width: "6px",
        height: "6px",
        backgroundColor: "#FF4D00D9",
        borderRadius: "50%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    },

    "& .fc-day-today .event-success": {
      backgroundImage: "none",
      "& *": {
        backgroundColor: "white",
      },
    },

    "& .event-warning": {
      backgroundImage: "none",
      "& *": {
        width: "6px",
        height: "6px",
        backgroundColor: "#FF4D00D9",
        borderRadius: "50%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    },

    "& .fc-day-today .event-warning": {
      backgroundImage: "none",
      "& *": {
        backgroundColor: "white",
      },
    },

    "& .event-error": {
      backgroundImage: "none",
      "& *": {
        width: "6px",
        height: "6px",
        backgroundColor: "#FF4D00D9",
        borderRadius: "50%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    },

    "& .fc-day-today .event-error": {
      backgroundImage: "none",
      "& *": {
        backgroundColor: "white",
      },
    },

    "& .event-light": {
      backgroundImage: "none",
      "& *": {
        width: "6px",
        height: "6px",
        backgroundColor: "#FF4D00D9",
        borderRadius: "50%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    },

    "& .fc-day-today .event-light": {
      backgroundImage: "none",
      "& *": {
        backgroundColor: "white",
      },
    },

    "& .event-dark": {
      backgroundImage: "none",
      "& *": {
        width: "6px",
        height: "6px",
        backgroundColor: "#FF4D00D9",
        borderRadius: "50%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    },

    "& .fc-day-today .event-dark": {
      backgroundImage: "none",
      "& *": {
        backgroundColor: "white",
      },
    },

    "& .fc-toolbar-chunk": {
      display: "flex",
    },
  };
});
