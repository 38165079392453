import { FormControlLabel, Stack, Switch, ThemeProvider, styled } from "@mui/material";
import theme from "assets/theme";
import axios from "axios";

import PropTypes from "prop-types";
import { useState } from "react";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 35,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#FF4D00D9" : "#FF4D00D9",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#fff",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 14,
    marginTop: 1,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#5F5F5FCC" : "#FF4D00D9",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const PermissionSwitch = ({ action, campaignId, userId, refreshUserInfo, value, customerId }) => {
  const [switchState, setSwitchState] = useState(
    action === "set_permission" ? value === "write" : value === 1
  );
  // TODO: check this
  const onSwitchChange = () => {
    axios
      .post(`/api/customers/${customerId}/profile/${userId}/${action}/toggle-action`, {
        campaign_id: campaignId,
      })
      .then(() => {
        setSwitchState(!switchState);
        refreshUserInfo();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <ThemeProvider theme={theme} />
      <Stack direction="row" alignItems="center">
        Off
        <FormControlLabel
          sx={{ margin: "0", padding: "0" }}
          checked={switchState}
          onChange={onSwitchChange}
          control={<IOSSwitch sx={{ m: 1 }} />}
        />
        On
      </Stack>
    </>
  );
};

PermissionSwitch.propTypes = {
  action: PropTypes.string.isRequired,
  campaignId: PropTypes.number.isRequired,
  refreshUserInfo: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  customerId: PropTypes.number.isRequired,
};
export default PermissionSwitch;
