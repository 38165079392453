import { deMill } from "@react-jvectormap/germany";
import { VectorMap } from "@react-jvectormap/core";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const SuiVectorMapRoot = ({ appointmentsOrOICLeads }) => {
  const mapData = {};

  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    setMarkers([]);
    if (appointmentsOrOICLeads && appointmentsOrOICLeads.length > 0) {
      const markerVals = appointmentsOrOICLeads.map((appointment) => {
        const marker = {
          latLng: [appointment.lat, appointment.lng],
          name: `${appointment.lead_identificator} (${appointment.full_address ?? ""})`,
          label: `${appointment.lead_identificator} (${appointment.full_address ?? ""})`,
          key: appointment.id,
          className: `event-${appointment.class_name}`,
        };
        return marker;
      });
      if (markerVals.length > 0) {
        setMarkers(markerVals);
      }
    }
    return () => {};
  }, [appointmentsOrOICLeads]);

  return markers.length > 0 ? (
    <VectorMap
      map={deMill}
      markers={markers}
      backgroundColor=""
      style={{
        height: "200px",
        borderRadius: "80px",
        backgroundColor: "white",
        padding: "10px",
      }}
      regionStyle={{
        initial: {
          fill: "#dadde9",
          "fill-opacity": 0.9,
          stroke: "none",
          "stroke-width": 0,
          "stroke-opacity": 0,
        },
        hover: {
          fill: "#b7b8ca",
          "fill-opacity": 1.1,

          "stroke-width": 1,
          "stroke-opacity": 1,
        },
      }}
      series={{
        regions: [
          {
            values: mapData,
            scale: ["#AAAAAA", "#444444"],
            normalizeFunction: "polynomial",
          },
        ],
      }}
    />
  ) : (
    ""
  );
};

SuiVectorMapRoot.defaultProps = {
  appointmentsOrOICLeads: [],
};

SuiVectorMapRoot.propTypes = {
  appointmentsOrOICLeads: PropTypes.arrayOf(PropTypes.any),
};

export default SuiVectorMapRoot;
