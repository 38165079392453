/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useEffect, useState } from "react";
import { Button, CardMedia, Grid, Stack, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EmailIcon from "@mui/icons-material/Email";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SchoolIcon from "@mui/icons-material/School";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import CallIcon from "@mui/icons-material/Call";
import MuiLink from "@mui/material/Link";
import NotesContext from "AppContext";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Swal from "sweetalert2";
import DateFormatter from "components/authentication_v2/DateFormatter";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import WorkIcon from "@mui/icons-material/Work";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Instagram from "components/Enrichment/components/iconComponents/Instagram";
import LinkedIn from "components/Enrichment/components/iconComponents/LinkedIn";
import Facebook from "components/Enrichment/components/iconComponents/Facebook";
import profileImg from "../../assets/images/enrichment/profileImg.svg";

const useStyles = makeStyles(() => ({
  employeeInfo: {
    width: "100%",
    float: "left",
    height: "40vh",
    overflow: "auto",
  },
  personalInfo: {
    width: "100%",
    float: "right",
    border: "1px solid white",
    paddingTop: "15px",
    backgroundColor: "#f0f0f0",
    height: "40vh",
    overflow: "auto",
  },
  "@media (min-width: 1120px)": {
    personalInfo: {
      width: "35%",
      height: "65vh",
    },
    employeeInfo: {
      width: "65%",
      height: "65vh",
    },
  },
  skillWrapper: {
    columns: 2,
    columnGap: "1em",
  },
  containerBox: {
    top: "5%",
    bottom: "5%",
    backgroundColor: "white",
    padding: "0%",
    "@media (min-width: 1536px)": {
      maxWidth: "50%",
      flexBasis: "50%",
    },
  },
  cardContent: {
    padding: "0",
    marginBottom: "10px",
    width: "85%",
    height: "35%",
    overflow: "hidden",
  },
  employeeName: {
    marginBottom: "0px",
    width: "100%",
    color: "rgb(100 100 100)",
  },
  employeeTitle: {
    fontSize: "14px",
    fontWeight: "400",
    width: "100%",
  },
  icon: {
    transition: "transform 0.1s ease",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  close: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-end",
    transition: "transform 0.1s ease",
    marginLeft: "20px",
    marginBottom: "200px",
    "@media (min-width: 768px)": {
      marginBottom: "70px",
    },
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  keyName: {
    fontSize: "15px",
    color: "#ED944D",
    fontWeight: "bold",
    letterSpacing: "2px",
  },
  valueName: {
    fontSize: "14px",
    fontWeight: "400",
    width: "90%",
    color: "#787878",
    wordBreak: "break-word",
  },
  profileImg: { width: 100, height: 100, margin: "0" },
  valueNameLink: {
    textDecoration: "underline",
    fontSize: "18px",
    fontWeight: "400",
    cursor: "pointer",
  },
  link: { color: "black !important" },
  box: { "& .css-19kzrtu": { padding: "5% 0" } },
  cardheader: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: "7%",
  },
  cardBox: {
    "@media (min-width: 320px and max-width :768px)": {
      display: "flex",
      width: "0",
    },
    "@media (min-width: 780px)": {
      width: "750px",
    },
  },
  headerDetails: {
    marginLeft: "8px",
    flex: 1,
    "@media (max-width: 425px)": {
      flex: "auto",
      width: "100%",
    },
  },
  boxHeader: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#f0f0f0",
  },
}));

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "10px",
          backgroundColor: "rgba(237, 108, 2, 1)",
          transition: "transform 0.1s ease",
          width: "100px",
          "&:hover": {
            backgroundColor: "rgb(255 138 43)",
            transform: "scale(1.1)",
          },
        },
      },
    },
  },
});

function OicEmployeeInfo() {
  const classes = useStyles();
  const { companyPhone } = useContext(NotesContext);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const jsonCustomerUser = localStorage.getItem("oicCustomerUser");
  const companyId = localStorage.getItem("oicCompanyId");
  const appointmentId = localStorage.getItem("oicAppointmentId");
  const customerUser = JSON.parse(jsonCustomerUser);

  useEffect(() => {
    const jsonEmployeeDetail = localStorage.getItem("oicEmployeeDetail");
    if (jsonEmployeeDetail) {
      const employeeDetail = JSON.parse(jsonEmployeeDetail);
      setSelectedEmployees(employeeDetail);
    }
  }, []);

  const renderSocialLinks = (links) =>
    Object.entries(links).map(([key, label]) => {
      let IconComponent = null;
      switch (key) {
        case "instagram":
          IconComponent = <Instagram color="#ED944D" />;
          break;
        case "facebook":
          IconComponent = <Facebook color="#ED944D" />;
          break;
        case "linkedin":
          IconComponent = <LinkedIn color="#ED944D" />;
          break;
        default:
          return null;
      }
      return (
        <div key={key} className={classes.icon}>
          <MuiLink
            target="_blank"
            style={{ marginLeft: "5px" }}
            rel="noopener noreferrer"
            href={label}
          >
            {IconComponent}
          </MuiLink>
        </div>
      );
    });

  const handleEnrichClick = (employeeName, rrEmployeeId) => {
    Swal.fire({
      title: `Do you want to enrich ${employeeName}?`,
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "rgba(211, 47, 47, 1)",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(
            `/api/customers/${customerUser.customerUser.customer.id}/oic-leads/${appointmentId}/enrich/employee/${rrEmployeeId}/${companyId}`
          )
          .then((response) => {
            if (response.data.warning) {
              Swal.fire({
                icon: "error",
                title: "Enrich employee",
                text: "You have 5 employee enriched!",
              });
            } else if (response.data.enrich_data) {
              const enrichedData = response.data.company_employee_data;
              setSelectedEmployees((prevState) => {
                if (!prevState || !prevState.id) return prevState;
                const enrichedItem = enrichedData.find((item) => item.rr_id === prevState.id);
                return enrichedItem || prevState;
              });
              Swal.fire({
                icon: "success",
                title: `Your have enriched ${employeeName}`,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Enrich employee",
                text: response.data.error,
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
        <Grid style={{ justifyContent: "center" }} className={classes.containerBox}>
          <div className={classes.boxHeader}>
            <Stack className={classes.cardBox}>
              <Stack className={classes.cardheader} gap={1} mt={3} mb={3}>
                <CardMedia
                  className={classes.profileImg}
                  image={selectedEmployees.profile_pic ? selectedEmployees.profile_pic : profileImg}
                />
                <Stack className={classes.headerDetails}>
                  <Typography className={classes.employeeName} variant="h5">
                    {selectedEmployees.name}
                  </Typography>
                  <Typography
                    style={{ paddingRight: "7%" }}
                    className={classes.employeeTitle}
                    color="#ED944D"
                  >
                    {selectedEmployees.current_title}
                  </Typography>
                  {selectedEmployees.rr_id == null && (
                    <ThemeProvider theme={theme}>
                      <Button
                        onClick={() =>
                          handleEnrichClick(
                            selectedEmployees.name,
                            selectedEmployees.rr_id || selectedEmployees.id
                          )
                        }
                        variant="contained"
                      >
                        Enrich
                      </Button>
                    </ThemeProvider>
                  )}
                </Stack>
              </Stack>
            </Stack>
            <div style={{ marginRight: "7%", marginTop: "60px" }}>
              {selectedEmployees.links && (
                <Typography style={{ display: "flex" }}>
                  {selectedEmployees.links ? renderSocialLinks(selectedEmployees.links) : "-"}
                </Typography>
              )}
            </div>
          </div>
          <div className="row 5">
            <div className={classes.employeeInfo}>
              <Stack className={classes.box}>
                <Stack width="100%" mt={2} paddingLeft="7%" spacing={1}>
                  <Grid item container={false} md={10}>
                    <Grid item md={10} mb={2}>
                      <Typography className={classes.keyName}>
                        <LocationOnIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                        Location
                      </Typography>
                      {selectedEmployees.location ? (
                        <Typography className={classes.valueName} color="#000000">
                          {selectedEmployees.location}
                        </Typography>
                      ) : (
                        "-"
                      )}
                    </Grid>
                    <Grid item md={12} mb={2}>
                      <Typography className={classes.keyName}>
                        <WorkIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                        Work
                      </Typography>
                      {selectedEmployees.job_history ? (
                        <div>
                          {selectedEmployees.job_history.map((history) => (
                            <Typography
                              className={classes.valueName}
                              style={{ marginTop: "5px" }}
                              color="#000000"
                            >
                              <div>
                                {history.company_name}
                                {history.company_linkedin_url && (
                                  <MuiLink
                                    target="_blank"
                                    style={{ marginLeft: "5px" }}
                                    rel="noopener noreferrer"
                                    href={history.company_linkedin_url}
                                    key="linkedin"
                                  >
                                    <LinkedIn width={13} height={13} color="#ED944D" />
                                  </MuiLink>
                                )}
                              </div>
                              {history.department && (
                                <div style={{ color: "#454545" }}>{history.department}</div>
                              )}
                              {history.sub_department && (
                                <div style={{ color: "#454545" }}>{history.sub_department}</div>
                              )}
                              {history.highest_level && (
                                <div style={{ color: "#818181" }}>{history.highest_level}</div>
                              )}
                              {history.title && (
                                <div style={{ color: "#818181" }}>{history.title}</div>
                              )}
                              <div style={{ color: "#b9b5b5" }}>
                                <DateFormatter dateTime={history.start_date} />{" "}
                                &nbsp;&nbsp;---&nbsp;&nbsp;
                                {history.end_date === "Present" ? (
                                  " Present"
                                ) : (
                                  <DateFormatter dateTime={history.end_date} />
                                )}
                              </div>
                            </Typography>
                          ))}
                        </div>
                      ) : (
                        "-"
                      )}
                    </Grid>
                    <Grid item md={10} mb={2}>
                      <Typography className={classes.keyName}>
                        <SchoolIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                        Education
                      </Typography>
                      {selectedEmployees.education ? (
                        <div>
                          {selectedEmployees.education.map((education) => (
                            <Typography
                              className={classes.valueName}
                              style={{ marginTop: "5px" }}
                              color="#000000"
                            >
                              <div>{education.school},</div>
                              {education.degree && (
                                <div style={{ color: "#373737" }}>Degree: {education.degree}</div>
                              )}
                              {education.major && (
                                <div style={{ color: "#373737" }}>Major: {education.major}</div>
                              )}
                              <div style={{ color: "#b9b5b5" }}>
                                {education.start}-{education.end}
                              </div>
                            </Typography>
                          ))}
                        </div>
                      ) : (
                        "-"
                      )}
                    </Grid>
                    <Grid item md={10} mb={2}>
                      <Typography className={classes.keyName}>
                        <ContactPageIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                        Employeer Website
                      </Typography>

                      {selectedEmployees &&
                        selectedEmployees.current_employer_website &&
                        selectedEmployees.original_response &&
                        selectedEmployees.current_employer_website !==
                          selectedEmployees.original_response.current_employer_website && (
                          <>
                            <MuiLink
                              target="_blank"
                              fontSize={15}
                              color="#787878"
                              style={{ marginLeft: "5px" }}
                              rel="noopener noreferrer"
                              href={selectedEmployees.current_employer_website}
                              key="website"
                            >
                              {selectedEmployees.current_employer_website}
                            </MuiLink>
                            <br />
                          </>
                        )}
                      {selectedEmployees.original_response &&
                        selectedEmployees.original_response.current_employer_website && (
                          <MuiLink
                            target="_blank"
                            fontSize={15}
                            color="#787878"
                            style={{ marginLeft: "5px" }}
                            rel="noopener noreferrer"
                            href={selectedEmployees.original_response.current_employer_website}
                            key="website"
                          >
                            {selectedEmployees.original_response.current_employer_website}
                          </MuiLink>
                        )}
                    </Grid>
                    <Grid item md={10} mb={2}>
                      <Typography className={classes.keyName}>
                        <CalendarMonthIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                        Birth Year
                      </Typography>
                      {selectedEmployees.birth_year ? (
                        <Typography className={classes.valueName} color="#000000">
                          {selectedEmployees.birth_year}
                        </Typography>
                      ) : (
                        "-"
                      )}
                    </Grid>
                    <Grid item md={10} mb={2}>
                      <Typography className={classes.keyName}>
                        <ManageAccountsIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                        Current Employer
                      </Typography>
                      {selectedEmployees.current_employer ? (
                        <Typography className={classes.valueName} color="#000000">
                          {selectedEmployees.current_employer}
                        </Typography>
                      ) : (
                        "-"
                      )}
                    </Grid>
                  </Grid>
                  <Grid item md={10}>
                    {selectedEmployees.skills ? (
                      <>
                        <Grid item md={12} mb={2}>
                          <Typography className={classes.keyName}>
                            <WorkspacePremiumIcon
                              style={{ marginRight: "5px", marginTop: "2px" }}
                            />
                            Skills
                          </Typography>
                          {selectedEmployees.skills.length > 0 ? (
                            <div className={classes.skillWrapper}>
                              {selectedEmployees.skills.map((skill) => (
                                <Typography className={classes.valueName} color="#000000">
                                  {skill}
                                </Typography>
                              ))}
                            </div>
                          ) : (
                            "-"
                          )}
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Stack>
              </Stack>
            </div>
            <div className={classes.personalInfo}>
              <Stack paddingLeft="7%">
                <Grid item md={10} mb={2}>
                  <Typography className={classes.keyName}>
                    <EmailIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                    Recommended Personal Email
                  </Typography>
                  {selectedEmployees.recommended_personal_email ? (
                    <Typography className={classes.valueName} color="#000000">
                      {selectedEmployees.recommended_personal_email}
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Grid>
                <Grid item md={10} mb={2}>
                  <Typography className={classes.keyName}>
                    <SendToMobileIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                    Personal Phone Number
                  </Typography>
                  {selectedEmployees.phones && selectedEmployees.phones.length > 0
                    ? selectedEmployees.phones.map((item) => (
                        <Typography className={classes.valueName} color="#000000">
                          {item.number}
                        </Typography>
                      ))
                    : "-"}
                </Grid>
                <Grid item md={10} mb={2} mt={2}>
                  <Typography className={classes.keyName}>
                    <AttachEmailIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                    Work Email
                  </Typography>
                  {selectedEmployees.current_work_email ? (
                    <Typography className={classes.valueName} color="#000000">
                      {selectedEmployees.current_work_email}
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Grid>
                <Grid item md={10} mb={2}>
                  <Typography className={classes.keyName}>
                    <CallIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                    Company Phone Number
                  </Typography>
                  {companyPhone ? (
                    <Typography className={classes.valueName} color="#000000">
                      {companyPhone}
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Grid>
                <Grid item md={10} mb={2} mt={2}>
                  <Typography className={classes.keyName}>
                    <AttachEmailIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                    Potential Emails
                  </Typography>
                  {selectedEmployees.emails && selectedEmployees.emails.length > 0 ? (
                    <Typography pl={2} className={classes.valueName} color="#000000">
                      <ul>
                        {selectedEmployees.emails.map((item) => (
                          <li>
                            Email: {item.email} <br />
                            SMTP valid: {item.smtp_valid} <br />
                            Professional: {item.professional} <br />
                            Validated at: {item.last_validation_check}
                            <br />
                            Grade: {item.grade}
                            <br />
                            <br />
                          </li>
                        ))}
                      </ul>
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Grid>
                <Grid item md={10} mb={2}>
                  <Typography className={classes.keyName}>
                    <CallIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                    Potential Phone Numbers
                  </Typography>
                  {selectedEmployees.phones && selectedEmployees.phones.length > 0 ? (
                    <Typography pl={2} className={classes.valueName} color="#000000">
                      <ul>
                        {selectedEmployees.phones.map((item) => (
                          <li>
                            Number: {item.number} <br />
                            Type: {item.type} <br />
                            Validity: {item.validity} <br />
                            Recommended: {item.recommended} <br />
                            Premium: {item.premium} <br />
                            Validated at: {item.last_checked} <br />
                            <br />
                          </li>
                        ))}
                      </ul>
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Grid>
              </Stack>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default OicEmployeeInfo;
