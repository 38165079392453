import { useSoftUIController } from "context";
import GoogleFileManager from "features/google-file-manager";
import Footer from "examples/Footer";
import { Grid, Stack, ThemeProvider, Typography, createTheme } from "@mui/material";
import "./FileManager.css";

function FileManager() {
  const [controller] = useSoftUIController();

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "lightgrey",
          },
        },
      },
    },
  });

  return (
    <Grid
      container
      sx={{ backgroundColor: "#FBFAFB", overflow: "hidden" }}
      ml={controller.miniSidenav ? "100px" : "300px"}
      md={controller.miniSidenav ? 10.8 : 9}
    >
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid
            item
            my={2}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "30px",
            }}
          >
            <Typography fontFamily="Poppins" fontSize="16px" fontWeight="700" color="#4A4A4A">
              File
            </Typography>
          </Grid>
          <Grid
            item
            mb={2}
            lg={8}
            md={8}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "30px",
            }}
          >
            <Grid container>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <GoogleFileManager />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
      <Stack sx={{ width: "100%", flexShrink: "0" }}>
        <Footer />
      </Stack>
    </Grid>
  );
}

export default FileManager;
