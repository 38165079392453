/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/default-props-match-prop-types */
import React from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import { Checkbox } from "@mui/material";
import { styled } from "@material-ui/core/styles";
import styles from "components/SuiSelect/styles";
import colors from "assets/theme/base/colors";

const OrangeCheckbox = styled(Checkbox)(() => ({
  color: "#FF7701",
  "&.Mui-checked": {
    color: "#FF7701",
  },
}));

const Select = (props) => {
  const { light } = colors;

  const allSelected = props.value.length === props.options.length;

  const handleCheckboxChange = (option) => {
    let newValue;

    if (option.value === props.allOption.value) {
      if (props.value.length < props.options.length) {
        newValue = props.options;
      } else {
        newValue = [];
      }
    } else {
      // Individual option clicked
      const index = props.value.findIndex((item) => item.value === option.value);
      if (index === -1) {
        newValue = [...props.value, option];
      } else {
        newValue = props.value.filter((item) => item.value !== option.value);
      }
    }

    props.onChange(newValue);
  };

  const formatOptions = (options) =>
    options.map((option) => ({
      ...option,
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <OrangeCheckbox
            checked={allSelected || props.value.some((item) => item.value === option.value)}
            onChange={() => handleCheckboxChange(option)}
            style={{ marginRight: "10px" }}
          />
          <span> {option.label}</span>
        </div>
      ),
    }));

  const customStyles = {
    ...styles(props.size, props.error, props.success),
    valueContainer: () => ({
      ...styles(props.size, props.error, props.success).valueContainer(),
      width: props.isMulti ? "180px" : "100%",
    }),
  };

  const selectProps = {
    ...props,
    styles: customStyles,
    theme: (theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary25: light.main,
        primary: light.main,
      },
    }),
    options: props.allowSelectAll
      ? formatOptions([props.allOption, ...props.options])
      : formatOptions(props.options),
    value: allSelected && props.allowSelectAll ? [props.allOption] : props.value,
    onChange: (selected) => {
      if (selected.length > 0 && selected[selected.length - 1].value === props.allOption.value) {
        return props.onChange(props.options);
      }
      return props.onChange(selected);
    },
  };

  return <ReactSelect {...selectProps} />;
};

Select.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  allowSelectAll: PropTypes.bool,
  isMulti: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
};

Select.defaultProps = {
  size: "medium",
  error: false,
  success: false,
  allowSelectAll: false,
  isMulti: false,
  allOption: {
    label: "Select all",
    value: "*",
  },
};

export default Select;
