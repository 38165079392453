import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Google from "../../../assets/images/enrichment/employeeCount.svg";
import ArrowDown from "../../../assets/images/enrichment/ArrowDown.svg";
// import NotFound from "./NotFound";

function TechTable2({ customerUser, appointmentId }) {
  const useStyles = makeStyles(() => ({
    TableContainer: {
      border: "1px solid #50555C",
      borderRadius: "3px",
    },
    tableCell: {
      borderBottom: "0",
      margin: "0",
    },
    table: {
      fontSize: "12px",
      paddingLeft: "15px",
      paddingTop: "5px",
      paddingBottom: "5px",
      // fontWeight: "400",
      borderTop: "1px solid #b8bdbd",
    },
    subDomain: { fontSize: "12px" },
    tableRow: { borderBottom: "1px solid #50555C" },
    logo: { marginTop: "7px" },
    key: {
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "1%",
      fontSize: "15px",
    },
    column: { display: "flex", flexDirection: "column" },
    index: { fontSize: "14px" },
    domain: { fontSize: "12px" },
    description: { fontSize: "12px" },
    showLess: {
      textAlign: "center",
      cursor: "pointer",
      color: "#FF8800",
    },
  }));
  const classes = useStyles();
  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "#FF7701",
          },
        },
      },
    },
  });

  const [technologies, setTechnologies] = useState([]);
  const [loadingTech, setLoadingTech] = useState(false);
  const [toggle, setToggle] = useState([]);

  useEffect(() => {
    if (appointmentId !== undefined && appointmentId) {
      setLoadingTech(true);
      axios
        .get(
          `/api/customers/${customerUser.customerUser.customer.id}/oic-leads/${appointmentId}/builtwith/technologies`
        )
        .then((response) => {
          setTechnologies(response.data);
          setLoadingTech(false);
        })
        .catch((error) => {
          console.error(error);
          setLoadingTech(false);
        });
    }
  }, [appointmentId]);

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const toggleTable = (key) => {
    setToggle((prevToggle) => ({
      ...prevToggle,
      [key]: !prevToggle[key],
    }));
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "#000000",
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(12),
      border: " 1px solid #FF7701",
      borderRadius: "10px",
    },
  }));

  return loadingTech ? (
    <ThemeProvider theme={theme}>
      <Stack mt="15%" flexDirection="column" alignItems="center">
        <CircularProgress size="5%" />
      </Stack>
    </ThemeProvider>
  ) : (
    <Grid container sx={{ zIndex: "1" }}>
      <Grid xs={1} sm={1} md={0.5} />
      <Grid className={classes.column} md={9.5} sm={10} xs={10}>
        {Object.keys(technologies).length > 0
          ? Object.entries(technologies).map(([key, value]) => (
              <ThemeProvider theme={theme}>
                <HtmlTooltip
                  placement="bottom-start"
                  title={
                    value.url ? (
                      <Box>
                        <Typography color="inherit">
                          This path technology data is built from multiple sub-pages and is only
                          relevant for domain only based lookups.
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )
                  }
                >
                  <Typography
                    onClick={() => toggleTable(key)}
                    className={classes.key}
                    mt={2}
                    mb={2}
                    color="#000000"
                  >
                    {`${value.sub_domain ? value.sub_domain : ""}${value.sub_domain ? "." : ""}${
                      value.domain ? value.domain : ""
                    }${value.url ? "*" : ""}`}
                    <img src={ArrowDown} alt="Arrow Down" />
                  </Typography>
                </HtmlTooltip>
                {toggle[key] && (
                  <Stack>
                    <TableContainer className={classes.TableContainer} component={Paper}>
                      {value.technologies &&
                        Object.entries(value.technologies).map(([index, label]) => (
                          <Table>
                            <TableHead>
                              <TableRow className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                  <Typography className={classes.index} color="#000000">
                                    {capitalizeFirstLetter(index)}
                                  </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>First Detected</TableCell>
                                <TableCell className={classes.tableCell}>Last Detected</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {label &&
                                label.map((tech) => (
                                  <TableRow>
                                    <TableCell
                                      className={classes.table}
                                      width="70%"
                                      component="th"
                                      scope="row"
                                    >
                                      <Stack
                                        className={classes.containerStack}
                                        flexDirection="row"
                                        alignItems="flex-start"
                                        gap={2}
                                      >
                                        <img
                                          className={classes.logo}
                                          width={25}
                                          src={Google}
                                          alt="Logo"
                                        />
                                        <Stack>
                                          <Typography className={classes.domain} color="#000000">
                                            {tech.name ? tech.name : null}
                                          </Typography>
                                          <Typography
                                            className={classes.description}
                                            color="#666666"
                                          >
                                            {tech.description ? tech.description : null}
                                          </Typography>
                                        </Stack>
                                      </Stack>
                                    </TableCell>
                                    <TableCell className={classes.table}>
                                      {tech.first_detected
                                        ? new Intl.DateTimeFormat("en-US", {
                                            year: "numeric",
                                            month: "short",
                                          }).format(tech.first_detected)
                                        : null}
                                    </TableCell>
                                    <TableCell className={classes.table}>
                                      {tech.last_detected
                                        ? new Intl.DateTimeFormat("en-US", {
                                            year: "numeric",
                                            month: "short",
                                          }).format(tech.last_detected)
                                        : null}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        ))}
                    </TableContainer>
                    <Typography onClick={() => toggleTable(key)} className={classes.showLess}>
                      Show Less
                    </Typography>
                  </Stack>
                )}
              </ThemeProvider>
            ))
          : null}
      </Grid>
      <Grid xs={1} sm={1} md={2} />
    </Grid>
  );
}

TechTable2.propTypes = {
  customerUser: PropTypes.any.isRequired,
  appointmentId: PropTypes.number.isRequired,
};

export default TechTable2;
