import React, { useLayoutEffect, useRef, useState } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Grid, Stack, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import LetterLogo from "assets/images/iconComponents/LetterLogo";
import Swal from "sweetalert2";
import AuthButton from "components/Buttons/AuthButton";
import authBG from "../../../assets/images/authentication_v2/authBG.svg";
import teleroidsArm from "../../../assets/images/authentication_v2/teleroidsArm.png";

function Confirmation() {
  const useStyles = makeStyles(() => ({
    container: {
      backgroundImage: `url(${authBG})`,
      backgroundAttachment: "fixed",
      height: "100vh",
      width: "100%",
    },
    gridItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "2%",
    },
    boxCon: {
      backgroundColor: "white !important",
      width: "100%",
    },
    inputs: { width: "100%" },
    codeInput: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      padding: "30%",
      border: "1px solid #FF4D00D9",
      borderRadius: "5px",
      "&:focus": {
        outline: "none",
      },
    },
    defaultTypo: { fontSize: "13px", fontFamily: "Poppins", fontWeight: "400" },
    boldTypo: { fontWeight: "600", fontFamily: "Poppins", fontSize: "20px" },
    footer: { fontWeight: "400", fontSize: "12px", fontFamily: "Poppins", padding: "3%" },
    resend: { fontSize: "12px", fontFamily: "Poppins", fontWeight: "600", cursor: "pointer" },
    received: { fontSize: "12px", fontFamily: "Poppins", fontWeight: "500" },
  }));

  const classes = useStyles();
  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            display: "none",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDEDED",
            color: "#5F5F5F",
            fontFamily: "Poppins",
            padding: "0 7% 0 7%",
            borderRadius: "6px",
            textTransform: "none",
            marginRight: "-2%",
            "&.Mui-selected": {
              backgroundColor: "#FF4D00D9",
              color: "#fff",
              fontFamily: "Poppins",
              borderRadius: "6px",
              padding: "1% 7% 1% 7%",
              marginRight: "-2%",
              zIndex: "1",
            },
          },
        },
      },
    },
  });

  const [user, setUser] = useState({
    id: "",
    question: "",
  });

  const [codeLoading, setCodeLoading] = useState(false);
  const [answerLoading, setAnswerLoading] = useState(false);

  const [code, setCode] = useState({
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
    field6: "",
    error_list: [],
  });

  const [answer, setAnswer] = useState({
    answer: "",
    error_list: [],
  });

  useLayoutEffect(() => {
    const userVal = JSON.parse(atob(localStorage.getItem("auth_user")));
    setUser(userVal);
    return () => {
      setUser({
        id: "",
        question: "",
      });
    };
  }, []);

  const inputRefs = Array.from({ length: 6 }, () => useRef());

  const hangleCodeInput = (e, index) => {
    e.persist();
    const { name, value } = e.target;
    const truncatedValue = value.charAt(0);
    setCode((prevCode) => ({
      ...prevCode,
      [name]: truncatedValue,
      error_list: [],
    }));
    if (value !== "") {
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.length) {
        inputRefs[nextIndex].current.focus();
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text").slice(0, 6);
    const pastedChars = pastedText.split("");
    const newCode = { ...code };

    pastedChars.forEach((char, index) => {
      const fieldName = `field${index + 1}`;
      if (index < inputRefs.length) {
        newCode[fieldName] = char;
      }
    });

    setCode(newCode);

    if (pastedChars.length > 0) {
      const nextIndex = pastedChars.length - 1;
      if (nextIndex < inputRefs.length) {
        inputRefs[nextIndex].current.focus();
      }
    }
  };

  const hangleAnswareInput = (e) => {
    e.persist();
    setAnswer({ ...answer, [e.target.name]: e.target.value, error_list: [] });
  };

  const submitCode = (e) => {
    e.preventDefault();
    setCodeLoading(true);
    const data = {
      user_id: user.id,
      two_factor_code:
        code.field1 + code.field2 + code.field3 + code.field4 + code.field5 + code.field6,
    };
    axios
      .post(`/api/verify-code`, data)
      .then((res) => {
        localStorage.setItem("auth_token", res.data.token);
        localStorage.setItem("auth_user", btoa(JSON.stringify(res.data.user)));
        setCodeLoading(false);
        window.location = "/";
      })
      .catch((error) => {
        setCode({ ...code, error_list: [] });
        const errorData = error.response === undefined ? null : error.response.data;

        if (errorData && errorData.errors !== undefined) {
          setCode({ ...code, error_list: errorData.errors });
        }

        if (errorData && errorData.message !== undefined) {
          Swal.fire({
            title: "Error!",
            text: errorData.message,
            icon: "error",
          });
          setCode({
            field1: "",
            field2: "",
            field3: "",
            field4: "",
            field5: "",
            field6: "",
          });
        }
        setCodeLoading(false);
        // return Promise.reject(error);
      });
  };

  const resendCode = (e) => {
    e.preventDefault();
    setCodeLoading(true);
    const data = {
      user_id: user.id,
    };
    axios
      .post(`/api/verify-code/resend`, data)
      .then((res) => {
        Swal.fire({
          title: "Code resended!",
          text: res.data.message,
          icon: "info",
        });
        setCode({
          field1: "",
          field2: "",
          field3: "",
          field4: "",
          field5: "",
          field6: "",
        });
        setCodeLoading(false);
      })
      .catch((error) => {
        setCode({ ...code, error_list: [] });
        const errorData = error.response === undefined ? null : error.response.data;

        if (errorData && errorData.errors !== undefined) {
          setCode({ ...code, error_list: errorData.errors });
        }

        if (errorData && errorData.message !== undefined) {
          Swal.fire({
            title: "Error!",
            text: errorData.message,
            icon: "error",
          });
          setCode({
            field1: "",
            field2: "",
            field3: "",
            field4: "",
            field5: "",
            field6: "",
          });
        }
        setCodeLoading(false);
        // return Promise.reject(error);
      });
  };

  const submitAnswer = (e) => {
    e.preventDefault();
    setAnswerLoading(true);
    const data = {
      user_id: user.id,
      answer: answer.answer,
    };
    axios
      .post(`/api/verify-question`, data)
      .then((res) => {
        localStorage.setItem("auth_token", res.data.token);
        localStorage.setItem("auth_user", btoa(JSON.stringify(res.data.user)));
        setAnswerLoading(false);
        window.location = "/";
      })
      .catch((error) => {
        setAnswer({ ...answer, error_list: [] });
        const errorData = error.response === undefined ? null : error.response.data;

        if (errorData && errorData.errors !== undefined) {
          setAnswer({ ...answer, error_list: errorData.errors });
        }

        if (errorData && errorData.message !== undefined) {
          Swal.fire({
            title: "Error!",
            text: errorData.message,
            icon: "error",
          });
          setAnswer({
            answer: "",
            error_list: [],
          });
        }
        setAnswerLoading(false);
        // return Promise.reject(error);
      });
  };

  const handleKeyAnswer = (e) => {
    if (e.key === "Enter") {
      submitAnswer(e);
    }
  };

  const handleKeyCode = (e) => {
    if (e.key === "Enter") {
      submitCode(e);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container className={classes.container}>
        <Grid item md={3.5} sm={2} xs={0.5} />
        <Grid item md={5} sm={8} xs={11} className={classes.gridItem}>
          <Stack direction="column" alignItems="center">
            <LetterLogo />
            <Typography className={classes.defaultTypo} mt={1} mb={5}>
              Enter your two steps of confirmations
            </Typography>
          </Stack>
          <Grid item md={12} sm={12} xs={12} className={classes.boxCon}>
            <Grid item md={12} sm={12} xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  md={3}
                  sm={5}
                  xs={5}
                  sx={{
                    marginLeft: { xs: "28%", sm: "30%", md: "0", lg: "0" },
                  }}
                >
                  <img width="100%" src={teleroidsArm} alt="Logo" />
                </Grid>
                <Grid
                  item
                  mb={2}
                  mt={6}
                  md={9}
                  sm={12}
                  xs={12}
                  sx={{
                    marginLeft: { xs: "4%", sm: "0", md: "0", lg: "0" },
                  }}
                >
                  <Typography ml={11} mb={3} className={classes.boldTypo}>
                    Verify Code
                  </Typography>
                  <Grid container md={9} xs={12} sm={12} spacing={2}>
                    {Array.from({ length: 6 }, (_, index) => (
                      <Grid item xs key={index}>
                        <input
                          ref={inputRefs[index]}
                          name={`field${index + 1}`}
                          size="small"
                          value={code[`field${index + 1}`]}
                          inputProps={{ maxLength: 1 }}
                          onChange={(event) => hangleCodeInput(event, index)}
                          onKeyDown={handleKeyCode}
                          onPaste={handlePaste}
                          className={classes.codeInput}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={3} sm={2.5} />
                <Grid item mt={2} md={6.5} xs={12} sm={7}>
                  <AuthButton
                    loading={codeLoading}
                    onClick={submitCode}
                    fontSize="15px"
                    backgroundColor="#FF4D00D9"
                  >
                    SEND CODE
                  </AuthButton>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={3} sm={1.5} />
                <Grid container mt={3} md={7} sm={9} direction="row" alignItems="center" gap={1}>
                  <Typography className={classes.received} ml={4.5}>
                    Haven’t received it?
                  </Typography>
                  <Typography onClick={resendCode} className={classes.resend}>
                    Resend a new code
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={3.2} sm={2} />
                <Grid item mt={4} md={6.5} sm={9}>
                  <Typography ml={8} className={classes.boldTypo}>
                    Security Question
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={3} sm={2.5} />
                <Grid item mt={3} md={6.5} sm={7} xs={12}>
                  <Typography className={classes.defaultTypo}>Answer</Typography>
                  <TextField
                    className={classes.inputs}
                    name="answer"
                    type="password"
                    variant="standard"
                    value={answer.answer}
                    inputProps={{ maxLength: 20 }}
                    onChange={hangleAnswareInput}
                    onKeyDown={handleKeyAnswer}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={3} sm={2.5} />
                <Grid item mb={3} mt={4} md={6.5} sm={7} xs={12}>
                  <AuthButton
                    loading={answerLoading}
                    fontSize="15px"
                    backgroundColor="#FF4D00D9"
                    onClick={submitAnswer}
                  >
                    SEND ANSWER
                  </AuthButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Typography ml={4} className={classes.footer}>
            Copyright © 2023 CASA AKTIVA GmbH
          </Typography>
        </Grid>
        <Grid item md={3.5} sm={2} xs={0.5} />
      </Grid>
    </ThemeProvider>
  );
}

export default Confirmation;
