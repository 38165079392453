import PropTypes from "prop-types";

function DocumentsIcon({ color }) {
  return (
    <svg width="34" height="34" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_795_3609)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4999 2.41602V10.2702C14.4999 10.7509 14.6909 11.2119 15.0308 11.5518C15.3707 11.8917 15.8317 12.0827 16.3124 12.0827H24.1666V24.166C24.1666 24.807 23.912 25.4216 23.4588 25.8749C23.0055 26.3281 22.3909 26.5827 21.7499 26.5827H7.24992C6.60898 26.5827 5.99429 26.3281 5.54108 25.8749C5.08786 25.4216 4.83325 24.807 4.83325 24.166V4.83268C4.83325 4.19174 5.08786 3.57705 5.54108 3.12384C5.99429 2.67063 6.60898 2.41602 7.24992 2.41602H14.4999ZM18.1249 18.1243H10.8749C10.5544 18.1243 10.2471 18.2517 10.0205 18.4783C9.79389 18.7049 9.66659 19.0122 9.66659 19.3327C9.66659 19.6532 9.79389 19.9605 10.0205 20.1871C10.2471 20.4137 10.5544 20.541 10.8749 20.541H18.1249C18.4454 20.541 18.7527 20.4137 18.9793 20.1871C19.2059 19.9605 19.3333 19.6532 19.3333 19.3327C19.3333 19.0122 19.2059 18.7049 18.9793 18.4783C18.7527 18.2517 18.4454 18.1243 18.1249 18.1243ZM12.0833 13.291H10.8749C10.5544 13.291 10.2471 13.4183 10.0205 13.6449C9.79389 13.8715 9.66659 14.1789 9.66659 14.4993C9.66659 14.8198 9.79389 15.1272 10.0205 15.3538C10.2471 15.5804 10.5544 15.7077 10.8749 15.7077H12.0833C12.4037 15.7077 12.7111 15.5804 12.9377 15.3538C13.1643 15.1272 13.2916 14.8198 13.2916 14.4993C13.2916 14.1789 13.1643 13.8715 12.9377 13.6449C12.7111 13.4183 12.4037 13.291 12.0833 13.291ZM16.9166 2.46797C17.3744 2.56507 17.7942 2.79301 18.1249 3.1241L23.4585 8.45768C23.7896 8.78843 24.0175 9.2082 24.1146 9.66602H16.9166V2.46797Z"
          fill={color}
          fillOpacity="0.85"
        />
      </g>
      <defs>
        <clipPath id="clip0_795_3609">
          <rect width="29" height="29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

DocumentsIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default DocumentsIcon;
