import PropTypes from "prop-types";

function AppointmentIcon({ color, width, height, viewBox }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 3H17V1.999C17 1.44772 16.5523 0.99998 16 0.999977C15.4477 0.999977 15 1.44772 15 1.999V3H9V1.999C9 1.44772 8.55228 0.999977 8 0.999977C7.44772 0.999977 7 1.44772 7 1.999V3H5C3.89543 3 3 3.89543 3 5V21C3 22.1046 3.89543 23 5 23H19C20.1046 23 21 22.1046 21 21V5C21 3.89543 20.1046 3 19 3ZM17 19H7V7H17V19ZM9 9H11V11H9V9ZM13 9H15V11H13V9ZM9 13H11V15H9V13ZM13 13H15V15H13V13Z"
        fill={color}
      />
    </svg>
  );
}

AppointmentIcon.defaultProps = {
  width: "34",
  height: "34",
  viewBox: "0 0 24 24",
};

AppointmentIcon.propTypes = {
  color: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};

export default AppointmentIcon;
