import { CircularProgress, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import QrCodeTwoToneIcon from "@mui/icons-material/QrCodeTwoTone";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import CompareIcon from "@mui/icons-material/Compare";
import LanguageIcon from "@mui/icons-material/Language";
import GroupsIcon from "@mui/icons-material/Groups";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import BusinessIcon from "@mui/icons-material/Business";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import FaxIcon from "@mui/icons-material/Fax";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TechTable2 from "./TechTable2";

const companyInfoIcons = {
  domain: LanguageIcon,
  phone: LocalPhoneIcon,
  industry: WarehouseIcon,
  fax: FaxIcon,
  num_employees: PeopleAltIcon,
  year_founded: CalendarMonthIcon,
};

const useStyles = makeStyles(() => ({
  skillWrapper: {
    margin: "5px",
    display: "flex",
  },
  styleValuesLinks: {
    textDecoration: "underline",
  },
  styleListItemText: {
    marginLeft: "10px",
    marginTop: "10px",
    marginBottom: "0px",
    display: "flex",
  },
  descriptionText: {
    marginLeft: "10px",
    marginTop: "8px",
    marginBottom: "0px",
    display: "flex",
  },
  styleKeys: {
    fontSize: "15px",
    color: "#767676",
    marginBottom: "0px",
    minWidth: "150px",
    fontWeight: "bold",
  },
  styleValues: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#000000",
  },
  scrollContainer: {
    maxHeight: "200px",
    overflowY: "auto",
  },
}));

function FieldInfo2({ customerUser, companyInfo, description, appointmentId, loadingCompany }) {
  const formatString = (str) => {
    const words = str.split("_");
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    const formattedString = capitalizedWords.join(" ");

    return formattedString;
  };

  const classes = useStyles();

  function concatAddress(street, city, region, countryCode, postalCode) {
    const addressParts = [street, city, region, countryCode, postalCode].filter((part) => part);
    return addressParts.join(", ");
  }

  const renderIcon = (key) => {
    const IconComponent = companyInfoIcons[key];
    return <IconComponent />;
  };

  const address = concatAddress(
    companyInfo.street,
    companyInfo.city,
    companyInfo.region,
    companyInfo.country_code,
    companyInfo.postal_code
  );

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "#FF7701",
          },
        },
      },
    },
  });
  return loadingCompany ? (
    <ThemeProvider theme={theme}>
      <Stack mt="16%" flexDirection="column" alignItems="center">
        <CircularProgress size="5%" />
      </Stack>
    </ThemeProvider>
  ) : (
    <Grid container spacing={1} flexDirection="row">
      <Grid>
        <Grid item xs={12}>
          {description ? (
            <Grid item xs={12} style={{ marginBottom: "20px" }}>
              <ListItemText className={classes.descriptionText}>
                <p style={{ textAlign: "start", fontSize: "14px" }}>{description.description}</p>
              </ListItemText>
            </Grid>
          ) : null}
        </Grid>
        {companyInfo ? (
          <>
            {Object.entries(companyInfo).map(([key, value]) =>
              key &&
              value &&
              ![
                "techstack",
                "competitors",
                "departments",
                "industries",
                "company_growth",
                "name",
                "revenue",
                "street",
                "postal_code",
                "country_code",
                "region",
                "city",
                "sic_codes",
                "links",
                "funding_investors",
                "id",
              ].includes(key) ? (
                <Grid item xs={12} key={key}>
                  <ListItem>
                    <ListItemText
                      className={classes.styleListItemText}
                      primary={
                        <Typography
                          className={classes.styleKeys}
                          style={{ display: "flex", alignItems: "center" }}
                          component="div"
                        >
                          <div style={{ marginRight: "5px", marginTop: "2px" }}>
                            {Object.prototype.hasOwnProperty.call(companyInfoIcons, key) &&
                              renderIcon(key)}
                          </div>
                          <div>{formatString(key)}</div>
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.styleValues} component="div">
                          {value}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              ) : null
            )}
            {companyInfo.revenue ? (
              <Grid item xs={12}>
                <ListItem>
                  <ListItemText
                    className={classes.styleListItemText}
                    primary={
                      <Typography className={classes.styleKeys} component="div">
                        <CurrencyExchangeIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                        Revenue
                      </Typography>
                    }
                    secondary={
                      <Typography className={classes.styleValues} component="div">
                        {parseFloat(companyInfo.revenue).toLocaleString("en-US", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </Typography>
                    }
                  />
                </ListItem>
              </Grid>
            ) : null}
            {address ? (
              <Grid item xs={12}>
                <ListItem>
                  <ListItemText
                    className={classes.styleListItemText}
                    primary={
                      <Typography className={classes.styleKeys} component="div">
                        <LocationOnIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                        Address
                      </Typography>
                    }
                    secondary={<Typography className={classes.styleValues}> {address}</Typography>}
                  />
                </ListItem>
              </Grid>
            ) : null}
            {companyInfo.sic_codes && companyInfo.sic_codes.length > 0 ? (
              <Grid item xs={12}>
                <ListItem>
                  <ListItemText
                    className={classes.styleListItemText}
                    primary={
                      <Typography className={classes.styleKeys} component="div">
                        <QrCodeTwoToneIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                        Sic Codes
                      </Typography>
                    }
                    secondary={
                      <Typography className={classes.styleValues}>
                        {companyInfo.sic_codes.join(" , ")}
                      </Typography>
                    }
                  />
                </ListItem>
              </Grid>
            ) : null}
            {companyInfo.naics_codes && companyInfo.naics_codes.length > 0 ? (
              <Grid item xs={12}>
                <ListItem>
                  <ListItemText
                    className={classes.styleListItemText}
                    primary={
                      <Typography className={classes.styleKeys} component="div">
                        <QrCodeTwoToneIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                        Naics Codes
                      </Typography>
                    }
                    secondary={
                      <Typography className={classes.styleValues}>
                        {companyInfo.naics_codes.join(" , ")}
                      </Typography>
                    }
                  />
                </ListItem>
              </Grid>
            ) : null}
            {companyInfo.industries && companyInfo.industries.length > 0 ? (
              <Grid item xs={12}>
                <ListItem>
                  <ListItemText
                    className={classes.styleListItemText}
                    primary={
                      <Typography className={classes.styleKeys} component="div">
                        <DomainAddIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                        Industries
                      </Typography>
                    }
                    secondary={
                      <Typography className={classes.styleValues}>
                        {companyInfo.industries.join(" , ")}
                      </Typography>
                    }
                  />
                </ListItem>
              </Grid>
            ) : null}
            {companyInfo.competitors && companyInfo.competitors.length > 0 ? (
              <Grid item xs={12}>
                <ListItem>
                  <ListItemText
                    className={classes.styleListItemText}
                    primary={
                      <Typography className={classes.styleKeys} component="div">
                        <CompareIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                        Competitors
                      </Typography>
                    }
                    secondary={
                      <Typography className={classes.styleValues}>
                        {companyInfo.competitors.join(" , ")}
                      </Typography>
                    }
                  />
                </ListItem>
              </Grid>
            ) : null}
            {companyInfo.techstack && companyInfo.techstack.length > 0 ? (
              <Grid item xs={12}>
                <ListItem>
                  <ListItemText
                    className={classes.styleListItemText}
                    primary={
                      <Typography className={classes.styleKeys} component="div">
                        <CollectionsBookmarkIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                        Techstack
                      </Typography>
                    }
                    secondary={
                      <Typography className={classes.styleValues}>
                        {companyInfo.techstack.join(" , ")}
                      </Typography>
                    }
                  />
                </ListItem>
              </Grid>
            ) : null}
          </>
        ) : null}
        {companyInfo.funding_investors && companyInfo.funding_investors.length > 0 ? (
          <Grid item xs={12}>
            <ListItem>
              <ListItemText
                className={classes.styleListItemText}
                primary={
                  <Typography className={classes.styleKeys} component="div">
                    <GroupsIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                    Funding Investors
                  </Typography>
                }
                secondary={
                  <Typography className={classes.styleValues}>
                    {companyInfo.funding_investors.join(" , ")}
                  </Typography>
                }
              />
            </ListItem>
          </Grid>
        ) : null}
        {companyInfo.departments && Object.keys(companyInfo.departments).length > 0 ? (
          <Grid item xs={12}>
            <ListItem>
              <ListItemText
                className={classes.styleListItemText}
                primary={
                  <Typography className={classes.styleKeys} component="div">
                    <BusinessIcon style={{ marginRight: "5px", marginTop: "2px" }} />
                    Departments
                  </Typography>
                }
                secondary={Object.entries(companyInfo.departments).map(([key, value]) => (
                  <Typography className={classes.styleValues}>
                    {key}: {value}
                  </Typography>
                ))}
              />
            </ListItem>
          </Grid>
        ) : null}
        {appointmentId && (
          <Grid item xs={12} md={12}>
            <Typography className={classes.styleKeys} component="div">
              Tech Stack per domain(s)
            </Typography>
            <TechTable2 customerUser={customerUser} appointmentId={appointmentId} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

FieldInfo2.propTypes = {
  customerUser: PropTypes.any.isRequired,
  description: PropTypes.string.isRequired,
  companyInfo: PropTypes.array.isRequired,
  appointmentId: PropTypes.number.isRequired,
  loadingCompany: PropTypes.bool.isRequired,
};

export default FieldInfo2;
