/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
// import curved7 from "assets/images/curved-images/curved7.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

import { useHistory, useLocation, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import Globe from "examples/Globe";
import LoadingButton from "@mui/lab/LoadingButton";

function Cover() {
  const { token } = useParams();
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    error_list: [],
  });

  const history = useHistory();
  const location = useLocation();

  const [newPasswordLoading, setNewPasswordLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    setEmail(params.get("email"));
    return () => {};
  }, []);

  const handleNewPasswordInput = (e) => {
    e.persist();
    delete newPassword.error_list[e.target.name];
    setNewPassword({ ...newPassword, [e.target.name]: e.target.value });
  };

  const submitNewPassword = (e) => {
    e.preventDefault();
    setNewPasswordLoading(true);
    const data = {
      token,
      email,
      password: newPassword.password,
      password_confirmation: newPassword.password_confirmation,
    };

    axios
      .post("/api/reset", data)
      .then((res) => {
        Swal.fire({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        setNewPasswordLoading(false);
        history.push("/authentication/sign-in/cover");
      })
      .catch((error) => {
        setNewPassword({ ...newPassword, error_list: [] });
        const errorData = error.response === undefined ? null : error.response.data;

        if (errorData && errorData.errors !== undefined) {
          setNewPassword({ ...newPassword, error_list: errorData.errors });
        }

        if (errorData && errorData.message !== undefined) {
          Swal.fire({
            title: "Error!",
            text: errorData.message,
            icon: "error",
          });
        }
        setNewPasswordLoading(false);
      });
  };

  return (
    <CoverLayout
      // image={curved7}
      header={
        <SuiBox textAlign="center">
          <SuiTypography variant="h4" fontWeight="bold">
            Teleroids
          </SuiTypography>
          <SuiTypography variant="body2" color="text">
            Enter password to unlock your account.
          </SuiTypography>
        </SuiBox>
      }
      top={30}
    >
      <Grid container>
        <Grid item xs={12}>
          <Globe
            display={{ xs: "none", md: "block" }}
            position="absolute"
            top="15%"
            right={0}
            mt={{ xs: -12, lg: 1 }}
            mr={{ xs: 0, lg: 24 }}
            canvass={{ marginTop: "3rem", outline: "none", transform: "scale(1.6)" }}
          />
        </Grid>
      </Grid>
      <SuiBox component="form" role="form" sx={{ zIndex: 100 }}>
        <SuiBox mb={2}>
          <SuiInput
            name="password"
            type="password"
            placeholder="Password"
            onChange={handleNewPasswordInput}
          />
          <SuiTypography component="label" variant="caption" fontWeight="bold" color="error">
            {newPassword.error_list.password}
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={2}>
          <SuiInput
            name="password_confirmation"
            type="password"
            placeholder="Password"
            onChange={handleNewPasswordInput}
          />
          <SuiTypography component="label" variant="caption" fontWeight="bold" color="error">
            {newPassword.error_list.password_confirmation}
          </SuiTypography>
        </SuiBox>
        {newPasswordLoading ? (
          <LoadingButton loading variant="outlined" color="primary" fullWidth>
            unlock
          </LoadingButton>
        ) : (
          <SuiButton
            variant="gradient"
            color="dark"
            size="large"
            fullWidth
            onClick={submitNewPassword}
          >
            unlock
          </SuiButton>
        )}
      </SuiBox>
    </CoverLayout>
  );
}

export default Cover;
