import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Typography,
  Pagination,
  Stack,
  Icon,
  Divider,
  FormControl,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
} from "@mui/material";
import TableSort from "components/authentication_v2/TableSort";
import Swal from "sweetalert2";
import axios from "axios";
import AuthButton from "components/Buttons/AuthButton";
import DateTimeFormatter from "components/authentication_v2/DateTimeFormatter";

function InvitationsTable({ data, customerId }) {
  const [invitationsDataTableData, setInvitationsDataTableData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [loadingInvitationTable, setLoadingInvitationTable] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);

  const [inviteeInfo, setInviteeInfo] = useState({
    timezone: "UTC",
    email: "",
    error_list: [],
  });

  const refreshInvitationsTable = () => {
    setLoadingInvitationTable(true);
    axios
      .get(`/api/customers/${customerId}/profile/invitations`, {
        params: {
          search: searchQuery,
        },
      })
      .then((res) => {
        setInvitationsDataTableData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingInvitationTable(false);
      });
  };

  const handleCancelInvitation = (id) => {
    setLoadingCancel(true);
    axios
      .get(`/api/customers/${customerId}/profile/invitations/${id}/cancel`)
      .then((res) => {
        setRefresh(!refresh);
        Swal.fire({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
      })
      .catch((error) => {
        const errorData = error.response === undefined ? null : error.response.data;

        if (errorData && errorData.message !== undefined) {
          Swal.fire({
            title: "Error!",
            text: errorData.message,
            icon: "error",
          });
        }
      })
      .finally(() => {
        setLoadingCancel(false);
      });
  };

  const submitInvitation = (e) => {
    e.preventDefault();
    setLoadingInvite(true);
    axios
      .post(`/api/customers/${customerId}/profile/send-invitation`, {
        ...inviteeInfo,
      })
      .then((res) => {
        setRefresh(!refresh);
        Swal.fire({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        setInviteeInfo({ ...inviteeInfo, email: "" });
      })
      .catch((error) => {
        setInviteeInfo({ ...inviteeInfo, error_list: [] });
        const errorData = error.response === undefined ? null : error.response.data;

        if (errorData && errorData.errors !== undefined) {
          setInviteeInfo({ ...inviteeInfo, error_list: errorData.errors });
        }

        if (errorData && errorData.message !== undefined) {
          Swal.fire({
            title: "Error!",
            text: errorData.message,
            icon: "error",
          });
        }
      })
      .finally(() => {
        setLoadingInvite(false);
      });
  };

  useEffect(() => {
    refreshInvitationsTable();

    return () => {};
  }, [null, refresh]);

  const useStyles = makeStyles(() => ({
    TableContainer: {
      padding: "0 2%",
    },
    tableHead: {
      borderBottom: "0",
      margin: "0",
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "Poppins",
      color: "#010101",
    },
    table: {
      fontSize: "11px",
      fontFamily: "Poppins",
      fontWeight: "400",
      borderTop: "1px solid #5F5F5F26",
      color: "#4A4A4A",
    },

    tableRow: { borderBottom: "1px solid #50555C" },
    pagination: { float: "right", padding: "2% 0" },
    itemStatus: {
      borderRadius: "5px",
      padding: "4% 0",
      fontSize: "12px",
      cursor: "pointer",
      textAlign: "center",
    },
    dialogPaper: { boxShadow: "none" },
    defaultTypo: { fontSize: "14px", fontFamily: "Poppins", fontWeight: "400", color: "#5F5F5F" },
    inputs: { width: 270 },
  }));
  const classes = useStyles();
  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "lightgrey",
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#626161",
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "Poppins",
            "&.Mui-selected": {
              backgroundColor: "transparent",
              color: "#010101",
              fontSize: "14px",
              fontWeight: "700",
              fontFamily: "Poppins",
            },
          },
        },
      },
    },
  });

  const loader2 = (
    <Stack
      alignItems="center"
      className="loader"
      display="flex"
      justifyContent="center"
      height="50vh"
      width="100%"
    >
      <CircularProgress /> Loading..
    </Stack>
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [sortingOrderBy, setSortingOrderBy] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const handleFavoriteSort = () => {
    if (sortingOrderBy === "Campaigns") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("Campaigns");
      setSortingOrder("asc");
    }
  };

  const handleStatusSort = () => {
    if (sortingOrderBy === "customer_role") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("customer_role");
      setSortingOrder("asc");
    }
  };
  const handleLeadSort = () => {
    if (sortingOrderBy === "is_disabled") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("is_disabled");
      setSortingOrder("asc");
    }
  };
  const handleDateTimeSort = () => {
    if (sortingOrderBy === "name") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("name");
      setSortingOrder("asc");
    }
  };

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      invitationsDataTableData && invitationsDataTableData.data
        ? invitationsDataTableData.data.length
        : null - page * rowsPerPage
    );

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleSearch(item) {
    const emailMatches = item.email.toLowerCase().includes(searchQuery.toLowerCase());
    const createdAt = item.created_at.toLowerCase().includes(searchQuery.toLowerCase());
    const validTill = item.valid_till.toLowerCase().includes(searchQuery.toLowerCase());

    return emailMatches || createdAt || validTill;
  }

  const handleTimezone = (e) => {
    setInviteeInfo({ ...inviteeInfo, timezone: e.value });
  };

  const handleInviteeInfo = (e) => {
    setInviteeInfo({ ...inviteeInfo, [e.target.name]: e.target.value });
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack mb={5}>
        <Typography fontSize="15px" fontWeight="700" color="#4A4A4A" mb={5} ml={3}>
          Invitations
        </Typography>
        <Stack ml={3} direction="row" alignItems="center">
          <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
            <Stack>
              <Typography className={classes.defaultTypo} mb={1}>
                Timezone
              </Typography>
              <FormControl sx={{ width: 260 }}>
                <Select
                  onChange={handleTimezone}
                  defaultValue={inviteeInfo.timezone}
                  variant="standard"
                  placeholder="Timezones"
                >
                  {data
                    ? data.timezones.map((item) => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      ))
                    : null}
                </Select>
                {inviteeInfo.error_list.timezone ? (
                  <Typography className={classes.errorTypo}>
                    {inviteeInfo.error_list.timezone}
                  </Typography>
                ) : (
                  ""
                )}
              </FormControl>
            </Stack>
            <Stack>
              <Typography className={classes.defaultTypo} mb={1}>
                Email
              </Typography>
              <TextField
                className={classes.inputs}
                name="email"
                type="email"
                variant="standard"
                placeholder="@gmail.com"
                defaultValue={inviteeInfo.answer}
                onChange={handleInviteeInfo}
              />
              {inviteeInfo.error_list.email ? (
                <Typography className={classes.errorTypo}>
                  {inviteeInfo.error_list.email}
                </Typography>
              ) : (
                ""
              )}
            </Stack>
            <Stack width="25%" mr={2} mt={3}>
              <AuthButton
                loading={loadingInvite}
                backgroundColor="#FF4D00D9"
                fontSize="13px"
                onClick={submitInvitation}
              >
                INVITE
              </AuthButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider orientation="horizontal" sx={{ marginRight: "2%", marginLeft: "2%" }} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mt={5} mb={3}>
        <Typography fontSize="15px" fontWeight="700" color="#4A4A4A" ml={3}>
          Invitations List
        </Typography>
        <Stack
          sx={{
            backgroundColor: "#F7F7F7",
            borderRadius: "5px",
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "10px",
            width: "17%",
          }}
          mr={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Icon sx={{ color: "#FF4D00D9" }}>search</Icon>
          <input
            style={{
              border: "0",
              background: "transparent",
              outline: "none",
            }}
            type="text"
            value={searchQuery}
            placeholder="Search..."
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Stack>
      </Stack>
      {loadingInvitationTable ? (
        loader2
      ) : (
        <TableContainer className={classes.TableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableSort
                  align="center"
                  sorted={sortingOrderBy === "Campaigns" ? sortingOrder : "none"}
                  onClick={handleFavoriteSort}
                >
                  Email
                </TableSort>
                <TableSort
                  align="left"
                  sorted={sortingOrderBy === "customer_role" ? sortingOrder : "none"}
                  onClick={handleStatusSort}
                >
                  Created At
                </TableSort>
                <TableSort
                  align="left"
                  sorted={sortingOrderBy === "is_disabled" ? sortingOrder : "none"}
                  onClick={handleLeadSort}
                >
                  Valid till
                </TableSort>
                <TableSort
                  align="left"
                  sorted={sortingOrderBy === "name" ? sortingOrder : "none"}
                  onClick={handleDateTimeSort}
                >
                  Action
                </TableSort>
              </TableRow>
            </TableHead>
            <TableBody>
              {invitationsDataTableData && invitationsDataTableData.data
                ? (rowsPerPage > 0
                    ? stableSort(
                        invitationsDataTableData.data.filter(handleSearch),
                        getComparator(sortingOrder, sortingOrderBy)
                      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : stableSort(
                        invitationsDataTableData.data,
                        getComparator(sortingOrder, sortingOrderBy)
                      )
                  ).map((item) => (
                    <TableRow>
                      <TableCell className={classes.table} scope="row">
                        {item.email ? item.email : null}
                      </TableCell>
                      <TableCell className={classes.table} scope="row">
                        <DateTimeFormatter dateTime={item.created_at ? item.created_at : null} />
                      </TableCell>
                      <TableCell className={classes.table} scope="row">
                        <DateTimeFormatter dateTime={item.valid_till ? item.valid_till : null} />
                      </TableCell>
                      <TableCell
                        onClick={() => handleCancelInvitation(item.id)}
                        className={classes.table}
                        scope="row"
                      >
                        <AuthButton
                          loading={loadingCancel}
                          fontSize="11px"
                          backgroundColor="#FF4D00D9"
                        >
                          Cancel
                        </AuthButton>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
              {emptyRows > 0 && (
                <TableRow style={{ height: 43 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Pagination
            className={classes.pagination}
            count={Math.ceil(
              invitationsDataTableData && invitationsDataTableData.data
                ? invitationsDataTableData.data.length / rowsPerPage
                : null
            )}
            page={page + 1}
            onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
    </ThemeProvider>
  );
}

InvitationsTable.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  customerId: PropTypes.number.isRequired,
};

export default InvitationsTable;
