import PropTypes from "prop-types";

function AnalyticsIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="30"
      height="30"
      viewBox="0 0 256 256"
    >
      <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
        <path
          fill={color}
          d="M 12.312 90.235 c -0.829 0 -1.5 -0.672 -1.5 -1.5 v -87 c 0 -0.829 0.671 -1.5 1.5 -1.5 s 1.5 0.671 1.5 1.5 v 87 C 13.812 89.563 13.14 90.235 12.312 90.235 z"
          transform=" matrix(1 0 0 1 0 0)"
        />
        <path
          fill={color}
          d="M 89 79.924 H 2 c -0.829 0 -1.5 -0.672 -1.5 -1.5 s 0.671 -1.5 1.5 -1.5 h 87 c 0.828 0 1.5 0.672 1.5 1.5 S 89.828 79.924 89 79.924 z"
          transform=" matrix(1 0 0 1 0 0)"
        />
        <path
          fill={color}
          d="M 20.967 55.139 c -0.513 0 -1.013 -0.263 -1.292 -0.736 c -0.422 -0.714 -0.186 -1.633 0.528 -2.055 L 77.92 18.225 c 0.716 -0.42 1.635 -0.186 2.055 0.528 c 0.422 0.713 0.186 1.633 -0.527 2.054 L 21.729 54.93 C 21.49 55.071 21.227 55.139 20.967 55.139 z"
          transform=" matrix(1 0 0 1 0 0)"
        />
        <path
          fill={color}
          d="M 76.184 30.75 c -0.123 0 -0.249 -0.016 -0.374 -0.048 c -0.803 -0.206 -1.286 -1.023 -1.08 -1.826 l 2.128 -8.282 l -8.281 -2.128 c -0.803 -0.206 -1.286 -1.023 -1.08 -1.826 c 0.206 -0.802 1.022 -1.287 1.826 -1.08 l 9.734 2.501 c 0.803 0.206 1.286 1.023 1.08 1.826 l -2.501 9.734 C 77.462 30.3 76.852 30.75 76.184 30.75 z"
          transform=" matrix(1 0 0 1 0 0)"
        />
        <path
          fill={color}
          d="M 29.677 67.234 h -9.142 c -0.733 0 -1.328 0.594 -1.328 1.328 V 79.17 h 11.798 V 68.562 C 31.005 67.828 30.41 67.234 29.677 67.234 z"
          transform=" matrix(1 0 0 1 0 0)"
        />
        <path
          fill={color}
          d="M 46.592 57.348 H 37.45 c -0.733 0 -1.328 0.594 -1.328 1.328 V 79.17 H 47.92 V 58.675 C 47.92 57.942 47.326 57.348 46.592 57.348 z"
          transform=" matrix(1 0 0 1 0 0)"
        />
        <path
          fill={color}
          d="M 63.508 47.461 h -9.142 c -0.733 0 -1.328 0.594 -1.328 1.328 V 79.17 h 11.798 V 48.789 C 64.835 48.056 64.241 47.461 63.508 47.461 z"
          transform=" matrix(1 0 0 1 0 0)"
        />
        <path
          fill={color}
          d="M 80.423 37.575 h -9.142 c -0.733 0 -1.328 0.594 -1.328 1.328 V 79.17 h 11.798 V 38.903 C 81.751 38.169 81.156 37.575 80.423 37.575 z"
          transform=" matrix(1 0 0 1 0 0)"
        />
      </g>
    </svg>
  );
}

AnalyticsIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default AnalyticsIcon;
