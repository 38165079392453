const AGENCY_SUPER_ADMIN = "agency_super_admin";
const AGENCY_ADMIN = "agency_admin";
const AGENCY_AGENT = "agency_agent";
const CUSTOMER_ADMIN = "customer_admin";
const CUSTOMER_USER = "customer_user";
const CUSTOMER_BUSINESS_PARTNER = "customer_business_partner";

const UserRoles = {
  AGENCY_SUPER_ADMIN,
  AGENCY_ADMIN,
  AGENCY_AGENT,
  CUSTOMER_ADMIN,
  CUSTOMER_USER,
  CUSTOMER_BUSINESS_PARTNER,
};

export default UserRoles;
