import React, { useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Typography, Grid, ListItem, ListItemText, styled } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import DateTimeFormatter from "components/authentication_v2/DateTimeFormatter";
import DateFormatter from "components/authentication_v2/DateFormatter";
import TimeFormatter from "components/authentication_v2/TimeFormatter";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import Map from "./Map";
// Styled components for better reusability and cleanliness
const CustomTypography = styled(Typography)({
  fontSize: "14px",
  color: "#626262",
  marginBottom: "7px",
  "&.body": {
    fontWeight: "400",
    fontSize: "18px",
    color: "black",
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(255, 119, 1, 1)",
    },
  },
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: "orange",
        },
      },
    },
  },
});

function AppointmentInfo({
  appointment,
  handleDownloadPdf,
  handleDownloadCsv,
  handleDownloadXls,
  loadingPdf,
  loadingCsv,
  loadingXls,
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          // backgroundColor: "white",
          borderRadius: 2,
        }}
      >
        {appointment && (
          <Grid container spacing={2} sx={{ padding: 3 }}>
            <Grid item xs={12} sm={4} lg={3}>
              <ListItem>
                <ListItemText
                  primary={
                    <CustomTypography
                      component="span"
                      sx={{ fontSize: "1.875rem", fontWeight: "bold" }}
                    >
                      <DateFormatter dateTime={appointment.appointment_at} />
                    </CustomTypography>
                  }
                  secondary={
                    <CustomTypography
                      component="span"
                      sx={{ fontSize: "2.875rem", fontWeight: "bold" }}
                    >
                      <TimeFormatter dateTime={new Date(appointment.appointment_at)} />
                    </CustomTypography>
                  }
                />
              </ListItem>
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <Map
                appointmentsOrOICLeads={[appointment]}
                fetchCampaignAppointmentsOrOics={null}
                openAppointmentModal={null}
                campaignType={appointment.campaign_type}
                campaignId={appointment.campaign_id}
              />
            </Grid>
            <Grid item xs={12} sm={3} lg={4} flexDirection="row">
              <Grid item xs={12} md={12}>
                <ListItem>
                  <ListItemText
                    sx={{ display: "flex", flexDirection: "column", alignItems: "right", gap: 1 }}
                    primary={
                      <Typography component="div" sx={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                        Campaign Name
                      </Typography>
                    }
                    secondary={
                      <Typography component="div" sx={{ fontSize: "0.875rem" }}>
                        {appointment.campaign_name.toString()}
                      </Typography>
                    }
                  />
                </ListItem>
              </Grid>
              <Grid item xs={12} lg={12}>
                <ListItem>
                  <ListItemText
                    sx={{ display: "flex", flexDirection: "column", alignItems: "right", gap: 1 }}
                    primary={
                      <Typography component="div" sx={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                        Info
                      </Typography>
                    }
                    secondary={
                      <Typography component="div" sx={{ fontSize: "0.875rem" }}>
                        {appointment.prospect_contacted_txt.toString()}
                      </Typography>
                    }
                  />
                </ListItem>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2} lg={2}>
              <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
              >
                <Button
                  size="small"
                  aria-controls={open ? "split-button-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  style={{ color: "white" }}
                  onClick={handleToggle}
                >
                  Export <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                sx={{ zIndex: 1 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {[
                            { handler: handleDownloadPdf, loading: loadingPdf },
                            { handler: handleDownloadCsv, loading: loadingCsv },
                            { handler: handleDownloadXls, loading: loadingXls },
                          ].map(({ handler, loading }, index) => (
                            <MenuItem
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              onClick={handler}
                              style={{ width: "150px", textAlign: "center" }}
                            >
                              {loading ? (
                                <CircularProgress size={25} sx={{ color: "primary.main" }} />
                              ) : (
                                <p>{["Pdf", "Csv", "Xls"][index]}</p>
                              )}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
}

AppointmentInfo.propTypes = {
  appointment: PropTypes.object.isRequired,
  handleDownloadPdf: PropTypes.func.isRequired,
  handleDownloadXls: PropTypes.func.isRequired,
  handleDownloadCsv: PropTypes.func.isRequired,
  loadingPdf: PropTypes.bool.isRequired,
  loadingCsv: PropTypes.bool.isRequired,
  loadingXls: PropTypes.bool.isRequired,
};

export default AppointmentInfo;
