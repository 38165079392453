/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import TimelineItem from "examples/Timeline/TimelineItem";
import { Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import ChevronDown from "components/Enrichment/components/iconComponents/ChevronDown";
import ChevronUp from "components/Enrichment/components/iconComponents/ChevronUp";

const TrackReviewChanges = ({ timeline }) => {
  const useStyles = makeStyles(() => ({
    typographyLabelStyle: {
      fontSize: "13px",
      color: "black",
    },
    suiBoxStyle: {
      fontSize: "13px",
    },
  }));
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} lg={12} mb={3}>
        <SuiTypography variant="h6" fontWeight="medium">
          <Button
            className={classes.buttonStyle}
            onClick={toggleVisibility}
            startIcon={!isVisible ? <ChevronDown /> : <ChevronUp />}
          >
            <Typography className={classes.typographyLabelStyle} component="div">
              Track review changes
            </Typography>
          </Button>
        </SuiTypography>
        {isVisible ? (
          <SuiBox className={classes.suiBoxStyle} mt={2}>
            {timeline && timeline.length > 0
              ? timeline.map((res) => (
                  <TimelineItem
                    color={res.color}
                    icon={res.icon}
                    title={res.name}
                    dateTime={res.value}
                  />
                ))
              : ""}
          </SuiBox>
        ) : null}
      </Grid>
    </>
  );
};

TrackReviewChanges.TrackReviewChanges = {
  timeline: [],
};

TrackReviewChanges.propTypes = {
  timeline: PropTypes.any.isRequired,
};

export default TrackReviewChanges;
