import { Grid } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import EmployeesInfoTable from "./EmployeesInfoTable";
import InvitationsTable from "./InvitationsTable";

function EmployeesInfo({ data, refreshUserInfo, customerId }) {
  return (
    <>
      {data.company ? (
        <Grid container>
          <Grid item md={12} sm={12} xs={12} sx={{ backgroundColor: "#FFFFFF", padding: "15px" }}>
            <EmployeesInfoTable refreshUserInfo={refreshUserInfo} customerId={customerId} />
          </Grid>
          <Grid
            item
            mt={2}
            md={12}
            sm={12}
            xs={12}
            sx={{ backgroundColor: "#FFFFFF", padding: "15px", maxHeight: "20%" }}
          >
            <InvitationsTable
              data={data}
              refreshUserInfo={refreshUserInfo}
              customerId={customerId}
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

EmployeesInfo.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  refreshUserInfo: PropTypes.func.isRequired,
  customerId: PropTypes.number.isRequired,
};

export default EmployeesInfo;
