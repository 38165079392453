import PropTypes from "prop-types";

function OicAnalyticsIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="25"
      height="25"
      viewBox="0 0 256 256"
    >
      <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
        <path
          d="M 77.474 17.28 L 61.526 1.332 C 60.668 0.473 59.525 0 58.311 0 H 15.742 c -2.508 0 -4.548 2.04 -4.548 4.548 v 80.904 c 0 2.508 2.04 4.548 4.548 4.548 h 58.516 c 2.508 0 4.549 -2.04 4.549 -4.548 V 20.496 C 78.807 19.281 78.333 18.138 77.474 17.28 z M 61.073 5.121 l 12.611 12.612 H 62.35 c -0.704 0 -1.276 -0.573 -1.276 -1.277 V 5.121 z M 74.258 87 H 15.742 c -0.854 0 -1.548 -0.694 -1.548 -1.548 V 4.548 C 14.194 3.694 14.888 3 15.742 3 h 42.332 v 13.456 c 0 2.358 1.918 4.277 4.276 4.277 h 13.457 v 64.719 C 75.807 86.306 75.112 87 74.258 87 z"
          fill={color}
          transform=" matrix(1 0 0 1 0 0) "
        />
        <path
          d="M 70.914 73.893 H 19.086 c -0.829 0 -1.5 -0.672 -1.5 -1.5 s 0.671 -1.5 1.5 -1.5 h 51.828 c 0.828 0 1.5 0.672 1.5 1.5 S 71.742 73.893 70.914 73.893 z"
          fill={color}
          transform=" matrix(1 0 0 1 0 0) "
        />
        <path
          d="M 45.303 67.466 c -0.829 0 -1.5 -0.672 -1.5 -1.5 V 52.49 c 0 -0.828 0.671 -1.5 1.5 -1.5 c 0.828 0 1.5 0.672 1.5 1.5 v 13.476 C 46.803 66.794 46.131 67.466 45.303 67.466 z"
          fill={color}
          transform=" matrix(1 0 0 1 0 0) "
        />
        <path
          d="M 35.303 67.466 c -0.829 0 -1.5 -0.672 -1.5 -1.5 V 45.856 c 0 -0.829 0.671 -1.5 1.5 -1.5 s 1.5 0.671 1.5 1.5 v 20.109 C 36.803 66.794 36.131 67.466 35.303 67.466 z"
          fill={color}
          transform=" matrix(1 0 0 1 0 0) "
        />
        <path
          d="M 25.303 67.466 c -0.829 0 -1.5 -0.672 -1.5 -1.5 v -6.738 c 0 -0.828 0.671 -1.5 1.5 -1.5 s 1.5 0.672 1.5 1.5 v 6.738 C 26.803 66.794 26.131 67.466 25.303 67.466 z"
          fill={color}
          transform=" matrix(1 0 0 1 0 0) "
        />
        <path
          d="M 55.303 67.466 c -0.828 0 -1.5 -0.672 -1.5 -1.5 V 32.381 c 0 -0.829 0.672 -1.5 1.5 -1.5 s 1.5 0.671 1.5 1.5 v 33.584 C 56.803 66.794 56.131 67.466 55.303 67.466 z"
          fill={color}
          transform=" matrix(1 0 0 1 0 0) "
        />
        <path
          d="M 65.303 67.466 c -0.828 0 -1.5 -0.672 -1.5 -1.5 V 45.856 c 0 -0.829 0.672 -1.5 1.5 -1.5 s 1.5 0.671 1.5 1.5 v 20.109 C 66.803 66.794 66.131 67.466 65.303 67.466 z"
          fill={color}
          transform=" matrix(1 0 0 1 0 0) "
        />
      </g>
    </svg>
  );
}

OicAnalyticsIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default OicAnalyticsIcon;
