import React from "react";
import PropTypes from "prop-types";

function LinkedIn({ color, width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_55_163)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.83911 0H13.1609C14.1782 0.00293375 15.153 0.410237 15.8723 1.13293C16.5917 1.85562 16.9971 2.83496 17 3.857V13.1444C16.9967 14.1662 16.5911 15.1452 15.8718 15.8676C15.1525 16.59 14.1779 16.9971 13.1609 17H3.83911C2.82205 16.9971 1.84746 16.59 1.12816 15.8676C0.40886 15.1452 0.00328491 14.1662 0 13.1444V3.857C0.00292014 2.83496 0.408335 1.85562 1.12768 1.13293C1.84702 0.410237 2.82181 0.00293375 3.83911 0Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.80526 6.58964H8.95378V7.70157H8.98421C9.28442 7.16228 10.0149 6.58964 11.1064 6.58964C13.3739 6.58964 13.7931 8.00735 13.7931 9.85176V13.7518H11.5533V10.2854C11.5533 9.49177 11.5367 8.47019 10.3884 8.47019C9.24015 8.47019 9.0437 9.33332 9.0437 10.2284V13.7588H6.80526V6.58964ZM5.53385 4.4186C5.53385 4.65006 5.46554 4.87633 5.33754 5.06878C5.20954 5.26124 5.02761 5.41124 4.81476 5.49982C4.6019 5.58839 4.36769 5.61157 4.14172 5.56641C3.91576 5.52126 3.7082 5.4098 3.54529 5.24613C3.38238 5.08246 3.27143 4.87393 3.22648 4.64691C3.18154 4.41989 3.20461 4.18459 3.29277 3.97074C3.38094 3.75689 3.53024 3.57412 3.72181 3.44552C3.91337 3.31693 4.13859 3.24829 4.36898 3.24829C4.67781 3.24866 4.97389 3.37208 5.19227 3.59147C5.41064 3.81087 5.53349 4.10832 5.53385 4.4186ZM3.20687 6.58964H5.53385V13.7518H3.20687V6.58964Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_55_163">
          <rect width="17" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
LinkedIn.defaultProps = {
  width: 24,
  height: 24,
};

LinkedIn.propTypes = {
  color: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LinkedIn;
