import PropTypes from "prop-types";

function OicLeadIcon({ color, width, height, viewBox }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 4.5H19V2.999C19 2.44772 18.5523 1.99998 18 1.99998C17.4477 1.99998 17 2.44772 17 2.999V4.5H7V2.999C7 2.44772 6.55228 1.99998 6 1.99998C5.44772 1.99998 5 2.44772 5 2.999V4.5H3.5C2.94772 4.5 2.5 4.94772 2.5 5.5V21.5C2.5 22.0523 2.94772 22.5 3.5 22.5H20.5C21.0523 22.5 21.5 22.0523 21.5 21.5V5.5C21.5 4.94772 21.0523 4.5 20.5 4.5ZM19 20H5V8H19V20ZM7 10H9V12H7V10ZM11 10H13V12H11V10ZM15 10H17V12H15V10ZM7 14H9V16H7V14ZM11 14H13V16H11V14ZM15 14H17V16H15V14Z"
        fill={color}
      />
    </svg>
  );
}

OicLeadIcon.defaultProps = {
  width: "34",
  height: "34",
  viewBox: "0 0 24 24",
};

OicLeadIcon.propTypes = {
  color: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};

export default OicLeadIcon;
