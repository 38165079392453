import { FormControlLabel, Grid, Switch, TextField, Typography } from "@mui/material";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { Link, useHistory } from "react-router-dom";
import AuthButton from "components/Buttons/AuthButton";

const theme = createTheme({
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: "#fff",
        },
      },
    },
  },
});

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 35,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#FF4D00D9" : "#FF4D00D9",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#fff",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 14,
    marginTop: 1,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#5F5F5FCC" : "#FF4D00D9",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const useStyles = makeStyles(() => ({
  container: { display: "flex", flexDirection: "column", alignItems: "center" },
  inputs: { width: "100%" },
  defaultTypo: { fontSize: "13px", fontFamily: "Poppins", fontWeight: "400" },
  errorTypo: { fontSize: "15px", fontFamily: "Poppins", color: "red" },
  resetTypo: {
    color: "#00000096",
    fontSize: "12px",
    fontFamily: "Poppins",
    textTransform: "none",
    fontWeight: "600",
  },
  forgetTypo: { color: "#8D8D8D", fontSize: "12px", fontFamily: "Poppins", fontWeight: "600" },
}));

function ClientLogin() {
  const classes = useStyles();
  const [sendLoading, setSendLoading] = useState(false);

  const [rememberMe, setRememberMe] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [signIn, setSignIn] = useState({
    email: "",
    password: "",
    error_list: [],
  });

  const handleSignInInput = (e) => {
    e.persist();
    delete signIn.error_list[e.target.name];
    setSignIn({ ...signIn, [e.target.name]: e.target.value });
  };
  const history = useHistory();

  const submitSignIn = (e) => {
    e.preventDefault();
    setSendLoading(true);
    axios.get("/sanctum/csrf-cookie").then(() => {
      const data = {
        email: signIn.email,
        password: signIn.password,
      };
      axios
        .post(`/api/login`, data)
        .then((res) => {
          localStorage.removeItem("auth_token");
          localStorage.setItem("auth_user", btoa(JSON.stringify(res.data.user)));
          setSendLoading(false);
          history.push("/authentication/verification/cover");
        })
        .catch((error) => {
          setSignIn({ ...signIn, error_list: [] });
          const errorData = error.response === undefined ? null : error.response.data;

          if (errorData && errorData.errors !== undefined) {
            setSignIn({ ...signIn, error_list: errorData.errors });
          }

          if (errorData && errorData.message !== undefined) {
            Swal.fire({
              title: "Error!",
              text: errorData.message,
              icon: "error",
            });
          }
          setSendLoading(false);
          // return Promise.reject(error);
        });
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      submitSignIn(e);
    }
  };
  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12} className={classes.container}>
        <Grid container spacing={5}>
          <Grid item md={2.5} sm={2.5} />
          <Grid item md={7.5} sm={7} xs={12}>
            <Typography className={classes.defaultTypo}>Email</Typography>
            <TextField
              className={classes.inputs}
              name="email"
              type="email"
              onChange={handleSignInInput}
              variant="standard"
              onKeyDown={handleKeyPress}
            />
            <Typography className={classes.errorTypo}>{signIn.error_list.email}</Typography>
          </Grid>
          <Grid item md={2} sm={2.5} />
          <Grid item md={2.5} sm={2.5} />
          <Grid item md={7.5} sm={7} xs={12}>
            <Typography className={classes.defaultTypo}>Password</Typography>
            <TextField
              className={classes.inputs}
              name="password"
              type="password"
              onChange={handleSignInInput}
              variant="standard"
              onKeyDown={handleKeyPress}
            />
            <Typography className={classes.errorTypo}>{signIn.error_list.password}</Typography>
          </Grid>
          <Grid item md={2} sm={2.5} />
        </Grid>
        <Grid container>
          <Grid item xs={12} md={2.7} sm={3} />
          <Grid item xs={12} md={9.3} sm={9} mt={2}>
            <FormControlLabel
              checked={rememberMe}
              onChange={handleSetRememberMe}
              control={<IOSSwitch sx={{ m: 1 }} />}
              label={<Typography className={classes.defaultTypo}>Remember me</Typography>}
            />
          </Grid>
        </Grid>
        <Grid container mt={3}>
          <Grid item md={2.5} sm={2.5} />
          <Grid item md={7} sm={7} xs={12} alignItems="center">
            <ThemeProvider theme={theme}>
              <AuthButton
                onClick={submitSignIn}
                backgroundColor="#FF4D00D9"
                fontSize="14px"
                loading={sendLoading}
              >
                SIGN IN NOW
              </AuthButton>
            </ThemeProvider>
            <Grid container alignItems="center">
              <Grid item xs={2.5} md={3} sm={2} />
              <Grid mt={3.5} item xs={5} sm={6} md={4.5}>
                <Typography className={classes.forgetTypo}>Forgot Password?</Typography>
              </Grid>
              <Grid mt={3} item xs={3} sm={2} md={2}>
                <Typography
                  component={Link}
                  to="/authentication/reset-password/cover"
                  variant="button"
                  className={classes.resetTypo}
                >
                  Reset
                </Typography>
              </Grid>
              <Grid item xs={2} md={2} sm={2} />
            </Grid>
          </Grid>
          <Grid item md={2} sm={2.5} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ClientLogin;
