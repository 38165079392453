/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Custom styles for Calendar
import CalendarRoot from "examples/Calendar/CalendarRoot";
import SuiButton from "components/SuiButton";
import { Grid } from "@mui/material";

function Calendar({ header, ...rest }) {
  const validClassNames = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ];

  const events = rest.events
    ? rest.events.map((el) => ({
        ...el,
        className: validClassNames.find((item) => item === el.className)
          ? `event-${el.className}`
          : "event-info",
      }))
    : [];

  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox pb={1} px={2} lineHeight={1}>
        <Grid container justifyContent="center" pt={2}>
          <Grid item xs={12} md={6}>
            {header.title ? (
              <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {header.title}
              </SuiTypography>
            ) : null}
          </Grid>
          {header.date ? (
            <SuiTypography component="p" variant="button" color="text" fontWeight="medium">
              {header.date}
            </SuiTypography>
          ) : null}
          {rest.showAppointmentCalendar && (
            <Grid item xs={12} md={6}>
              <SuiTypography component="h6" variant="button" color="text" textAlign="right">
                <SuiButton size="small" color="warning" onClick={rest.showAppointmentCalendar}>
                  Subscribe
                </SuiButton>
              </SuiTypography>
            </Grid>
          )}
        </Grid>
      </SuiBox>
      <CalendarRoot p={2}>
        <FullCalendar
          {...rest}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          height="100%"
        />
      </CalendarRoot>
    </Card>
  );
}

// Setting default values for the props of Calendar
Calendar.defaultProps = {
  header: {
    title: "",
    date: "",
  },
};

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
};

export default Calendar;
