function ArrowCloseIcon() {
  return (
    <svg width="33" height="33" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1057_353)">
        <path
          d="M11.4502 8.78858L10.0951 7.4335L6.02795 11.4997L10.0942 15.5659L11.4492 14.2099L9.69837 12.458H16.9213V10.5414H9.69837L11.4502 8.78858Z"
          fill="#FF4D00"
          fillOpacity="0.85"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.0417 4.7915C22.0417 3.77484 21.6378 2.79982 20.919 2.08093C20.2001 1.36204 19.225 0.95817 18.2084 0.95817H4.79171C3.77504 0.95817 2.80002 1.36204 2.08113 2.08093C1.36224 2.79982 0.958374 3.77484 0.958374 4.7915V18.2082C0.958374 19.2248 1.36224 20.1999 2.08113 20.9187C2.80002 21.6376 3.77504 22.0415 4.79171 22.0415H18.2084C19.225 22.0415 20.2001 21.6376 20.919 20.9187C21.6378 20.1999 22.0417 19.2248 22.0417 18.2082V4.7915ZM18.2084 2.87484H4.79171C4.28338 2.87484 3.79586 3.07677 3.43642 3.43622C3.07697 3.79566 2.87504 4.28317 2.87504 4.7915V18.2082C2.87504 18.7165 3.07697 19.204 3.43642 19.5635C3.79586 19.9229 4.28338 20.1248 4.79171 20.1248H18.2084C18.7167 20.1248 19.2042 19.9229 19.5637 19.5635C19.9231 19.204 20.125 18.7165 20.125 18.2082V4.7915C20.125 4.28317 19.9231 3.79566 19.5637 3.43622C19.2042 3.07677 18.7167 2.87484 18.2084 2.87484Z"
          fill="#FF4D00"
          fillOpacity="0.85"
        />
      </g>
      <defs>
        <clipPath id="clip0_1057_353">
          <rect width="23" height="23" fill="white" transform="matrix(1 0 0 -1 0 23)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ArrowCloseIcon;
