import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";
import { makeStyles } from "@material-ui/core/styles";

function ReviewStatus({
  reasonVal,
  appointmentReviewStatuses,
  appointmentReviewStatus,
  appointment,
  handleAppointmentReviewStatusChange,
}) {
  const useStyles = makeStyles(() => ({
    suiSelectStyle: {
      maxWidth: "80%",
      "& .css-l4kyc0-Control": {
        backgroundColor: "rgba(255, 119, 1, 1)",
        fontSize: "13px",
        height: "1.5rem",
        border: "none",
        borderRadis: "1px",
      },
      "& .css-12vptoo-Control": {
        backgroundColor: "rgba(255, 119, 1, 1)",
        fontSize: "14px",
        height: "1.7rem",
      },
      "& .css-mxm12o-SingleValue": {
        color: "black",
      },
      "& .css-1rkaz1j-DropdownIndicator svg": {
        fill: "white",
        stroke: "white",
      },
    },
    typographyLabelStyle: {
      fontSize: "12px",
      color: "#626262",
      marginBottom: "7px",
    },
    gridOneStyle: {
      alignItems: "center",
    },
  }));
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      {appointmentReviewStatuses.length > 0 &&
      appointmentReviewStatus in
        [appointmentReviewStatuses[0].value, appointmentReviewStatuses[1].value] ? (
        <Grid item xs={12} lg={8} mb={3}>
          {appointmentReviewStatus}
        </Grid>
      ) : (
        appointment.customer_write_permission && (
          <Grid item xs={12} lg={12} mb={3} sx={{ zIndex: 1000 }}>
            <Grid className={classes.gridOneStyle} item xs={12} sm={12}>
              <SuiBox p={1}>
                <SuiSelect
                  className={classes.suiSelectStyle}
                  style={{ zIndex: 1000 }}
                  placeholder="Type here..."
                  defaultValue={{ value: "0", label: "REVIEW STATUS" }}
                  onChange={handleAppointmentReviewStatusChange}
                  options={[
                    {
                      label: `${reasonVal}`,
                      value: "0",
                      isDisabled: true,
                    },
                    ...appointmentReviewStatuses.map((i) => ({
                      label:
                        i.label.replace("_", " ").charAt(0).toUpperCase() +
                        i.label.replace("_", " ").slice(1),
                      value: i.value,
                    })),
                  ]}
                />
              </SuiBox>
            </Grid>
          </Grid>
        )
      )}
    </Grid>
  );
}

ReviewStatus.propTypes = {
  reasonVal: PropTypes.any.isRequired,
  handleAppointmentReviewStatusChange: PropTypes.func.isRequired,
  appointmentReviewStatuses: PropTypes.array.isRequired,
  appointmentReviewStatus: PropTypes.any.isRequired,
  appointment: PropTypes.object.isRequired,
};

export default ReviewStatus;
