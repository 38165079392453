import React from "react";
import PropTypes from "prop-types";

function LetterLogo({ width }) {
  return (
    <svg
      width={width}
      height="32"
      viewBox="0 0 201 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1314 9.29009H13.8928V31.2148H7.23863V9.29009H0V3.9668H21.1314V9.29009Z"
        fill="black"
      />
      <path
        d="M42.362 31.2148H24.2367V3.9668H41.9497V9.24312H30.776V14.4359H41.1616V19.801H30.776V25.7662H42.362V31.2148Z"
        fill="black"
      />
      <path
        d="M62.9713 31.2148H45.895V3.9668H52.57V25.7192H62.9661V31.2148H62.9713Z"
        fill="black"
      />
      <path
        d="M84.2434 31.2148H65.8102V3.9668H83.8259V9.24312H72.4591V14.4359H83.0222V19.801H72.4591V25.7662H84.2434V31.2148Z"
        fill="black"
      />
      <path
        d="M109.273 31.2148H102.019L96.7376 21.2153H94.1595V31.2148H87.782V3.9668H97.9015C100.913 3.9668 103.183 4.56697 104.717 5.76732C106.732 7.31212 107.739 9.49885 107.739 12.3327C107.739 16.0538 106.126 18.6267 102.901 20.0567L109.273 31.2148ZM101.33 12.6354C101.33 11.4351 100.928 10.5061 100.119 9.84851C99.4463 9.36316 98.5486 9.11787 97.4161 9.11787H94.1491V16.1999H97.4161C98.5434 16.1999 99.4463 15.9599 100.119 15.4693C100.928 14.8117 101.33 13.8671 101.33 12.6354Z"
        fill="black"
      />
      <path
        d="M134.236 7.73985C136.595 10.1823 137.774 13.4702 137.774 17.6088C137.774 21.7474 136.595 25.0458 134.236 27.4987C131.877 29.9568 128.683 31.1832 124.654 31.1832C120.625 31.1832 117.426 29.9568 115.051 27.4987C112.677 25.0406 111.492 21.7474 111.492 17.6088C111.492 13.4702 112.677 10.1823 115.051 7.73985C117.426 5.2974 120.625 4.07617 124.654 4.07617C128.683 4.07617 131.877 5.2974 134.236 7.73985ZM129.414 23.3809C130.515 21.8779 131.068 19.926 131.068 17.5253C131.068 15.1246 130.515 13.178 129.414 11.6906C128.307 10.2032 126.721 9.45687 124.654 9.45687C122.587 9.45687 120.99 10.2032 119.874 11.6906C118.757 13.178 118.198 15.1246 118.198 17.5253C118.198 19.926 118.757 21.8779 119.874 23.3809C120.99 24.884 122.582 25.6355 124.654 25.6355C126.726 25.6355 128.313 24.8892 129.414 23.3809Z"
        fill="black"
      />
      <path d="M149.042 31.2144H142.268V10.417H149.042V31.2144Z" fill="black" />
      <path
        d="M177.652 17.5569C177.652 22.0451 176.259 25.5314 173.472 28.0156C171.154 30.1292 167.898 31.1887 163.707 31.1887H154.229V3.9668H163.707C167.846 3.9668 171.102 5.02624 173.472 7.1399C176.259 9.68151 177.652 13.1573 177.652 17.5569ZM170.951 17.5099C170.951 14.7073 170.262 12.5937 168.884 11.1689C167.642 9.88505 165.862 9.2379 163.545 9.2379H160.774V25.7401H163.545C165.862 25.7401 167.642 25.0982 168.884 23.8091C170.262 22.3843 170.951 20.2863 170.951 17.5099Z"
        fill="black"
      />
      <path
        d="M200.004 6.66473L197.765 11.3513C196.915 10.6833 195.808 10.1144 194.446 9.64994C193.084 9.18024 191.832 8.98714 190.699 9.06021C188.293 9.21677 187.135 10.0153 187.228 11.4505C187.291 12.4212 188.142 13.1414 189.786 13.6215C190.015 13.7207 190.469 13.8564 191.143 14.0338C191.821 14.2113 192.541 14.4044 193.303 14.6027C194.065 14.8062 194.53 14.9263 194.702 14.9732C196.205 15.4899 197.369 16.0536 198.183 16.6642C199.9 17.9167 200.834 19.6755 200.98 21.9405C201.142 24.4873 200.307 26.6114 198.475 28.3128C196.638 30.0142 194.149 30.9692 190.991 31.1727C189.081 31.298 187.181 31.0788 185.282 30.5204C183.387 29.962 181.806 29.153 180.543 28.0988L183.22 23.1356C184.186 23.8819 185.371 24.5134 186.78 25.0301C188.189 25.552 189.499 25.7712 190.72 25.6929C193.319 25.5259 194.571 24.6648 194.472 23.12C194.441 22.6503 194.232 22.238 193.851 21.8883C193.465 21.5386 193.141 21.309 192.87 21.1994C192.599 21.0898 192.207 20.9698 191.701 20.8393C191.419 20.7454 190.683 20.5366 189.493 20.213C188.309 19.8895 187.599 19.6911 187.375 19.6181C185.845 19.1901 184.603 18.6317 183.643 17.9428C181.9 16.7216 180.95 14.9054 180.794 12.4995C180.632 9.95264 181.55 7.86508 183.554 6.23677C185.297 4.87464 187.583 4.10224 190.402 3.91958C192.28 3.79954 194.097 4.02917 195.84 4.60847C197.593 5.18255 198.981 5.87145 200.004 6.66473Z"
        fill="black"
      />
      <path
        d="M145.587 6.92028C147.498 6.92028 149.047 5.37112 149.047 3.46014C149.047 1.54916 147.498 0 145.587 0C143.676 0 142.127 1.54916 142.127 3.46014C142.127 5.37112 143.676 6.92028 145.587 6.92028Z"
        fill="#FF9455"
      />
    </svg>
  );
}

LetterLogo.defaultProps = {
  width: "200",
};

// Typechecking props for the Sidenav
LetterLogo.propTypes = {
  width: PropTypes.string,
};

export default LetterLogo;
