/* eslint-disable no-unused-vars */
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./Header";
import FieldInfo2 from "./FieldInfo2";
import Relationship from "./Relationship";
import TechStack from "./TechStack";
import EmployeeSidebar from "./EmployeeSidebar";

const useStyles = makeStyles(() => ({
  companyInfoWrapper: {
    flex: "left",
    width: "100%",
    padding: "20px",
    overflow: "auto",
  },
  employeeInfoWrapper: {
    flex: "right",
    width: "100%",
    border: "1px solid white",
    paddingTop: "10px",
    backgroundColor: "#f0f0f0",
    overflow: "auto",
  },
  row: {
    display: "flex",
    flexDirection: "column",
    height: "auto",
  },
  "@media (min-width: 1441px)": {
    row: {
      flexDirection: "row",
      height: "95vh",
    },
    companyInfoWrapper: {
      width: "75%",
    },
    employeeInfoWrapper: {
      width: "25%",
    },
  },
  "@media (max-width: 1440px)": {
    row: {
      flexDirection: "row",
      height: "95vh",
    },
    companyInfoWrapper: {
      width: "67%",
    },
    employeeInfoWrapper: {
      width: "33%",
    },
  },
  "@media (max-width: 768px)": {
    row: {
      flexDirection: "column",
      height: "auto",
    },
    companyInfoWrapper: {
      width: "100%",
    },
    employeeInfoWrapper: {
      width: "100%",
    },
  },
}));

const CompanyIndex = forwardRef(
  (
    {
      customerUser,
      loadingCompany,
      companyInfo,
      aboutInfo,
      appointmentId,
      employeeInfo,
      setEmployeeInfo,
    },
    ref
  ) => {
    const classes = useStyles();

    return (
      <div className={classes.row}>
        <div className={classes.companyInfoWrapper}>
          <Header loadingCompany={loadingCompany} companyInfo={companyInfo} />
          <FieldInfo2
            customerUser={customerUser}
            loadingCompany={loadingCompany}
            companyInfo={companyInfo}
            description={aboutInfo}
          />
          {appointmentId !== undefined && (
            <Relationship customerUser={customerUser} appointmentId={appointmentId} />
          )}
          <TechStack
            customerUser={customerUser}
            loadingCompany={loadingCompany}
            appointmentId={appointmentId}
          />
        </div>
        <div className={classes.employeeInfoWrapper} ref={ref}>
          <EmployeeSidebar
            customerUser={customerUser}
            loadingCompany={loadingCompany}
            employeeInfo={employeeInfo}
            setEmployeeInfo={setEmployeeInfo}
            domain={companyInfo.domain}
            companyId={companyInfo.id}
            companyName={companyInfo.name}
            appointmentId={appointmentId}
          />
        </div>
      </div>
    );
  }
);

CompanyIndex.propTypes = {
  customerUser: PropTypes.any.isRequired,
  loadingCompany: PropTypes.bool.isRequired,
  companyInfo: PropTypes.any.isRequired,
  employeeInfo: PropTypes.any.isRequired,
  setEmployeeInfo: PropTypes.any.isRequired,
  aboutInfo: PropTypes.object.isRequired,
  appointmentId: PropTypes.number.isRequired,
};

export default CompanyIndex;
