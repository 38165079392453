import {
  Box,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Swal from "sweetalert2";
import AuthButton from "components/Buttons/AuthButton";
import plus from "../../../../../assets/images/authentication_v2/plus.svg";

const useStyles = makeStyles(() => ({
  container: { display: "flex", flexDirection: "column", alignItems: "center" },
  inputs: {
    width: "100%",
  },
  inputs2: { width: "120%" },
  inputs3: { width: "120%" },
  defaultTypo: { fontSize: "14px", fontFamily: "Poppins", fontWeight: "400", color: "#5F5F5F" },
  errorTypo: { fontSize: "14px", fontFamily: "Poppins", color: "red" },
  resetTypo: {
    color: "#00000096",
    fontSize: "12px",
    fontFamily: "Poppins",
    textTransform: "none",
    fontWeight: "600",
  },
  forgetTypo: { color: "#8D8D8D", fontSize: "12px", fontFamily: "Poppins", fontWeight: "600" },
}));
const theme = createTheme({
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: "#fff",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: "400",
          color: "#5F5F5F",
        },
      },
    },
  },
});

function UserProfile({ data, customerId, refreshData }) {
  const classes = useStyles();

  const [securityInput, setSecurityInput] = useState({
    question: data.user.question,
    answer: data.user.answer,
    error_list: [],
  });

  const handleSecurityInput = (e) => {
    e.preventDefault();
    delete securityInput.error_list[e.target.name];
    setSecurityInput({ ...securityInput, [e.target.name]: e.target.value });
  };

  const submitSecurityInput = (e) => {
    e.persist();
    axios
      .put(`/api/customers/${customerId}/profile/update-security-input`, {
        ...securityInput,
      })
      .then((res) => {
        setSecurityInput({ ...securityInput, error_list: [] });
        Swal.fire({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
      })
      .catch((error) => {
        setSecurityInput({ ...securityInput, error_list: [] });
        const errorData = error.response === undefined ? null : error.response.data;

        if (errorData && errorData.errors !== undefined) {
          setSecurityInput({ ...securityInput, error_list: errorData.errors });
        }

        if (errorData && errorData.message !== undefined) {
          Swal.fire({
            title: "Error!",
            text: errorData.message,
            icon: "error",
          });
        }
      });
  };
  const [basicInfo, setBasicInfo] = useState({
    name: data.user.name,
    timezone: data.user.timezone,
    email: data.user.email,
    linkedin: data.user.linkedin,
    xing: data.user.xing,
    error_list: [],
  });

  const [linkedinUrlError, setLinkedinUrlError] = useState("");
  const [xingUrlError, setXingUrlError] = useState("");

  const validateLinkedinUrl = (inputUrl) => {
    const urlPattern = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[A-Za-z0-9_-]+\/?$/;
    return urlPattern.test(inputUrl);
  };

  const validateXingUrl = (inputUrl) => {
    const urlPattern = /^(https?:\/\/)?(www\.)?xing\.com\/profile\/[A-Za-z0-9_-]+\/?$/;
    return urlPattern.test(inputUrl);
  };

  const handleBasicInfo = (e) => {
    console.log("eee", e.target.value);
    if (e.target.name === "linkedin") {
      if (!validateLinkedinUrl(e.target.value) && e.target.value !== "") {
        setLinkedinUrlError("Please set a valid LinkedIn url");
      } else {
        setLinkedinUrlError("");
        e.preventDefault();
        delete basicInfo.error_list[e.target.name];
        setBasicInfo({
          ...basicInfo,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "xing") {
      if (!validateXingUrl(e.target.value) && e.target.value !== "") {
        setXingUrlError("Please set a valid Xing url");
      } else {
        setXingUrlError("");
        e.preventDefault();
        delete basicInfo.error_list[e.target.name];
        setBasicInfo({
          ...basicInfo,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      e.preventDefault();
      delete basicInfo.error_list[e.target.name];
      setBasicInfo({
        ...basicInfo,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleTimezone = (e) => {
    setBasicInfo({ ...basicInfo, timezone: e.target.value });
  };
  const handleLanguage = (e) => {
    console.log(e.target.value);
  };

  const submitBasicInfo = (e) => {
    e.persist();
    axios
      .put(`/api/customers/${customerId}/profile/update-basic-info`, { ...basicInfo })
      .then((res) => {
        refreshData();
        Swal.fire({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
      })
      .catch((error) => {
        setBasicInfo({ ...basicInfo, error_list: [] });
        const errorData = error.response === undefined ? null : error.response.data;

        if (errorData && errorData.errors !== undefined) {
          setBasicInfo({ ...basicInfo, error_list: errorData.errors });
        }

        if (errorData && errorData.message !== undefined) {
          Swal.fire({
            title: "Error!",
            text: errorData.message,
            icon: "error",
          });
        }
      });
  };

  const [newPassword, setNewPassword] = useState({
    password: null,
    password_confirmation: null,
    error_list: [],
  });

  const handleInput = (e) => {
    e.persist();
    setNewPassword({ ...newPassword, [e.target.name]: e.target.value });
  };

  const submitNewPassword = (e) => {
    e.preventDefault();
    axios
      .put(`/api/customers/${customerId}/profile/update-password`, { ...newPassword })
      .then((res) => {
        setNewPassword({
          ...newPassword,
          error_list: [],
        });
        Swal.fire({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
      })
      .catch((error) => {
        setNewPassword({ ...newPassword, error_list: [] });
        const errorData = error.response === undefined ? null : error.response.data;

        if (errorData && errorData.errors !== undefined) {
          setNewPassword({ ...newPassword, error_list: errorData.errors });
        }

        if (errorData && errorData.message !== undefined) {
          Swal.fire({
            title: "Error!",
            text: errorData.message,
            icon: "error",
          });
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item xs={12} sm={12} md={2.5} mr="25px">
          <Grid
            item
            mb={2}
            sx={{
              backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <Box
              sx={{
                width: "85px",
                height: "85px",
                borderRadius: "50%",
                background: "radial-gradient(at bottom left, #fff, #FF4D00)",
                position: "relative",
              }}
            >
              <img
                style={{ position: "absolute", bottom: "2px", right: "4px" }}
                src={plus}
                alt="+"
              />
            </Box>
            <Typography
              mt={2}
              fontSize="14px"
              fontWeight="700"
              fontFamily="Poppins"
              color="#4A4A4A"
            >
              {data.user.name}
            </Typography>
            <Typography
              mb={2}
              fontSize="12px"
              fontWeight="500"
              fontFamily="Poppins"
              color="#010101"
            >
              {data.user.email}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              backgroundColor: "#FFFFFF",
              padding: "25px 20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              textAlign="center"
              fontSize="16px"
              fontWeight="700"
              fontFamily="Poppins"
              color="#4A4A4A"
              letterSpacing="0.5px"
              mb={2}
            >
              Security Question
            </Typography>
            <Grid item md={12} sm={12} xs={12} mb={4}>
              <Typography className={classes.defaultTypo} mb={1.5}>
                Question
              </Typography>
              <TextField
                className={classes.inputs}
                name="question"
                variant="standard"
                defaultValue={securityInput.question}
                onChange={handleSecurityInput}
              />
              {securityInput.error_list.question ? (
                <Typography className={classes.errorTypo}>
                  {securityInput.error_list.question}
                </Typography>
              ) : (
                ""
              )}
            </Grid>
            <Grid item md={12} sm={12} xs={12} mb={3}>
              <Typography className={classes.defaultTypo} mb={1.5}>
                Answer
              </Typography>
              <TextField
                className={classes.inputs}
                name="answer"
                type="password"
                variant="standard"
                defaultValue={securityInput.answer}
                onChange={handleSecurityInput}
              />
              {securityInput.error_list.answer ? (
                <Typography className={classes.errorTypo}>
                  {securityInput.error_list.answer}
                </Typography>
              ) : (
                ""
              )}{" "}
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Stack width="110%" ml="-5%">
                <AuthButton
                  backgroundColor="#FF4D00D9"
                  fontSize="13px"
                  onClick={submitSecurityInput}
                >
                  Upadate Question
                </AuthButton>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            marginTop: { xs: "10px", sm: "10px", md: "0", lg: "0" },
            backgroundColor: "#FFFFFF",
          }}
          xs={12}
          md={9.2}
          sm={12}
        >
          <Grid
            item
            md={5.7}
            sm={5.7}
            ml={2}
            mb={0}
            sx={{
              backgroundColor: "#FFFFFF",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              textAlign="center"
              fontSize="16px"
              fontWeight="700"
              fontFamily="Poppins"
              color="#4A4A4A"
              letterSpacing="0.5px"
              mb={2}
            >
              Basic Information
            </Typography>
            <Stack mb={4}>
              <Typography className={classes.defaultTypo} mb={1.5}>
                Name
              </Typography>
              <TextField
                className={classes.inputs2}
                name="name"
                type="text"
                variant="standard"
                defaultValue={basicInfo.name}
                onChange={handleBasicInfo}
              />
              {basicInfo.error_list.name ? (
                <Typography className={classes.errorTypo}>{basicInfo.error_list.name}</Typography>
              ) : (
                ""
              )}
            </Stack>

            <Stack mb={4}>
              <Typography className={classes.defaultTypo} mb={1.5}>
                Email
              </Typography>
              <TextField
                className={classes.inputs2}
                name="email"
                type="email"
                variant="standard"
                onChange={handleBasicInfo}
                value={basicInfo.email}
              />
              {basicInfo.error_list.email ? (
                <Typography className={classes.errorTypo}>{basicInfo.error_list.email}</Typography>
              ) : (
                ""
              )}{" "}
            </Stack>
            <Stack mb={4}>
              <Typography className={classes.defaultTypo} mb={1.5}>
                Linkedin
              </Typography>
              {basicInfo.linkedin ? (
                <TextField
                  className={classes.inputs2}
                  name="linkedin"
                  type="text"
                  variant="standard"
                  multiline
                  rows={2}
                  defaultValue={basicInfo.linkedin}
                  onChange={handleBasicInfo}
                />
              ) : (
                <TextField
                  className={classes.inputs2}
                  name="linkedin"
                  type="text"
                  variant="standard"
                  defaultValue={basicInfo.linkedin}
                  onChange={handleBasicInfo}
                />
              )}
              {basicInfo.error_list.linkedin || linkedinUrlError ? (
                <Typography className={classes.errorTypo}>
                  {linkedinUrlError}
                  {basicInfo.error_list.linkedin}
                </Typography>
              ) : (
                ""
              )}{" "}
            </Stack>
            <Stack mb={4}>
              <Typography className={classes.defaultTypo} mb={1.5}>
                Xing
              </Typography>
              {basicInfo.xing ? (
                <TextField
                  className={classes.inputs2}
                  name="xing"
                  type="text"
                  variant="standard"
                  multiline
                  rows={2}
                  defaultValue={basicInfo.xing}
                  onChange={handleBasicInfo}
                />
              ) : (
                <TextField
                  className={classes.inputs2}
                  name="xing"
                  type="text"
                  variant="standard"
                  defaultValue={basicInfo.xing}
                  onChange={handleBasicInfo}
                />
              )}
              {basicInfo.error_list.xing || xingUrlError ? (
                <Typography className={classes.errorTypo}>
                  {xingUrlError} {basicInfo.error_list.xing}
                </Typography>
              ) : (
                ""
              )}{" "}
            </Stack>
            <Stack mb={4}>
              <Typography className={classes.defaultTypo} mb={1.5}>
                Timezone
              </Typography>
              <FormControl sx={{ width: 200 }}>
                <Select
                  onChange={handleTimezone}
                  defaultValue={basicInfo.timezone}
                  variant="standard"
                >
                  {data.timezones.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {basicInfo.error_list.timezone ? (
                <Typography className={classes.errorTypo}>
                  {basicInfo.error_list.timezone}
                </Typography>
              ) : (
                ""
              )}
            </Stack>
            <Stack mb={4}>
              <Typography className={classes.defaultTypo} mb={1.5}>
                Language
              </Typography>
              <FormControl sx={{ width: 200 }}>
                <Select
                  disabled
                  onChange={handleLanguage}
                  defaultValue="English"
                  variant="standard"
                >
                  <MenuItem value="English">English</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Grid item md={12} sm={12} xs={12}>
              <Stack width="145%">
                <AuthButton
                  disabled={xingUrlError || linkedinUrlError}
                  backgroundColor="#FF4D00D9"
                  fontSize="13px"
                  onClick={submitBasicInfo}
                >
                  Upadate Profile
                </AuthButton>
              </Stack>
            </Grid>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              marginTop: "30px",
              marginBottom: "30px",
              display: { xs: "none", sm: "none", md: "block" },
            }}
          />
          <Grid
            item
            ml={2}
            md={5.7}
            sm={5.7}
            sx={{
              backgroundColor: "#FFFFFF",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              textAlign="center"
              fontSize="16px"
              fontWeight="700"
              fontFamily="Poppins"
              color="#4A4A4A"
              letterSpacing="0.5px"
              mb={2}
            >
              Change Password
            </Typography>

            <Stack mb={4}>
              <Typography className={classes.defaultTypo} mb={1.5}>
                New Password
              </Typography>
              <TextField
                className={classes.inputs2}
                name="password"
                type="password"
                variant="standard"
                defaultValue={newPassword.password}
                onChange={handleInput}
              />
              {securityInput.error_list.answer ? (
                <Typography className={classes.errorTypo}>
                  {securityInput.error_list.answer}
                </Typography>
              ) : (
                ""
              )}{" "}
            </Stack>
            <Stack mb={4}>
              <Typography className={classes.defaultTypo} mb={1.5}>
                Confirm New Password
              </Typography>
              <TextField
                className={classes.inputs2}
                type="password"
                variant="standard"
                name="password_confirmation"
                defaultValue={newPassword.password_confirmation}
                onChange={handleInput}
              />
              {securityInput.error_list.answer ? (
                <Typography className={classes.errorTypo}>
                  {securityInput.error_list.answer}
                </Typography>
              ) : (
                ""
              )}{" "}
            </Stack>
            <Grid item md={12} sm={12} xs={12}>
              <Stack width="130%">
                <AuthButton backgroundColor="#FF4D00D9" fontSize="13px" onClick={submitNewPassword}>
                  UPDATE PASSWORD
                </AuthButton>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

UserProfile.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  customerId: PropTypes.number.isRequired,
  refreshData: PropTypes.func.isRequired,
};

export default UserProfile;
