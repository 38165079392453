import { useLayoutEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
// import { useHistory } from "react-router-dom";
import axios from "axios";
import { Avatar, Button, Card, CardHeader, Grid, Icon, Slider } from "@mui/material";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CampaignImage from "assets/images/enrichment/campaign.svg";
import CreatedImage from "assets/images/enrichment/subscriptions.svg";
import StartDateImage from "assets/images/enrichment/start-date.svg";
import StatusImage from "assets/images/enrichment/status-img.svg";
import BookedImage from "assets/images/enrichment/booked-img.svg";
import ButtonImage1 from "assets/images/enrichment/button1.svg";
import SuiVectorMapRoot from "components/SuiVectorMap/SuiVectorMapRoot";
import Comment from "layouts/dashboards/oic/components/Comment";
import RapidApi from "./RapidApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "1rem",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

// const color = "secondary";

const OICModal = ({ campaignId, oicLeadId, open, handleClose, customerUser }) => {
  const [oicLead, setOICLead] = useState(null);
  const [isLeadActive, setIsLeadActive] = useState(true);
  const [isFavorite, setIsFavorite] = useState(null);
  const [rate, setRate] = useState(null);

  const [downloadCounter, setDownloadCounter] = useState(0);

  useLayoutEffect(() => {
    axios
      .get(`/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/oic/${oicLeadId}`)
      .then((res) => {
        setOICLead(res.data.data);
        setDownloadCounter(res.data.data.download_counter.toString());

        setIsFavorite(res.data.data.is_favorite);
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
    return () => {
      setOICLead(null);
    };
  }, [oicLeadId]);

  const handleDownloadPdf = () => {
    const payload = {
      is_oic_lead_approved_pdf: "YES",
    };

    if (oicLead.customer_write_permission) {
      axios
        .post(
          `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/oic/${oicLeadId}/download-oic-lead-pdf`,
          payload,
          {
            responseType: "blob",
          }
        )
        // .then((res) => res.blob())
        .then((res) => {
          const filename = `export_${oicLead.campaign_lead.lead_identificator}_oicLead_${new Date()
            .toJSON()
            .slice(0, 16)
            .replace(":", "_")}.pdf`;
          saveAs(res.data, filename);
          Swal.fire("Success!", res.data.message, "success");
          setDownloadCounter(parseInt(downloadCounter, 10) + 1);
        })
        .catch((err) => {
          Swal.fire("Error!", err.message, "error");
        });
    }
  };

  const handleDownloadCsv = () => {
    const payload = {
      is_oic_lead_approved_csv: "YES",
    };

    if (oicLead.customer_write_permission) {
      axios
        .post(
          `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/oic/${oicLeadId}/download-oic-lead-csv`,
          payload,
          {
            responseType: "blob",
          }
        )
        // .then((res) => res.blob())
        .then((res) => {
          const filename = `export_${oicLead.campaign_lead.lead_identificator}_oicLead_${new Date()
            .toJSON()
            .slice(0, 16)
            .replace(":", "_")}.csv`;
          saveAs(res.data, filename);
          Swal.fire("Success!", res.data.message, "success");
          setDownloadCounter(parseInt(downloadCounter, 10) + 1);
        })
        .catch((err) => {
          Swal.fire("Error!", err.message, "error");
        });
    }
  };

  const handleDownloadXls = () => {
    const payload = {
      is_oic_lead_approved_csv: "YES",
    };

    if (oicLead.customer_write_permission) {
      axios
        .post(
          `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/oic/${oicLeadId}/download-oic-lead-xls`,
          payload,
          {
            responseType: "blob",
          }
        )
        // .then((res) => res.blob())
        .then((res) => {
          const filename = `export_${oicLead.campaign_lead.lead_identificator}_oicLead_${new Date()
            .toJSON()
            .slice(0, 16)
            .replace(":", "_")}.xls`;
          saveAs(res.data, filename);
          Swal.fire("Success!", res.data.message, "success");
          setDownloadCounter(parseInt(downloadCounter, 10) + 1);
        })
        .catch((err) => {
          Swal.fire("Error!", err.message, "error");
        });
    }
  };

  const handleUpdateFavorite = (e) => {
    e.persist();
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/oic/${oicLeadId}/update-favorite-status`
      )
      .then((res) => {
        const oicLeadRes = res.data;
        setIsFavorite(oicLeadRes.value);
        // Swal.fire("Success!", oicLeadRes.message, "success");
      })
      .catch(() => {
        // Swal.fire("Error!", err.message, "error");
      });
  };

  const changeRate = (pRate) => {
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/oic/${oicLeadId}/rate`,
        { rate: pRate }
      )
      .then(() => {
        // Swal.fire("Success!", res.data.message, "success");
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
  };

  const handleRate = (e, value) => {
    if (value === rate) return;
    setRate(value);
    changeRate(value);
  };

  return oicLead ? (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <SuiBox sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <Grid container spacing={3} direction="row">
            <Grid item sm={6}>
              <SuiBox display="flex" justifyContent="flex-start">
                <span pl={3}>OIC Lead</span>
              </SuiBox>
            </Grid>
            <Grid item sm={6}>
              <SuiBox display="flex" justifyContent="flex-end">
                <Button
                  onClick={handleClose}
                  sx={{
                    backgroundColor: "rgb(52, 71, 103)",
                    color: "#FFFFFF",
                    padding: "12px",
                    ":hover": {
                      backgroundColor: "rgb(52, 71, 103)",
                      color: "#FFFFFF",
                      padding: "12px",
                    },
                  }}
                >
                  <Icon>close</Icon>
                </Button>
              </SuiBox>
            </Grid>
          </Grid>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, height: "78vh", overflow: "auto" }}>
          <SuiBox pb={3}>
            <Grid container spacing={3} mb={2}>
              <Grid item xs={12} md={6} lg={3} xl={4}>
                <SuiBox p={1}>
                  <CardHeader
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    avatar={<Avatar aria-label="recipe" src={CampaignImage} />}
                    title={
                      <Typography sx={{ fontSize: "14px" }} component="div" fontWeight="semibold">
                        Campaign Name
                      </Typography>
                    }
                    subheader={
                      <Typography variant="h6" component="div" fontWeight="semibold">
                        {oicLead.campaign_name !== null ? oicLead.campaign_name.toString() : "-"}
                      </Typography>
                    }
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4}>
                <SuiBox p={1}>
                  <CardHeader
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        src={CreatedImage}
                        sx={{
                          borderRadius: "0",
                        }}
                      />
                    }
                    title={
                      <Typography sx={{ fontSize: "14px" }} component="div" fontWeight="semibold">
                        Created
                      </Typography>
                    }
                    subheader={
                      <Typography variant="h6" component="div" fontWeight="semibold">
                        {oicLead.campaign_created !== null
                          ? oicLead.campaign_created.toString()
                          : null}
                      </Typography>
                    }
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4}>
                <SuiBox p={1}>
                  <CardHeader
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        src={StartDateImage}
                        sx={{
                          borderRadius: "0",
                        }}
                      />
                    }
                    title={
                      <Typography sx={{ fontSize: "14px" }} component="div" fontWeight="semibold">
                        Start Date
                      </Typography>
                    }
                    subheader={
                      <Typography variant="h6" component="div" fontWeight="semibold">
                        {oicLead.campaign_start_date !== null
                          ? oicLead.campaign_start_date.toString()
                          : null}
                      </Typography>
                    }
                  />
                </SuiBox>
              </Grid>
            </Grid>

            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} md={7} lg={4} xl={4}>
                <SuiBox p={1}>
                  <CardHeader
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        src={StatusImage}
                        sx={{
                          borderRadius: "0",
                        }}
                      />
                    }
                    title={
                      <Typography sx={{ fontSize: "14px" }} component="div" fontWeight="semibold">
                        Status
                      </Typography>
                    }
                    subheader={
                      <Typography variant="h6" component="div" fontWeight="semibold">
                        {oicLead.campaign_status !== null
                          ? oicLead.campaign_status.toString().toUpperCase().replace("_", " ")
                          : null}
                      </Typography>
                    }
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12} md={7} lg={4} xl={4}>
                <SuiBox p={1}>
                  <CardHeader
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        src={BookedImage}
                        sx={{
                          borderRadius: "0",
                        }}
                      />
                    }
                    title={
                      <Typography sx={{ fontSize: "14px" }} component="div" fontWeight="semibold">
                        Booked on
                      </Typography>
                    }
                    subheader={
                      <Typography variant="h6" component="div" fontWeight="semibold">
                        {oicLead.created_at ? oicLead.created_at.toString() : "-"}
                      </Typography>
                    }
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12} md={7} lg={4} xl={4}>
                <SuiBox p={1}>
                  <CardHeader
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    title={
                      <Typography sx={{ fontSize: "14px" }} component="div" fontWeight="semibold">
                        Info
                      </Typography>
                    }
                    subheader={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{ fontSize: "18px", fontWeight: "500", marginRight: "20px" }}
                          component="div"
                          fontWeight="semibold"
                        >
                          {downloadCounter}
                        </Typography>

                        <Button
                          sx={{
                            width: "10px",
                            marginRight: "8px",
                            backgroundColor: "#FF7701",
                            minWidth: "0",
                            ":hover": {
                              backgroundColor: "#FF7701",
                            },
                          }}
                          variant="contained"
                          endIcon={<ArrowDownwardIcon />}
                          onClick={() => handleDownloadPdf()}
                        >
                          PDF
                        </Button>
                        <Button
                          sx={{
                            width: "8px",
                            marginRight: "8px",
                            backgroundColor: "#FF7701",
                            minWidth: "0",
                            ":hover": {
                              backgroundColor: "#FF7701",
                            },
                          }}
                          variant="contained"
                          endIcon={<ArrowDownwardIcon />}
                          onClick={() => handleDownloadCsv()}
                        >
                          CSV
                        </Button>
                        <Button
                          sx={{
                            width: "10px",
                            marginRight: "8px",
                            backgroundColor: "#FF7701",
                            minWidth: "0",
                            ":hover": {
                              backgroundColor: "#FF7701",
                            },
                          }}
                          variant="contained"
                          endIcon={<ArrowDownwardIcon />}
                          onClick={() => handleDownloadXls()}
                        >
                          XLS
                        </Button>
                      </div>
                    }
                  />
                </SuiBox>
              </Grid>
              <Grid container spacing={3} mb={2} justifyContent="space-between" alignItems="center">
                <Grid item xs={12} md={6}>
                  {rate ? (
                    <Slider
                      aria-label="Rating"
                      defaultValue={rate}
                      // getAriaValueText={handleRate}
                      valueLabelDisplay="auto"
                      // valueLabelDisplay="on"
                      step={25}
                      marks
                      min={25}
                      max={100}
                      color="secondary"
                      onChangeCommitted={handleRate}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={3} margin="2%">
                  <Button
                    onClick={() => {
                      setIsLeadActive(true);
                    }}
                    sx={{
                      width: "100%",
                      paddingY: "6%",
                      backgroundColor: "#2659B9",
                      "&:hover": {
                        backgroundColor: "#2659B9",
                      },
                      "&:focus:not(:hover)": {
                        backgroundColor: "#2659B9",
                        boxShadow: "0rem 0rem 0rem 0.1rem rgb(53 53 53 / 50%)",
                      },
                      "& .MuiButton-endIcon": {
                        display: "none",
                      },
                    }}
                    variant="contained"
                  >
                    <span style={{ fontSize: "1.4rem", fontWeight: 400, color: "white" }}>
                      Lead
                    </span>
                    <img
                      src={ButtonImage1}
                      alt="Lead Button"
                      style={{ marginLeft: "8px", width: "20px", height: "20px" }}
                    />
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                {isLeadActive ? (
                  <Grid item xs={12} lg={8} md={8} mb={3}>
                    <Card>
                      <Grid item xs={12} lg={12} md={12} mb={3} paddingLeft={5}>
                        <Typography width="90%" mb={4} gutterBottom variant="h5" component="div">
                          <Grid container spacing={3} direction="row">
                            <Grid item sm={6}>
                              <SuiBox display="flex" justifyContent="flex-start">
                                <span pl={3}> Lead Info</span>
                              </SuiBox>
                            </Grid>
                            <Grid item sm={6}>
                              <SuiBox display="flex" justifyContent="flex-end">
                                <Icon
                                  onClick={handleUpdateFavorite}
                                  fontSize="medium"
                                  sx={{ cursor: "pointer" }}
                                  color={isFavorite ? "warning" : "light"}
                                >
                                  thumb_up
                                </Icon>
                              </SuiBox>
                            </Grid>
                          </Grid>
                        </Typography>
                        <SuiBox mb={3}>
                          <Typography variant="body1" color="text.secondary">
                            <Grid sx={{ marginTop: "1px" }} container spacing={4}>
                              {[
                                "Firmenname",
                                "Telefon",
                                "Letzter Kontakt",
                                "Name",
                                "EMail",
                                "PLZ",
                                "ORT",
                                "Straße",
                                "Mobil",
                                "Domain - URL - Website",
                              ].map((desiredKey) => {
                                const keyMapping = {
                                  Firmenname: "company-name_1577967963",
                                  Mobil: "mobil-1574245529",
                                  PLZ: "zip-1574245433",
                                  EMail: "email-1574245510",
                                  ORT: "ort-1574245453",
                                  Name: "name-1574245387",
                                  "Domain - URL - Website": "domain_1577994166",
                                  Straße: "strasse-1574245420",
                                  Telefon: "telefon-1574245499",
                                  "Letzter Kontakt": "letzter-kontakt_1624447809",
                                };

                                const originalKey = keyMapping[desiredKey];
                                const value = oicLead.campaign_lead.lead_info[originalKey];

                                return value !== null ? (
                                  <Grid item xs={6} key={desiredKey}>
                                    <Typography
                                      fontFamily="Poppins"
                                      fontWeight="700"
                                      fontSize="16px"
                                      color="#4A4A4A"
                                    >
                                      {desiredKey}
                                    </Typography>

                                    <Typography
                                      fontFamily="Poppins"
                                      fontSize="14px"
                                      color="#5F5F5F"
                                    >
                                      {desiredKey === "Domain - URL - Website" ? (
                                        <a
                                          href={`http://${value}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {value}
                                        </a>
                                      ) : (
                                        value
                                      )}
                                    </Typography>
                                  </Grid>
                                ) : null;
                              })}
                            </Grid>
                          </Typography>
                        </SuiBox>
                      </Grid>
                    </Card>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <RapidApi oicLead={(oicLead, customerUser)} />
                  </Grid>
                )}
                {oicLead ? (
                  <Grid item xs={12} lg={4} mb={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <SuiBox mb={3}>
                          <SuiVectorMapRoot
                            appointmentsOrOICLeads={[oicLead]}
                            fetchCampaignOICLeadsOrOics={null}
                            openAppointmetModal={null}
                            campaignType={oicLead.campaign_type}
                            campaignId={oicLead.campaign_id}
                          />
                        </SuiBox>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
              {oicLead.customer_write_permission && (
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Comment oicLead={oicLead} customerUser={customerUser} campaignId={campaignId} />
                </Grid>
              )}
            </Grid>
          </SuiBox>
        </Typography>
      </SuiBox>
    </Modal>
  ) : (
    ""
  );
};

OICModal.propTypes = {
  campaignId: PropTypes.number.isRequired,
  oicLeadId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  customerUser: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default OICModal;
