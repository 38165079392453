import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar/Navbar";
import Footer from "examples/Footer";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect, useState, useContext } from "react";
import { dateToYMD } from "common/helpers";
import { CustomerUserContext } from "App";
import DynamicTabInfo from "./DynamicTabInfo";

const ROIData = () => {
  const [customerUser] = useContext(CustomerUserContext);
  const [today, setToday] = useState(new Date());

  function CustomTabPanel(props) {
    const { children, value, index } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  useEffect(() => {
    setToday(dateToYMD(new Date()));

    return () => {};
  }, [today]);

  const renderCampaignItems = () => <DynamicTabInfo customerUser={customerUser} />;

  return (
    <DashboardLayout>
      <DashboardNavbar componentName="ROI" />
      <SuiBox>
        <Grid container spacing={3} sx={{ zIndex: 100 }}>
          <Grid item xs={12} lg={12} mb={3}>
            {renderCampaignItems()}
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
};

export default ROIData;
