const ADMIN = "customer_admin";
const USER = "customer_user";
const BUSINESS_PARTNER = "customer_business_partner";

const CustomerUserRoles = {
  ADMIN,
  USER,
  BUSINESS_PARTNER,
};

export default CustomerUserRoles;
