/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import axios from "axios";
import PropTypes from "prop-types";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box, Button, CardContent, CircularProgress, Stack, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import avatarChat from "../../../../../assets/images/avatarChat.svg";
import blackUser from "../../../../../assets/images/blackUser.svg";
import Editor from "./Editor";

const theme = createTheme({
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: "#FF7701",
        },
      },
    },
  },
});

function InternalNote({ appointment, customerUser }) {
  const [showEditor, setShowEditor] = useState([]);
  const [notes, setNotes] = useState([]);
  const containerRef = useRef(null);
  const [fetchLoading, setFetchLoading] = useState(false);

  const fetchNotes = () => {
    axios
      .get(
        `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${appointment.campaign_lead.campaign_id}/appointments/${appointment.id}/comments`
      )
      .then((res) => {
        const notesRes = res.data.data.map((r) => ({
          image1: avatarChat,
          image2: blackUser,
          id: r.id,
          authorId: r.author_id,
          name: r.author,
          message: r.note,
          createdAt: r.created_at,
        }));
        setNotes(notesRes);
        setFetchLoading(false);
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      })
      .catch(() => {
        setNotes([]);
        setFetchLoading(false);
      });
  };

  const [forceRerender, setForceRerender] = useState(false);

  const forceUpdateComponent = (id) => {
    setShowEditor(showEditor.filter((k) => k !== id));
    setForceRerender((prev) => !prev);
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  };

  useEffect(() => {
    // Scroll to the top of the container after rendering
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, []); // This effect runs only once after initial rendering

  useEffect(() => {
    fetchNotes();
    return () => {};
  }, [appointment, forceRerender]);

  const handleDelete = (id) => {
    axios
      .delete(
        `api/customers/${customerUser.customerUser.customer.id}/campaigns/${appointment.campaign_lead.campaign_id}/appointments/${appointment.id}/comments/${id}/delete`
      )
      .then(() => {
        forceUpdateComponent(id);
      })
      .catch(() => {});
  };

  const useStyles = makeStyles(() => ({
    footer: {
      paddingTop: "1%",
      display: "flex",
      justifyContent: "flex-end",
    },
    input: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      backgroundColor: "white",
      paddingTop: "15px",
      // border: "1px solid #D1D5DB",

      // borderRadius: "35px",
      marginRight: "3%",
      marginLeft: "3%",
    },
    placeholder: {
      border: "none",
      width: "80%",
      padding: "1% 0 1% 0",
      background: "transparent",
      "&:focus": {
        border: "0",
        outline: "none",
      },
    },
    sendButton: {
      backgroundColor: "#757575",
      // border: "1px solid",
      cursor: "pointer",
      marginRight: "0%",
      color: "red",
      // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      "&:hover": {
        backgroundColor: "#666", // Change color on hover if needed
      },
    },
    header: {
      padding: "1%",
      fontSize: "15px",
      fontWeight: "bold",
      color: "#000000",
      backgroundColor: "#F0F0F0",
      textAlign: "center",
    },
    cardContent: { margin: "0", padding: "0" },
    card: {
      borderRadius: "0px 0px 3.5px 3.5px",
      marginBottom: "5%",
      paddingBottom: "2%",
      // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    },
    body: {
      padding: "5%",
      maxWidth: "100%",
      maxHeight: "300px",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
    },
    stackMessages: {
      width: "100%",
      minWidth: "230px",
      padding: "10px 7px 10px 15px",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "row",
      wordBreak: "break-word",
      overflowWrap: "normal",
    },
    messages: {
      border: "none",
      fontSize: "12px",
      fontWeight: "400",
      fontFamily: "Poppins",
      color: "#4A4A4A",
    },
  }));

  const classes = useStyles();

  const checkShowEditorIdValExists = (id = null) => {
    if (id) {
      if (showEditor.includes(id)) {
        setShowEditor(showEditor.filter((k) => k !== id));
      } else {
        setShowEditor([...showEditor, id]);
      }
    }
    console.log(id, showEditor.includes(id), showEditor);
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.header} variant="h6">
          Appointment Note
        </Typography>
        <Box className={classes.body} ref={containerRef}>
          {fetchLoading ? (
            <ThemeProvider theme={theme}>
              <Stack mt="4%" flexDirection="column" alignItems="center">
                <CircularProgress size="7%" />
              </Stack>
            </ThemeProvider>
          ) : notes.length === 0 ? (
            <Typography sx={{ textAlign: "center", mt: 2, fontSize: "13px" }} variant="body2">
              No Appointment note available
            </Typography>
          ) : (
            notes.map(({ image1, image2, id, name, message, authorId, createdAt }) => (
              <Box
                key={id}
                display="flex"
                alignItems={name === "me" ? "flex-end" : "flex-start"}
                flexDirection="column"
                mt={2}
              >
                <Box
                  className={classes.boxMessages}
                  sx={
                    name === "me"
                      ? {
                          display: "flex",
                          flexDirection: "row-reverse",
                          gap: "15px",
                        }
                      : {
                          display: "flex",
                          flexDirection: "row",
                          gap: "15px",
                        }
                  }
                >
                  <img
                    style={{ marginBottom: "20px" }}
                    src={name === "me" ? image2 : image1}
                    alt={name}
                  />
                  <Stack
                    direction="column"
                    sx={name === "me" ? { alignItems: "flex-end" } : { alignItems: "flex-start" }}
                  >
                    <Stack
                      sx={
                        name === "me"
                          ? { backgroundColor: "#FF4D00D9" }
                          : { backgroundColor: "#F7F7F7" }
                      }
                      className={classes.stackMessages}
                    >
                      {showEditor.includes(id) &&
                      authorId === JSON.parse(atob(localStorage.getItem("auth_user"))).id ? (
                        <Editor
                          noteId={id}
                          message={message}
                          appointment={appointment}
                          customerUser={customerUser}
                          forceUpdateComponent={forceUpdateComponent}
                        />
                      ) : (
                        <Typography
                          sx={
                            name === "me"
                              ? { color: "white !important", paddingRight: "10px" }
                              : { color: "#4A4A4A !important", paddingRight: "10px" }
                          }
                          className={classes.messages}
                        >
                          {ReactHtmlParser(message)}
                        </Typography>
                      )}
                    </Stack>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {authorId === JSON.parse(atob(localStorage.getItem("auth_user"))).id ? (
                        <div className="row">
                          <Button
                            onClick={() => checkShowEditorIdValExists(id)}
                            sx={{
                              padding: "0",
                              marginRight: "5px",
                              fontSize: "10px",
                              minWidth: "0",
                              color: "#919191",
                              "&:hover": {
                                color: "gray",
                              },
                            }}
                          >
                            <span className="material-icons-round">edit</span>
                          </Button>
                          <Button
                            onClick={() => handleDelete(id)}
                            sx={{
                              fontSize: "10px",
                              minWidth: "0",
                              color: "primary.main",
                              padding: "0",
                              margin: "0",
                            }}
                          >
                            <span className="material-icons-round">delete</span>
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                      <Typography fontSize="11px" marginLeft={2} marginTop={1}>
                        {createdAt}
                      </Typography>
                    </div>
                  </Stack>
                </Box>
              </Box>
            ))
          )}
        </Box>
        <Editor
          appointment={appointment}
          customerUser={customerUser}
          forceUpdateComponent={forceUpdateComponent}
        />
      </CardContent>
    </Card>
  );
}

export default InternalNote;

InternalNote.propTypes = {
  appointment: PropTypes.objectOf(PropTypes.object).isRequired,
  customerUser: PropTypes.objectOf(PropTypes.object).isRequired,
};
