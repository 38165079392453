/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import { useEffect, useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import axios from "axios";
import PropTypes from "prop-types";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box, CardContent, CircularProgress, Grid, Icon, Stack, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Swal from "sweetalert2";
import Echo from "laravel-echo";
import "./Chat.css";
import { pusherOptions } from "common/helpers";
import ReactQuill from "react-quill";
import avatarChat from "../../../../assets/images/avatarChat.svg";
import blackUser from "../../../../assets/images/blackUser.svg";
import PaperPlane from "../../../../assets/images/authentication_v2/PaperPlane.svg";

const theme = createTheme({
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: "#FF7701",
        },
      },
    },
  },
});

function InternalNote({ appointment, customerUser }) {
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [sendLoading, setSendLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [forceRerender, setForceRerender] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const inputRef = useRef(null);

  const fetchNotes = () => {
    axios
      .get(
        `/api/customers/${customerUser.customerUser.customer.id}/campaign-leads/${appointment.campaign_lead.id}/notes/mark-notes-as-read`
      )
      .then(() => {
        setFetchLoading(false);
      })
      .catch(() => {
        setFetchLoading(false);
      });
    setFetchLoading(true);

    axios
      .get(
        `/api/customers/${customerUser.customerUser.customer.id}/campaign-leads/${appointment.campaign_lead.id}/notes/order-by/desc`
      )
      .then((res) => {
        const notesRes = res.data.data.map((r) => ({
          image1: avatarChat,
          image2: blackUser,
          id: r.id,
          authorId: r.author_id,
          name: r.author,
          message: r.message,
          createdAt: r.created_at,
          canBeDeleted: r.can_be_deleted,
          attachments: r.attachments,
        }));
        setNotes(notesRes);
        setFetchLoading(false);
      })
      .catch(() => {
        setNotes([]);
        setFetchLoading(false);
      });
  };

  const eraseNote = (id) => {
    axios
      .delete(
        `/api/customers/${customerUser.customerUser.customer.id}/campaign-leads/${appointment.campaign_lead.id}/notes/${id}/delete`
      )
      .then(() => {
        fetchNotes();
      })
      .catch(() => {
        Swal.fire({
          text: "You can not perform this action",
          icon: "error",
        });
      });
  };
  const handleImagesUpload = (event) => {
    const files = Array.from(event.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const forceUpdateComponent = () => {
    setForceRerender((prev) => !prev);
  };

  const onSubmitNote = () => {
    setSendLoading(true);
    const payload = new FormData();
    payload.append("note", note);

    images.forEach((image) => {
      payload.append("images[]", image);
    });

    axios
      .post(
        `/api/customers/${customerUser.customerUser.customer.id}/campaign-leads/${appointment.campaign_lead.id}/create-note`,
        payload
      )
      .then(() => {
        setSendLoading(false);
        fetchNotes();
        setNote("");

        if (inputRef.current) {
          inputRef.current.value = "";
        }
        setImages([]);
        forceUpdateComponent();
      })
      .catch(() => {
        setSendLoading(false);
        fetchNotes();
        setNote("");
        if (inputRef.current) {
          inputRef.current.value = "";
        }
        setImages([]);
      });
  };
  useEffect(() => {
    const echo = new Echo(pusherOptions);

    echo
      .private(`chat.customer.${customerUser.customerUser.customer.id}`)
      .listen(".new-note", (e) => {
        if (
          e.customer_id === customerUser.customerUser.customer.id &&
          e.note.author_id !== JSON.parse(atob(localStorage.getItem("auth_user"))).id &&
          e.note.campaign_lead_id === appointment.campaign_lead.id
        ) {
          const r = e.note;
          setNotes((current) => [
            {
              image1: avatarChat,
              id: r.id,
              name: "Agent",
              authorId: r.author_id,
              message: r.message,
              createdAt: new Date(r.created_at).toLocaleString("en-US", {
                weekday: "short",
                month: "short",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              }),
              canBeDeleted: false,
              attachments: r.attachments,
            },
            ...current,
          ]);
        }
      });
    return () => {
      echo.leave(`chat.customer.${customerUser.customerUser.customer.id}`);
    };
  }, []);

  useEffect(() => {
    fetchNotes();
    const echo = new Echo(pusherOptions);
    echo
      .join("online")
      .here((users) => {
        const usersIds = [];
        users.forEach(({ id }) => {
          usersIds.push(id);
        });
        setOnlineUsers(usersIds);
      })
      .joining((user) => {
        setOnlineUsers((oldArray) => [...oldArray, user.id]);
      })
      .leaving((user) => {
        const usersIds = onlineUsers.filter((id) => id !== user.id);
        setOnlineUsers(usersIds);
      });
    return () => {};
  }, [appointment, forceRerender]);

  const useStyles = makeStyles(() => ({
    footer: {
      paddingTop: "1%",
      display: "flex",
      justifyContent: "flex-end",
    },
    input: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      backgroundColor: "white",
      paddingTop: "15px",
      // border: "1px solid #D1D5DB",
      // borderRadius: "35px",
      marginRight: "3%",
      marginLeft: "3%",
    },
    placeholder: {
      border: "none",
      width: "80%",
      padding: "1% 0 1% 0",
      background: "transparent",
      "&:focus": {
        border: "0",
        outline: "none",
      },
    },
    sendButton: {
      background: "transparent",
      border: "0",
      cursor: "pointer",
      marginLeft: "5px",
    },
    header: {
      padding: "1%",
      fontSize: "15px",
      fontWeight: "bold",
      color: "#000000",
      backgroundColor: "#F0F0F0",
      textAlign: "center",
    },
    cardContent: { margin: "0", padding: "0" },
    card: {
      borderRadius: "0px 0px 3.5px 3.5px",
      marginBottom: "5%",
      paddingBottom: "2%",
      // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    },
    body: {
      padding: "5%",
      maxWidth: "100%",
      maxHeight: "200px",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column-reverse",
    },
    stackMessages: {
      width: "100%",
      minWidth: "230px",
      padding: "10px 7px 10px 15px",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "row",
      wordBreak: "break-word",
      overflowWrap: "normal",
    },
    messages: {
      border: "none",
      fontSize: "12px",
      fontWeight: "400",
      fontFamily: "Poppins",
      color: "#4A4A4A",
    },
  }));

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.header} variant="h6">
          Messages
        </Typography>
        <Box className={classes.body}>
          {fetchLoading ? (
            <ThemeProvider theme={theme}>
              <Stack mt="4%" flexDirection="column" alignItems="center">
                <CircularProgress size="7%" />
              </Stack>
            </ThemeProvider>
          ) : notes.length === 0 ? (
            <Stack mt="4%" flexDirection="column" alignItems="center">
              <Typography sx={{ textAlign: "center", mt: 2, fontSize: "13px" }}>
                No messages available
              </Typography>
            </Stack>
          ) : (
            notes.map(
              ({
                image1,
                image2,
                id,
                name,
                message,
                canBeDeleted,
                createdAt,
                authorId,
                attachments,
              }) => (
                <Box
                  key={id}
                  display="flex"
                  alignItems={name === "me" ? "flex-end" : "flex-start"}
                  flexDirection="column"
                  mt={2}
                >
                  <Box
                    className={classes.boxMessages}
                    sx={
                      name === "me"
                        ? {
                            display: "flex",
                            flexDirection: "row-reverse",
                            gap: "15px",
                          }
                        : {
                            display: "flex",
                            flexDirection: "row",
                            gap: "15px",
                          }
                    }
                  >
                    <div style={{ position: "relative" }}>
                      {name !== "me" && (
                        <div
                          className={
                            onlineUsers.includes(authorId)
                              ? "online-indicator"
                              : "offline-indicator"
                          }
                        >
                          <span className="blink" />
                        </div>
                      )}
                      <img
                        style={{ marginBottom: "20px" }}
                        src={name === "me" ? image2 : image1}
                        alt={name}
                      />
                    </div>
                    <Stack
                      direction="column"
                      sx={name === "me" ? { alignItems: "flex-end" } : { alignItems: "flex-start" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          width: "100%",
                        }}
                      >
                        {message !== null && (
                          <Stack
                            sx={
                              name === "me"
                                ? { backgroundColor: "#FF4D00D9" }
                                : { backgroundColor: "#F7F7F7" }
                            }
                            className={classes.stackMessages}
                          >
                            <Typography
                              sx={{
                                color: name === "me" ? "white !important" : "#4A4A4A !important",
                              }}
                              className={classes.messages}
                            >
                              {ReactHtmlParser(message)}
                            </Typography>
                          </Stack>
                        )}

                        {attachments.length !== 0 && (
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            {attachments.map((img) => (
                              <a
                                key={img.id}
                                href={`${process.env.REACT_APP_API_URL}${img.url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ margin: "5px 0" }}
                              >
                                <img
                                  key={img.id}
                                  src={`${process.env.REACT_APP_API_URL}${img.url}`}
                                  alt={img.filename}
                                  style={{
                                    width: "200px",
                                    height: "150px",
                                    margin: "5px 0",
                                  }}
                                />
                              </a>
                            ))}
                            <Typography fontSize="11px">{createdAt}</Typography>
                          </div>
                        )}
                      </div>
                    </Stack>
                    <Stack flexDirection="row" alignItems="center">
                      {canBeDeleted ? (
                        <Typography onClick={() => eraseNote(id)} sx={{ cursor: "pointer" }}>
                          <Icon sx={{ color: "#5F5F5FCC", fontSize: "15px" }}>delete</Icon>
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Stack>
                  </Box>
                </Box>
              )
            )
          )}
        </Box>
        <Box className={classes.footer}>
          <form className={classes.input}>
            <Grid container flexDirection="column">
              <Grid item>
                <ReactQuill
                  style={{ width: "100%" }}
                  theme="snow"
                  ref={inputRef}
                  value={note}
                  onChange={setNote}
                />
              </Grid>
              <Grid container spacing={1} mt={2}>
                {images.map((image, index) => (
                  <Grid item key={index}>
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`preview-${index}`}
                      style={{ maxWidth: "100px", maxHeight: "100px", margin: "5px" }}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid item sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Stack direction="row" textAlign="center" alignItems="center">
                  <Grid item sx={{ textAlign: "right" }}>
                    <input
                      type="file"
                      name="images"
                      id="file-upload"
                      style={{ display: "none" }}
                      multiple
                      accept="image/png, image/jpeg"
                      onChange={handleImagesUpload}
                    />
                    <label
                      htmlFor="file-upload"
                      style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                    >
                      {/* {upload} */}
                      <CloudUploadIcon
                        style={{ color: "#FF4D00D9", marginRight: "10px" }}
                        size="100%"
                      />
                    </label>
                  </Grid>
                  <Grid mt={1}>
                    {sendLoading ? (
                      <ThemeProvider theme={theme}>
                        <Stack width="3%" ml="auto" mt={1}>
                          <CircularProgress size="100%" />
                        </Stack>
                      </ThemeProvider>
                    ) : (
                      <button className={classes.sendButton} type="submit" onClick={onSubmitNote}>
                        <img src={PaperPlane} alt="Send" />
                      </button>
                    )}
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}

export default InternalNote;

InternalNote.propTypes = {
  appointment: PropTypes.objectOf(PropTypes.object).isRequired,
  customerUser: PropTypes.objectOf(PropTypes.object).isRequired,
};
